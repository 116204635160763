import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux'
import DropzoneComponent from '@modul-connect/shared/components/molecules/DropZone/dropZoneComponent'
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import View from '@modul-connect/shared/components/atoms/view'
import theme from '@modul-connect/shared/theme';
import CloudUpload from '@modul-connect/shared/img/cloud_upload.svg'
import { Link } from "@modul-connect/shared/components/atoms/text/text";
import IconButton from "@modul-connect/shared/components/atoms/iconButton/iconButton";
import { ModalBase } from "@modul-connect/shared/components/molecules/modal/ModalBase";
import { useAuth0 } from '@auth0/auth0-react'
import { uploadDocument } from "../../../../state/actions/vehicles";

const UploadFileModal = ({
  open,
  onClose,
  onSave,
  acceptedFileTypes,
  onFileUploaded, 
  textForAcceptedFileTypes,
  pdfOnly,
  imageOnly,
  title,
  updateFileOnChange,
  uploadingStatus,
  uploadDocument
}) => {

  const fileInputRef = useRef(null);
  const previewRef = useRef(null);

  const [isUploaded, setIsUploaded] = useState(false)
  const [file, setFile] = useState(false)
  const [showMultipleFilesError, setShowMultipleFilesError] = useState(false)

  useEffect(() => {
    setShowMultipleFilesError(false)
    setIsUploaded(false)
    setFile(false)
  }, [open])

  const handleWordClick = () => {
    if(fileInputRef.current) return
    fileInputRef.current.click();
  };

  const { getAccessTokenSilently } = useAuth0()

  const sendUploadDocument = (file) => {
    if(!file) return

    let formData = new FormData()
        formData.append("pdf", file)
    getAccessTokenSilently()
    .then((accessToken) =>
    {
      uploadDocument(accessToken, formData)
    })
  }

  useEffect(() => {
    if (pdfOnly) sendUploadDocument(file)
    if(updateFileOnChange) onFileUploaded && onFileUploaded(file)
  }, [file])

  const handleFileChange = (e) => {
    console.log('handleFileChange!', e)
    if(e.length > 1) {
      setShowMultipleFilesError(true)
      return
    }

    setShowMultipleFilesError(false)
    const selectedFile = e?.[0]
    console.log('selectedFile', selectedFile)
    setIsUploaded(false)

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (previewRef?.current) previewRef.current.src = reader.result;
      };

      reader.readAsDataURL(selectedFile);
      setIsUploaded(true)
      console.log('setFile', selectedFile)
      setFile(selectedFile)
    }
  };

  useEffect(() => {
    if (uploadingStatus === 'failed') {
      setFile(false)
      setIsUploaded(false)
    }
  }, [uploadingStatus])

  const CloudUploadIcon = <img src={CloudUpload} />
  const isUploading = uploadingStatus === "uploading"
  const disabled = pdfOnly ? !file : !isUploaded

  const ClickableUpload = ({}) => {
    return (
      <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }}>
        <View style={{color: theme.colors.warningIcon}}>
          { showMultipleFilesError ? 
            <View style={{display : 'flex', flexDirection: 'column', justifyContent: 'center', alignItems : 'center'}}>
              <View >
                {'Invalid number of files selected.'  }
              </View>
              <View>
                {'Please select no more than one file'}
              </View>
            </View>
            : null}
        </View>
        <View>
          {
            isUploaded && imageOnly ?
              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                <View>
                  {'Selected image'}
                </View>
                <img
                  ref={previewRef}
                  alt="File Preview"
                  style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '300px' }}
                />
              </View>
              : (isUploaded && !imageOnly) ? <View style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <Link>{file.name}</Link>
                <IconButton
                  icon={'DeleteX'}
                  extend={{ zIndex: 100 }}
                  v2
                  onClick={() => {
                    setIsUploaded(false)
                    setFile(false)
                  }}
                />
              </View>
              : CloudUploadIcon
          }
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <View>
            {'Drop your file here or '}
          </View>
          <View style={{
            color: theme.colors.blue,
            textDecorationLine: 'underline',
          }}
          //  onClick={handleWordClick}
          >
            {' browse for file'}
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
              disabled={isUploading}
              accept={acceptedFileTypes ?? ".jpeg,.png,.jpg" }
            />
          </View>
        </View>
        
        <View>
          {textForAcceptedFileTypes ? textForAcceptedFileTypes : null }
        </View>
      </View>
    )
  }
  return (
    <ModalBase
      dropShadow
      open={open}
      onClose={(event, reason) => () => onClose()}
    >
      <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: '5%', gap: 30, height: '100%', width: '100%' }}>

        <DropzoneComponent
          pdfOnly={pdfOnly}
          imageOnly={imageOnly}
          onFileDropped={(file) => { handleFileChange(file) }}
          customChildComponent={<ClickableUpload/>}
        />

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
          <Button
            extend={{ width: '30%' }}
            disabled={disabled}
            processing={isUploading}
            onClick={() => {
              onSave && onSave();
              onFileUploaded && onFileUploaded(file)
            }}
          >
            {'Save'}
          </Button>

          <Button
            border
            noBg
            onClick={() => {
              onClose()
            }}
          >
            {'Cancel'}
          </Button>
        </View>

      </View>
    </ModalBase>
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  uploadDocument: (accessToken, file) => dispatch(uploadDocument(accessToken, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileModal);