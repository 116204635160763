export const fetchUsers = (accessToken, options) => (
  {
    type: 'FETCH_USERS',
    accessToken: accessToken,
    options: options
  }
)

export const fetchAuthenticatedUser = (accessToken, email) => (
  {
    type: 'FETCH_AUTHENTICATED_USER',
    accessToken: accessToken,
    email: email
  }
)

export const addUser = (accessToken, info) => (
  {
    type: 'ADD_USER',
    accessToken: accessToken,
    firstName: info.firstName,
    lastName: info.lastName,
    email: info.email,
    tags: info.tags,
    tagName: info.tagName
  }
)

export const removeUser = (accessToken, email, tags) => (
  {
    type: 'REMOVE_USER',
    accessToken: accessToken,
    email: email,
    tags: tags
  }
)

export const userIsLoggedIn = () => ({
  type: 'USER_LOGGED_IN'
})