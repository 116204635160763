import DropdownTreeSelect from 'react-dropdown-tree-select'
import React, { useState, useEffect } from 'react'
import './dropdownTree.css'

const DropdownTree = ({ data, title, inlineSearchInput, onSelect, onToggle, mobile }) => {

    let searchState = false
    const [search, setSearch] = useState(searchState)

   /*  const onPressKey = e => {
      if (e.key === 'Home') {
        searchState = !searchState
        setSearch(searchState)
      }
    }

    useEffect(() => {
      window.addEventListener('keydown', onPressKey)
      return () => window.removeEventListener('keydown', onPressKey)
    }, [title]) */

    return (
      (<DropdownTreeSelect
        className={( /* mobile ? 'dropdown-menu-mobile' :  */("dropdown-menu")) + (search ? ' dropdown-search' : ' dropdown-not-search')}
        inlineSearchInput={ inlineSearchInput }
        texts={ { placeholder: title } }
        data={ data }
        onNodeToggle={ toggledNode => onToggle?.(toggledNode.uuid) }
        onChange={ (currentNode, selectedNodes) => {
          onSelect?.(selectedNodes.map(item => item.uuid)) 
        }}
      />)
    );
}

export default DropdownTree