import React from "react";
import { Divider } from "@mui/material";
import {
  StatusTitle,
  StatusValue,
} from "@modul-connect/shared/components/atoms/text/text";
import Card from "../card/card";
import theme from "../../../theme";

const cardStyle = (width) => {return {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  borderRadius: "4px",
  flex: 1,
  maxHeight: 90,
  overflow: "hidden",
  width: "100%",
  paddingTop: "14px",
  paddingBottom: "14px",
  paddingLeft: "8px",
  paddingRight: "8px",
  minWidth: width ?? 220,
  maxWidth: width ?? 220,
}};

const StatusWidget = ({ title, value, color, width }) => {
  return (
    <Card extend={cardStyle(width)}>
      <StatusTitle text={title} />

      <Divider
        sx={{
          borderBottomWidth: 1,
          borderBlockColor: theme.colors.primaryDark,
          padding: 0,
          margin: 0,
          width: "100%",
        }}
      />

      <StatusValue color={color} text={value ? value : "-"} />
    </Card>
  );
};

export default StatusWidget;
