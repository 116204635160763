import React, { useCallback, useEffect, useState } from 'react'
import ReportPage from '../../reportPage.v2'
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers'
import { DateTime } from 'luxon'
import { ServiceType } from '@modul-connect/shared/utils/services'
import { getServiceList } from '../../../../utils/ServiceList'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchOverweightReportSummaries, fetchOverweightReport } from '../../../../state/actions/vehicles'
import { GroupingOptionSelector } from '../../tollPassages/summary/groupingOptionSelector'
import { useLocation, useNavigate } from 'react-router-dom';
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import { getDayName } from '@modul-connect/shared/utils/text'
import { logColumns, summaryColumns } from './columns'
import SimpleTable from '@modul-connect/shared/components/molecules/tableWidget/simpleTable';
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { convertToSelectedUnit, formatAddress } from '../../../../utils/unitConverter'
import { getOverweightLogsId } from '../../../../state/reducers/app'

const prepareData = (data) => {
  if (!data || !data?.length) return data

  let lastDay = null

  const toReturn = []

  data.forEach((log, index) => {
    const startDT = DateTime.fromISO(log.startTime)
    if (startDT.day + '_' + startDT.month + '_' + startDT.year !== lastDay) {
      toReturn.push({ isDivider: true, text: dateTimeToString(startDT, 'day-and-date') })
      lastDay = startDT.day + '_' + startDT.month + '_' + startDT.year
    }

    const endDT = DateTime.fromISO(log.endTime)

    toReturn.push([
      {
        value1: dateTimeToString(startDT, "time-only"),
        value2: !log?.endTime
          ? "ongoing"
          : dateTimeToString(endDT, "time-only"),
      },
      /* {
        value1: formatAddress(log.startAddress),
        value2: !log?.endTime ? "ongoing" : formatAddress(log.stopAdress),
      }, */
      { value: convertToSelectedUnit(log.distance, 1, "distance"), sortValue: log.distance },
      { value: log.vehicle },
      { value: `${(log.gross.ratio * 100).toFixed(0)} %`, sortValue: log.gross.ratio },
      { value: `${(log.front.ratio * 100).toFixed(0)} %`, sortValue: log.front.ratio },
      { value: `${(log.rear.ratio * 100).toFixed(0)} %`, sortValue: log.rear.ratio },
    ]);
  })

  return toReturn
}

export const groupingOptions = {
  day: 'day',
  all: 'all'
}

const WeightReportPageNoGeo = ({
  selected_organisations,
  log_loading,
  loading,
  services,
  fetchOverweightReportSummaries,
  fetchOverweightReport,
  weight_report_summary,
  themes,
  overweight_report_v2
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [groupBy, setGroupBy] = useState(
    useQuery().get("groupby") ?? groupingOptions.day
  );

  const hasNoWeightService =
    !services.hasService(ServiceType.WeightReportsNoGeo) &&
    services.hasService(ServiceType.WeightReports);

  let [startDate, setStartDate] = useState(DateTime.local().startOf("month"));
  let [endDate, setEndDate] = useState(DateTime.local().endOf("month"));

  const isLoadingSummaries = log_loading.fetchOverweightReportSummaries === "loading"
  const loadingSummariesFailed = log_loading.fetchOverweightReportSummaries === "failed"

  const logId = getOverweightLogsId(null, startDate.toSeconds(), endDate.toSeconds())
  const isLoadingAllLogs = loading?.fetchWeightReport_v2 && loading?.fetchWeightReport_v2[logId] === 'loading'
  const loadingAllLogsFailed = loading?.fetchWeightReport_v2 && loading?.fetchWeightReport_v2[logId] === 'failed'


  useEffect(() => {
    if (groupBy === groupingOptions.day) {
      getAccessTokenSilently().then((accessToken) => {
        fetchOverweightReportSummaries(
          accessToken,
          startDate,
          endDate,
          false
        );
        });
    } else {
      const logId = getOverweightLogsId(null, startDate.toSeconds(), endDate.toSeconds())
      getAccessTokenSilently().then((accessToken) => {
        fetchOverweightReport(accessToken, startDate, endDate, null);
      });
    }
  }, [startDate, endDate, groupBy,selected_organisations]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const changeGroupBy = (group) => {
    setGroupBy(group);
    navigate("/reports/reports_weight-reports/" + "?groupby=" + group);
  };

  const [summaryTableData, setSummaryTableData] = useState([])
  const [allLogsTableData, setAllLogsTableData] = useState([])

  useEffect(() => {
    if (!overweight_report_v2 || groupBy !== groupingOptions.all)
      return

    const logId = getOverweightLogsId(null, startDate?.toSeconds(), endDate?.toSeconds())

    setAllLogsTableData(overweight_report_v2[logId]?.data?.logs ?? [])
  }, [overweight_report_v2, groupBy])

  useEffect(() => {
    if (!weight_report_summary || groupBy !== groupingOptions.day)
      return

    const summaries_prepared = prepareSummariesForTable(weight_report_summary, overweight_report_v2)
    setSummaryTableData(summaries_prepared)
  }, [weight_report_summary, overweight_report_v2, groupBy])

  const prepareSummariesForTable = useCallback(
    (summaries, logs) => {
      if (!summaries || !summaries.length) return [];
  
        const prepared_data = summaries.map((s, index) => {
          const dayDT = DateTime.fromISO(s.day)
          const reportId = getOverweightLogsId(null, dayDT.startOf('day').toSeconds(), dayDT.endOf('day').toSeconds())
          const relevantLogs = logs && logs[reportId]
  
          return {
            id: s.day,
            count: s.count,
            day: getDayName(dayDT),

            isExpandable: true,
            onExpand: () => {
              const selectedDay = dayDT;
              const start = selectedDay.startOf("day");
              const end = selectedDay.endOf("day");

              getAccessTokenSilently().then((accessToken) => {
                console.log("fetch for data:", start);
                fetchOverweightReport(accessToken, start, end, null);
              });
            },
            collapsibleData: relevantLogs,
          };
      });
      return prepared_data;
    },
    [overweight_report_v2, weight_report_summary,selected_organisations]
  );

  
  // TODO: sorting arrows on label not showing up

  const tables =
    groupBy === groupingOptions.day ? (
      weight_report_summary?.length ? (
        [
          <TableWidgetSeparated
            key={"summaries"}
            data={summaryTableData}
            loadingStatus={isLoadingSummaries}
            totalItems={weight_report_summary?.length}
            columns={summaryColumns}
            themes={themes}
            isSortable

            collapsibleDataLoadingStatus={(id) => {
              const logId = getOverweightLogsId(null, DateTime.fromISO(id).startOf('day').toSeconds(), DateTime.fromISO(id).endOf('day').toSeconds())
              return loading.fetchWeightReport_v2 && loading.fetchWeightReport_v2[logId]
            }}
            collapsibleLogColumns={logColumns}
            prepareCollapsibleData={(data) => {return prepareData(data?.data?.logs ?? [])}}
            showInnerSortArrows
          />,
        ]
      ) : (
        []
      )
    ) : allLogsTableData?.length ? (
      [
        <SimpleTable // TODO: sorting isn't working right
          data={allLogsTableData?.length ? prepareData(allLogsTableData) : []}
          isLoading={isLoadingAllLogs}
          loadingFailed={loadingAllLogsFailed}
          columns={logColumns}
          showSortArrows
          //defaultSortIndex={defaultSortIndex}
          //defaultSortOrder={defaultSortOrder}
        />
      ]
    ) : []

  return (
    <ReportPage
      title={"Weight Report"}
      noDataMessage={
        "There are no weight warnings for the selected time period."
      }
      disabled={hasNoWeightService}
      noSearch
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom,
      ]}
      startDate={startDate}
      endDate={endDate}
      onTimeframePicked={(start, end) => {
        setStartDate(start);
        setEndDate(end);
      }}
      isLoading={groupBy === groupingOptions.all ? isLoadingAllLogs : isLoadingSummaries}
      fetchingDataFailed={groupBy === groupingOptions.all ? loadingAllLogsFailed : loadingSummariesFailed}
      tables={tables}
      groupingOptionSelector={
        <GroupingOptionSelector
          onChange={(selection) => {
            changeGroupBy(selection);
          }}
          options={groupingOptions}
          value={groupBy}
          disabled={isLoadingSummaries}
        />
      }
    />
  );
};

const mapStateToProps = (props) => ({
  ...props,
  services: getServiceList(props),
});

const mapDispatchToProps = dispatch => ({
  fetchOverweightReportSummaries: (accessToken, start, end, desc ) => dispatch(fetchOverweightReportSummaries(accessToken, start, end, desc )),
  fetchOverweightReport: (accessToken, start, end, boxId) => dispatch(fetchOverweightReport(accessToken, start, end, boxId)),
});


export default connect(mapStateToProps, mapDispatchToProps)(WeightReportPageNoGeo);