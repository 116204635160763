import React, { Fragment, useEffect, useState } from 'react'
import Notification from '@modul-connect/shared/img/Notification.svg'
import SecurityWarning from '@modul-connect/shared/img/security_alarm.svg'
import AssetWarning from '@modul-connect/shared/img/asset_drill.svg'
import WeightWarning from '@modul-connect/shared/img/weight_round.svg'
import ClimateWarning from '@modul-connect/shared/img/climate_round.svg'
import BatteryWarning from '@modul-connect/shared/img/battery_round.svg'
import OrangeCircle from '@modul-connect/shared/img/orange_circle.svg'
import ArrowDown from '@modul-connect/shared/img/expand_arrow_small.svg'
import Image from '@modul-connect/shared/components/atoms/image'
import View from '../../atoms/view'
import ScrollView from "@modul-connect/shared/components/atoms/scroll-view";
import theme from '../../../theme'
import { RouteMarker } from '../../../../portal/src/pages/dashboard/sidebars/details/components/routeMarker'
import { DateTime } from 'luxon'
import { getDayNameWithTime } from '../../../utils/text'
import { connect } from 'react-redux'


const SecurityWarnings = 'Security warnings'
const AssetWarnings = 'Asset warnings'
const WeightWarnings = 'Weight warnings'
const ClimateWarnings = 'Climate warnings'
const BatteryWarnings = 'Battery warnings'

const hideOtherThanSecurity = true

const getTitle = (warningType, tool, missing) => {
  switch (warningType) {
    case 'Security':
      return 'Alarm triggered'
    /* case 'Asset':
      return missing ? tool + 'lost' : tool + 'found' */
    default:
      return 'Warning'
  }
}

const NotificationTitle = () => {
  return (
    <View extend={{ display: 'flex', flexDirection: 'column', color: theme.colors.white }}>
      <View extend={{ ...styles.title, }}>
        {'Notifications'}
      </View>
      <View extend={{ ...styles.sub_title, }}>
        {'Your notifications from the last 30 days.'}
      </View>
    </View>
  )
}

const NotificationEntity = ({
  icon,
  title,
  subTitle,
  description,
  hasUserSeenIt,
  paddingBottom,
  extend
}) => {
  return (
    <View extend={{ display: 'flex', flexDirection: 'row', gap: 10, ...extend }}>
      <Image
        svg={icon}
        extend={{ ...styles.logo(), flex: 1 }}
        mode="fit"
      />

      <View extend={{ display: 'flex', flexDirection: 'row', flex: 8, justifyContent: 'space-between', paddingBottom: paddingBottom ? 5 : null }}>
        <View extend={{ display: 'flex', flexDirection: 'column', gap: 4, flex: 5 }}>
          <View extend={{ ...styles.notification_enity(700, theme.colors.dark_white) }}>
            {title}
          </View>
          <View extend={{ ...styles.notification_enity(300, theme.colors.dark_white) }}>
            {subTitle}
          </View>
          <View extend={{ ...styles.notification_enity(700, theme.colors.dark_orange) }}>
            {description}
          </View>
        </View>

        <View extend={{ flex: 1, height: 'fit-content' }} >
          {
            !hasUserSeenIt
              ?
              <Image
                svg={OrangeCircle}
                extend={{ ...styles.logo('12px'), display: 'flex', justifyContent: 'flex-end' }}
                mode="fit"
              />
              :
              null
          }
        </View>
      </View>

    </View>
  )
}

const GroupDropdown = ({
  setOpen,
  isOpen,
  icon,
  nameOfWidget,
  data,
  setSeenNotifications,
  disabled
 }) => {

  const unseen_notifications = data.filter(d => !d.seen)?.length
  const notificationIds = []
  data.map(d => !d.seen ? notificationIds.push(d.id) : null)
  return (
    <View>
      <View
        extend={{ ...styles.button_group, display: 'flex', flexDirection: 'row', gap: 10, opacity: disabled ? 0.4 : 1 }}
        onClick={() => {
          if(disabled) return
          if (isOpen)
            setSeenNotifications(nameOfWidget, notificationIds)
        }}
      >
        <Image
          svg={icon}
          extend={{ ...styles.logo(undefined, disabled), height: 'fit-content', width: 'fit-content' }}
          mode="fit"
        />

        <View extend={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 4, gap: 10 }}>
          <View extend={{ justifyContent: 'flex-start' }}>
            {nameOfWidget}
          </View>

          <View extend={{ ...styles.notification_marker }} onClick={() => disabled ? null : setOpen(!isOpen)}>
            {
              unseen_notifications
                ? <RouteMarker
                  text={unseen_notifications}
                  color={theme.colors.dark_orange}
                />
                : null
            }
            
            <Image
              svg={ArrowDown}
              extend={{ ...styles.logo(undefined, disabled), display: 'flex', flex: 1, justifyContent: 'flex-end' }}
              mode="fit"
            />
          </View>
        </View>
      </View>

      {
        isOpen ?
          data.length ?
            <ScrollView extend={{ maxHeight: '50vh', scrollbarColor: `white ${theme.colors.navBarBackground}`}}>
              {
                data?.map((d, index) =>
                  <NotificationEntity
                    key={index}
                    icon={d.group_icon}
                    title={getTitle(d.group)}
                    subTitle={d.vehicle}
                    description={getDayNameWithTime(DateTime.fromISO(d.time_stamp).toLocal())}
                    hasUserSeenIt={d.seen}
                    paddingBottom
                    extend={{ padding: 10 }}
                  />)
              }
            </ScrollView>
            : <View extend={{ color: theme.colors.dark_white, textAlign: 'center' }}>
              {'There are no notifications'}
            </View>
          : null
      }

    </View>
  )
}

const NotificationDropdown = ({
  isOpen,
  extend,
  data,
  setSeenNotifications,
  loading
 }) => {

  const [isSecurityOpen, setIsSecurityOpen] = useState(false)
  const [isAssetOpen, setIsAssetOpen] = useState(false)
  const [isWeightOpen, setIsWeightOpen] = useState(false)
  const [isClimateOpen, setIsClimateOpen] = useState(false)
  const [isBatteryOpen, setIsBatteryOpen] = useState(false)

  const security_warnings = data?.filter(d => d.group === 'Security')
  const assets_warnings = data?.filter(d => d.group === 'Asset')
  const weight_warnings = data?.filter(d => d.group === 'Weight')
  const climate_warnings = data?.filter(d => d.group === 'Climate')
  const battery_warnings = data?.filter(d => d.group === 'Battery')

  useEffect(() => {
    if (isSecurityOpen) {
      const securityNotificationIds = []
      security_warnings.map(d => !d.seen ? securityNotificationIds.push(d.id) : null)
      setSeenNotifications(SecurityWarnings, securityNotificationIds)
      setIsSecurityOpen(false)
    }

    if (isAssetOpen) {
      const assetNotificationIds = []
      assets_warnings.map(d => !d.seen ? assetNotificationIds.push(d.id) : null)
      setSeenNotifications(AssetWarnings, assetNotificationIds)
      setIsAssetOpen(false)
    }
    if (isWeightOpen) {
      const weightNotificationIds = []
      weight_warnings.map(d => !d.seen ? weightNotificationIds.push(d.id) : null)
      setSeenNotifications(WeightWarnings, weightNotificationIds)
      setIsWeightOpen(false)
    }
    if (isClimateOpen) {
      const climateNotificationIds = []
      climate_warnings.map(d => !d.seen ? climateNotificationIds.push(d.id) : null)
      setSeenNotifications(ClimateWarnings, climateNotificationIds)
      setIsClimateOpen(false)
    }
    if (isBatteryOpen) {
      const batteryNotificationIds = []
      battery_warnings.map(d => !d.seen ? batteryNotificationIds.push(d.id) : null)
      setSeenNotifications(BatteryWarnings, batteryNotificationIds)
      setIsBatteryOpen(false)
    }

  }, [isOpen])

  const noDataExplanation = (
    <View extend={{ color: theme.colors.dark_white, textAlign: 'center' }}>
      {
        loading.fetchNotifications === 'loading' 
          ? 'Loading notifications' 
          : loading.fetchNotifications === 'failed'
            ? 'Notifications failed to load' 
            :  'There are no notifications'
      }
    </View>
  )

  const allNotifications = data?.map((d, index) =>
    <NotificationEntity
      key={d.id + index}
      icon={d.group_icon}
      title={getTitle(d.group)}
      subTitle={d.vehicle}
      description={getDayNameWithTime(DateTime.fromISO(d.time_stamp)?.toLocal())}
      hasUserSeenIt={d.seen}
    />)

  const notificationSections = (
    <Fragment>
      <GroupDropdown
        nameOfWidget={SecurityWarnings}
        isOpen={isSecurityOpen}
        setOpen={setIsSecurityOpen}
        icon={SecurityWarning}
        data={security_warnings}
        setSeenNotifications={setSeenNotifications}
        disabled = {loading.fetchNotifications === 'failed'}
      />

      {hideOtherThanSecurity ? null : <GroupDropdown
        nameOfWidget={AssetWarnings}
        isOpen={isAssetOpen}
        setOpen={setIsAssetOpen}
        icon={AssetWarning}
        data={assets_warnings}
        setSeenNotifications={setSeenNotifications}
        disabled = {loading.fetchNotifications === 'failed'}
      />}

      {hideOtherThanSecurity ? null : <GroupDropdown
        nameOfWidget={WeightWarnings}
        isOpen={isWeightOpen}
        setOpen={setIsWeightOpen}
        icon={WeightWarning}
        data={weight_warnings}
        setSeenNotifications={setSeenNotifications}
        disabled = {loading.fetchNotifications === 'failed'}
      />}

      {hideOtherThanSecurity ? null : <GroupDropdown
        nameOfWidget={ClimateWarnings}
        isOpen={isClimateOpen}
        setOpen={setIsClimateOpen}
        icon={ClimateWarning}
        data={climate_warnings}
        setSeenNotifications={setSeenNotifications}
        disabled = {loading.fetchNotifications === 'failed'}
      />}

      {hideOtherThanSecurity ? null : <GroupDropdown
        nameOfWidget={BatteryWarnings}
        isOpen={isBatteryOpen}
        setOpen={setIsBatteryOpen}
        icon={BatteryWarning}
        data={battery_warnings}
        setSeenNotifications={setSeenNotifications}
        disabled = {loading.fetchNotifications === 'failed'}
      />}
    </Fragment>
  )

  return (
    isOpen ?
      <ScrollView extend={{ ...extend, scrollbarColor: `white ${theme.colors.navBarBackground}` }}>
        <NotificationTitle />
        {
          !data.length ? noDataExplanation : (
            <View style={{display: 'flex', flexDirection: 'column', gap: 15}}>
              <ScrollView extend={{ maxHeight: '40vh', flexShrink: 0.75, scrollbarColor: `white ${theme.colors.navBarBackground}`, display: 'flex', flexDirection: 'column', gap: 10 }}>
                { allNotifications }
              </ScrollView>

              <View extend={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
                { notificationSections }
              </View>
            </View>
          )
        }

        

      </ScrollView> : null
  )
}

const NotificationTreeView = ({
  data,
  setSeenNotifications,
  loading
}) => {

  const [openNotification, setOpenNotification] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)

  const unseen_notifications = data.filter(d => !d.seen)?.length

  useEffect(() => {
    setWindowHeight(window.innerHeight - theme.layout.navBarHeight)
  },[window.innerHeight])

  return (
    <View>
      <View
        onClick={() => {
          setOpenNotification(!openNotification)
        }}
      >
        <View extend={{ ...styles.notification_marker, width: 60 }}>
          <Image
            svg={Notification}
            extend={styles.logo()}
            mode="fit"
          />
          {
            unseen_notifications
              ? <RouteMarker
                text={unseen_notifications}
                color={theme.colors.dark_orange}
              />
              : null
          }
        </View>
      </View>
      <NotificationDropdown
        isOpen={openNotification}
        extend={styles.notification_page(windowHeight, theme.layout.navBarHeight) }
        data={data}
        setSeenNotifications={setSeenNotifications}
        loading={loading}
      />

    </View>
  )
}

const mapStateToProps = ({
  loading
}) => ({
  loading
})


export default connect(mapStateToProps) (NotificationTreeView)

const styles = {
  logo: (height, disabled) => ({
    height: height ?? '20px',
    width: 'fit-content',
    cursor: disabled ? null : 'pointer',
  }),
  title: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '30.46px',
    letterSpacing: '0.04em',
    textAlign: 'left',
  },
  sub_title: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15.23px',
    textAlign: 'left',
  },
  notification_page: (height, top) => ({
    display: 'flex',
    flexDirection: 'column',
    height: height,
    top: top,
    right: 0,
    backgroundColor: theme.colors.navBarBackground,
    position: 'absolute',
    zIndex: 10000000,
    padding: 24,
    gap: 24
  }),
  notification_enity: (weight, color) => ({
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: weight,
    textAlign: 'left',
    color: color,
  }),
  button_group: {
    padding: '8px 12px 8px 12px',
    gap: 10,
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    borderColor: theme.colors.lightDividerGrey,
    borderTop: `1px solid ${theme.colors.lightDividerGrey}`,
    borderBottom: `1px solid ${theme.colors.lightDividerGrey}`,
    borderRight: `1px solid ${theme.colors.lightDividerGrey}`,
    borderLeft: `1px solid ${theme.colors.lightDividerGrey}`,
    color: theme.colors.dark_white
  },
  notification_marker: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  }
}