import columns_byVehicle from './tollSummaryByVehicleColumns'


const columns = [
  {
    ...columns_byVehicle[0],
    id: 'driver',
    label: 'Driver',
  },
  columns_byVehicle[1],
  columns_byVehicle[2],
  columns_byVehicle[3]
]

export default columns