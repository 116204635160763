import { ApiKey, CustomerActionTypes, EXPIRED_API_KEY_BY_FORCE, FETCHED_API_KEYS, FETCHED_ORGANISATION_NAME, FETCH_API_KEYS, FETCH_ORGANISATION_NAME, FETCH_SUBTREES, FORCE_EXPIRE_API_KEY, NewApiKey, RECEIVED_NEW_API_KEY, REQUEST_API_KEY, Subtree, SUBTREES_FETCHED, SET_SELECTED_ORGANISATIONS, APP_STARTED, AUTHENTICATED_USER_FETCHED } from "../types/customer"

export const fetchSubtrees = (accessToken: string) : CustomerActionTypes => ({
  type: FETCH_SUBTREES,
  accessToken
})

export const fetchOrganisationName = (accessToken: string, id: string) : CustomerActionTypes => ({
  type: FETCH_ORGANISATION_NAME,
  accessToken,
  id
})

export const fetchApiKeys = (accessToken: string, includeExpired: boolean) : CustomerActionTypes => ({
  type: FETCH_API_KEYS,
  accessToken,
  includeExpired
})

export const requestApiKey = (accessToken: string, expiresAt: Date, organisations: string[]) : CustomerActionTypes => ({
  type: REQUEST_API_KEY,
  accessToken,
  expiresAt,
  organisations
})

export const forceExpireApiKey = (accessToken: string, id: string) : CustomerActionTypes => ({
  type: FORCE_EXPIRE_API_KEY,
  accessToken,
  id
})

export const subtreesFetched = (subtrees: Subtree[]) : CustomerActionTypes => ({
  type: SUBTREES_FETCHED,
  subtrees,
  total: subtrees.length
})

export const fetchedOrganisationName = (data: any) : CustomerActionTypes => ({
  type: FETCHED_ORGANISATION_NAME,
  organisation: {
    translated: data.translated,
    uuid: data.organisationId
  }
})

export const fetchedApiKeys = (keys: ApiKey[], total: number) : CustomerActionTypes => ({
  type: FETCHED_API_KEYS,
  keys,
  total
})

export const receivedNewApiKey = (key: NewApiKey) : CustomerActionTypes => ({
  type: RECEIVED_NEW_API_KEY,
  key
})

export const expiredApiKeyByForce = (id: string) : CustomerActionTypes => ({
  type: EXPIRED_API_KEY_BY_FORCE,
  id
})

export const setSelectedOrganisations = (uuids: string[]) : CustomerActionTypes => ({
  type: SET_SELECTED_ORGANISATIONS,
  uuids
}) 

export const appStarted = (token: string, email: string) : CustomerActionTypes => ({
  type: APP_STARTED,
  token,
  email
})

export const authenticatedUserFetched = (token: string, user: object, isNewUser: boolean) : CustomerActionTypes => ({
  type: AUTHENTICATED_USER_FETCHED,
  token,
  user,
  isNewUser
})