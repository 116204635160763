import React from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import View from "../view/view";

const TextInputBox = ({ defaultValue, value, onChange, placeholder, rows, disabled, label, extend }) => {
  return (
   
      <Box
        component="form"
        sx={{
          width: "100%",
          "& .MuiTextField-root": { width: "100%" },
          ...extend,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-multiline-static"
          label={label}
          sx={{ backgroundColor: 'white' }}
          multiline
          rows={rows}
          value={value}
          disabled={disabled}
          onChange={(e) => {onChange(e.target.value)}}
          placeholder={placeholder}
          defaultValue={defaultValue}
          variant="outlined"
        />
      </Box>
  );
};

export default TextInputBox;
