import React, { useEffect, useState, useRef } from 'react'
import View from "@modul-connect/shared/components/atoms/view";
import Card from "@modul-connect/shared/components/atoms/card/card";
import { DateTime } from "luxon";
import { SubTitle } from '@modul-connect/shared/components/atoms/text';
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { InfoLine, getDuration } from './routeInfoText';
import { getRouteImageUrl, getWeightWarningColor } from '../../../../utils/vehicleUtils';
import { convertToSelectedUnit, formatAddress } from '../../../../utils/unitConverter'
import theme from "@modul-connect/shared/theme";
import { Divider } from '@mui/material';
import { P } from '@modul-connect/shared/components/atoms/text';
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";


const padding = 8

const styles = {
  subView: {
    backgroundColor: 'transperant',
    display: 'flex',
    minWidth: 300,
    maxWidth: 400,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  section: {
    minHeight: 64,
    marginBottom: padding + 'px',
    marginRight: padding + 'px',
    overflow: 'clip',
    flex: 1,
  },
  infoBoxes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
}

const RouteDetailsSubpage = ({
  log,
  isOngoing,
  themes,
  route,
  highestWeightPercentage,
  loading
}) => {
  const topRowBoxRef = useRef(undefined)
  const infoBoxContainerRef = useRef(undefined)
  const firstCellRef = useRef(undefined)


  const [topRowHeight, setTopRowHeight] = useState(0)
  const [infoBoxContainerHeight, setInfoBoxContainerHeight] = useState(0)
  const [firstCellWidth, setFirstCellWidth] = useState(0)

  useEffect(() => {
    handleResize()
  })

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    };
    
  }, [])

  function handleResize() {
    setTopRowHeight(topRowBoxRef?.current?.offsetHeight - padding)
    setInfoBoxContainerHeight(infoBoxContainerRef?.current?.offsetHeight)
    setFirstCellWidth(firstCellRef?.current?.offsetWidth)
  }

  const getDistance = () => {
    if (isOngoing) return "Ongoing ..."

    function getDifference(a, b) {
      return Math.abs(a - b);
    }
    const distance = getDifference(log?.endMileage, log?.startMileage)

    return convertToSelectedUnit(distance, 1, 'distance')
  }

  const getMileageStr = (mileage) => {
    return convertToSelectedUnit(mileage, mileage > 100000 ? 0 : 2, 'distance')
  }
  const isLoadingFailed = loading.fetchDrivingLogDetails === 'failed'
  const isloading = loading.fetchDrivingLogDetails === 'loading'
  return (
    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
      <View extend={styles.infoBoxes}>
        <View extend={styles.subView}>
          <div ref={infoBoxContainerRef}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div ref={topRowBoxRef}>
                <InfoBox divRef={firstCellRef} title={'DRIVING DISTANCE'} value={getDistance()} />
              </div>
              <InfoBox title={'CATEGORY'} value={log?.purpose ? (log.purpose.toLowerCase() === 'work' ? 'Business' : 'Private') : null} />
            </View>

            <InfoBox
              title={'DURATION'}
              secondTitle={'TIME'}
              value={isOngoing ? 'Ongoing ...' : getDuration(Date.parse(log?.startTime), Date.parse(log?.endTime))}
              secondValue={
                dateTimeToString(DateTime.fromISO(log?.startTime), 'time-only') + 
                ' - ' + 
                (isOngoing ? 'ongoing' : dateTimeToString(DateTime.fromISO(log?.endTime), 'time-only'))}
              firstCellWidth={firstCellWidth}
            />

            <InfoBox
              title={'START MILEAGE'}
              secondTitle={'END MILEAGE'}
              value={(log?.startMileage || log?.startMileage === 0) ? getMileageStr(log.startMileage) : '-'}
              secondValue={isOngoing ? 'Ongoing ...' : (log?.endMileage || log?.endMileage === 0) ? getMileageStr(log.endMileage) : '-'}
              firstCellWidth={firstCellWidth}
            />

            <InfoBox
              title={'START'}
              secondTitle={'END'}
              smallValues
              value={log?.startAddress ? formatAddress(log.startAddress) : '-'}
              secondValue={!isOngoing ? (log?.stopAdress ? formatAddress(log.stopAdress) : '-') : 'Ongoing ...'}
              firstCellWidth={firstCellWidth}
              extend={{ paddingBottom: '15px' }}
            />
          </div>

        </View>

        <View extend={styles.subView}>
          <InfoBox
            title={'DRIVER'}
            value={log?.driver ?? '-'}
            extend={topRowHeight ? { maxHeight: topRowHeight, minHeight: topRowHeight } : null}
          />
          <InfoBox
            title={'NOTE'}
            value={log?.note ? log?.note : 'No note for this trip'}
            extend={!log?.note ? { color: theme.colors.disabledGrey, fontStyle: 'italic' } : null}
          />
        </View>
      </View>
      <View extend={[styles.subView, { maxWidth: undefined, height: infoBoxContainerHeight, flexGrow: 1 }]}>
        {isloading 
        ? <BubbleLoadingWidget text={'Loading map...'} /> 
        : (route && route?.length ) || isLoadingFailed   
        ? <MapImage
          themes={themes}
          route={route}
          isOngoing={isOngoing}
          highestWeightPercentage={highestWeightPercentage}
        /> : <P>Route could not be fetched.</P>}
      </View>
    </View >
  )
}

export const MapImage = ({ themes, route, isOngoing, highestWeightPercentage, noPadding }) => {
  const imageContainerRef = useRef(undefined)

  const [imageHeight, setImageHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

  useEffect(() => {
    handleResize()
  })

  function handleResize() {
    setImageHeight(imageContainerRef?.current?.clientHeight)
    setImageWidth(imageContainerRef?.current?.clientWidth)
  }

  return (
    <Card extend={[{
      height: '100%',
      width: '100%',
      marginBottom: padding + 'px',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    }, noPadding && { padding: 0 }]}>
      <img
        ref={imageContainerRef}
        style={{
          width: '100%',
          height: '100%',
        }}
        src={(imageHeight && imageWidth) ? getRouteImageUrl(
          themes,
          route,
          isOngoing,
          getWeightWarningColor(highestWeightPercentage).substring(1),
          imageHeight,
          imageWidth
        ) : null}
        alt={"Map showing the driving route."}
      />
    </Card>
  )
}

class InfoBox extends React.Component {
  constructor() {
    super()
  }

  render() {
    const {
      firstCellWidth, title, value, secondTitle, secondValue, smallValues, extend, divRef
    } = this.props

    const infoLineStyle = {
      fontFamily: 'Inter',
      fontSize: smallValues ? undefined : '18px',

    }

    const marginLeftSide = 10 + padding
    const leftSidePadding = {
      // 10 is the left/right padding of the card. Should probably not hardcode this.
      marginRight: marginLeftSide,
    }

    const marginRightSide = 10
    const rightSidePadding = {
      marginLeft: marginRightSide,
    }

    return (
      (<Card extend={{
        ...styles.section,
        ...extend,
      }}>
        <div ref={divRef} style={{
          width: firstCellWidth ?? '100%',
          minWidth: firstCellWidth ?? 'auto', // min and max are necessary, too, with width alone the right-hand side can eat into the left side, for some reason
          maxWidth: firstCellWidth ?? 'auto',
          ...(secondTitle ? leftSidePadding : {}),
        }}>
          <SubTitle title={title}></SubTitle>
          <Divider sx={[firstCellWidth ? {
            width: (firstCellWidth + marginLeftSide + marginRightSide)
          } : {
            width: '100%'
          }]} />
          <InfoLine extend={{ ...infoLineStyle }} small={smallValues} value={value}></InfoLine>
        </div>
        {
          secondTitle ? (
            <View style={{ ...rightSidePadding, flexGrow: 1 }}>
              <SubTitle title={secondTitle}></SubTitle>
              <Divider sx={{ width: '100%' }} />
              <InfoLine extend={infoLineStyle} small={smallValues} value={secondValue}></InfoLine>
            </View>
          ) : null
        }
      </Card>)
    );
  }
}
export default RouteDetailsSubpage