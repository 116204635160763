import React, { useState } from "react";
import ReportPage from "../reportPage.v2";
import { checklistSteps } from "./dummyData";
import View from "@modul-connect/shared/components/atoms/view/view";
import {
  H1,
  InfoText,
  P,
  TextWithBulletPoints,
} from "@modul-connect/shared/components/atoms/text/text";
import { Accordion, AccordionDetails, Typography, AccordionSummary, Divider } from "@mui/material";
import { ArrowDropDown, Info } from "@mui/icons-material";
import IconButton from "@modul-connect/shared/components/atoms/iconButton/iconButton";
import { MdDelete, MdEdit } from "react-icons/md";
import theme from "@modul-connect/shared/theme";

export const ChecklistManagementPage = ({}) => {
  const [steps, setSteps] = useState(checklistSteps);

  const removeStep = (index) => {
    const newSteps = [...steps]
    newSteps.splice(index, 1)
    setSteps(newSteps)
  }

  return (
    <ReportPage
      headerSection={null}
      title={"Checklist Management"}
      noHeader
      noFilterBar
      noTimePickerBar
      noSearch
    >
      <View
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: "2.5%",
          gap: 10,
        }}
      >

        <InfoText extend={{ marginBottom: 16 }}>
          {'Here you can edit the daily safety checklist for the vehicles in your fleet.'}
        </InfoText>

        {steps?.map((step, index) => {
          const prevEntry = index > 0 ? steps[index - 1] : undefined;
          const categoryChanged = prevEntry?.category !== step.category;

          return (
            <View>
              {categoryChanged ? (
                <H1
                  extend={index ? { paddingTop: 20 } : {}}
                  text={step.category}
                />
              ) : null}
              <ChecklistStepDisplay
                title={step.title}
                stepNr={index + 1}
                instruction={step.instruction}
                detailedInfo={step.moreInfo}
                onRemove={() => {removeStep(index)}}
              />
              <Divider />
            </View>
          );
        })}
      </View>
    </ReportPage>
  );
};
const ChecklistStepDisplay = ({
  title,
  instruction,
  detailedInfo,
  stepNr,
  onRemove
}) => {
  return (
    <View style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10, marginBottom: 16 }}>
      <View style={{ flexGrow: 1 }}>
        <P noPadding>{stepNr + ". " + title}</P>
          
        <InfoText extend={{ margin: 0, marginLeft: 16, marginBottom: 16 }}>{instruction}</InfoText>
        
        <Accordion>
        <AccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Info style={{ alignSelf: 'center', marginRight: 5 }} fontSize={"small"}/>
            <Typography>Detailed description</Typography>
          </AccordionSummary>
          <AccordionDetails style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            {
              detailedInfo?.map((info, index) => {
                return <TextWithBulletPoints 
                    key={'step_' + index}
                    text={info.textLines?.join("\n")}
                    bulletPoints={info.bulletPoints} />
                })
            }
          </AccordionDetails>
        </Accordion>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'flex-start' }}>
        <IconButton noBg noPadding>
          <MdEdit color={theme.colors.text} size={20} />
        </IconButton>

        <IconButton noBg noPadding>
          <MdDelete color={theme.colors.text} size={20} onClick={() => onRemove()} />
        </IconButton>
      </View>
    </View>
  );
};

const EditableText = ({ children, onPressEdit }) => {
  return <View style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'flex-top' }}>
    {children}
    <IconButton noBg noPadding extend={{ height: 23, display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
      <MdEdit color={theme.colors.text} size={16} />
    </IconButton>
  </View>
}
