export const log_columns = [
    {
        id: 'startTimeString',
        label: 'From',
        width: 120,
        hide: [],
        align: 'center'
    },
    {
        id: 'endTimeString',
        label: 'To',
        width: 120,
        hide: [],
        align: 'center'
    },
    {
        id: 'vehicle',
        label: 'Vehicle',
        minWidth: 200,
        hide: [],
        align: 'center'
    },
    {
        id: 'assetTrackerName',
        label: 'Asset Tracker',
        hide: [],
        minWidth: 200,
    },
    {
      id: 'category',
      label: 'Category',
      hide: [],
      minWidth: 75,
    }
]