const mphLocales = new Set<string>([
  'chr-US',
  'en-US',
  'haw-US',
  'lkt-US',
  'es-US',
  'kw-GB',
  'en-GB',
  'gd-GB',
  'cy-GB',
  'en-PR',
  'es-PR',
  'en-BS',
  'es-BS',
  'en-AG',
  'es-AG',
  'en-VG',
  'es-VG',
  'my-MM',
  'en-MM',
  'en-KN',
  'es-KN',
  'en-LC',
  'es-LC',
  'en-VI',
  'es-VI',
  'en-GD',
  'es-GD',
  'en-GI',
  'en-SH',
  'en-AS',
  'en-VC',
  'es-VC',
  'en-GU',
  'en-LR',
  'ff-LR',
  'kpe-LR',
  'vai-LR'
])

const lbsLocales = new Set<string>([
  'chr-US',
  'en-US',
  'haw-US',
  'lkt-US',
  'es-US',
  'my-MM',
  'en-MM',
  'en-LR',
  'ff-LR',
  'kpe-LR',
  'vai-LR'
])

function formatNumbers(number: string, imperial: boolean) : string {
  var parts = number.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, imperial ? ',' : ' ');
  return parts.join('.');
}

const location = navigator.language
const speed = 'speed'
const distance = 'distance'
const weight = 'weight'
export const convertToSelectedUnit = (value: number, fix: number, type: typeof speed | typeof distance | typeof weight, withoutUnit: boolean) : string => {
  if (!value && value !== 0) {
    return ''
  }

  if (type === weight) {
    return formatNumbers(value.toFixed(fix).toString() + ' kg', false)
  }
  if (mphLocales.has(location))
    return formatNumbers((value * 0.6213712).toFixed(fix).toString() + (type === speed ? ' mph' : ' mi'), true)

  return withoutUnit ? 
      formatNumbers(value.toFixed(fix).toString(), false) : 
      formatNumbers(value.toFixed(fix).toString() + (type === speed ? ' km/h' : ' km'), false)
}

export const getDistanceUnit = () : string => {
  if (mphLocales.has(location))
    return 'mi'
  return 'km'
}

export interface AddressObject {
  streetNumber: number,
  route: string,
  city: string,
  country: string
}

const uppercaseEveryWord = (str: string) : string => {
  if(!str) return ''

  try {
    return str.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()).replace(/(^[\u00E0-\u024F]|\s[\u00E0-\u024F])/g, m => m.toUpperCase());
  } catch {
    return ''
  }
}

export const formatAddress = (address: AddressObject) : string => {
  if (!address) return '-'

  let addressElements=[]

  let streetStr = ''
  let cityStr = ''
  //let countryStr = ''

  if (address.country === 'GB') {
    if (address.route && address.streetNumber) streetStr = address.streetNumber.toString() + ' '
    if (address.route) streetStr += uppercaseEveryWord(address.route)

    addressElements.push(streetStr)
  }
  else {
    if (address.route) streetStr = uppercaseEveryWord(address.route)
    if (address.route && address.streetNumber) streetStr += ' ' + address.streetNumber.toString()

    addressElements.push(streetStr)
  }
  
  if (address.city) {
    cityStr = address.city
    
    addressElements.push(cityStr)
  }
  
 // if (address.country) {
 //   countryStr = address.country

 //   addressElements.push(countryStr)
 // }

  const filtered = addressElements.filter(el => el !== '')

  return filtered.length === 0 ? '-' : filtered.join(', ')
}