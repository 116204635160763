import Paper from '@modul-connect/shared/components/atoms/paper';
import Page from '@modul-connect/shared/components/atoms/page.v2';
import React, { Fragment } from 'react'
import View from '@modul-connect/shared/components/atoms/view';
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { H3, P } from '@modul-connect/shared/components/atoms/text'
import Card from '@modul-connect/shared/components/atoms/card/card';

// TODO: handle smaller screen sizes better
// TODO: abstract more things and build them here rather than defining them in the report pages
const ReportPage = ({
  title,
  headerSection, // TODO: should build this here
  isPreparingFile,
  isLoading,
  noDataMessage,
  downloadFailed,
  dateSelectionComponent,
  tablesInOwnSubPage,
  subpageTitle,
  tables,
  fetchingDataFailed,
  kpi,
  reportGraph,
  children,
  disabled,
  extend
}) => {
  return (
  <Fragment> 
    {disabled ? 
    <View>
      <MissingServiceAllert />
    <View extend={styles.pageCover}>
    <Page headerSection={headerSection}>
      {children ? children
        : <Paper title={title}>
          {isPreparingFile
            ?
            <BubbleLoadingWidget text={'Preparing file ...'} />
            :
            <View extend={{ width: "100%" }}>
              <View>
                {dateSelectionComponent}

                {kpi ? <Card extend={styles.kpiDisplay}> {kpi} </Card> : null}


                {reportGraph ? <Card> {reportGraph} </Card> : null}

                {tablesInOwnSubPage ? null : <View extend={styles.tableContainer}>
                  {downloadFailed ? // TODO: better way of displaying this
                    <H3>Something went wrong when trying to download the report.</H3>
                    : null
                  }

                  {isLoading ? <BubbleLoadingWidget text={'Loading ...'} />
                    : fetchingDataFailed ? disabled ? <DisabledMessage />  :  <CouldNotFetchMessage />
                      : (tables?.length || tables?.length === 0) ?
                        tables?.length ? tables.map((table, index) => <React.Fragment key={index}>{table}</React.Fragment>)
                          : <MessageDisplay message={noDataMessage} />
                        : tables ? tables
                          : <MessageDisplay message={noDataMessage} />
                  }
                </View>}
              </View>
            </View>
          }
        </Paper>}
      {
        // TODO: if we ever have such a page, handle downloading feedback here, like we do above
        tablesInOwnSubPage ?
          <Paper title={subpageTitle}>
            {
              isLoading ? <BubbleLoadingWidget text={'Loading ...'} />
                : fetchingDataFailed ? disabled ? <DisabledMessage /> : <CouldNotFetchMessage />
                  : (tables?.length || tables?.length === 0) ?
                    tables?.length ? tables.map((table, index) => <React.Fragment key={index}>{table}</React.Fragment>)
                      : <MessageDisplay message={noDataMessage} />
                    : tables ? tables
                      : <MessageDisplay message={noDataMessage} />
            }
          </Paper>
          : null
      }
    </Page>
    </View>
    </View>
    : 
    <Page headerSection={headerSection}>
    {children ? children
      : <Paper title={title}>
        {isPreparingFile
          ?
          <BubbleLoadingWidget text={'Preparing file ...'} />
          :
          <View extend={{ width: "100%" }}>
            <View>
              {dateSelectionComponent}

              {kpi ? <Card extend={styles.kpiDisplay}> {kpi} </Card> : null}


              {reportGraph ? <Card> {reportGraph} </Card> : null}

              {tablesInOwnSubPage ? null : <View extend={[styles.tableContainer, extend]}>
                {downloadFailed ? // TODO: better way of displaying this
                  <H3>Something went wrong when trying to download the report.</H3>
                  : null
                }

                {isLoading ? <BubbleLoadingWidget text={'Loading ...'} />
                  : fetchingDataFailed ? <CouldNotFetchMessage />
                    : (tables?.length || tables?.length === 0) ?
                      tables?.length ? tables.map((table, index) => <React.Fragment key={index}>{table}</React.Fragment>)
                        : <MessageDisplay message={noDataMessage} />
                      : tables ? tables
                        : <MessageDisplay message={noDataMessage} />
                }
              </View>}
            </View>
          </View>
        }
      </Paper>}
    {
      // TODO: if we ever have such a page, handle downloading feedback here, like we do above
      tablesInOwnSubPage ?
        <Paper title={subpageTitle}>
          {
            isLoading ? <BubbleLoadingWidget text={'Loading ...'} />
              : fetchingDataFailed ? <CouldNotFetchMessage />
                : (tables?.length || tables?.length === 0) ?
                  tables?.length ? tables.map((table, index) => <React.Fragment key={index}>{table}</React.Fragment>)
                    : <MessageDisplay message={noDataMessage} />
                  : tables ? tables
                    : <MessageDisplay message={noDataMessage} />
          }
        </Paper>
        : null
    }
  </Page> }
  </Fragment>
  )
}

const CouldNotFetchMessage = () => {
  return (
    <MessageDisplay message={'There was an issue trying to fetch the data from the server.'} />
  )
}

const DisabledMessage = () => {
  return <MessageDisplay message={'You have no active subscription for this page.'} />
}

const MessageDisplay = ({ message }) => {
  return <H3>{message}</H3>
}

const kpiMargin = '15px'
const styles = {
  kpiDisplay: {
    marginTop: kpiMargin,
    marginBottom: kpiMargin,
  },
  tableContainer: {
    paddingTop: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  pageCover: {
    opacity: 0.3,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: "white",
    position: 'absolute',
    pointerEvents: 'none'
  },
}

const MissingServiceAllert = () => {
  return (
    <View
      style={{
        minWidth: 180,
        minHeight: 100,
        maxWidth: 180,
        padding: 5,
        backgroundColor: "black",
        display: "flex",
        flex: 1,
        width: "15%",
        zIndex: 2,
        borderRadius: 10,
        margin: "auto",
        position: "relative",
        top: "40vh",
      }}
    >
      <P
        extend={{
          color: "white",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          textAlign: "center"
        }}
      >
        There is no active subscription for this service
      </P>
    </View>

  )
}



export default ReportPage