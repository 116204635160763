import React from 'react'
import { connect } from 'react-redux'
import { getServiceList } from '../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';
import WeightReportPageNoGeo from './noGeo/weightReportPage';
import WeightReportPage_oldDesign from './old_design/weightReportPage'


const WeightReportPage = ({
  services,
}) => {
  const versionWithoutGeo = services.hasService(ServiceType.WeightReportsNoGeo)

  if (versionWithoutGeo) return <WeightReportPageNoGeo />
  else return <WeightReportPage_oldDesign />
}

const mapStateToProps = (props) => ({ services: getServiceList(props) });

export default connect(mapStateToProps)(WeightReportPage);