import theme from '@modul-connect/shared/theme';

export const styles = {
  inputField: {
    textAlign: 'left',
    color: theme.colors.offWhite,
    width: 160,
    height: 30,
    backgroundColor: theme.colors.darkGrey,
    flex: 1,
    padding: 10,

    cursor: 'pointer'
  },
  dayPickerWithTitle: () => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: '4px',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  }),

  monthPickerView: ({ theme: { colors } }) => ({
    ...getStyle(colors).baseStyle,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--start):not(.DayPicker-Day--hoverRange)": getStyle(colors).style_selectedRange,
    "& .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--start)": getStyle(colors).style_hoverRange,
  }),

  dayPickerView: ({ theme: { colors } }) => ({
    ...getStyle(colors).baseStyle,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    zIndex: '100 !important',

    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--start):not(.DayPicker-Day:hover)": getStyle(colors).style_selectedRange,
    "& .DayPicker-Day:hover:not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--start):not(.DayPicker-Day--hoverRange)": getStyle(colors).style_hoverDay,
  }),

  filterHeaderContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: 5,
    minWidth: '130px',
  },

  filterSelectContainer: { display: 'flex', flexDirection: 'row' }
}

const getStyle = (colors) => {
  return {
    baseStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      justifyContent: 'space-between',
      // width: 350,
      height: '100%',

      "& .DayPicker": {
        fontSize: 14,
      },
      "& .DayPicker-Day--disabled": {
        pointerEvents: 'none',
      },

      "& .DayPicker-Day--today:not(.DayPicker-Day--outside)": {
        color: `${colors.offWhite} !important`,
      },
      "& .DayPicker-Day--today:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected):not(.DayPicker-Day--hoverRange)": {
        color: `${colors.darkGrey} !important`
      },
      "& .DayPicker-Day--start:not(.DayPicker-Day--outside)": {
        backgroundColor: `${colors.darkGrey} !important`,
        borderTopLeftRadius: '50% !important',
        borderBottomLeftRadius: '50% !important',
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
      },
      "& .DayPicker-Day--end:not(.DayPicker-Day--outside)": {
        backgroundColor: `${colors.darkGrey} !important`,
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        borderTopRightRadius: '50% !important',
        borderBottomRightRadius: '50% !important',
      }
    },
    style_selectedRange: {
      backgroundColor: `${colors.darkGrey} !important`,
      opacity: '0.5',
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
    style_hoverRange: {
      backgroundColor: `${colors.darkGrey} !important`,
      color: `${colors.offWhite} !important`,
      opacity: '0.6',
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
    style_hoverDay: {
      backgroundColor: `${colors.darkGrey} !important`,
      color: `${colors.offWhite} !important`,
      opacity: '0.6',
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    }
  }
}