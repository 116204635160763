import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { P, Link, DownloadLink } from "@modul-connect/shared/components/atoms/text/text";
import View from "@modul-connect/shared/components/atoms/view";
import theme from "@modul-connect/shared/theme";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import IconButton from "@modul-connect/shared/components/atoms/iconButton/iconButton";
import { CircularProgress } from "@mui/material";

import UploadFileModal from "../uploadFileModal";
import ConfirmationModal from "../confirmationModal";
import { Icon } from "@modul-connect/shared/components/atoms/icon/icon";
import { EditableTextDisplay } from "@modul-connect/shared/components/molecules/editableTextDisplay";

const styles = {
  container: {
    width: "100%",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.uiBorderColor}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 8,
  },
  rightSide: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: 8,
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }
};

const DocumentDisplay = ({
  oneRow,

  comment,
  documentFileURI,
  documentFileName,
  commentEditable = true,
  commentPlaceholder,

  saving,
  isSavingComment,
  isSavingDocument,
  date,

  onUpdateComment, 
  onAttachDocument,
  onRemoveDocument,

  uploaded_file
}) => {
  if (!onUpdateComment) console.error('Document Display is missing property onAttachDocument. This can lead to crashes.')
  if (!onAttachDocument) console.error('Document Display is missing property onUpdateComment. This can lead to crashes.')
  if (!onRemoveDocument) console.error('Document Display is missing property onRemoveDocument. This can lead to crashes.')

  const hasDocument = documentFileURI ? true : false;

  const [attachDocument, setAttachDocument] = useState(false)

  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false)

  const commentDisplay = commentEditable ? <EditableTextDisplay
      characterLimit={50}
      value={comment}
      placeholder={commentPlaceholder}
      isSaving={isSavingComment}
      onSave={(newComment) => onUpdateComment(newComment)}
    /> : <P noPadding> {comment && comment !== "" ? comment : commentPlaceholder} </P>

  return (
    <Fragment>
      <View style={styles.container}>
        <View style={styles.topRow}>
          {oneRow ? commentDisplay : <P noPadding> {dateTimeToString(date, "date-only")} </P>}
          <View style={styles.rightSide}>
            {
              isSavingDocument 
              
              ? <CircularProgress color={'primary'} size={20} />

              : <IconRow>
              {hasDocument ? <DownloadLink url={documentFileURI} text={documentFileName} /> : null}

              {hasDocument ? (<IconButton v2 size={24} icon={"NewWindow"} onClick={() => setAttachDocument(true)}  />) : null}

              {hasDocument ? (<IconButton v2 size={16} icon={"Delete"} onClick={() => setShowDeletionConfirmationModal(true)} />) : null}

              <DeletionConfirmationModal
                open={showDeletionConfirmationModal}
                documentFileURI={documentFileURI}
                documentFileName={documentFileName}
                onRemoveDocument={onRemoveDocument}
                onClose={() => {setShowDeletionConfirmationModal(false)}}
              />
            
              {!hasDocument ?
                <View 
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onClick={() => setAttachDocument(true)} 
                >
                  <Link gap={5} iconPosition={"after"} icon={<IconButton v2 size={24} icon={"NewWindow"}/>}>Attach document</Link>
                </View>
                : null}
            </IconRow>  }

          </View>
        </View>

        {oneRow ? null : commentDisplay}
      </View>

      <UploadFileModal
        pdfOnly
        updateFileOnChange
        open={attachDocument}
        textForAcceptedFileTypes={'Supported file types: PDF'}
        acceptedFileTypes={'.pdf'}
        onClose={() => {
          setAttachDocument(false);
        }}
        onSave={() => {
          setAttachDocument(false);
          onAttachDocument(uploaded_file.blobUrl, uploaded_file.fileName)
        }}
        uploadingStatus={saving.uploadDocument}
      />
    </Fragment>
  );
};

export const DeletionConfirmationModal = ({ open, documentFileURI, documentFileName, onRemoveDocument, onClose }) => {
  return (
    <ConfirmationModal v2
      open={open}
      body={
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 450, gap: 16 }}>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 12}}>
            <P noPadding>Do you want to delete</P>
            <Icon icon={"Delete"}/>
          </View>

          <DownloadLink noIcon url={documentFileURI} text={documentFileName} />
        </View>
      }
      confirmText={'Delete'}
      confirmIcon={'Delete'}
      onConfirm={() => {onRemoveDocument(); onClose()}}
      onClose={() => {onClose()}}></ConfirmationModal>
  )
}

const IconRow = ({ children }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        alignSelf: 'flex-start',
        gap: 5,
      }}
    >
      {children}
    </View>
  );
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDisplay);
