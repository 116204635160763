import React from "react";
import { styles as textStyles } from '@modul-connect/shared/components/atoms/text/text'
import { TextField, InputAdornment } from "@mui/material";
import View from '@modul-connect/shared/components/atoms/view'
import Pencil from '@modul-connect/shared/img/pepicons-pencil_pen.svg'
import theme from "../../../theme";
import './hideSafariAutocompleteIcon.css'

export const InputField = ({
  placeholder,
  value,
  onChange,
  isRequired,
  error,
  helperText,
  label,
  noBorder,
  noIcon,
  width,
  size,
}) => {
  return (
    <View style={width ? {width: width} : null}>
      {isRequired ? <View style={{ color: theme.colors.inputTextGrey }}>*</View> : null}
      <TextField
        id="outlined-basic"
        variant={noBorder ? "standard" : "outlined"}
        autoComplete="off"
        fullWidth
        size={size ?? "small"}
        label={label}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{ input: { color: textStyles.inputText.color } }}
        slotProps={{
          input: {
            disableUnderline: true,
            endAdornment: !noIcon && (
              <InputAdornment position="end">
                <img src={Pencil} />
              </InputAdornment>
            ),
            style: textStyles.inputText
          }
        }}
      />
    </View>
  );
};
