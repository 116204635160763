import React from 'react'
import FormControl from '@mui/material/FormControl';
import { createTheme } from '@mui/material/styles';
import View from "@modul-connect/shared/components/atoms/view";
import theme from "@modul-connect/shared/theme"
import { DatePickerTitle } from '@modul-connect/shared/components/atoms/text'
import { RadioButtonSelector } from '@modul-connect/shared/components/molecules/radioButtonSelector'

const styles = {
  radioButtons: () => ({
    color: theme.colors.midDarkGrey,
    fontFamily: 'Inter',
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 0,
    flexWrap: 'wrap',
    //width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  })
}

export const GroupingOptionSelector = ({
  onChange,
  options,
  value,
  noTitle,
  disabled
}) => {
  return (
    <View extend={styles.radioButtons}>
      <FormControl sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
        component="fieldset">
        {noTitle ? <View extend={{ alignSelf: 'flex-start', flex: 1, marginRight: '10px' }}>
          <DatePickerTitle title1={'Group entries by:'}/> 
        </View> : null}
        <View extend={{ display: 'flex', flex: 3, justifyContent: 'center' }}>
          <RadioButtonSelector onChange={onChange} options={options} value={value} disabled={disabled}/>
        </View>
      </FormControl>
    </View>
  )
}

const radio_theme = createTheme({
  palette: {
    primary: {
      main: '#3A8643',
    },
  },
  typography: {
    fontSize: 12,
    fontWeight: 200,
    body1: {
      color: theme.colors.grey
    },
  }
})