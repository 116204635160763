import React from "react";
import View from "../../atoms/view";
import { connect } from "react-redux";
import { SimpleDropdown } from "../../atoms/dropdown/simpleDropdown";
import FilterIcon from '@modul-connect/shared/img/filter.svg'
import ClearFilterIcon from '@modul-connect/shared/img/clear_filter.svg'
import theme from "../../../theme";
import Button from "@modul-connect/shared/components/atoms/button";

const fontStyle = {fontWeight: 500, color: theme.colors.midDarkGrey, textTransform: 'none'}

const FilterBar = ({ selected_organisations, themes }) => {
  return (
    <View
      extend={{
        ...styles.bar,
        ...(themes.device == "tablet"
          ? styles.top_tablet
          : themes.device == "mobile"
          ? styles.top_mobile
          : styles.top),
      }}
    >
      <View
        extend={[
          styles.barContainer,
          themes.device == "tablet" && styles.bar_tablet,
          themes.device == "mobile" && styles.bar_mobile,
        ]}
      >
        <View extend={styles.toolBar}>
          <TextWithIcon text={'Filter'} icon={FilterIcon}/>

          <SimpleDropdown addOptionNone multiple options={['Entire Fleet', 'Suborg 1', 'Suborg 2']} selectedValue={['Entire Fleet']}/>

          <SimpleDropdown addOptionNone multiple options={['Show all vehicles', 'Vehicle 1', 'Vehicle 2']} selectedValue={['Show all vehicles']}/>

          <SimpleDropdown addOptionNone multiple options={['Show all drivers', 'Driver 1', 'Driver 2']} selectedValue={['Show all drivers']}/>

          <ClearFilterButton />
        </View>
      </View>
    </View>
  );
};

const TextWithIcon = ({ text, icon, iconSize }) => {
  return  (
    <View style={{ paddingRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <img width={iconSize ?? 15} height={iconSize ?? 15} style={{ marginRight: 10 }} src={icon} />
      <View style={fontStyle}>{text}</View>
    </View>
  )
}

// TODO: functionality
const ClearFilterButton = () => {
  return (
    <Button noBg extend={fontStyle}>
      <TextWithIcon text={'Clear filter'} iconSize={22} icon={ClearFilterIcon}/>
    </Button>
  )
}

const mapStateToProps = ({ selected_organisations, themes }) => ({
  selected_organisations,
  themes,
});

export default connect(mapStateToProps)(FilterBar);

const styles = {
  bar: ({ theme: { colors, layout } }) => ({
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    width: "100%",
    zIndex: 100,
    height: layout.filterBarHeight,
  }),
  top: ({ theme: { colors, layout } }) => ({
    top: layout.navBarHeight,
  }),
  top_tablet: ({ theme: { colors, layout } }) => ({
    top: layout.navBarHeight_tablet,
  }),
  top_mobile: ({ theme: { colors, layout } }) => ({
    top: layout.navBarHeight_mobile,
  }),
  bar_tablet: ({ theme: { colors, layout } }) => ({
    height: layout.filterBarHeight,
  }),
  bar_mobile: ({ theme: { colors, layout } }) => ({
    height: layout.filterBarHeight,
  }),
  barContainer: ({ theme: { layout, colors } }) => ({
    backgroundColor: colors.barBackground,
    width: "100%",
    height: layout.filterBarHeight,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto",
    padding: 10,
    zIndex: 31,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px'
  }),
  toolBar: ({ theme: { layout } }) => ({
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 4px",
    gap: '25px'
  }),
};
