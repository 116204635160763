import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Page from '@modul-connect/shared/components/atoms/page'
import { DateTime } from 'luxon'
import { useAuth0 } from '@auth0/auth0-react'
import { downloadTollReport, fetchTollReport, fetchVehiclesData } from '../../../../state/actions/vehicles'
import TollPassageTable from './tollPassageTable'
import { dateTimeToEpoch, dateTimeToString } from '../../../../../../shared/utils/dateTimeUtils';
import ReportPage from '../../reportPage.v2';
import { groupingOptions } from '../../drivingLog/drivingLogPage';
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers';

export const TollPassagesDetailedPage = ({
  selected_organisations,
  loading,
  fetchTollReport,
  toll_reports,
  downloadTollReport,
  boxId,
  driverId,
  selectedMonth,
  vehicles_data,
  fetchVehiclesData,
  downloading,
  groupBy,
  disabled,
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const navigate = useNavigate()

  const dateTime = selectedMonth ?? DateTime.local()

  const [startDateDetails, setStartDateDetails] = useState(dateTime.startOf('month'))
  const [endDateDetails, setEndDateDetails] = useState(DateTime.min(dateTime.endOf('month'), DateTime.local().endOf('day')))

  const [passages, setPassages] = useState([])
  const [vehicle, setVehicle] = useState(null)
  const [tollLogs, setTollLogs] = useState([])

  function logsByDay(logs) {
    const logs_by_day = {}

    for (const log of logs) {
      const ts = DateTime.fromISO(log.timeBefore)
      const day = dateTimeToString(ts, 'day-and-date')
      const newLog = { ...log, time: dateTimeToString(ts, 'time-only') }

      if (logs_by_day[day])
        logs_by_day[day].push(newLog)
      else
        logs_by_day[day] = [newLog]
    }
    return logs_by_day

  }

  useEffect(() => {
    getAccessTokenSilently().then(fetchVehiclesData)
  }, [selected_organisations])

  useEffect(() => {
    setVehicle(vehicles_data?.data?.find(y => y.mainbox_id === boxId))
  }, [vehicles_data])

  useEffect(() => {
    const dataKey = (driverId ?? boxId) + '_' + dateTimeToEpoch(startDateDetails)
    const relevantLogs = Object.keys(toll_reports).length ? toll_reports[dataKey] : undefined
    setTollLogs(relevantLogs)
    const passages_prepared = relevantLogs?.data?.map((x, i) => {
      const logVehicle = vehicles_data?.data?.find(y => y.mainbox_id === x.boxId)
      const day = dateTimeToString(DateTime.fromISO(x.timeBefore), 'day-and-date')

      return ({
        ...x,
        key: day + '_' + x.timeBefore,
        id: day + '_' + x.timeBefore,
        purpose: x.purpose.toLowerCase() === 'work' ? 'Business' : 'Private',
        vehicleName: logVehicle?.vehicleName ?? (logVehicle?.vehicle.baseVehicleName ?? '-'),
        date: day
      })
    }) ?? []

    setPassages(logsByDay(passages_prepared))
  }, [toll_reports, vehicles_data])

  useEffect(() => {
    getAccessTokenSilently().then(accessToken => {
      const start = startDateDetails && startDateDetails.startOf('day')
      const end = endDateDetails && endDateDetails.endOf('day')
      fetchTollReport(accessToken, boxId, driverId, start, end)
    })
  }, [startDateDetails, endDateDetails, selected_organisations])

  const onDownloadTollReport = () =>
    getAccessTokenSilently().then(accessToken =>
      downloadTollReport(accessToken, boxId, driverId, startDateDetails, endDateDetails, groupBy === groupingOptions.byVehicle ? 'vehicle' : 'driver'))

  if (!selectedMonth || (!boxId && !driverId)) {
    return <Page title='Toll Passages' linkBack={navigate} />
  }

  const filtered = passages
  const firstKey = Object.keys(filtered)?.length ? Object.keys(filtered)[0] : undefined
  const firstEntry = firstKey ? (filtered[firstKey]?.length ? filtered[firstKey][0] : undefined) : undefined
  const driverName = firstEntry ? firstEntry?.driverUserId ? firstEntry.driver : "No driver assigned" : undefined

  const objectName= groupBy === groupingOptions.byDriver ? driverName : vehicle?.vehicleName

  return (
    <ReportPage
      title={'Toll Passages'+  ((loading.fetchTollReport === 'loading' || !objectName) ? '' : (  ', ' + objectName ))}
      timeSelectors={[timespanOptions.custom]}
      useOldDatePickers
      startDate={startDateDetails}
      endDate={endDateDetails}
      onStartDatePicked={(date) => setStartDateDetails(date?.startOf('day'))}
      onEndDatePicked={(date) => setEndDateDetails(date?.endOf('day'))}
      isLoading={loading.fetchTollReport === 'loading'}
      disabled={disabled}
      noSearch
      onDownload={() => onDownloadTollReport()}
      noDataMessage={'There are no toll passages for the selected time period.'}
      fetchingDataFailed={loading.fetchTollReport === 'failed'}
      tables={Object.keys(passages)?.map(key => {
          const log = passages[key]
          return <TollPassageTable
            tableTitle={key}
            boxId={boxId}
            tableData={log}
            toll_reports={tollLogs}
          />
        })
      }
      isPreparingFile={downloading?.toll_report && downloading.toll_report.boxId === boxId && downloading.toll_report.status === 'working'}
      downloadFailed={downloading?.toll_report && downloading.toll_report.boxId === boxId && downloading.toll_report.status === 'failed'}
    />
  )
}

const mapStateToProps = ({
  selected_organisations,
  loading,
  toll_reports,
  vehicles_data,
  downloading,
}) => ({
  selected_organisations,
  loading,
  toll_reports,
  vehicles_data,
  downloading,
})

const mapDispatchToProps = dispatch => ({
  fetchTollReport: (accessToken, boxId, driverId, start, end) => dispatch(fetchTollReport(accessToken, boxId, driverId, start, end)),
  downloadTollReport: (accessToken, boxId, userId, start, end, groupBy) => dispatch(downloadTollReport(accessToken, boxId, userId, start, end, groupBy)),
  fetchVehiclesData: accessToken => dispatch(fetchVehiclesData(accessToken))
})

export default connect(mapStateToProps, mapDispatchToProps)(TollPassagesDetailedPage)