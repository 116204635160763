
export const users = (state = {}, action) => {
  switch (action.type) {
    case 'USERS_FETCHED':
      return {
        data: action.users,
        total: action.total
      }
    case 'ADD_USER': {
      return {
        ...state,
        data: [
          {
            email: action.email,
            firstName: action.firstName,
            lastName: action.lastName,
            metadata: {
              info: {
                tags: [action.tags]
              }
            },
            translatedTags: [
              [action.tagName]
            ],
          },
          ...state.data,
        ]
      }
    }
    case 'INCORRECT_EMAIL':
    case 'USER_ALREADY_EXISTS':
    case 'ADDING_USER_FAILED': {
      let newData = state.data?.filter(d => !(d.email === action.data.email && d.firstName === action.data.firstName && d.lastName === action.data.lastName))

      return {
        ...state,
        data: newData
      }
    }
    case 'REMOVE_USER': {
      let user_to_remove_index = state.data?.findIndex(u => u.email === action.email)
      let user_to_remove = state.data ? state.data[user_to_remove_index] : null
      let newData = state.data?.filter(d => !(d.email === user_to_remove.email))

      let newPending = state.pendingRemoval ?? []
      newPending.push({user: user_to_remove, index: user_to_remove_index})

      return {
        ...state,
        data: newData,
        pendingRemoval: newPending,
        total: state.total - 1
      }
    }
    case 'USER_REMOVED': {
      let newPending = state.pendingRemoval?.filter(u => u.user.email !== action.email) ?? []

      return {
        ...state,
        pendingRemoval: newPending
      }
    }
    case 'REMOVING_USER_FAILED': {
      let failed_user = state.pendingRemoval.find(u => u.user.email === action.email)
      let newPending = state.pendingRemoval?.filter(u => u.user.email !== action.email) ?? []
      let newData = state.data ?? []
      newData.splice(failed_user.index, 0, failed_user.user)

      return {
        ...state,
        data: newData,
        pendingRemoval: newPending,
        total: state.total + 1
      }
    }

    default:
      return state
  }
}

export const authenticated_user = (state = null, action) => {
  switch (action.type) {
    case 'AUTHENTICATED_USER_FETCHED':
      return {
        email: action.user.email,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        created: action.user.created,
        updated: action.user.updated,
        tags: action.user.user_metadata.info.tags
      }
    default:
      return state
  }
}

// export const userAdded = (state = {}, action) => {
//   switch (action.type) {
//     case 'USER_ADDED':
//       return action.data
//     default:
//       return state
//   }
// }