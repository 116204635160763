import React, { Fragment, useEffect, useState } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import { connect } from "react-redux";
import { timespanOptions } from "../../../datePickers/timespanPickers/timespanPickers";
import ReportPage from "../../../reportPage.v2";
import { lps_summary_columns } from "./summary_columns";
import { fetchChargingData } from "../../../../../state/actions/vehicles";
import { useAuth0 } from "@auth0/auth0-react";
import KpiMultipleRows from '@modul-connect/shared/components/molecules/kpiRow/kpiMultipleRows';
import GreenBullet from '@modul-connect/shared/img/green_bullet.svg'
import GreyBullet from '@modul-connect/shared/img/grey_bullet.svg'
import BlackBullet from '@modul-connect/shared/img/black_bullet.svg'
import { H3 } from '@modul-connect/shared/components/atoms/text';
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { DateTime } from 'luxon'
import theme from "@modul-connect/shared/theme";
import ChartBar from "../../ChartBar";

const ISBIGSCREEN = window.screen.width >= 2000

const monthNames = 
[
  "Jan.", "Feb.", "March", "April", "May", "June",
  "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."
];

const CollapsibleDetails = ({ lps_charging_data, boxId, startDate, endDate }) => {
  let isYearSelected = false
  let isMoreThanWeekLessThanMonth = false

  const data = []
  if(!lps_charging_data[boxId]?.length) return

  const isMoreThanMonth = endDate.diff(startDate, ["months"]).toObject().months > 1
  const isMoreThanWeek = endDate.diff(startDate, ["weeks"]).toObject().weeks > 1
  isMoreThanWeekLessThanMonth = isMoreThanWeek && !isMoreThanMonth
  isYearSelected = isMoreThanMonth ? true : false

  lps_charging_data[boxId].map(d => {
    return data.push({
      name: d.date,
      DC: d.accumulated12Dc.value?.toFixed(2),
      AC: d.accumulated230Vac.value?.toFixed(2),
      Solar: d.accumulatedSolar.value?.toFixed(2),
      max_value: Math.max(d.accumulated12Dc.value, d.accumulated230Vac.value, d.accumulatedSolar.value)?.toFixed(2),
    })
  })

  const maxValue = Math.ceil(data.reduce((max, obj) => (obj.max_value > max ? obj.max_value : max), Number.MIN_SAFE_INTEGER));
  var width = ISBIGSCREEN ? 0.8 * window.screen.width : 0.75 * window.screen.width
  const lines = []
  let number = 0
  let numberOfLine = 0
  let yAxisValues = [0]
  do{
    numberOfLine++
    number += maxValue * 0.1
    var shouldThickLine = numberOfLine % 5 === 0
    if(shouldThickLine)
      yAxisValues.push(number)
    lines.push(
      {
        value: number,
        colorLine: shouldThickLine ? theme.colors.blackLine : theme.colors.greyLine
      },
    )
  }while(number < maxValue)


  const LabelTextTop = ({children, x, y}) => {
    return (
      <text x={x} y={y + 6} textAnchor="middle" dominantBaseline="central" style={{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px'
      }}>
        {children}
      </text>
    )
  }
  const LabelTextBottom = ({children, x, y}) => {
    return (
      <text x={x} y={y + 20} textAnchor="middle" dominantBaseline="central" style={{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '12px'
      }}>
        {children}
      </text>
    )
  }
  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    const dateTime = DateTime.fromISO(payload.value)
    if (isMoreThanWeekLessThanMonth) {
      return <Fragment>
        <LabelTextTop x={x} y={y}>{dateTime.day}</LabelTextTop>
        <LabelTextBottom x={x} y={y}>{monthNames[dateTime.month - 1]}</LabelTextBottom>
      </Fragment>
    }
    return (
      <Fragment>
        <LabelTextTop x={x} y={y}>{dateTime.year}</LabelTextTop>
        <LabelTextBottom x={x} y={y}>{!isYearSelected ? dateTime.day + ' ' + monthNames[dateTime.month - 1] : monthNames[dateTime.month - 1]}</LabelTextBottom>
      </Fragment>
    )
  };

  return (
    lps_charging_data[boxId].length >= 1  
    ? <View  style={{ 
      display:'flex',
      flexDirection:'column',
      alignItems: 'flex-start',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px',
      }}>
      <View style={{      
        marginTop: 20,
        marginLeft: 20,
        marginBottom:24 }}>
        {'Charged'}
      </View>
      <View style={{marginLeft: 45,  marginRight: 40, marginBottom: 5 }} >
        <ChartBar data={data} lines={lines} width={width}  maxValue={maxValue} tickXaxis={renderCustomBarLabel} tickYaxis={yAxisValues}/>
      </View>
      <View style={{display:'flex',flexDirection:'row',alignItems: 'flex-start', width: width, marginBottom:24 }}>
        <DataAnalyser lps_charging_data={lps_charging_data} boxId={boxId}/>
      </View>
    </View> 
    : <H3>No data to display.</H3>
  )
}

const DataAnalyserRoute = ({ label, unit }) => {
  return (
    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <View>{label}</View>
      <View>{unit}</View>
    </View>
  )
}
const DataAnalyser = ({lps_charging_data, boxId}) => {
  const staticRows = 
    [
      {
        icon: <img src={ GreenBullet }/>,
        value: <DataAnalyserRoute label={'Solar'} unit={'(kWh)'} />
      },
      {
        icon: <img src={ GreyBullet }/>,
        value: <DataAnalyserRoute label={'AC'} unit={'(kWh)'} />
      },
      {
        icon: <img src={ BlackBullet }/>,
        value: <DataAnalyserRoute label={'DC'} unit={'(kWh)'} />
      },
  ]

  const preparedRows = []
  lps_charging_data[boxId].map(d =>
    {
      return preparedRows.push([
        {
          icon: null,
          value: d.accumulatedSolar.value?.toFixed(2)
        },
        {
          icon: null,
          value: d.accumulated230Vac.value?.toFixed(2)
        },
        {
          icon: null,
          value: d.accumulated12Dc.value?.toFixed(2)
        },
      ])
    } )
    
  return (
    <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      <View style={{
        padding: 2, 
        marginLeft: 20,
        marginBottom: 12, 
        minWidth: ISBIGSCREEN ? '5%' : '8%',
        flex:1,
        }}>
        <KpiMultipleRows rows={staticRows}/>
      </View>
      {preparedRows.map((row, index) =>
        <View style={{
          padding: 2, 
          marginBottom: 12,
          flex:10}} 
          key={index}>
            <KpiMultipleRows rows={row} extend={{alignItems:'center'}}/>
        </View>
        )   
      }
    </View>
  )
}

const expandableArrowText = ' '

const HistorySubpage = ({
  isLoading,
  loadingFailed,
  disabled,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  loading,
  themes,
  lps_report,
  lps_charging_data,
  fetchChargingData
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [tableData, setTableData] = useState([] ?? [])

  useEffect(() => {
    setTableData(lps_report?.summary?.map(entry => {
      const totalCharged = entry?.energyMeter?.accumulated230Vac?.value + entry?.energyMeter?.accumulated12Dc?.value + entry?.energyMeter?.accumulatedSolar?.value

      return {
        id: entry?.mainboxId,
        boxId: entry?.mainboxId,
        telematicsId: entry?.telematicsId,
        vehicle: entry?.name ?? entry?.mainboxId,
        chargedKWH: totalCharged,
        solarKWH: entry?.energyMeter?.accumulatedSolar?.value,
        dcKWH: entry?.energyMeter?.accumulated12Dc?.value,
        acKWH: entry?.energyMeter?.accumulated230Vac?.value,
        isExpandable: true,
        expandableArrowText: expandableArrowText,
        collapsibleComponent: 
          loading.fetchLpsChargingData === 'loading' ? <BubbleLoadingWidget horizontal text={"Loading ..."} />
          : loading.fetchLpsChargingData === 'failed' ? <H3> Failed to fetch charging data</H3> 
          : <CollapsibleDetails lps_charging_data={lps_charging_data} boxId={entry?.mainboxId} startDate={startDate} endDate={endDate}/>,
        onExpand:() => {
          getAccessTokenSilently().then((accessToken) => {
            fetchChargingData(accessToken, entry?.mainboxId, startDate, endDate)
          })
        },
      }
    }).sort(function(a, b) {
      var textA = a.vehicle?.toUpperCase();
      var textB = b.vehicle?.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }))
  }, [lps_report, lps_charging_data, loading])

  const [searchStr, setSearchStr] = useState('')

  const [filteredData, setFilteredData] = useState(tableData)

  useEffect(() => {
    tableData?.length && setFilteredData((!searchStr || searchStr === '') ? tableData : tableData?.filter(entry => { return (entry?.vehicle ?? '').toLowerCase().includes(searchStr?.toLowerCase()) }))
  }, [searchStr, tableData])
  
  // TODO: why does opening and closing the collapsible component take such a long time?
  return (
    <ReportPage
      title={"Charged"}
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom
      ]}
      noDataMessage={loadingFailed ? "There was an error loading the LPS logs from the server." : "There are no LPS logs for the selected time period."}
      isLoading={isLoading}
      fetchingDataFailed={loadingFailed}
      disabled={disabled}
      startDate={startDate}
      endDate={endDate}
      onTimeframePicked={(start, end) => {
        setStartDate(start);
        setEndDate(end);
      }}
      onSearchChange={setSearchStr}
      tables={ tableData?.length ?
        <TableWidgetSeparated
          data={filteredData}
          loadingStatus={loading.fetchLPSReportDetails}
          columns={lps_summary_columns}
          themes={themes}
          isSortable
          pagination={false}
          collapsibleDataLoadingStatus={null} // TODO

        /> : []
      }
    />
  )
}

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  fetchChargingData: (accessToken, boxId, start, end) => dispatch(fetchChargingData(accessToken, boxId, start, end))
});

export default connect(mapStateToProps, mapDispatchToProps)(HistorySubpage);