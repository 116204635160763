import React from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import { connect } from "react-redux";
import theme from "@modul-connect/shared/theme";


const CustomLabel = ({ viewBox, data, styleText }) => {
  const { cx, cy } = viewBox;
  return (
    <React.Fragment>
      <text x={cx - 25} y={cy}>
        <tspan
          style={styleText}
        >
          {data[0].value || data[0].value === 0 ? data[0].value?.toFixed(0) + ' %' : '-'}
        </tspan>
      </text>
      <text x={"50%"} y={cy + 25} textAnchor="middle">
        <tspan
          style={{...styleText, fontWeight: 300, fontSize: '22px'}}
        >
        {data[0].textValue || data[0].textValue === 0 ? data[0].textValue + ' kWh' : '-'}

        </tspan>
      </text>
    </React.Fragment>
  );
};

const ChartPie = ({
  data,
  width,
  height,
  innerRadius,
  outerRadius,
  styleText,
  customLabel = false
}) => {
  return (
    data?.length ?
      <PieChart width={width} height={height} style={{outline: 'none'}} >
        <Pie
          data={data}
          dataKey="value"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          style={{outline: 'none'}}
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry, index) => {
            if (index === 1) {
              return <Cell key={`cell-${index}`} fill={entry?.color} style={{ opacity: '20%' }} />;
            }
            return <Cell key={`cell-${index}`} fill={entry?.color} />;
          })}
          {
            customLabel 
            ?
            <Label
            content={<CustomLabel data={data} styleText={styleText}/>}
            position="center"
            />
          :
            <Label
              value={data[0].value || data[0].value === 0 ? data[0].value?.toFixed(0) + ' %' : '-'}
              position="center"
              fill={theme.colors.black}
              style={{styleText}}
            /> 

          }
        </Pie>
      </PieChart> : null
  )
}


const mapStateToProps = (props) => props;

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ChartPie);
