import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';
import theme from "@modul-connect/shared/theme"
import chroma from "chroma-js";

export default class SelectableBarChart extends PureComponent {
  get data() { return this.props.data ?? [] }
  get unit() { return this.props.unit ?? [] }
  get tooltipText() { return this.props.tooltipText ?? [] }
  get onSelect() { return this.props.onSelect ?? [] }
  get onDeselect() { return this.props.onDeselect ?? [] }

  onClick (data) {
    const vehicleId = data?.activePayload[0].payload.vehicleId
    const index = data?.activeTooltipIndex
    const wasSelected = this.state.selectedIndex === index
    this.setState({
      selectedIndex: wasSelected ? null : index
    });
    if (wasSelected) {
      if (this.onDeselect)
        this.onDeselect()
    }
    else {
      if (this.onSelect)
        this.onSelect(vehicleId)
    }
  }

  constructor() {
    super()
    this.state = { selectedIndex: null}
    this.onClick = this.onClick.bind(this);
  }

  render() {
    return (
        <BarChart
          layout='vertical'
          data={this.data}
          width={600} height={250}
          onClick={this.onClick}
          title={'sdfghjk'}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis dataKey="name" type="category" width={120}/>
          <XAxis type="number" domain={[0, 100]} tickFormatter={ tick => tick + this.unit }/>
          <Tooltip 
            formatter={(value, name, props) => [
              <div>
                {value + this.unit}
                <br />
                <br />
                {props.payload?.additionalInfo ? props.payload.additionalInfo : ''}
              </div>,
            this.tooltipText]  
          }
          />
          <Bar dataKey="percentage" stackId="a" barSize={20} fill={theme.colors.error}>
            {this.data.map((entry, index) => (
              <Cell 
                cursor="pointer" 
                fill={ 'rgb(' + 
                  (index === this.state.selectedIndex ? chroma(theme.colors.error) : chroma(theme.colors.error).darken())
                    .rgb().join(',') + ')'
                  } 
                key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
    );
  }
}