import { Subtree, SUBTREES_FETCHED, FETCHED_API_KEYS, ApiKey, NewApiKey, RECEIVED_NEW_API_KEY, EXPIRED_API_KEY_BY_FORCE, CustomerActionTypes, FETCHED_ORGANISATION_NAME, SelectedOrganisations, SET_SELECTED_ORGANISATIONS, AUTHENTICATED_USER_FETCHED } from "../types/customer"

// SUBTREES

export interface Subtrees {
  subtrees: Subtree[]
  total: number
}

const initialSubtrees: Subtrees = {
  subtrees: [],
  total: 0
}
export const subtrees = (state = initialSubtrees, action: CustomerActionTypes) : Subtrees => {
  switch (action.type) {
    case SUBTREES_FETCHED:
      return {
        subtrees: action.subtrees,
        total: action.total
      }
    default:
      return state
  }
}

// ORGANISATION

export const organisationNames = (state = {}, action: CustomerActionTypes) => {
  switch (action.type) {
    case FETCHED_ORGANISATION_NAME:
      return {
        ...state,
        [action.organisation.uuid]: action.organisation.translated
      }
    default:
      return state
  }
}

// API KEYS

export interface ApiKeys {
  keys: ApiKey[],
  total: number
}

const initialApiKeys: ApiKeys = {
  keys: [],
  total: 0
}

export const api_keys = (state = initialApiKeys, action: CustomerActionTypes) : ApiKeys => {
  let newKeys: ApiKey[]
  switch (action.type) {
    case FETCHED_API_KEYS:
      return {
        keys: action.keys,
        total: action.total
      }
    case EXPIRED_API_KEY_BY_FORCE:
      newKeys = state.keys.filter(key => key.id !== action.id)
      return {
        keys: newKeys,
        total: newKeys.length
      }
    case RECEIVED_NEW_API_KEY:
      newKeys = [
        ...state.keys,
        {
          id: action.key.public_id,
          expiresAt: action.key.expires_at_utc,
          organisations: []
        }
      ]
      return {
        keys: newKeys,
        total: newKeys.length,
      }
    default:
      return state
  }
}

// NEW API KEY

const initialNewApiKey: NewApiKey = {
  public_id: '',
  expires_at_utc: new Date(),
  api_key: '',
}

export const new_api_key = (state = initialNewApiKey, action: CustomerActionTypes) : NewApiKey => {
  switch (action.type) {
    case RECEIVED_NEW_API_KEY:
      return action.key
    default:
      return state
  }
}

// SELECTED ORGANISATION

export const selected_organisations = (state = [], action: CustomerActionTypes | {type: "USER_LOGGED_IN"}) : SelectedOrganisations => {
  switch (action.type) {
    case SET_SELECTED_ORGANISATIONS:
      return action.uuids
    case AUTHENTICATED_USER_FETCHED:
      return action.isNewUser ? [] : state
    case "USER_LOGGED_IN":
      return []
    default:
      return state
  }
}