export const styles = {
    default: ({ theme: { layout } }) => ({
      position: "absolute",
      top: layout.navBarHeight + layout.navigationBarHeight,
      right: 0,
      left: 0
    }),
    switchMap: {
      height: 29,
      width: 29,
      background: "#fff",
      position: 'absolute',
      right: 10,
      borderRadius: 4,
      fontSize: 27,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
      '&:hover': {
        backgroundColor: '#efefef'
      }
    },
    switchMap_control_pos: {
      bottom: 100,
    },
    switchMap_control_pos_mobile: {
      top: 65
    }
  }
