import React from 'react'
import { connect } from 'react-redux'

const ReportDashboardPage = ({ 
}) => {        
    return null // TODO once we have the design
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboardPage);