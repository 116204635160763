export const themes = (state = {}, action) => {
    switch (action.type) {
      case 'VIEW_DIMENSION_UPDATED':
        return {
            ...state,
            device: action.device,
            orientation: action.orientation
        }
      default:
        return state;
    }
}