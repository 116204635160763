import React from 'react'
import View from "@modul-connect/shared/components/atoms/view";

const PaddedContainer = ({ children }) => {
  return (
    <View style={{ padding: 10, width: '100%' }}>
      { children }
    </View>
  )
}

export default PaddedContainer