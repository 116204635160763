import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { DateTime } from "luxon";
import { fetchDrivingLogSummaries, fetchVehiclesData, fetchTemperatureReport } from '../../../state/actions/vehicles'
import TemperatureReportDetails from './temperatureReportDetails/temperatureReportDetails';
import { getServiceList } from '../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';

const TemperatureReportPage = ({
  selected_organisations,
  fetchVehiclesData,
  fetchTemperatureReport,
  subtrees
}) =>{
  const { getAccessTokenSilently } = useAuth0()
  const disabled = !getServiceList({subtrees}).hasService(ServiceType.ClimateReports)


    let [startDateDetails, setStartDateDetails] = useState(DateTime.local().startOf('month'))
    let [endDateDetails, setEndDateDetails] = useState(DateTime.local())
    let [selectedVehicle, setSelectedVehicle] = useState(null)

    useEffect(() => {
      getAccessTokenSilently().then(accessToken => {
          const start = startDateDetails ? startDateDetails : null
          const end =  endDateDetails ? endDateDetails : null
          fetchTemperatureReport(accessToken, start, end, selectedVehicle)
      })
  }, [startDateDetails, endDateDetails, selectedVehicle, selected_organisations])

  useEffect(() => {
    getAccessTokenSilently().then(fetchVehiclesData)
}, [selected_organisations])

return (
    <React.Fragment>
        <TemperatureReportDetails
            startDate={startDateDetails} 
            endDate={endDateDetails} 
            onStartDatePicked={(date) => setStartDateDetails(date.startOf('day'))}
            onEndDatePicked={(date) => setEndDateDetails(date.endOf('day'))}
            onVehicleSelected={setSelectedVehicle}
            disabled={disabled}
        />
    </React.Fragment>
)
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
    fetchDrivingLogSummaries: (accessToken, start, end) => dispatch(fetchDrivingLogSummaries(accessToken, start, end)),
    fetchVehiclesData: (accessToken) => dispatch(fetchVehiclesData(accessToken)),
    fetchTemperatureReport: (accessToken, start, end, boxId) => dispatch(fetchTemperatureReport(accessToken, start, end, boxId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TemperatureReportPage);