import React from 'react'
import View from '@modul-connect/shared/components/atoms/view'

export const RouteMarker = ({ text, large, color, textColor }) => {
  const numberTooBigToFit = large && !isNaN(text)&& text > 99
  return (
    <View extend={[
      styles.routeMarker,
      color && {backgroundColor: color},
      textColor && {color: textColor}, 
      large && {fontWeight: 300, fontSize: numberTooBigToFit ? '14px' : '18px', width: '28px', height: '28px'},
    ]}>{text}</View>
  )
}

const styles = {
  routeMarker: ({ theme: { layout, colors } }) => ({
    borderRadius: 100,
    color: colors.white,
    background: colors.grey,
    fontSize: 14,
    width: 19,
    height: 19,
    textAlign: 'center',
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
}