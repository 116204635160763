import React, {Fragment} from 'react';
import { Modal } from "@mui/material";
import Fade from '@mui/material/Fade';

import View from '@modul-connect/shared/components/atoms/view'
import { H1, P } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'


const ModalAjaxError = ({
    openModal,
    onClose
}) => {

    return (
        <Modal
            open={ openModal }
            onClose={() => onClose() }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={'Save failed'}/>
                        </View>
                        <View extend={ modal.content }>
                            <Fragment>
                                <P>This was embarrassing, the save failed. Please try again or contact support.</P>
                            </Fragment>
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg noPadding onClick={ () => onClose() }>
                                Close
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalAjaxError;