import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { DateTime } from "luxon";
import { log_columns } from './log_columns'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { useNavigate } from "react-router-dom";
import TopFiveDisplay from './topFiveDisplay/topFiveDisplay';
import KpiRow from '@modul-connect/shared/components/molecules/kpiRow/kpiRow';
import theme from "@modul-connect/shared/theme"
import TableWidgetSeparated from "@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2"
import ReportPage from '../../reportPage.v2';
import { getDayName } from '@modul-connect/shared/utils/text'
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers';

const BatteryReportDetails = ({
  startDate,
  endDate,
  onStartDatePicked,
  onEndDatePicked,
  themes,
  loading,
  battery_report,
  onVehicleSelected,
  disabled
}) => {
  let navigate = useNavigate()

  let [reportData, setReportLogs] = useState(null)
  let [kpiData, setKpiData] = useState(null)
  let [selectedVehicleId, setSelectedVehicleId] = useState(null)
  let [data, setData] = useState({})

  const goToVehicleDetailsPage = (event, index) => {
    const logs = battery_report?.data?.logs
    if (!logs || logs.length < index) {
      return
    }

    const log = logs[index]

    if (!log?.boxId) {
      return
    }

    navigate('/fleet/fleet_vehicles/' + log.boxId);
  }

  const getDataByDay = (data) => {

    let dividedByDay = {}
    let dailyList = []

    let prevTimestamp = ''

    if (data?.length) {
      data.forEach(log => {

        if (prevTimestamp && (log.timestamp.startOf("day").toMillis() !== prevTimestamp.startOf("day").toMillis())) {

          dividedByDay[prevTimestamp] = [...dailyList]
          dailyList = []
        }
        dailyList.push(log)
        prevTimestamp = log.timestamp
      })
    }
    if (dailyList.length > 0) {

      dividedByDay[prevTimestamp] = [...dailyList]
    }
    return dividedByDay
  }

  const totalHours = (endDate - startDate) / 60 / 60 / 1000
  const top_five_data = battery_report?.data?.byVehicle ? battery_report?.data?.byVehicle.map(d => {
    return {
      name: d.vehicle,
      percentage: Math.min((d.hours / totalHours * 100).toFixed(2), 100),
      additionalInfo: 'Number of warnings: ' + d.occasions,
      vehicleId: d.id
    }
  }) : []

  useEffect(() => {
    let nextId = -1
    let preparedData = []

    const logs = battery_report?.data?.logs
    if (logs && logs.length >= 0) {
      setReportLogs(logs.map((log, index) => {
        nextId++
        preparedData.push({
          id: nextId,
          key: nextId + '_' + index,
          boxId: log.boxId,
          batteryId: log.batteryId,
          voltage: {
            value: `${log.voltage.toFixed(2)} V`,
            guard: log.lowerBatteryGuard ?? 11.7
          },
          timestamp: log.startTime ? DateTime.fromISO(log.startTime) : null,
          timestamp_end: log.endTime ? DateTime.fromISO(log.endTime) : null,
          vehicle: log.vehicle,
          endTimeString: log.endTime ? dateTimeToString(DateTime.fromISO(log.endTime), 'time-only') : null,
          startTimeString: log.startTime ? dateTimeToString(DateTime.fromISO(log.startTime), 'time-only') : null,
        })
      }))
    }
    setKpiData([
      {
        title: 'Incidents',
        value: battery_report?.data?.count ?? '-'
      },
      {
        title: 'Hours of warnings',
        value: battery_report?.data?.totalHours?.toFixed(0) ?? '-'
      },
      {
        title: 'Average warning time',
        value: battery_report?.data ? toPercentage(battery_report.data.ratio ?? 0) : '-',
        unit: battery_report?.data ? '%' : '',
        color: percentageColor(battery_report?.data?.ratio ?? 0)
      }
    ])

    if (preparedData && preparedData.length > 0) {
      setData(getDataByDay(preparedData))
    } else {
      setData({})
    }

  }, [battery_report])

  const toPercentage = (ratio) => `${(100 * ratio).toFixed(1)}`
  const percentageColor = (ratio) => ratio > 0.9 ? theme.colors.error : ratio > 0.67 ? theme.colors.yellowText : theme.colors.black
  
  const topFiveDisplay = (
      loading.fetchBatteryReport === null && (!reportData || reportData.length === 0) ? null
      : top_five_data?.length > 0 && !loading.fetchBatteryReport &&
        <TopFiveDisplay
          title={'Top 5 vehicles with battery warnings'}
          unit={'%'}
          data={top_five_data}
          tooltipText={'Time spent with battery warning'}
          onSelect={(selectedId) => {
            setSelectedVehicleId(selectedId)
            onVehicleSelected(selectedId)
          }}
          onDeselect={() => {
            setSelectedVehicleId(null)
            onVehicleSelected(null)
          }}
        />
  )

  const tables = Object.keys(data).map(key => {
    const log = data[key]
    return <TableWidgetSeparated
      tableTitle={getDayName(DateTime.fromISO(key))}
      key={key}
      data={log}
      loadingStatus={loading.progress}
      totalItems={reportData}
      columns={log_columns}
      themes={themes}
      isSortable={false}
      pagination={false}
      onClickRow={goToVehicleDetailsPage}
      rowsOptions={[log?.length]}
    />
  })

  return (
    <ReportPage
      useOldDatePickers
      title={'Battery Report'}
      noDataMessage={'There are no battery warnings for the selected time period.'}
      disabled={disabled}
      timeSelectors={[timespanOptions.custom]}
      startDate={startDate}
      endDate={endDate}
      onStartDatePicked={onStartDatePicked}
      onEndDatePicked={onEndDatePicked}
      noSearch
      tablesInOwnSubPage
      isLoading={loading?.progress === 'loading'}
      fetchingDataFailed={loading?.progress === 'failed'}
      subpageTitle={"Battery Incidents" +
        (selectedVehicleId ?
          (' for ' + top_five_data.find(v => v.vehicleId === selectedVehicleId)?.name)
          : '')}
      kpi={kpiData ? <KpiRow title={'Status'} width={'100%'} bgColor={'white'} data={kpiData} isLoading={loading.progress} /> : null}
      tables={tables}
      reportGraph={topFiveDisplay}
    />
  ) 
}

const mapStateToProps = props => props;

export default connect(mapStateToProps, {})(BatteryReportDetails);
