import { combineReducers } from 'redux'
import AsyncStorage from '@react-native-community/async-storage';
import { persistReducer } from 'redux-persist'

import {
    users,
    authenticated_user
} from './users'
import {
    selectedVehicle,
    selectedRoute,
    selectedAssetTracker,
    vehicles,
    vehicle_animation_status,
    vehicles_data,
    vehicles_sorted,
    battery_logs,
    lps_logs,
    weight_logs,
    weight_report_summary,
    battery_report,
    temperature_report,
    humidity_report,
    asset_tracker_report,
    lps_report,
    lps_footprint,
    lps_charging_data,
    tyrePressure_report,
    overweight_report,
    overweight_report_v2,
    driving_logs,
    driving_log_summary,
    driving_log_details,
    warning_logs,
    warning_logs_history,
    toll_reports,
    current_drivers,
    current_mileage,
    signalRConnection,
    temperature_logs,
    humidity_logs,
    security_reports_summary,
    security_reports_detailed,
    selectedSecurityAlarm,
    notificationsSignalRConnection,
    vehicles_security_system_status,
    vehicles_locks
} from './vehicles'
import {
    subtrees,
    organisationNames,
    api_keys,
    new_api_key,
    selected_organisations
} from './customer'
import {
    themes
} from './themes'
import {
    saving,
    loading,
    log_loading,
    vehicle_loading,
    userTerms,
    acceptedUserTerms,
    downloading,
    showWarningBar,
    showSuccessBar,
    showDisturbanceBar,
    uploaded_file,
} from './app'
import { beacons } from './beacons'
import { map_settings, user_settings } from './settings'
import { mapPopups } from './map'
import { 
  assets, 
  vehicle_assets, 
  asset_addresses, 
  maintenance_tasks, 
  maintenance_summary,
  maintenance_plans,
  asset_documents
 } from './assets';
 import {notifications} from './notifications'

const rootPersistConfig = {
  key: 'root',
  storage: AsyncStorage,
  debug: true,
  whitelist: [
    'selected_organisations',
    'authenticated_user',
    'user_settings'
  ]
}

const reducers = combineReducers({
    // boxes_registered,
    // is_logged_in,
    authenticated_user,
    selectedVehicle,
    selectedRoute,
    selectedAssetTracker,
    battery_report,
    temperature_report,
    humidity_report,
    asset_tracker_report,
    lps_report,
    lps_charging_data,
    tyrePressure_report,
    overweight_report,
    overweight_report_v2,
    driving_logs,
    driving_log_summary,
    driving_log_details,
    warning_logs,
    warning_logs_history,
    toll_reports,
    signalRConnection,
    subtrees,
    organisationNames,
    api_keys,
    new_api_key,
    themes,
    users,
    vehicles,
    vehicle_animation_status,
    vehicles_data,
    vehicles_sorted,
    saving,
    loading,
    log_loading,
    vehicle_loading,
    battery_logs,
    lps_logs,
    lps_footprint,
    weight_logs,
    weight_report_summary,
    current_drivers,
    current_mileage,
    userTerms,
    acceptedUserTerms,
    selected_organisations,
    downloading,
    beacons,
    map_settings,
    mapPopups,
    temperature_logs,
    humidity_logs,
    assets,
    maintenance_tasks,
    maintenance_summary,
    maintenance_plans,
    asset_documents,
    vehicle_assets,
    asset_addresses,
    showWarningBar,
    showSuccessBar,
    showDisturbanceBar,
    uploaded_file,
    user_settings,
    security_reports_summary,
    security_reports_detailed,
    selectedSecurityAlarm,
    notifications,
    notificationsSignalRConnection,
    vehicles_security_system_status,
    vehicles_locks
    // userAdd  ed,
    // users_registered,
})

export default persistReducer(rootPersistConfig, reducers)