import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import View from '@modul-connect/shared/components/atoms/view'
import Vehicle from '@modul-connect/shared/img/vehicle.svg'
import Workshop from '@modul-connect/shared/img/workshop.svg'
import OnSite from '@modul-connect/shared/img/on_site.svg'
import UncheckedBox from '@modul-connect/shared/img/small_unchecked_box.svg'
import CheckedBox from '@modul-connect/shared/img/check-box-ok.svg'
import { DateSelector } from "@modul-connect/shared/components/molecules/daySelector/daySelector";
import { useAuth0 } from "@auth0/auth0-react";
import { InputField } from '@modul-connect/shared/components/atoms/textInput/inputField'
import { DateTime } from "luxon";
import { markMaintenancePeriodAsDone } from "../../../../state/actions/assets";
import { clearUploadedDocuments, removeDocument } from "../../../../state/actions/vehicles";
import { ModalBase } from "@modul-connect/shared/components/molecules/modal/ModalBase";
import UploadFileLink from "@modul-connect/shared/components/molecules/uploadFileLink";

export const homeBaseType = {
  Workshop: "Workshop",
  Vehicle: "Vehicle",
  OnSite: "OnSite"
}
const AddOrUpdatePerformedMaintenancePeriod = ({
  open,
  onClose,
  onSave,
  existingMaintenancePeriod, // not currently sent from anywhere

  setOpenUploadPDF,

  maintenanceTask,

  markMaintenancePeriodAsDone,
  removeDocument,
  clearUploadedDocuments,

  uploaded_file,
  saving
}) => {
  const [document, setDocument] = useState(existingMaintenancePeriod?.document ?? (uploaded_file ?? null))
  const [comment, setComment] = useState(existingMaintenancePeriod?.comment ?? '')
  const [maintenanceDate, setMaintenanceDate] = useState((existingMaintenancePeriod?.endDate && DateTime.fromISO(existingMaintenancePeriod.endDate)) ?? DateTime.local().startOf('day'))

  const commentTooLong = comment.length > 50
  const isUploadingDocument = saving.uploadDocument === 'uploading'

  useEffect(() => {
    setDocument(existingMaintenancePeriod?.document ?? null)
    setComment(existingMaintenancePeriod?.comment ?? '')
    setMaintenanceDate(existingMaintenancePeriod?.endDate ?? DateTime.local().startOf('day'))

    if (open) clearUploadedDocuments()
  }, [open])

  const disabled = !comment || commentTooLong || !maintenanceDate || isUploadingDocument || !maintenanceDate


  const { getAccessTokenSilently } = useAuth0();

  const sendPerformedMaintenanceTask = () => {
    getAccessTokenSilently().then((accessToken) =>
    markMaintenancePeriodAsDone(
      accessToken,
      maintenanceTask.periodId,
      maintenanceDate.toISO({ includeOffset: false }),
      uploaded_file?.blobUrl,
      comment,
      maintenanceTask.maintenancePlanId,
      maintenanceTask.assetId,
      uploaded_file?.fileName
    ))
  }

  return (
    <ModalBase open={open} onClose={onClose}>
        <View style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
          <UploadFileLink />

        <DateSelector isRequired opensOn={'middle'} placeholder={"Day of maintenance"} disableDaysAfter={DateTime.local()} disableDaysBefore={maintenanceTask && DateTime.fromISO(maintenanceTask.startDate)} date={maintenanceDate} onDayPicked={setMaintenanceDate}/>

        <InputField
          isRequired
          placeholder={'Add comments'}
          label={'Comment'}
          value={comment}
          onChange={(newComment) => {
            if (!commentTooLong || newComment?.length < comment.length) setComment(newComment)
          }}
          error={commentTooLong}
          helperText={`(${comment.length}/50)`}
        />
      
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
          <Button
            extend={{ width: '30%' }}
            disabled={disabled}
            onClick={() => {
              if (!existingMaintenancePeriod?.endDate) sendPerformedMaintenanceTask();
              onSave();
            }}
          >
            {'Save'}
          </Button>

          <Button
            border
            noBg
            onClick={() => {onClose()}}
          >
            {'Cancel'}
          </Button>
        </View>
      </View>
    </ModalBase>
  )
}

export const CustomHomebaseSelector = ({
  vehicleIconSelected,
  setVehicleIconSelected,
  workshopIconSelected,
  setWorkshopIconSelected,
  onSiteIconSelected,
  setOnSiteIconSelected,
  onEnd,
  fullWidth,
  allowOneSelection
}) => {
  const VehicleIcon = <img src={Vehicle} />
  const OrganisationIcon = <img src={Workshop} />
  const OnSiteIcon = <img src={OnSite} />
  const UncheckedBoxIcon = <img src={UncheckedBox} />
  const CheckedBoxIcon = <img src={CheckedBox} />

  return (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: onEnd ? 'flex-end' : 'flex-start', width: fullWidth ? '100%' : 'auto', gap: 20, padding: 5 }}>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
        <View onClick={() => {
          if (allowOneSelection) {
            setVehicleIconSelected(true);
            setWorkshopIconSelected(false);
            setOnSiteIconSelected(false)
          }
          else {
            setVehicleIconSelected(!vehicleIconSelected);
          }
        }}>
          {vehicleIconSelected ? CheckedBoxIcon : UncheckedBoxIcon}
        </View>
        <View>
          {VehicleIcon}
        </View>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }} >
        <View onClick={() => {
          if (allowOneSelection) {
            setVehicleIconSelected(false);
            setWorkshopIconSelected(true);
            setOnSiteIconSelected(false)
          }
          else {
            setWorkshopIconSelected(!workshopIconSelected);
          }
        }} >
          {workshopIconSelected ? CheckedBoxIcon : UncheckedBoxIcon}
        </View>
        <View>
          {OrganisationIcon}
        </View>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
        <View onClick={() => {
          if (allowOneSelection) {
            setVehicleIconSelected(false);
            setWorkshopIconSelected(false);
            setOnSiteIconSelected(true)
          }
          else {
            setOnSiteIconSelected(!onSiteIconSelected);
          }
        }}>
          {onSiteIconSelected ? CheckedBoxIcon : UncheckedBoxIcon}
        </View>
        <View>
          {OnSiteIcon}
        </View>
      </View>
    </View>
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  markMaintenancePeriodAsDone: (
    accessToken,
    periodId,
    endDate,
    document,
    comment,
    maintenancePlanId,
    assetId,
    fileName
  ) => dispatch(markMaintenancePeriodAsDone(
    accessToken,
    periodId,
    endDate,
    document,
    comment,
    maintenancePlanId,
    assetId,
    fileName
  )),
  removeDocument: (accessToken, documentId) => dispatch(removeDocument(accessToken, documentId)),
  clearUploadedDocuments: () => dispatch(clearUploadedDocuments())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdatePerformedMaintenancePeriod);