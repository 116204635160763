import React, { Fragment } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { Divider } from "@mui/material";


const KpiMultipleRows = ({
  rows, 
  extend
}) => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'column', ...extend}}>
        {
          rows.map((row, index) =>
            <Fragment key={index}>
              <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 4, marginTop: 4 }}>
                <View>
                  {row.icon ? row.icon : null}
                </View>
                <View style={{ paddingLeft: 4, width: '100%' }}>
                  {row.value ? row.value : '-'}
                </View>
              </View>
              <Divider sx={{ width: '100%' }} />
            </Fragment>
          )
        }
      </View>
    </View>
  )
}

export default KpiMultipleRows;
