import React, { Fragment } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { connect } from "react-redux";
import { H3, P } from "@modul-connect/shared/components/atoms/text";
import Input from "@modul-connect/shared/components/atoms/input";
import "./vehicleList.css";
import VehicleListItem from "./vehicleListItem";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { sortFunction_alphabetical } from "../../../utils/text";

const VehiclesList = ({
  vehicles,
  vehicle_loading,
  map_settings,
  loading,
  vehicles_security_system_status,
  current_drivers,
  acceptedUserTerms,
}) => {
  const [search, setSearch] = React.useState("");

  const shouldBeDisabled = (vehicle) => {
    if (map_settings?.isLive) {
      return vehicle.isPrivate;
    }

    return false;
  };

  const vehicles_parsed = vehicles
    .map((v) => {
      return {
        ...v,
        disabled: shouldBeDisabled(v),
      };
    })
    .sort(sortFunction_alphabetical);

  const allVehicles = vehicles_parsed; //.filter(v => !v.disabled)
  //const publicVehicles = vehicles_parsed.filter(v => !v.disabled)
  //const privateVehicles = vehicles_parsed.filter(v => v.disabled)

  const isLoading =
    vehicle_loading.fetchVehicles === "loading" ||
    (!map_settings.isLive &&
      (loading.fetchWarningLogs === "loading" ||
        loading.fetchLastKnownPositions === "loading" ||
        loading.settingMapDate));

  const loadingFailed =
    vehicle_loading.fetchVehicles === "failed" ||
    (!map_settings.isLive &&
      (loading.fetchWarningLogs === "failed" ||
        loading.fetchVehicleRoutes === "failed" ||
        loading.fetchLastKnownPositions === "failed"));

  const getDriver = (vehicle) => {
    if (
      current_drivers?.data[vehicle.boxId]?.id ===
      acceptedUserTerms?.id?.replace("user/", "")
    )
      return "ME";
    return (
      current_drivers?.data[vehicle.boxId]?.name ? 
      current_drivers?.data[vehicle.boxId]?.name?.split(" ")[0][0] +
      "" +
      current_drivers?.data[vehicle.boxId]?.name?.split(" ")[1][0]
      : "?"
    );
  };
  return (
    <Fragment>
      {vehicles?.length ? (
        <View extend={styles.container}>
          <Fragment>
            <View extend={[styles.header, { minHeight: 79 }]}>
              <View style={styles.vehiclesHeading}>
                <H3 noPadding>
                  {map_settings.isLive ? "CURRENT VEHICLES" : "VEHICLES"}
                </H3>
              </View>
              <Input
                name="SearchVehicle"
                placeholder="Search..."
                onChange={(event) => setSearch(event.target.value.trim())}
              />
            </View>

            <View extend={styles.vehicles_list}>
              {
                // publicVehicles.filter(vehicle => vehicle.name.toLowerCase().includes(search.toLowerCase()))
                //   .map((vehicle, index) => <VehicleListItem key={index} vehicle={vehicle} />)
                allVehicles
                  .filter((vehicle) =>
                    vehicle.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((vehicle, index) => (
                    <VehicleListItem
                      key={index}
                      vehicle={vehicle}
                      securitySystemStatus={
                        vehicles_security_system_status?.filter(
                          (s) => s.mainboxId === vehicle.boxId
                        )[0]
                      }
                      currentDriver={getDriver(vehicle)}
                    />
                  ))
              }
            </View>

            {isLoading ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <BubbleLoadingWidget horizontal text={"Loading ..."} />
              </View>
            ) : null}
          </Fragment>

          {/* {privateVehicles?.length 
          ? <Fragment>
            <View extend={styles.header}>
              <View style={styles.vehiclesHeading}>
                <H3 noPadding>{'PRIVATE VEHICLES'}</H3>
              </View>
            </View>
            <View extend={styles.vehicles_list}>
              {
                isLoading ? <P>Updating vehicle list ...</P> : null
              }
              {
                privateVehicles.filter(vehicle => vehicle.name.toLowerCase().includes(search.toLowerCase()))
                  .map((vehicle, index) => <VehicleListItem key={index} vehicle={vehicle} />)
              }
            </View>
            </Fragment> 
            : null} */}
        </View>
      ) : (!vehicles?.length) &&
        (isLoading || loadingFailed) ? (
        <View>
          {isLoading ? (
            <BubbleLoadingWidget text={"Loading ..."} />
          ) : loadingFailed ? (
            "Could not fetch all vehicle data."
          ) : null}
        </View>
      ) : null}
    </Fragment>
  );
};

const styles = {
  vehicles_list: ({ theme: { colors, layout } }) => ({
    gap: "12px",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    borderTop: `1px solid ${colors.white}`,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    // '::-webkit-scrollbar': {
    //   display: 'none'
    // }
  }),
  container: {
    // marginTop: 30,
    height: "100%",
    flex: 1,
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    // borderRadius: 8,
    // border: '1px solid #ccc'
  },
  header: {
    padding: "20px 0 10px 0",
  },
  // vehiclesHeading: { marginBottom: '5px', display: 'flex', alignItems: 'center', marginBottom: '5px', "> svg": { marginRight: '8px important!'} }
};

const mapStateToProps = ({
  vehicle_loading,
  map_settings,
  loading,
  current_drivers,
  acceptedUserTerms,
}) => ({
  vehicle_loading,
  map_settings,
  loading,
  current_drivers,
  acceptedUserTerms,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesList);
