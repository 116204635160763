import React, { useEffect, useState } from 'react'
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import { connect } from 'react-redux'
import { DateTime } from "luxon";
import { log_columns } from './log_columns'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { getDayName } from '@modul-connect/shared/utils/text'
import { useNavigate } from "react-router-dom";
import TopFiveDisplay from './topFiveDisplay/topFiveDisplay';
import KpiRow from '@modul-connect/shared/components/molecules/kpiRow/kpiRow';
import theme from "@modul-connect/shared/theme"
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import ReportPage from '../../reportPage.v2';
import NoIncidents from "@modul-connect/shared/components/molecules/noIncidents";
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers';

const TemperatureReportDetails = ({
  startDate,
  endDate,
  onStartDatePicked,
  onEndDatePicked,
  themes,
  loading,
  temperature_report,
  onVehicleSelected,
  disabled
}) => {
  let navigate = useNavigate()

  let [reportData, setReportLogs] = useState(null)
  let [kpiData, setKpiData] = useState(null)
  let [selectedVehicleId, setSelectedVehicleId] = useState(null)
  let [data, setData] = useState({})

  const goToVehicleDetailsPage = (event, index) => {
    const logs = temperature_report?.data?.logs
    if (!logs || logs.length < index) {
      return
    }

    const log = logs[index]

    if (!log?.boxId) {
      return
    }

    navigate('/fleet/fleet_vehicles/' + log.boxId);
  }

  const getDataByDay = (data) => {

    let dividedByDay = {}
    let dailyList = []

    let prevTimestamp = ''

    if (data?.length) {
      data.forEach(log => {

        if (prevTimestamp && (log.timestamp.startOf("day").toMillis() !== prevTimestamp.startOf("day").toMillis())) {

          dividedByDay[prevTimestamp] = [...dailyList]
          dailyList = []
        }
        dailyList.push(log)
        prevTimestamp = log.timestamp
      })
    }
    if (dailyList.length > 0) {

      dividedByDay[prevTimestamp] = [...dailyList]
    }
    return dividedByDay
  }

  const totalHours = (endDate - startDate) / 60 / 60 / 1000
  const top_five_data = temperature_report?.data?.byVehicle ? temperature_report?.data?.byVehicle.map(d => {
    return {
      name: d.vehicle,
      percentage: Math.min((d.hours / totalHours * 100).toFixed(2), 100),
      additionalInfo: 'Number of warnings: ' + d.occasions,
      vehicleId: d.id
    }
  }) : []

  useEffect(() => {
    let nextId = -1
    let preparedData = []

    const logs = temperature_report?.data?.logs
    if (logs && logs.length >= 0) {
      setReportLogs(logs.map(log => {
        nextId++
        preparedData.push({
          id: nextId,
          boxId: log.boxId,
          thermometerName: log.name ? log.name : '-',
          temperature: {
            value: `${log.celcius?.toFixed(1)} °C`,
            guard: log.warningType
          },
          timestamp: log.startTime ? DateTime.fromISO(log.startTime) : null,
          timestamp_end: log.endTime ? DateTime.fromISO(log.endTime) : null,
          vehicle: log.vehicle,
          endTimeString: log.endTime ? dateTimeToString(DateTime.fromISO(log.endTime), 'time-only') : null,
          startTimeString: log.startTime ? dateTimeToString(DateTime.fromISO(log.startTime), 'time-only') : null,
        })
      }))
    }

    setKpiData([
      {
        title: 'Incidents',
        value: temperature_report?.data?.count ?? '-'
      },
      {
        title: 'Hours of warnings',
        value: temperature_report?.data?.totalHours?.toFixed(0) ?? '-'
      },
      {
        title: 'Average warning time',
        value: temperature_report?.data ? toPercentage(temperature_report.data.ratio ?? 0) : '-',
        unit: temperature_report?.data ? '%' : '',
        color: percentageColor(temperature_report?.data?.ratio ?? 0)
      }
    ])

    if (preparedData && preparedData.length > 0) {
      setData(getDataByDay(preparedData))
    } else {
      setData({})
    }

  }, [temperature_report])

  const toPercentage = (ratio) => `${(100 * ratio).toFixed(1)}`
  const percentageColor = (ratio) => ratio > 0.9 ? theme.colors.error : ratio > 0.67 ? theme.colors.yellowText : theme.colors.black

  const tables = (
    Object.keys(data)?.length ? Object.keys(data).map(key => {
      const log = data[key]
      return <TableWidgetSeparated
        tableTitle={getDayName(DateTime.fromISO(key))}
        key={key}
        data={log}
        loadingStatus={loading.fetchTemperatureReport}
        totalItems={reportData}
        columns={log_columns}
        themes={themes}
        isSortable={false}
        pagination={false}
        onClickRow={goToVehicleDetailsPage}
        rowsOptions={[log?.length]}
      />
    }) : []
  )

  const kpiSection = kpiData ? <KpiRow title={'Status'} bgColor={'white'} data={kpiData} isLoading={loading.fetchTemperatureReport} />
    : loading.fetchTemperatureReport === null &&
      (!reportData || reportData.length === 0) ?
      <NoIncidents />
      : null

  return (
    <ReportPage
      title={'Temperature Report'}
      timeSelectors={[timespanOptions.custom]}
      useOldDatePickers
      startDate={startDate}
      endDate={endDate}
      onStartDatePicked={onStartDatePicked}
      onEndDatePicked={onEndDatePicked}
      tablesInOwnSubPage
      noSearch
      noDataMessage={"There are no temperature warnings for the selected time period."}
      isLoading={loading.fetchTemperatureReport === 'loading'}
      disabled={disabled}
      fetchingDataFailed={loading.fetchTemperatureReport === 'failed'}
      subpageTitle={"Temperature Warnings" +
        (selectedVehicleId ?
          (' for ' + top_five_data.find(v => v.vehicleId === selectedVehicleId)?.name)
          : '')}
      kpi={(loading.fetchTemperatureReport === 'loading') ? <BubbleLoadingWidget text={'Loading ...'} /> : kpiSection}
      tables={tables}
      reportGraph={top_five_data?.length > 0 && !loading.fetchTemperatureReport &&
        <TopFiveDisplay
          title={'Top 5 vehicles with temperature warnings'}
          unit={'%'}
          data={top_five_data}
          tooltipText={'Time spent with temperature warning'}
          onSelect={(selectedId) => {
            setSelectedVehicleId(selectedId)
            onVehicleSelected(selectedId)
          }}
          onDeselect={() => {
            setSelectedVehicleId(null)
            onVehicleSelected(null)
          }}
        />}
    />
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps, {})(TemperatureReportDetails);