import React from 'react';
import Card from '../../atoms/card';
import { SearchField } from './searchField';
import theme from '../../../theme';

const SearchWidget = ({
  children,
  extend,
  onChange,
  placeholder,
  initialValue,
}) => {
  return (
    <Card extend={[{width: "100%", paddingTop: 21, paddingBottom: 21}, extend]}>
      <SearchField 
        onChange={onChange}
        placeholder={placeholder}
        initialValue={initialValue}
        extend={{marginLeft: 25, backgroundColor: theme.colors.lightGrey}}
      />
      { children }
    </Card>
  )
}

export default SearchWidget;