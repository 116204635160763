import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';

export const redirectUri = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return 'http://localhost:3000/login_success'
  }
  return window.location.pathname === '/' ? window.location.href: window.location.href.replace(window.location.pathname, "")
}

export const adalConfig = {
  tenant: 'ed3e59fa-e0e0-4803-bace-49dd4e590239',
  clientId: 'b190f9b9-9ee3-4fe5-8b7d-0c8710e6cacc',
  redirectUri: redirectUri(),
  endpoints: {
    api: 'b190f9b9-9ee3-4fe5-8b7d-0c8710e6cacc'
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = () => adalGetToken(authContext, adalConfig.endpoints.api)