import theme from "@modul-connect/shared/theme";
import { checkListResponseOptions } from "./dummyData";

const linkStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#3D5DAF',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}

export const summary_columns = [
  {
    id: "vehicle",
    label: "Vehicle",
    align: "left",
    minWidth: 220
  },
  {
    id: "amount",
    label: "Checks performed",
    align: "right",
  },
  {
    id: "issues",
    label: "With issues",
    align: "right",
    warningColor: theme.colors.tag["warning"],
    warning: (val) => val > 0
  },
  {
    id: "danger",
    label: "Danger",
    align: "right",
    warning: (val) => val > 0
  },
];

export const report_columns = [
  {
    id: "date",
    label: "Date",
    align: "flex-start",
    isSortable: true,
    minWidth: 155
  },
  {
    id: "issues",
    label: "Issues",
    align: "flex-end",
    isSortable: true,
    icon: true, 
    minWidth: 120
  },
  {
    id: "danger",
    label: "Danger",
    align: "flex-end",
    isSortable: true,
    icon: true,
    minWidth: 120
  },
  {
    id: "user",
    label: "Reported by",
    align: "flex-start",
    isSortable: true,
    minWidth: 150
  },
  {
    id: "informed",
    label: "Reported to",
    align: "flex-start",
    isSortable: true,
    minWidth: 150
  },
  {
    id: "download",
    label: "Download",
    align: "flex-end",
    width: 200
  },
  {
    id: 'expand'
  }
];

export const defect_columns = [
  {
    id: "date",
    label: "Date",
    align: "left",
    width: 155
  },
  {
    id: "vehicle",
    label: "Vehicle",
    align: "left",
    minWidth: 125
  },
  /* {
    id: "user",
    label: "User",
    align: "left",
    minWidth: 150
  }, */
  {
    id: "description",
    label: "Description",
    align: "left",
    extend: { fontSize: '14px' },
    minWidth: 120
  },
  {
    id: "danger",
    label: "Dangerous",
    align: "right",
    width: 150,
    warning: (val) => {return val === 'Yes'}
  },
  {
    id: "solved",
    label: "Resolved",
    align: "left",
    width: 130,
  },
  {
    id: "informed",
    label: "Reported to",
    align: "left",
    minWidth: 180
  },
  /* {
    id: "attachments",
    label: "Attachments",
    align: "right",
    extend: linkStyle, 
  }, */
]


export const checklist_columns = [
  {
    id: "title",
    label: "Checklist item",
    align: "left",
  },
  {
    id: 'result',
    label: "Result",
    warningColor: (val) => {return (val === 'Dangerous issue' ? undefined : theme.colors.tag["warning"])}, // undefined -> default colour, which is red
    warning: (val) => val !== checkListResponseOptions.OK,
    minWidth: 200
  },
  {
    id: "comment",
    label: "Comment",
    align: "left",
    extend: { fontSize: '14px' } // TODO: make this work
  },
  {
    id: "attachments",
    label: "Attachments",
    align: "right",
    extend: (val) => {return (val && val !== "-") ? linkStyle : null},
    width: 200
  },
];