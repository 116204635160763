import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { H3 } from "@modul-connect/shared/components/atoms/text";
import theme from "@modul-connect/shared/theme";

export const ListItem = ({
  onClick,
  isSelected,
  isDisabled,
  title,
  titleComponent,
  subtitle,
  subtitleComponent,
  rightSideComponent,
  scrollRef,
  extend,
  dontChangeBgColorOnSelection=false
}) => {
  const styles = getStyles(dontChangeBgColorOnSelection)

  return (
    <View
      innerRef={scrollRef}
      onClick={onClick}
      extend={[isSelected ? styles.selected : "", extend]}
    >
      <View
        className={"vehicleHeader"}
        extend={[styles.wrapper, isDisabled && styles.disabled]}
      >
        <View extend={styles.container}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {titleComponent ? titleComponent : <H3 noPadding>{title}</H3>}
            {subtitleComponent ? subtitleComponent : <H3 color={theme.colors.grey} noPadding>
              {subtitle ? subtitle : ""}
            </H3>}
          </View>

          {rightSideComponent ? rightSideComponent : null}
        </View>
      </View>
    </View>
  );
};

const getStyles = (dontChangeBgOnSelection) => {
  return {
    container: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 4,
      width: "100%",
    },
    wrapper: ({ theme: { colors, layout } }) => ({
      display: "flex",
      color: colors.text,
      width: "100%",
      "& h3": {
        // height: 27
      },
      "& p": {
        position: "relative",
        top: -8,
      },
      padding: `8px 4px`,
      "& .icon": {
        width: 24,
      },
      borderRadius: layout.borderRadius,
      "&:hover": {
        cursor: "pointer",
        "& h3": {
          color: colors.primary,
        },
        "& h4": {
          color: colors.primary,
        },
        "& .icon": {
          color: colors.primary,
        },
        "& p": {
          color: colors.primary,
        },
      },
    }),
    selected: ({ theme: { layout, colors } }) =>
      !dontChangeBgOnSelection
        ? {
            borderRadius: layout.borderRadius,
            color: colors.primary,
            "& .vehicleHeader": {
              backgroundColor: colors.primaryLight,
              "& h3": {
                color: colors.primary,
              },
              "& p": {
                color: colors.white,
              },
              "& .icon": {
                color: colors.primary,
              },
            },
          }
        : {
            "& h3": {
              color: colors.primary,
            },
            "& h4": {
              color: colors.primary,
            },
            "& .icon": {
              color: colors.primary,
            },
            "& p": {
              color: colors.primary,
            },
          },
    disabled: ({ theme: { layout, colors } }) => ({
      "& h3": {
        color: colors.grey,
      },
      "& h4": {
        color: colors.grey,
      },
      "& p": {
        color: colors.grey,
      },
      "& .icon": {
        color: colors.grey,
      },
      "&:hover": {
        cursor: "default",
        "& h3": {
          color: colors.grey,
        },
        "& h4": {
          color: colors.grey,
        },
        "& .icon": {
          color: colors.grey,
        },
        "& p": {
          color: colors.grey,
        },
      },
    }),
  };
};
