export const log_columns_view_all = [
  {
    id: "homebase",
    label: null,
    align: "left",
    icon: false,
    hasValues: true,
    isSortable: true,
    width: 100 / 7 + "%",
  },
  {
    id: "missing",
    label: null,
    hide: [],
    width: 75,
    icon: true,
    hasValues: true,
    isSortable: true,
    align: "center",
  },
  {
    id: "name",
    label: null,
    align: "left",
    icon: true,
    hasValues: true,
    isSortable: true,
    width: 100 / 7 + "%",
  },
  {
    id: "category",
    label: null,
    align: "left",
    icon: false,
    hasValues: true,
    isSortable: true,
    width: 100 / 7 + "%",
  },
  {
    id: "batteryWarning",
    label: null,
    align: "center",
    icon: true,
    hasValues: true,
    isSortable: true,
  },
  {
    id: "geoLocation",
    label: null,
    align: "left",
    icon: true,
    hasValues: true,
    isSortable: true,
  },
  {
    id: "notification",
    label: null,
    align: "center",
    icon: true,
    hasValues: true,
    isSortable: true,
    width: 45,
  },
  {
    id: "details",
    label: null,
    icon: false,
    hasValues: true,
    isSortable: true,
    align: "flex-end",
    width: 100 / 7 + "%",
  },
];
