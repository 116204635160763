import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  fetchVehicleWeights,
  fetchVehicleBatteryLogs,
  fetchVehicleTemperatureLogs,
  fetchVehicleHumidityLogs,
  downloadClimateLogs,
  climateSensorReportTypeOptions,
  fetchLpsBatteryLogs
} from '../../../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import LogChart from "@modul-connect/shared/components/organisms/logChart";
import moment from "moment";
import { DateTime } from "luxon";
import KpiRow from "@modul-connect/shared/components/molecules/kpiRow/kpiRow";
import { ticks_24_hours, ticks_7_days } from "./ticks";
import theme from "@modul-connect/shared/theme"
import { convertToSelectedUnit } from "../../../../../utils/unitConverter";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { P } from '@modul-connect/shared/components/atoms/text';
import BatteryLogGraph from "./batteryLogGraph";
import { getHumidityWarningLevel, getTemperatureWarningLevel, getHumiditySensors, getThermometers, getWeightWarningColor } from "../../../../../utils/vehicleUtils";
import { getServiceList } from "../../../../../utils/ServiceList";
import Paper from '@modul-connect/shared/components/atoms/paper'
import { ClimateSensorGraph } from "./climateSensorGraph";
import { isHumiditySensorBroken } from "@modul-connect/shared/config";
import { ServiceType } from "@modul-connect/shared/utils/services";
import LpsLogGraph from "./lpsLogGraph";


const groupByBatteryId = (logs) => {
  let grouped = {}

  logs.forEach(log => {
    const id = log.id
    if (!grouped[id]) {
      grouped[id] = []
    }
    grouped[id].push(log)
  })

  return grouped
}

export const intervals = {
  LAST_24_H: "24 hours",
  LAST_WEEK: "7 days",
}

const LogGraphs = ({
  vehicleId,
  vehicle,
  battery_logs,
  weight_logs,
  temperature_logs,
  humidity_logs,
  lps_logs,
  fetchVehicleWeights,
  fetchVehicleBatteryLogs,
  fetchVehicleTemperatureLogs,
  fetchVehicleHumidityLogs,
  fetchLpsBatteryLogs,
  log_loading,
  vehicle_loading,
  loading,
  subtrees,
  downloadClimateLogs,
  downloading
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const getSamplingInterval = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return 10
      case intervals.LAST_WEEK:
        return 60
      default:
        return 0
    }
  }

  const getStartTS = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return DateTime.local().minus({ days: 1 }).startOf('hour')
      case intervals.LAST_WEEK:
        return DateTime.local().minus({ weeks: 1 }).startOf('day')
      default:
        return DateTime.local().minus({ days: 1 }).startOf('hour')
    }
  }

  const getEndTs = () => {
    return DateTime.local()
  }

  const getTicks = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return ticks_24_hours()
      case intervals.LAST_WEEK:
        return ticks_7_days()
      default:
        return []
    }
  }

  const filterWeights = () => {
    const relevant = weight_logs[vehicleId]
    if (!relevant) return null
    const samplingInterval = getSamplingInterval(weightLogInterval)
    const now = DateTime.now()
    const timefilter = weightLogInterval === intervals.LAST_WEEK
      ? DateTime.now().plus({ days: -7 })
      : DateTime.now().plus({ hours: -24 })
    return relevant.filter(log => {
      if (log.status === 255 || log.samplingInterval !== samplingInterval) return false
      const timestamp = DateTime.fromISO(log.timestamp)
      return timestamp <= now && timestamp >= timefilter
    })
  }

  const [batteryLogInterval, setBatteryLogInterval] = React.useState(intervals.LAST_WEEK)
  const [weightLogInterval, setWeightLogInterval] = React.useState(intervals.LAST_WEEK)
  const [temperatureLogInterval, setTemperatureLogInterval] = React.useState(intervals.LAST_WEEK)
  const [humidityLogInterval, setHumidityLogInterval] = React.useState(intervals.LAST_WEEK)
  const [lpsLogInterval, setLpsLogInterval] = React.useState(intervals.LAST_WEEK)

  const onBatteryIntervalChanged = (newInterval) => {
    setBatteryLogInterval(newInterval)
    fetchVehicleBatteryLogsWithAccessToken(newInterval)
  }

  const onWeightIntervalChanged = (newInterval) => {
    setWeightLogInterval(newInterval)
    fetchVehicleWeightLogsWithAccessToken(newInterval)
  }

  const onTemperatureIntervalChanged = (newInterval) => {
    setTemperatureLogInterval(newInterval)
    fetchVehicleTemperatureLogsWithAcessToken(newInterval)
  }

  const onHumidityIntervalChanged = (newInterval) => {
    setHumidityLogInterval(newInterval)
    fetchVehicleHumidityLogsWithAcessToken(newInterval)
  }

  const onLpsIntervalChanged = (newInterval) => {
    setLpsLogInterval(newInterval)
    fetchVehicleLpsLogsWithAccessToken(newInterval)
  }

  const fetchVehicleBatteryLogsWithAccessToken = (newInterval) => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchVehicleBatteryLogs(
          accessToken,
          getStartTS(newInterval ? newInterval : batteryLogInterval),
          getEndTs(newInterval ? newInterval : batteryLogInterval),
          vehicleId,
          getSamplingInterval(newInterval ? newInterval : batteryLogInterval))
      })
  }

  const fetchVehicleLpsLogsWithAccessToken = (newInterval) => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchLpsBatteryLogs(
          accessToken,
          getStartTS(newInterval ? newInterval : lpsLogInterval),
          getEndTs(newInterval ? newInterval : lpsLogInterval),
          vehicleId,
          getSamplingInterval(newInterval ? newInterval : lpsLogInterval))
      })
  }

  const fetchVehicleWeightLogsWithAccessToken = (newInterval) => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchVehicleWeights(
          accessToken,
          getStartTS(newInterval ? newInterval : weightLogInterval),
          getEndTs(newInterval ? newInterval : weightLogInterval),
          vehicleId,
          getSamplingInterval(newInterval ? newInterval : weightLogInterval))
      })
  }

  const fetchVehicleTemperatureLogsWithAcessToken = (newInterval) => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchVehicleTemperatureLogs(
          accessToken,
          getStartTS(newInterval ? newInterval : temperatureLogInterval),
          getEndTs(newInterval ? newInterval : temperatureLogInterval),
          vehicleId,
          getSamplingInterval(newInterval ? newInterval : temperatureLogInterval))
      })
  }

  const fetchVehicleHumidityLogsWithAcessToken = (newInterval) => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchVehicleHumidityLogs(
          accessToken,
          getStartTS(newInterval ? newInterval : humidityLogInterval),
          getEndTs(newInterval ? newInterval : humidityLogInterval),
          vehicleId,
          getSamplingInterval(newInterval ? newInterval : humidityLogInterval))
      })
  }

  React.useEffect(() => {
    fetchVehicleBatteryLogsWithAccessToken()
    fetchVehicleWeightLogsWithAccessToken()
    fetchVehicleTemperatureLogsWithAcessToken()
    fetchVehicleHumidityLogsWithAcessToken()
    fetchVehicleLpsLogsWithAccessToken()
  }, [])

  let filtered_weights = filterWeights()
  let prepared_weights = filtered_weights ? filtered_weights.map(weight => {
    return {
      ...weight,
      timestamp: moment(weight.timestamp).unix(),
      max: vehicle?.vehicle?.maxWeight?.maxGross
    }
  }) : []
  prepared_weights.forEach(weight => {
    weight.gross = weight.gross && Number.parseInt(convertToSelectedUnit(weight.gross, 0, 'weight').replace(' ', '').replace(',', ''))
    weight.front = weight.front && Number.parseInt(convertToSelectedUnit(weight.front, 0, 'weight').replace(' ', '').replace(',', ''))
    weight.rear = weight.rear && Number.parseInt(convertToSelectedUnit(weight.rear, 0, 'weight').replace(' ', '').replace(',', ''))
    weight.max = weight.max && Number.parseInt(convertToSelectedUnit(weight.max, 0, 'weight').replace(' ', '').replace(',', ''))
  })

  let prepared_battery_logs = battery_logs ? battery_logs.filter(b => b.boxId === vehicleId).map(log => {
    return {
      ...log,
      timestamp: moment(log.timestamp).unix(),
    }
  }) : []

  let prepared_temperature_logs = temperature_logs ? temperature_logs.map(array =>
    array.map(log => {
      return {
        boxId: log.boxId,
        id: log.id,
        telematicsId: log.telematicsId,
        celcius: log.celcius.toFixed(1),
        max: log.highThreshold,
        min: log.lowThreshold,
        warning_max: (log.highThreshold === null || log.highThreshold === undefined) ? undefined : log.highThreshold - 2,
        warning_min: (log.lowThreshold === null || log.lowThreshold === undefined) ? undefined : log.lowThreshold + 2,
        timestamp: moment(log.timestamp).unix(),
      }
    })) : []

  let prepared_humidity_logs = humidity_logs ? humidity_logs.map(array => array.map(log => {
    return {
      boxId: log.boxId,
      id: log.id,
      telematicsId: log.telematicsId,
      humidity: log.humidity.toFixed(0),
      max: log.highThreshold,
      min: log.lowThreshold,
      timestamp: moment(log.timestamp).unix(),
    }
  })) : []

  let prepared_lps_logs = lps_logs ? lps_logs.map(log => {
    return {
      ...log,
      timestamp: moment(log.timestamp).unix(),
    }
  }) : []

  let logs_per_battery = groupByBatteryId(prepared_battery_logs)

  const getPercentage = (maxValue, value) => {
    if (!value || !maxValue)
      return null

    return ((value / maxValue) * 100).toFixed(0)
  }

  // newly fetched values may be more up to date than what we get from currentStatus (VEHICLE_STATUS_UPDATED does not update components)
  const fetchedGross = filtered_weights?.length ? filtered_weights[filtered_weights.length - 1].gross : undefined
  const fetchedAxle1 = filtered_weights?.length ? filtered_weights[filtered_weights.length - 1].axle1 : undefined
  const fetchedAxle2 = filtered_weights?.length ? filtered_weights[filtered_weights.length - 1].axle2 : undefined
  const fetchedNet = filtered_weights?.length ? filtered_weights[filtered_weights.length - 1].net : undefined

  let gross_percentage = getPercentage(vehicle?.vehicle?.maxWeight?.maxGross, fetchedGross ?? vehicle?.currentStatus?.weightSystem?.gross)
  let front_percentage = getPercentage(vehicle?.vehicle?.maxWeight?.maxFront, fetchedAxle1 ?? vehicle?.currentStatus?.weightSystem?.axle1)
  let rear_percentage = getPercentage(vehicle?.vehicle?.maxWeight?.maxRear, fetchedAxle2 ?? vehicle?.currentStatus?.weightSystem?.axle2)

  let gross_value = vehicle.currentStatus?.weightSystem?.gross || filtered_weights?.length ?
    convertToSelectedUnit(fetchedGross ?? vehicle.currentStatus.weightSystem.gross, 0, 'weight')
    : undefined
  let capacity_value = vehicle.vehicle.maxWeight?.maxGross && (fetchedGross || vehicle.currentStatus?.weightSystem?.gross) ?
    convertToSelectedUnit(vehicle.vehicle.maxWeight.maxGross - (fetchedGross ?? vehicle.currentStatus?.weightSystem.gross), 0, 'weight') : undefined

  const splitGross = gross_value?.replace(',', ' ').split(' ')
  const weightUnit = splitGross?.pop()
  const grossValue = splitGross?.join(' ')
  const splitCapacity = capacity_value?.replace(',', ' ').split(' ')
  if (splitCapacity) splitCapacity.pop()
  const capacityValue = splitCapacity?.join(' ')

  let kpiData_weight = [
    {
      title: 'Current Weight',
      value: grossValue ?? '-',
      unit: weightUnit,
      color: getWeightWarningColor(gross_percentage),
      width: 200,
    },
    {
      title: 'Capacity Left',
      value: capacityValue ?? '-',
      unit: weightUnit,
      color: getWeightWarningColor(gross_percentage),
      width: 200,
    },
    {
      title: 'Total Load',
      value: gross_percentage !== null ? gross_percentage : '-',
      unit: '%',
      color: getWeightWarningColor(gross_percentage),
      width: 200,
    },
    {
      title: 'Front Load',
      value: front_percentage !== null ? front_percentage : '-',
      unit: '%',
      color: getWeightWarningColor(front_percentage),
      width: 200,
    },
    {
      title: 'Rear Load',
      value: rear_percentage !== null ? rear_percentage : '-',
      unit: '%',
      color: getWeightWarningColor(rear_percentage),
      width: 200,
    }
  ]
  let kpiData_temperature = []

  // Note: Anders XC60 has two thermometers, because one was installed temporarily. All other thermometers have id == 0.
  // This may need to be changed in the future.
  getThermometers(vehicle).forEach(thermometer => {
    const warningLevel = getTemperatureWarningLevel(vehicle, thermometer?.celcius.toFixed(1), thermometer.id)
    kpiData_temperature.push(
    {
      //thermometer.id
      title: 'Temperature — ' + `${vehicle.climateSensors?.find(sensor => sensor.sensor_id == thermometer.id).name
        ? vehicle.climateSensors?.find(sensor => sensor.sensor_id == thermometer.id).name
        : thermometer.id}`,
      value: thermometer?.celcius.toFixed(1),
      unit: '°C',
      color: theme.colors.tag[warningLevel ?? 'primary'],
      width: 230
    }
  )})


  let kpiData_humidity = []
  getHumiditySensors(vehicle).forEach(humiditySensor => {
    const warningLevel = getHumidityWarningLevel(vehicle, humiditySensor?.humidity.toFixed(0), humiditySensor.id)
    kpiData_humidity.push(
    {
      title: 'Humidity — ' + `${vehicle?.climateSensors?.find(sensor => sensor.sensor_id == humiditySensor.id).name
        ? vehicle?.climateSensors.find(sensor => sensor.sensor_id == humiditySensor.id).name
        : humiditySensor.id}`,
      value: humiditySensor?.humidity.toFixed(0),
      unit: '%',
      color: theme.colors.tag[warningLevel ?? 'primary']
    }
  )})
  
  const services = getServiceList({subtrees})

  const downloadClimateLogsWithAccessToken = (type) => {
    getAccessTokenSilently()
      .then(accessToken => downloadClimateLogs(
        accessToken,
        vehicle,
        null,
        DateTime.local().minus({ days: 30 }).startOf('day'),
        getEndTs(),
        5,
        null,
        type))
  }

  const isDownloadingTemperatureReport = downloading?.climate_logs?.temperature?.status === 'working' && downloading?.climate_logs?.temperature?.boxId === vehicleId
  const isDownloadingHumidityReport = downloading?.climate_logs?.humidity?.status === 'working' && downloading?.climate_logs?.humidity?.boxId === vehicleId

  return (
    <Fragment>
      {
        services.hasService(ServiceType.BatteryWarnings) && vehicle?.currentStatus?.batteries?.length ?
          <Paper title={"Battery"}>
            {
              (logs_per_battery && log_loading.fetchBatteryLogs !== 'loading') ?
                vehicle?.currentStatus?.batteries.map(battery => {
                  return <BatteryLogGraph
                    key={'battery_log_graph_' + battery.id}
                    log_loading={log_loading}
                    vehicle={vehicle}
                    logs={logs_per_battery[battery.id]}
                    showBatteryId={vehicle.currentStatus?.batteries.length > 1}
                    batteryId={battery.id}
                    onBatteryIntervalChanged={onBatteryIntervalChanged}
                    batteryLogInterval={batteryLogInterval}
                    intervals={intervals} />
                })
                : log_loading.fetchBatteryLogs === 'loading' ? <BubbleLoadingWidget text={'Loading battery data ...'} /> : <P>Could not fetch battery data.</P>
            }
          </Paper> : null
      }

      {services.hasService(ServiceType.WeightReports) && vehicle?.currentStatus?.weightSystem ?
        <Paper title={"Weight"}>

          <KpiRow width={'100%'} data={kpiData_weight} />

          {
            log_loading.fetchWeightLogs !== 'loading' ?
              <LogChart
                intervalOptions={Object.values(intervals)}
                selectedInterval={weightLogInterval}
                onIntervalChanged={onWeightIntervalChanged}
                data={prepared_weights}
                data_key="gross"
                data_name="Vehicle Weight"
                max_name="Max. Vehicle Weight"
                title="Vehicle Weight"
                unit={weightUnit}
                line_type={"stepAfter"}
                domain={[
                  ((vehicle.currentStatus.weightSystem.gross || fetchedGross) && (vehicle.currentStatus.weightSystem.net || fetchedNet)) ?
                    (fetchedGross ?? vehicle.currentStatus.weightSystem.gross) - (fetchedNet ?? vehicle.currentStatus.weightSystem.net) - 50 :
                    Math.min(
                      (Math.floor(Math.min(...prepared_weights.map(log => log.gross)) / 100) * 100) - 100,
                      vehicle?.vehicle?.maxWeight?.maxGross - 100
                    ),
                  Math.max(
                    vehicle?.vehicle?.maxWeight?.maxGross + 100,
                    Math.ceil(Math.max(...prepared_weights.map(log => log.gross)) / 10) * 10) + 50
                ]}
                displayTimeInHours={weightLogInterval === intervals.LAST_24_H ? true : false}
                ticks={getTicks(weightLogInterval)}
              />
              : log_loading.fetchWeightLogs === 'loading' ? <BubbleLoadingWidget text={'Loading weight data ...'} /> : <P>Could not fetch weight data.</P>
          }
        </Paper> : null
      }


      {services.hasService(ServiceType.ClimateReports) && getThermometers(vehicle).length ?
        <ClimateSensorGraph
          vehicle={vehicle}
          vehicleId={vehicleId}
          type={'temperature'}
          title={"Temperature"}
          loadingStatus={log_loading.fetchVehicleTemperatureLogs ?? vehicle_loading.fetchVehicle }
          onDownload={_ => downloadClimateLogsWithAccessToken(climateSensorReportTypeOptions.temperature)}
          isDownloadingReport={isDownloadingTemperatureReport}
          kpiData={kpiData_temperature}
          downloadStatus={downloading?.climate_logs?.temperature}
          logs={prepared_temperature_logs}
          logInterval={temperatureLogInterval}
          onLogIntervalChanged={onTemperatureIntervalChanged}
          getTicks={getTicks}
          loadingMessage={'Loading temperature data ...'}
          loadingFailedMessage={'Could not fetch temperature data.'}
        /> : null
      }

      {!isHumiditySensorBroken && services.hasService(ServiceType.ClimateReports) && getHumiditySensors(vehicle).length ?
        <ClimateSensorGraph
          vehicle={vehicle}
          vehicleId={vehicleId}
          type={'humidity'}
          title={"Humidity"}
          loadingStatus={log_loading.fetchVehicleHumidityLogs ?? vehicle_loading.fetchVehicle }
          onDownload={_ => downloadClimateLogsWithAccessToken(climateSensorReportTypeOptions.humidity)}
          isDownloadingReport={isDownloadingHumidityReport}
          kpiData={kpiData_humidity}
          downloadStatus={downloading?.climate_logs?.humidity}
          logs={prepared_humidity_logs}
          logInterval={humidityLogInterval}
          onLogIntervalChanged={onHumidityIntervalChanged}
          getTicks={getTicks}
          loadingMessage={'Loading humidity data ...'}
          loadingFailedMessage={'Could not fetch humidity data.'}
        /> : null
      }

      {
        services.hasService(ServiceType.BatteryWarnings) && vehicle?.currentStatus?.lithiumPowerSupply ?
          <Paper title={"Lithium Power Supply"}>
            {
              (log_loading.fetchLpsLogs !== 'loading') ?
                <LpsLogGraph
                    key={'lps'}
                    log_loading={log_loading}
                    vehicle={vehicle}
                    logs={prepared_lps_logs}
                    onBatteryIntervalChanged={onLpsIntervalChanged}
                    batteryLogInterval={lpsLogInterval}
                    intervals={intervals} />
                : log_loading.fetchLpsLogs === 'loading' ? <BubbleLoadingWidget text={'Loading lithium power supply data ...'} /> : <P>Could not fetch lithium power supply data.</P>
            }
          </Paper> : null
      } 

    </Fragment>
  )
}

const mapStateToProps = ({
  weight_logs,
  battery_logs,
  temperature_logs,
  humidity_logs,
  log_loading,
  vehicle_loading,
  loading,
  subtrees,
  downloading,
  lps_logs,
}) => ({
  weight_logs,
  battery_logs,
  temperature_logs,
  humidity_logs,
  log_loading,
  vehicle_loading,
  loading,
  subtrees,
  downloading,
  lps_logs
})

const mapDispatchToProps = dispatch => ({
  fetchVehicleWeights: (accessToken, startEpoch, endEpoch, id, samplingInterval) => dispatch(fetchVehicleWeights(accessToken, startEpoch, endEpoch, id, samplingInterval)),
  fetchVehicleBatteryLogs: (accessToken, startEpoch, endEpoch, id, samplingInterval) => dispatch(fetchVehicleBatteryLogs(accessToken, startEpoch, endEpoch, id, samplingInterval)),
  fetchVehicleTemperatureLogs: (accessToken, startEpoch, endEpoch, id, samplingInterval) => dispatch(fetchVehicleTemperatureLogs(accessToken, startEpoch, endEpoch, id, samplingInterval)),
  fetchVehicleHumidityLogs: (accessToken, startEpoch, endEpoch, id, samplingInterval) => dispatch(fetchVehicleHumidityLogs(accessToken, startEpoch, endEpoch, id, samplingInterval)),
  downloadClimateLogs: (accessToken, vehicle, deviceType, start, end, samplingInterval, avgOverMinutes, typeOfReport) => dispatch(downloadClimateLogs(accessToken, vehicle, deviceType, start, end, samplingInterval, avgOverMinutes, typeOfReport)),
  fetchLpsBatteryLogs: (accessToken, startEpoch, endEpoch, id, samplingInterval) => dispatch(fetchLpsBatteryLogs(accessToken, startEpoch, endEpoch, id, samplingInterval)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LogGraphs);


