import { redirectUri } from "./adalConfig/adalConfig"
import { environment as node_env } from '../env.js'

// Feature-toggle that hides UI elements pertaining to humidity.
// Set to false to display them again.
export const isHumiditySensorBroken = false

export const serverURL = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-poc.azurewebsites.net'
    //return 'http://localhost:5000'
  } else {
    return 'https://mc-prod.azurewebsites.net'
  }
}

export const auth0Domain = () => {
  if (!node_env || node_env === 'development') {
    return 'dev-rzl4knic.eu.auth0.com'
  } else {
    return 'modul-system.eu.auth0.com'
  }
}

export const auth0ClientId = () => {
  if (!node_env || node_env === 'development') {
    return '9647lAC2SX539ql14jqyWPePKIU0vUvk'
  } else {
    return 'EEfXyOOGYmktvHP8LJJMXIPwGCyjK7rd'
  }
}

export const auth0_params = {
  redirectUri: redirectUri(),
  audience: "api.modulconnect.com.customer",
};

export const customerCommandServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-customer-poc.azurewebsites.net'
    // return 'https://localhost:5001'
  } else {
    return 'https://mc-customer-cmd.azurewebsites.net'
  }
}

export const assetsCommandServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-assets-poc-command.azurewebsites.net'
    //return 'https://localhost:5001'
  } else {
    return 'https://mc-assets-command.azurewebsites.net'
  }
}

export const customerServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-customer-poc-query.azurewebsites.net'
    //return 'https://localhost:5004'
  } else {
    return 'https://mc-customer.azurewebsites.net'
  }
}

export const notificationsServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-notifications-database-poc.azurewebsites.net/'
    //return 'https://localhost:5006'
  } else {
    return 'https://mc-notifications-database.azurewebsites.net/'
  }
}

export const endUserServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-end-user-poc-query.azurewebsites.net'
  } else {
    return 'https://mc-end-user-query.azurewebsites.net'
  }
}

export const redirectUri_logout = () => {
  const logoutLandingPagePath = 'logout'

  return window.location.pathname === '/' ? window.location.href + logoutLandingPagePath 
    : window.location.href.replace(window.location.pathname, '/' + logoutLandingPagePath)
}
