import React from "react";
import { connect } from 'react-redux'
import View from "@modul-connect/shared/components/atoms/view";
import ReportPage from "../../../reportPage.v2";
import { timespanOptions } from "../../../datePickers/timespanPickers/timespanPickers";
import theme from "@modul-connect/shared/theme";
import { Divider } from "@mui/material";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import ChartPie from "../../ChartPie";
import Top5Vehicles from "../../Top5Vehicles";
import SolarIcon from '@modul-connect/shared/img/solar_icon.svg'
import AcIcon from '@modul-connect/shared/img/AC_icon.svg'
import DcIcon from '@modul-connect/shared/img/DC_icon.svg'



const ViewOfFootprint = ({ lps_footprint, loading }) => {


  const prepared_data = [
    [
      { name: "Solar", value: lps_footprint?.percentageSolarCharge, textValue: lps_footprint?.solarCharge?.toFixed(2) , color: theme.colors.solarGreen, icon: SolarIcon },
      { name: "no value", value: 100 - lps_footprint?.percentageSolarCharge, color: theme.colors.remainingOnPie },
    ],
    [
      { name: "AC", value: lps_footprint?.percentage230vacCharge, textValue: lps_footprint?.charge230vac?.toFixed(2), color: theme.colors.acGrey, icon: AcIcon },
      { name: "no value", value: 100 - lps_footprint?.percentage230vacCharge, color: theme.colors.remainingOnPie },
    ],

    [
      { name: "DC", value: lps_footprint?.percentage12DcCharge, textValue: lps_footprint?.charge12dc?.toFixed(2), color: theme.colors.dcBlack, icon: DcIcon },
      { name: "no value", value: 100 - lps_footprint?.percentage12DcCharge, color: theme.colors.remainingOnPie },
    ]
  ]

  const prepared_data_top_5 = {
    solar: [],
    dc: [],
    ac: []
  }

  if (lps_footprint?.topSolarChargedVehicles?.length) {
    var solarTopVehicles = []
    lps_footprint.topSolarChargedVehicles.map(vehicle =>
      solarTopVehicles.push(
        [{ name: "Solar", value: vehicle?.percentage, color: theme.colors.solarGreen, vehicleName: vehicle.name, icon: SolarIcon },
        { name: "no value", value: 100 - vehicle.percentage, color: theme.colors.remainingOnPie }],
      ))

    solarTopVehicles.sort(function(a, b) {
      var dcA = a[0]?.value;
      var dcB = b[0]?.value;
      return (dcA < dcB) ? 1 : (dcA > dcB) ? -1 : 0;
      })
    while (solarTopVehicles.length < 5) {
      solarTopVehicles.push(
        [{ name: "Solar", value: null, color: theme.colors.solarGreen, vehicleName: '-', icon: SolarIcon },
        { name: "no value", value: 100, color: theme.colors.remainingOnPie }],
      )
    }
    prepared_data_top_5.solar = solarTopVehicles
  }

  if (lps_footprint?.top230VacChargedVehicles?.length) {
    var AcTopVehicles = []
    lps_footprint.top230VacChargedVehicles.map(vehicle =>
      AcTopVehicles.push(
        [{ name: "AC", value: vehicle.percentage, color: theme.colors.acGrey, vehicleName: vehicle.name, icon: AcIcon },
        { name: "no value", value: 100 - vehicle.percentage, color: theme.colors.remainingOnPie }],
      ))
    AcTopVehicles.sort(function(a, b) {
      var dcA = a[0]?.value;
      var dcB = b[0]?.value;
      return (dcA < dcB) ? 1 : (dcA > dcB) ? -1 : 0;
      })

    while (AcTopVehicles.length < 5) {
      AcTopVehicles.push(
        [{ name: "AC", value: null, color: theme.colors.acGrey, vehicleName: '-', icon: AcIcon },
        { name: "no value", value: 100, color: theme.colors.remainingOnPie }],
      )
    }
    prepared_data_top_5.ac = AcTopVehicles
  }

  if (lps_footprint?.top12DcChargedVehicles?.length) {
    var DcTopVehicles = []
    lps_footprint.top12DcChargedVehicles.map(vehicle =>
      DcTopVehicles.push(
        [{ name: "DC", value: vehicle.percentage, color: theme.colors.dcBlack, vehicleName: vehicle.name, icon: DcIcon},
        { name: "no value", value: 100 - vehicle.percentage, color: theme.colors.remainingOnPie }],
      ))

    DcTopVehicles.sort(function(a, b) {
      var dcA = a[0]?.value;
      var dcB = b[0]?.value;
      return (dcA < dcB) ? -1 : (dcA > dcB) ? 1 : 0;
      })
    while (DcTopVehicles.length < 5) {
      DcTopVehicles.push(
        [{ name: "DC", value: null, color: theme.colors.dcBlack, vehicleName: '-', icon: DcIcon },
        { name: "no value", value: 100, color: theme.colors.remainingOnPie }],
      )
    }
    prepared_data_top_5.dc = DcTopVehicles
  }


  const isLoading = loading.fetchLpsFootprint === 'loading'
  const loadingFailed = loading.fetchLpsFootprint === 'failed'

  return (
    isLoading ?
      <BubbleLoadingWidget text={'Loading footprint...'} />
      : lps_footprint
        ? 
        <View style={{ display: 'flex', flexDirection: 'column', width:'fit-content', minWidth:'100%' ,backgroundColor: theme.colors.white }}>

          <Divider variant="middle" sx={{ marginTop: 2, marginBottom: 2, borderStyle: 'dashed' }} />

          <View style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '22px',
            paddingLeft: '24px',
            paddingTop: '12px',
            marginBottom: 40
          }}>
            {'Fleet'}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              prepared_data.map((data, index) =>
                <View key={index} style={{ textAlign: 'center', gap: 10, flex: 1, }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 5 }}>
                    <img width={22} src={data?.[0].icon} />
                    <View>
                      {
                        data?.[0].name
                      }
                    </View>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                    <ChartPie
                      data={data}
                      width={300}
                      height={250}
                      innerRadius={82}
                      outerRadius={87}
                      styleText={{
                        fontSize: "28px",
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontFamily: "Inter",
                      }}
                      customLabel={true}
                    />
                  </View>
                </View>
              )
            }
          </View>

          <Divider variant="middle" sx={{ marginTop: 2, marginBottom: 2, borderStyle: 'dashed' }} />


          <View style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '22px',
            paddingLeft: '24px',
            paddingTop: '12px',
            marginBottom: 40
          }}>
            {'Top 5 vehicles'}
          </View>
          {
            <View style={{ display: 'flex', flexDirection: 'row', paddingLeft: '32px', paddingRight: '32px', paddingTop: '12px', marginBottom: 40 }}>
              <View style={{ flex: 3, marginRight: 40 }}>
                <Top5Vehicles data={prepared_data_top_5.solar} title={"Solar"} width={60} height={60} innerRadius={25} outerRadius={28} titleIcon={SolarIcon}/>
              </View>

              <View style={{ flex: 3, marginRight: 40 }}>
                <Top5Vehicles data={prepared_data_top_5.ac} title={"AC"} width={60} height={60} innerRadius={25} outerRadius={28} titleIcon={AcIcon}/>
              </View>

              <View style={{ flex: 3, }}>
                <Top5Vehicles data={prepared_data_top_5.dc} title={"DC"} width={60} height={60} innerRadius={25} outerRadius={28} titleIcon={DcIcon}/>
              </View>
            </View>
          }
        </View>

        :

        <View style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 300,
          fontSize: '18px',
          lineHeight: '22px',
          paddingLeft: '24px',
          paddingTop: '12px',
        }} >
          {
            loadingFailed ? 'The footprint data could not be fetched.' : 'There is no footprint data for the selected time frame.'
          }
        </View>
  )
}

const FootprintSubpage = ({
  isLoading,
  loadingFailed,
  disabled,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  loading,
  themes,
  lps_footprint
}) => {
  return (
    <ReportPage
      title={"Statistics Charged"}
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom,
      ]}
      noDataMessage={loadingFailed ? "There was an error loading the LPS logs from the server." : "There are no LPS logs for the selected time period."}
      isLoading={isLoading}
      fetchingDataFailed={loadingFailed}
      disabled={disabled}
      startDate={startDate}
      endDate={endDate}
      noSearch={true}
      onTimeframePicked={(start, end) => {
        setStartDate(start);
        setEndDate(end);
      }}
      tables={<ViewOfFootprint lps_footprint={lps_footprint} loading={loading} />}
    />
  )
}

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FootprintSubpage);