import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchDrivingLogSummaries, fetchVehiclesData, fetchHumidityReport } from '../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import { DateTime } from "luxon";
import HumidityReportDetails from './humidityReportDetails/humidityReportDetails';
import { getServiceList } from '../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';


const HumidityReportPage = ({ 
  selected_organisations,
    fetchVehiclesData,
    fetchHumidityReport,
    subtrees,
}) => {    
    const { getAccessTokenSilently } = useAuth0()

    const disabled = !getServiceList({subtrees}).hasService(ServiceType.ClimateReports)
    let [startDateDetails, setStartDateDetails] = useState(DateTime.local().startOf('month'))
    let [endDateDetails, setEndDateDetails] = useState(DateTime.local())
    let [selectedVehicle, setSelectedVehicle] = useState(null)

    useEffect(() => {
        getAccessTokenSilently().then(accessToken => {
            const start = startDateDetails ? startDateDetails : null
            const end =  endDateDetails ? endDateDetails : null
            fetchHumidityReport(accessToken, start, end, selectedVehicle)
        })
    }, [startDateDetails, endDateDetails, selectedVehicle, selected_organisations])

    useEffect(() => {
      getAccessTokenSilently().then(fetchVehiclesData)
    }, [selected_organisations])
    
    return (
        <React.Fragment>
            <HumidityReportDetails
                startDate={startDateDetails} 
                endDate={endDateDetails} 
                disabled={disabled}
                onStartDatePicked={(date) => setStartDateDetails(date.startOf('day'))}
                onEndDatePicked={(date) => setEndDateDetails(date.endOf('day'))}
                onVehicleSelected={setSelectedVehicle}
            />
        </React.Fragment>
    )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
    fetchDrivingLogSummaries: (accessToken, start, end) => dispatch(fetchDrivingLogSummaries(accessToken, start, end)),
    fetchVehiclesData: (accessToken) => dispatch(fetchVehiclesData(accessToken)),
    fetchHumidityReport: (accessToken, start, end, boxId) => dispatch(fetchHumidityReport(accessToken, start, end, boxId))
});

export default connect(mapStateToProps, mapDispatchToProps)(HumidityReportPage);