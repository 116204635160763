import 'react'
import React from 'react'
import View from '@modul-connect/shared/components/atoms/view'
import StatusDisplay from '@modul-connect/shared/components/atoms/statusDisplay'
import { H5 } from "@modul-connect/shared/components/atoms/text";
import { 
  batteryIssue,
  batteryWarning,
  getHumidityWarningLevel,
  getTemperatureWarningLevel,
  getThermometers,
  getHumiditySensors,
  weightWarningThreshold,
  weightIssueThreshold
} from '../../../../../utils/vehicleUtils';
import { isHumiditySensorBroken } from '@modul-connect/shared/config';

// const isDev = !node_env || node_env === 'development'
const showTemperature = true//isDev
const showHumidity = !isHumiditySensorBroken //isDev

export const StatusKpis = ({ vehicle}) => {
  const currentLoad = vehicle?.currentStatus?.weightSystem
  const maxLoad = vehicle?.vehicle?.maxWeight

  const hasLoad = currentLoad?.gross > 0 && maxLoad?.maxGross
  const hasFront = currentLoad?.axle1 > 0 && maxLoad?.maxFront
  const hasRear = currentLoad?.axle2 > 0 && maxLoad?.maxRear

  // not currently used
  /* const loadPercentage = hasLoad && (currentLoad.net / (maxLoad.maxGross - (currentLoad.gross - currentLoad.net)) * 100).toFixed(1)
  const frontLoadPercentage = hasFront && (currentLoad.axle1 / maxLoad.maxFront * 100).toFixed(1)
  const rearLoadPercentage = hasRear && (currentLoad.axle2 / maxLoad.maxRear * 100).toFixed(1) */

  const loadPercentage_gross = hasLoad && ((currentLoad.gross / maxLoad.maxGross) * 100).toFixed(1)
  const frontLoadPercentage_gross = hasFront && (currentLoad.axle1 / maxLoad.maxFront * 100).toFixed(1)
  const rearLoadPercentage_gross = hasRear && (currentLoad.axle2 / maxLoad.maxRear * 100).toFixed(1)

  const vehicleStatus = {
    load: hasLoad && {
      value: loadPercentage_gross,
      isWarning: !isNaN(loadPercentage_gross) && loadPercentage_gross > weightWarningThreshold, // TODO: should warning and issue be based on gross now, or still net?
      isIssue: !isNaN(loadPercentage_gross) && loadPercentage_gross > weightIssueThreshold,
    },
    frontLoad: hasFront && {
      value: frontLoadPercentage_gross,
      isWarning: !isNaN(frontLoadPercentage_gross) && frontLoadPercentage_gross > weightWarningThreshold,
      isIssue: !isNaN(frontLoadPercentage_gross) && frontLoadPercentage_gross > weightIssueThreshold
    },
    rearLoad: hasRear && {
      value: rearLoadPercentage_gross,
      isWarning: !isNaN(rearLoadPercentage_gross) && rearLoadPercentage_gross > weightWarningThreshold,
      isIssue: !isNaN(rearLoadPercentage_gross) && rearLoadPercentage_gross > weightIssueThreshold
    },

    grossLoad_kg: hasLoad && {
      value: currentLoad?.gross,
      isWarning: !isNaN(loadPercentage_gross) && loadPercentage_gross > weightWarningThreshold,
      isIssue: !isNaN(loadPercentage_gross) && loadPercentage_gross > weightIssueThreshold,
    },
    grossFrontLoad_kg: hasFront && {
      value: currentLoad?.axle1,
      isWarning: !isNaN(frontLoadPercentage_gross) && frontLoadPercentage_gross > weightWarningThreshold,
      isIssue: !isNaN(frontLoadPercentage_gross) && frontLoadPercentage_gross > weightIssueThreshold
    },
    grossRearLoad_kg: hasRear && {
      value: currentLoad?.axle2,
      isWarning: !isNaN(rearLoadPercentage_gross) && rearLoadPercentage_gross > weightWarningThreshold,
      isIssue: !isNaN(rearLoadPercentage_gross) && rearLoadPercentage_gross > weightIssueThreshold
    }
  }
  // TODO: use actual battery guard, function in vehicleUtils
  
  return (
    vehicle ?
    <View extend={styles.kpis}>
      <H5 extend={ [styles.sidebar_title, {marginBottom: 0}] }>STATUS</H5>
      <View extend={styles.row}>
        {
          vehicle.currentStatus?.batteries?.map(battery =>
            <StatusDisplay
              dark
              unit='V'
              title={'BATTERY' + (vehicle.currentStatus.batteries.length > 1 ? ' ' + battery.id : '' )}
              key={battery.id}
              warning={batteryWarning(battery.voltage, vehicle?.vehicle?.batteryGuards?.length ? vehicle?.vehicle?.batteryGuards[0].lowGuard : 11.7)}
              issue={batteryIssue(battery.voltage, vehicle?.vehicle?.batteryGuards?.length ? vehicle?.vehicle?.batteryGuards[0].lowGuard : 11.7)}
              value={battery.voltage?.toFixed(1)}
            />)
        }
      </View>
      {
        showTemperature && 
        <View extend={styles.row}>
          {
            getThermometers(vehicle)
            .sort((a, b) => (a.id > b.id) ? 1 : -1 )
            .map(thermometer => 
              <StatusDisplay
                dark
                unit='&deg;C'
                title={ 'TEMPERATURE'}
                subTitle={`${vehicle.climateSensors?.find(sensor => sensor.sensor_id == thermometer.id)?.name 
                  ? vehicle.climateSensors?.find(sensor => sensor.sensor_id == thermometer.id)?.name  
                  : vehicle.climateSensors?.find(sensor => sensor.sensor_id == thermometer.id)?.sensor_id }`}
                key={thermometer.id}
                value={thermometer.celcius.toFixed(1)}
                warning={getTemperatureWarningLevel(vehicle, thermometer.celcius.toFixed(1), thermometer.id) === 'warning'}
                issue={getTemperatureWarningLevel(vehicle, thermometer.celcius.toFixed(1), thermometer.id) === 'issue'}
              /> )
          }
        </View>  
      }

      {
        showHumidity && 
        <View extend={styles.row}>
          {
            getHumiditySensors(vehicle).sort((a, b) => (a.id > b.id) ? 1 : -1 )
            .map(humiditySensor => 
              <StatusDisplay
                dark
                unit = '%'
                title = {'HUMIDITY'}
                subTitle={`${vehicle.climateSensors?.find(sensor => sensor.sensor_id == humiditySensor.id)?.name 
                  ? vehicle.climateSensors?.find(sensor => sensor.sensor_id == humiditySensor.id)?.name  
                  : vehicle.climateSensors?.find(sensor => sensor.sensor_id == humiditySensor.id)?.sensor_id }`}
                key = {humiditySensor.id}
                value = {humiditySensor.humidity.toFixed(0)}
                warning={getHumidityWarningLevel(vehicle, humiditySensor.humidity.toFixed(0), humiditySensor.id) === 'warning'}
                issue={getHumidityWarningLevel(vehicle, humiditySensor.humidity.toFixed(0), humiditySensor.id) === 'issue'}
              />)
          }
        </View>  
      }

      { currentLoad && <WeightKpiRows vehicleStatus={vehicleStatus}/>
      }

     {/*  { vehicle?.currentStatus?.tyres?.length &&
        <View extend={styles.row}>
            <StatusDisplay
              dark
              value={vehicle?.currentStatus?.tyres[0].pressure}
              unit='kPa'
              title={`AXLE ${vehicle?.currentStatus?.tyres[0].axleId} POSITION ${vehicle?.currentStatus?.tyres[0].position}`}
              warning={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.warningThreshold}
              issue={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.issueThreshold}
            />
            <StatusDisplay
              dark
              value={vehicle?.currentStatus?.tyres[1].pressure}
              unit='kPa'
              title={`AXLE ${vehicle?.currentStatus?.tyres[1].axleId} POSITION ${vehicle?.currentStatus?.tyres[1].position}`}
              warning={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.warningThreshold}
              issue={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.issueThreshold}
            />
        </View>
      } */}

      {/* { vehicle?.currentStatus?.tyres?.length &&
        <View extend={styles.row}>
            <StatusDisplay
              dark
              value={vehicle?.currentStatus?.tyres[2].pressure}
              unit='kPa'
              title={`AXLE ${vehicle?.currentStatus?.tyres[2].axleId} POSITION ${vehicle?.currentStatus?.tyres[2].position}`}
              warning={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.warningThreshold}
              issue={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.issueThreshold}
            />
            <StatusDisplay
              dark
              value={vehicle?.currentStatus?.tyres[3].pressure}
              unit='kPa'
              title={`AXLE ${vehicle?.currentStatus?.tyres[3].axleId} POSITION ${vehicle?.currentStatus?.tyres[3].position}`}
              warning={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.warningThreshold}
              issue={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.issueThreshold}
            />
        </View>
      } */}

      { /*vehicle?.currentStatus?.tyres?.length &&
        <View extend={styles.row}>
          {
            vehicle?.currentStatus?.tyres?.splice(2).map(t => (
            <StatusDisplay
              dark
              value={vehicleStatus.load.value}
              unit='%'
              title={`AXLE ${t.axleId} POSITION ${t.position}`}
              warning={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.warningThreshold}
              issue={!isNaN(vehicleStatus.load.value) &&
                vehicleStatus.load.value > vehicleStatus.load.issueThreshold}
            />
            ))
              }
        </View>
      */}
    </View>
    : null
  )
}

const WeightStatusDisplay = ({ weightInfo, unit, title }) => {
  if (!weightInfo) return null
  return (
    <StatusDisplay
      dark
      value={weightInfo.value}
      unit={unit}
      title={title}
      warning={weightInfo.isWarning}
      issue={weightInfo.isIssue}
    />
  )
}

const WeightKpiRows = ({ vehicleStatus }) => {
  const hasKgValues = vehicleStatus?.grossLoad_kg || vehicleStatus?.grossFrontLoad_kg || vehicleStatus?.grossRearLoad_kg
  return <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <View extend={styles.row}>
      <WeightStatusDisplay weightInfo={vehicleStatus?.load} unit={'%'} title={'VEHICLE WEIGHT'} />
      <WeightStatusDisplay weightInfo={vehicleStatus?.frontLoad} unit={'%'} title={'FRONT WEIGHT'} />
      <WeightStatusDisplay weightInfo={vehicleStatus?.rearLoad} unit={'%'} title={'REAR WEIGHT'} />    
    </View>

    {hasKgValues ? <View extend={styles.row}>
      <WeightStatusDisplay weightInfo={vehicleStatus?.grossLoad_kg} unit={'kg'} title={'VEHICLE WEIGHT'} />
      <WeightStatusDisplay weightInfo={vehicleStatus?.grossFrontLoad_kg} unit={'kg'} title={'FRONT WEIGHT'} />
      <WeightStatusDisplay weightInfo={vehicleStatus?.grossRearLoad_kg} unit={'kg'} title={'REAR WEIGHT'} />    
    </View> : null}
  </View>
}

const styles = {
  sidebar_title: {
    marginBottom: 8,
    paddingTop: 12
  },
  kpis: ({ theme: { layout, colors } }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 12,
    paddingBottom: 18,
  }),
  row: ({ theme: { layout, colors } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'green',
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: colors.white,
    borderBottomLeftRadius: layout.borderRadius,
    borderBottomRightRadius: layout.borderRadius,
    paddingBottom: 18,
  })
}
