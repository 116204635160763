export const lps_summary_columns = [
  {
    id: "vehicle",
    label: "Vehicle",
    align: "left",
    width: 100 / 7 + "%",
  },
  {
    id: "solarKWH",
    label: "Solar",
    align: "left",
    width: 100 / 7 + "%",
    format: (value) => (value || value === 0) ? value.toFixed(2) + ' kWh' : '-',
  },
  {
    id: "acKWH",
    label: "AC",
    align: "left",
    width: 100 / 7 + "%",
    format: (value) => (value || value === 0) ? value.toFixed(2) + ' kWh' : '-',
  },
  {
    id: "dcKWH",
    label: "DC",
    align: "left",
    width: 100 / 7 + "%",
    format: (value) => (value || value === 0) ? value.toFixed(2) + ' kWh' : '-',
  },
  {
    id: "chargedKWH",
    label: "Total",
    align: "left",
    width: 100 / 7 + "%",
    format: (value) => (value || value === 0) ? value.toFixed(2) + ' kWh' : '-',
  },
];
