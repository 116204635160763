const columns = [
  {
    id: 'vehicleName',
    label: 'Vehicle',
    minWidth: 150,
    width: '15%',
    hide: [],
    align: 'left'
  },
  {
    id: 'stations',
    label: 'Stations Passed',
    minWidth: 150,
    width: '15%',
    align: 'right',
    hide: []
  },
  {
    id: 'charge',
    label: 'Charge',
    minWidth: 150,
    width: '15%',
    align: 'right',
    hide: []
  },
  {
    id: 'currency',
    label: 'Currency',
    width: '15%',
    align: 'right',
    minWidth: 150,
    hide: []
  },
]

export default columns