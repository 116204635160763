const categoryColumn = {
  id: "category",
  label: null,
  align: "center",
  icon: true,
  width: 70,
  isSortable: true,
}

const nameColumn = {
  id: "name",
  label: null,
  align: "flex-start",
  hasValues: true,
  width: 200,
  isSortable: true,
}

const hasTrackerColumn = {
  id: "hasTracker",
  label: null,
  align: "flex-start",
  width: 70,
  icon: true,
  isSortable: true,
}

const brandColumn = {
  id: "brand",
  label: null,
  align: "flex-start",
  hasValues: true,
  width: 200,
  isSortable: true,
}

const dueDateColumn = {
  id: "dueDate",
  label: null,
  align: "flex-start",
  minWidth: 300,
  hasValues: true,
  isSortable: true,
}

const homebaseColumn = {
  id: "homebase",
  label: null,
  align: "flex-start",
  minWidth: 300,
  hasValues: true,
  icon: true,
  isSortable: true,
}

const frequencyColumn = {
  id: "frequency",
  label: null,
  align: "flex-start",
  minWidth: 200,
  hasValues: true,
  isSortable: true,
}

const detailsColumn = {
  id: "details",
  label: null,
  align: "flex-end",
  hasValues: true,
  minWidth: 100
}

export const log_columns_collapsable = [
  categoryColumn,
  nameColumn,
  hasTrackerColumn,
  brandColumn,
  dueDateColumn,
  frequencyColumn,
  detailsColumn
];

export const log_columns_viewAll = [
  categoryColumn,
  nameColumn,
  hasTrackerColumn,
  brandColumn,
  dueDateColumn,
  homebaseColumn,
  frequencyColumn,
  detailsColumn
];
