import { call, put, select } from 'redux-saga/effects'

export function* get(accessToken, url, ignoreSelectedOrgs = false) {
  return yield ajax(accessToken, url, null, ignoreSelectedOrgs)
}

export function* post(accessToken, url, body) {
  return yield ajax(accessToken, url,  {
      body: JSON.stringify(body),
      method: 'POST',
    })
}

export function* patch(accessToken, url, body) {
  return yield ajax(accessToken, url, {
    body: JSON.stringify(body),
    method: 'PATCH',
  })
}


export function* upload(url, config, token) {
  const request_config = {
    ...config,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
  console.log('Request: ' + url)

  let response =  yield fetch(url, request_config)

  console.warn('uploadRespnse', response)

  let data
  switch (response.status) {
    case 200:
      data = yield call([response, response.json]);
      return {
        status: 'ok',
        data
      }
    case 201:
    case 202:
      return {
        status: 'ok'
      }
    case 204:
      return {
        status: 'no_content',
      }
    case 400:
      return {
        status: 'bad_request'
      }
    case 401:
      console.warn('User not authorized')
      yield put({ type: 'LOGGING_OUT' })

      return null
    case 404:
      return {
        status: 'not_found'
      }
    case 409:
      return {
        status: 'conflict'
      }
    default:
      return null
  }
}

function* ajax(accessToken, url, config, ignoreSelectedOrgs) {
  try {
    const selectedOrganisation = yield select(state => state.selected_organisations)
    const request_config = {
      ...config,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }

    if (selectedOrganisation.length && !ignoreSelectedOrgs)
      request_config.headers.Organisation = selectedOrganisation.join(';')

    const response = yield call(fetch, url, request_config)

    console.log(response)

    let data
    if (response.status > 199 && response.status < 300) {
      try {
        data = yield call([response, response.json])
      }
      catch {
        data = null
      }
    }
    switch (response.status) {
      case 200:
        return {
          status: 'ok',
          data
        }
      case 202:
        return {
          status: 'ok',
          data
        }
      case 204:
        return {
          status: 'no_content',
        }
      case 400:
        return {
          status: 'bad_request'
        }
      case 401:
        console.warn('User not authorized')
        yield put({ type: 'LOGGED_OUT' })

        return null
      case 403:
        yield put({ type: 'FORBIDDEN_SERVER_CALL' })
        return {
          status: 'forbidden',
          data
        }
      case 404:
        if(config && config.method === 'POST') {
          yield put({ type: 'AJAX_ERROR' })
        }
        return null
      case 409:
        return {
          status: 'conflict'
        }
      case 422:
        return {
          status: 'unprocessable_entity'
        }
      default:
        return null
    }
  } catch (error) {
    switch(error.msg) {
      // case "Token Renewal Failed":
      //   if(failedFetchTokenCounter < 2) {
      //     console.log('Token Renewal Failed; new try to refresh token (#' + failedFetchTokenCounter + ')')
      //     yield fork(ajax(url, config))
      //     failedFetchTokenCounter++
      //   } else {
      //     yield put({ type: 'LOGGED_OUT' })
      //     console.log('Token Renewal Failed; logging out')
      //     failedFetchTokenCounter = 0
      //   }

      //   return
      // case "login_required":
      //   console.log('User not authorized, login required.')
      //   yield put({ type: 'LOGGED_OUT' })
      //   failedFetchTokenCounter = 0
      //   return
      default:
        console.warn('Ajax request ', url + ' FAILED', error)
        return
    }
  }
}