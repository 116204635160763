import React, { useContext } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { PaperTitle } from "@modul-connect/shared/components/atoms/text/text";
import TimeSelectionRow from "./timepickerRow"
import { borderStyle } from "./paperWithHeader";
import { ReportContext } from "../../../../portal/src/pages/reports/reportPage.v2";
import { SearchField } from '../../molecules/searchWidget.v2/searchField';
import { Button } from "@mui/material";
import theme from "../../../theme";

const styles = {
  headerWrapper: ({ theme: { layout, colors } }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.barBackground,
    borderTopRightRadius: `${1 * layout.grid}px`,
    borderTopLeftRadius: `${1 * layout.grid}px`,
    "> div": {
      display: "flex",
      flexDirection: "row",
      color: colors.primary,
      "> p": {
        color: colors.primary,
      },
    },
  }),
  headerItem: ({ theme: { layout, colors } }) => ({
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 7.5,
    paddingBottom: 7.5,
  }),
}

export const Header = ({
  title,
  groupingOptionSelector,
  onTimeframePicked,
  onStartDatePicked,
  onEndDatePicked,
  noFilterBar,
  noTimePickerBar,
  noSearch,
  onSearchChange,
  onDownload,
  filterDropdowns,
  searchValue,
  timeSelectors,
  button,
  sideFitleringComponent
}) => {
  return (
    <View extend={styles.headerWrapper}>
      <View extend={[styles.headerItem, {borderBottom: borderStyle}]}>
        {title && <PaperTitle text={title} />}
      </View>
      {button ? <View style={{background: theme.colors.white, padding: '22px, 0px, 22px, 0px', height: 50}}>
        {button}
      </View> : null}

      {noTimePickerBar ? null : <View
        extend={[
          styles.headerItem,
          {
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: borderStyle,
          },
        ]}
      >
        <TimeSelectionRow
          onTimeframePicked={onTimeframePicked}
          onStartDatePicked={onStartDatePicked}
          onEndDatePicked={onEndDatePicked}
          onDownload={onDownload}
          timeSelectors={timeSelectors}
        />
      </View>}

      {noFilterBar 
        ? null 
        : (
            <View extend={styles.headerItem}>
            {
              <FilterRow
                groupingOptionSelector={groupingOptionSelector}
                noSearch={noSearch}
                onSearchChange={onSearchChange}
                filterDropdowns={filterDropdowns}
                searchValue={searchValue}
                sideFitleringComponent={sideFitleringComponent}
              />
            }
            </View>
      )}
    </View>
  );
};

// TODO: implement filtering via the dropdowns
// TODO: implement searching
const rowItemStyle = {
  flex: 1,
  height: "100%",
  display: "flex",
  alignItems: "center",
};
const FilterRow = ({ 
  groupingOptionSelector, 
  noSearch, 
  onSearchChange, 
  filterDropdowns, 
  searchValue,
  sideFitleringComponent
 }) => {

  const isLoading = useContext(ReportContext).isLoading

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <View style={{ flex: 1, display: "flex", alignContent: "flex-start" }}>
        {
          groupingOptionSelector 
            ? groupingOptionSelector 
            : null}
      </View>

      <View style={{ flex: 1, display: "flex", flexDirection: "row", gap: 32 }}>
      {
        filterDropdowns?.length 
          ? filterDropdowns.map((filter, index) => {
            return <View key={index} style={rowItemStyle}>{filter}</View>
            }) 
          : !sideFitleringComponent
            ? <View style={rowItemStyle} />
            : null
      }
        { 
          sideFitleringComponent 
            ? sideFitleringComponent
            : null
        }
        {/*<View style={rowItemStyle}>
           <SimpleDropdown
            flexWidth
            options={["Show all", "Business", "Private"]}
            selectedValue={"Show all"}
          /> 
        </View>*/}

        {/*<View style={rowItemStyle}>
           <SimpleDropdown
            flexWidth
            options={["Show all", "Missing Notes"]}
            selectedValue={"Show all"}
          /> 
        </View>*/}
        <View style={{...rowItemStyle}}>
          {noSearch 
            ? null 
            : <SearchField 
                disabled={isLoading} 
                v2 
                flexWidth 
                boxShadow 
                placeholder="Search" 
                defaultValue={searchValue} 
                onChange={onSearchChange && ((event) => onSearchChange(event.target.value))} 
              />
          }
        </View>
      </View>
    </View>
  );
};