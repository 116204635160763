import React from "react";
import View from "../../atoms/view";

const MainContainer = ({ children, extend, themes, setActive = true, showFilterBar, blockScrolling }) => {
  const [state, setState] = React.useState(setActive);

  return (
    <View style={{ overflowY: blockScrolling ? 'hidden' : 'scroll', height: '100%', paddingBottom: '150px' }} extend={!state ? styles(showFilterBar).sidebar_inactive : [styles(showFilterBar).sidebar_active,
        themes.device==="desktop" ? styles(showFilterBar).sidebar_desktop :
        (themes.device==="tablet" ? styles(showFilterBar).sidebar_tablet :
        (themes.device==="mobile" && styles(showFilterBar).sidebar_mobile)), extend]
    }>
      {children}
    </View>
  );
};

const styles = (showFilterBar) => { return {
  sidebar_active: ({ theme: { colors } }) => ({
    backgroundColor: colors.bg,
    position: 'absolute',
    left: 0,
    // bottom: 0,
    zIndex: 5,
    // boxShadow: '2px 2px 5px 3px rgba(0,0,0,.05)',
    borderRight: '1px solid rgb(240, 240, 240)',
    paddingBottom: '150px !important'
  }),
  sidebar_inactive: {
    display: "none"
  },
  sidebar_desktop: ({ theme: { layout } }) => ({
    top: layout.navBarHeight + (showFilterBar() ? layout.filterBarHeight : 0) + layout.navigationBarHeight
  }),
  sidebar_tablet: ({ theme: { layout } }) => ({
    top: layout.navBarHeight_tablet + (showFilterBar() ? layout.filterBarHeight : 0) + layout.navigationBarHeight
  }),
  sidebar_mobile: ({ theme: { layout } }) => ({
    top: layout.navBarHeight_mobile + (showFilterBar() ? layout.filterBarHeight : 0) + layout.navigationBarHeight
  })
}};

export default MainContainer;
