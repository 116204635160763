import React, { Fragment, useCallback, useState } from 'react'
import View from '../../atoms/view'
import { useLocation, useNavigate } from 'react-router-dom'
import { authContext } from '@modul-connect/shared/adalConfig/adalConfig'
import Image  from '@modul-connect/shared/components/atoms/image'
import logo from '@modul-connect/shared/img/logo.svg'
import { useAuth0 } from '@auth0/auth0-react'
import Dropdown from '@modul-connect/shared/components/atoms/dropdown/dropdown'
import OrgFilterTreeView from '../../molecules/orgFilterTreeView/orgFilterTreeView'
import { connect } from 'react-redux'
import FilterBar from '../filter-bar/filterBar'
import { Icon } from '../../atoms/icon/icon'
import { CustomTooltip } from '../../molecules/tooltip'
import NotificationTreeView from '../../molecules/notification/notificationTreeView'
import { redirectUri_logout } from '../../../config'
import theme from '../../../theme'
import { Switch } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { toggleSound } from '../../../../portal/src/state/actions/app'
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '../../atoms/iconButton/iconButton'

let expanded = []
let organisations = []

const formatOrganisationName = translation =>
  translation?.length && [...translation].reverse().join(' > ')

const formatOrganisationTree = (tree, rootNames, isParentChecked, ) => { 
  const isChecked = (organisations.includes(tree.uuid) || isParentChecked) ?? false
  return(
  {
    uuid: tree.uuid,
    label: formatOrganisationName(rootNames?.[tree.uuid]) ?? tree.name,
    children: tree.children?.map(child => formatOrganisationTree(child, undefined, isChecked)),
    checked: isChecked,
    expanded: expanded.includes(tree.uuid) ?? false
  }
)}

const NavBar = ({noNotifications, noOrgSelection, noUser, user_settings, showFilterBar, themes, routes, authTool, username, organisationTrees, organisationNames, setSelectedOrganisations, selected_organisations, notifications, setSeenNotifications, toggleSound}) => {
  return (
    <View as='nav' extend={styles.nav}>
      <HeaderBar 
        sound={user_settings?.notificationSound}
        toggleSound={toggleSound}
        showFilterBar
        themes={themes}
        authTool={authTool}
        organisationTrees={organisationTrees}
        selected_organisations={selected_organisations}
        setSelectedOrganisations={setSelectedOrganisations}
        organisationNames={organisationNames}
        username={username}
        notifications={notifications}
        setSeenNotifications={setSeenNotifications}
        noNotifications={noNotifications}
        noOrgSelection={noOrgSelection}
        noUser={noUser}
      />

      {showFilterBar() ? <FilterBar /> : null}

      <NavigationBar themes={themes} routes={routes} />
    </View>
  )
}

const HeaderBar = ({sound, toggleSound, noNotifications, noOrgSelection, noUser, themes, authTool, organisationTrees, selected_organisations, setSelectedOrganisations, organisationNames, username, notifications, setSeenNotifications }) => {
  organisations = selected_organisations

  let logoutFunction
  if (authTool === 'auth0') {
    const { logout } = useAuth0()
    logoutFunction = () =>  logout({logoutParams: {returnTo : redirectUri_logout()}})
  } else if (authTool === 'adal') {
    logoutFunction = () => authContext.logOut()
  } else {
    throw 'No authentication tool selected for navbar'
  }

  const DropdownTree = useCallback(() => {
    if (noOrgSelection) return null
    return (organisationTrees?.total > 1 || (organisationTrees?.total && organisationTrees.subtrees[0].children?.length ) ?
      <OrgFilterTreeView
        mobile={themes.device === 'desktop' ? false : true}
        data={ organisationTrees.total
          ? organisationTrees.subtrees.map(tree => formatOrganisationTree(tree, organisationNames))
          : []
        }
        onSelect={ (selectedNodes) => {
          organisations = selectedNodes
          setSelectedOrganisations(selectedNodes)
        }}
        onToggle={ uuid => expanded.includes(uuid) ? expanded = expanded.filter(x => x !== uuid) : expanded.push(uuid) }
      />
    : <></>)
    },
    [organisationTrees, organisationNames, themes.device, noOrgSelection]
  )

  return (
    <View extend={[styles.navContainer, themes.device == 'tablet' && styles.nav_tablet, themes.device == 'mobile' && styles.nav_mobile]}>
        <View extend={styles.toolBar}>
          { themes.device === 'desktop' && (
            <View className='parent' extend={styles.toolBar}>
              <View extend={styles.left_side}>
                <Logo />
              </View>
              <View extend={styles.right_side}>
                {noNotifications ? null : <SoundToggle toggleSound={toggleSound} sound={sound} />}
                {noNotifications ? null : <NotificationTreeView
                  data={notifications}
                  setSeenNotifications={setSeenNotifications}
                />}
                {noOrgSelection ? null : <DropdownTree />}
                { !noUser && username &&
                  <Dropdown
                    styles={[styles.link, {color: 'white'}]}
                    title={ username }
                    data={[
                      {label: 'Logout', onClick: logoutFunction}
                    ]}
                  />
                }
              </View>
            </View>
          )}

          { themes.device !== 'desktop' &&  (
            <Fragment>
              <View extend={styles.left_side}>
                <Logo />
              </View>

              <View extend={styles.right_side}>
                {noNotifications ? null : <SoundToggle mobileVersion={themes.device === 'mobile'} toggleSound={toggleSound} sound={sound} />}
                {noNotifications ? null : <NotificationTreeView
                  data={notifications}
                  setSeenNotifications={setSeenNotifications}
                />}
                {noOrgSelection ? null : <DropdownTree />}
                {noUser ? null : <View extend={styles.dropdown}>
                  <View key={'username'}>
                    <View extend={{...textStyle, textTransform: 'none', color: 'white'}} onClick={() => logoutFunction()}>
                      {'Log out'}
                    </View>
                  </View>
                </View>}
              </View>
            </Fragment>
          )}
        </View>
      </View>
  )
}

const SoundToggle = ({
  toggleSound,
  sound,
  mobileVersion
}) => {
  const [checked, setChecked] = useState(sound)
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '10',
    height: '29px',
    padding: '5px',
    borderRadius: 4,
    marginRight: '8px'
  }

  const onClick = () => {
    setChecked(!checked),
    toggleSound(!checked)
  }
  const icon = sound ? <VolumeUpIcon style={{color: 'white'}}/> : <VolumeOffIcon style={{color: 'white'}}/>

  if (mobileVersion) return (
    <View extend={style}>
      <IconButton 
        v2
        iconComponent={icon}
        onClick={onClick}
      />
    </View>
  )

  return (
    <View extend={style}>
      <ThemeProvider theme={theme.mui}>
      {
        icon
      }
      <View extend={{width: 60 }}>
        <Switch
          checked={checked}
          onChange={onClick}
          color={"success"}
          name="sosundToggle"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </View>
      </ThemeProvider>
    </View>
  );
}

const Logo = ({}) => {
  const navigate = useNavigate()

  return (
    <View
      onClick={() => {
        navigate('/');
      }}
    >
      <Image
        svg={logo}
        svgWidth={styles.logo.width}
        svgHeight={styles.logo.height}
        extend={styles.logo}
        mode="fit"
      />
    </View>
  );
};

const NavigationBar = ({ themes, routes }) => {
  const navigate = useNavigate();
  const location = useLocation()

  const routes_navbar = routes.filter((x) => {
    return x.navbar;
  });

  
  const navLinkPath = location.pathname.split("/")[1];

  return (
    <View
      extend={[
        styles.navContainer,
        themes.device == "tablet" && styles.nav_tablet,
        themes.device == "mobile" && styles.nav_mobile,
        styles.navigationBar,
      ]}
    >
       <View className="parent" extend={[styles.toolBar, {margin: 0}]}>
        {routes_navbar.map(
            (route) => {
              const item = <View
              onClick={() => {
                navigate(route.url)
              }}
              extend={[
                styles.navLink,
                route.url.split("/")[1] === navLinkPath
                  ? styles.navLink_selected
                  : styles.navLink_unselected,
              ]}
              key={route.url}
            >
              <Icon dark icon={ route.icon } />
              <a>{route.name}</a>
            </View>

            if (route.name) {
              if (route.tooltip) return <CustomTooltip key={'tooltip_' + route.url} title={route.tooltip} placement="bottom"> {item} </CustomTooltip>
              else return item
            }
            else return null
          }
        )}
        </View>
    </View>
  );
};

const mapStateToProps = ({
  selected_organisations,
  user_settings
}) => ({
  selected_organisations,
  user_settings
})
const mapDispatchToProps = dispatch => ({
  toggleSound: (isSound) => dispatch(toggleSound(isSound))
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)


const textStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px',
  textAlign: 'right',
  textTransform: 'capitalize'
}
const styles = {
  nav: ({ theme: { colors, layout } }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 30
  }),
  nav_tablet:({ theme: { colors, layout } }) => ({
    height: layout.navBarHeight_tablet
  }),
  nav_mobile:({ theme: { colors, layout } }) => ({
    height: layout.navBarHeight_mobile
  }),
  navContainer: ({ theme: { layout, colors } }) => ({
    backgroundColor: colors.navBarBackground,
    width: '100%',
    height: layout.navBarHeight,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 'auto',
    zIndex: 31,
    boxShadow: '4px -10px 7px 15px rgb(0,0,0,0.1)'
  }),
  navigationBar: ({ theme: { layout, colors } }) => ({
    height: layout.navigationBarHeight,
    backgroundColor: colors.white,
    zIndex: 10, // so the dropdowns of the header bar can show up on top
    boxShadow: null,
  }),
  toolBar: ({ theme: { layout } }) => ({
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 10px',
  }),
  left_side: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    height: '100%',
  },
  right_side: {
    
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
  },
  links: ({ theme: { layout } }) => ({
    display: 'flex',
    flexDirection: 'row'
  }),
  links_desktop: {
    '& div': {
      marginRight: 10
    }
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'row',
    '> div': {
      marginLeft: 10
    },
    right: 0
  },
  link_button: ({ theme: { colors } }) => ({
    width: '65px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer'
  }),
  link_button_mobile_text: ({ theme: { colors } }) => ({
    fontSize: '10px',
    color: colors.white,
    margin: 0,
    textDecorationColor: colors.black
  }),
  logo: {
    height: '21px',
    width: '155px',

    cursor: 'pointer',
  },
  logo_small: {
    height: '24px',
    width: '80px',
  },
  link: ({ theme: { colors } }) => ({
    cursor: 'pointer',
    '& a': textStyle
  }),
  navLink: ({ theme: { colors } }) => ({
      flex: 1,
      '& a': {color: colors.iconBlack},
      cursor: 'pointer',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'uppercase',
      
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '15px',
      height: '100%',

      border: `1px solid ${colors.selectedGrey}`,
  }),
  navLink_unselected: ({ theme: { colors } }) => ({
    backgroundColor: colors.barBackground,
    '&:hover': {
      backgroundColor: colors.midGrey,
    }
  }),
  navLink_selected: ({ theme: { colors } }) => ({
    backgroundColor: colors.white
  }),
  icon: ({ theme: { colors, layout } }) => ({
    color: `${3 * layout.grid}px`
  })
}
