import React from 'react'
import View from '@modul-connect/shared/components/atoms/view'
import theme from "@modul-connect/shared/theme";
import { P } from '../atoms/text';

export const ArmedVehicle = ({map_settings, securitySystemStatus}) => {

  return(
    map_settings.isLive &&
    securitySystemStatus?.securitySystem &&
    securitySystemStatus?.isArmed && (
      <View
        extend={{
          display: "flex",
          height: "19px",
          width: "54px",
          backgroundColor: theme.colors.armedRed,
          justifyContent: "center",
          alignSelf: "flex-end",
          alignItems: "center",
        }}
      >
        <P
          small
          extend={{
            display: "flex",
            color: theme.colors.white,
            textAlign: "center",
            justifyContent: "center",
          }}
          noPadding
        >
          Armed
        </P>
      </View>
    )
  )
}
