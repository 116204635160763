import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import Card from "@modul-connect/shared/components/atoms/card/card";
import { CardTitle } from "../../atoms/text/text";
import StatusWidget from "../../atoms/widget/status";

const KpiRow = ({
  data,
  isLoading,
  title,
  width,
  bgColor,
  noPadding,
  maxWidth = "100%",
}) => {
  return (
    <Card
      extend={{
        marginTop: "20px",
        backgroundColor: bgColor ?? 'auto',
        flex: 0,
        padding: noPadding ? 0 : 20,
        marginBottom: "2%",
        maxWidth: maxWidth,
        width: width || "fit-content",
      }}
    >
      <View
        extend={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        {title ? <CardTitle>{title}</CardTitle> : null}

        <View
          extend={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "10px",
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          {data.map((d, index) => (
            <StatusWidget
              key={index}
              title={d.title}
              value={isLoading ? ' - ' : (d.value ? d.value + (d.unit ? ' ' + d.unit : '') : ' - ')}
              color={d.color}
              width={d.width}
            />
          ))}
        </View>
      </View>
    </Card>
  );
};

export default KpiRow;
