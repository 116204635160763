import React, { useEffect, useState } from 'react'
import ReportPage from "../reportPage.v2"
import { connect } from "react-redux";
import LocksStatusGraph, { intervals } from './locksStatusGraph';
import { fetchLockReports, fetchLockSummary } from '../../../state/actions/vehicles';
import { useAuth0 } from '@auth0/auth0-react';
import { getDayName } from '@modul-connect/shared/utils/text';
import { DateTime } from "luxon";
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import { log_columns } from './log_columns';
import { getServiceList } from '../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';


const LockReportPage = ({
  vehicles_locks,
  fetchLockSummary,
  fetchLockReports,
  loading,
  themes,
  subtrees,
  selected_organisations
}) => {
  const disabled = !getServiceList({subtrees}).hasService(ServiceType.LocksReports)
  const { getAccessTokenSilently } = useAuth0();

  let [reportData, setReportLogs] = useState(null)
  let [data, setData] = useState({})
  const [logInterval, setLogInterval] = useState(intervals.LAST_WEEK)

  const getDaysForFetching = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return 1
      case intervals.LAST_WEEK:
        return 7
       case intervals.LAST_MONTH:
        return 30
      default:
        return 1
    }
  }

  useEffect(() => {
    getAccessTokenSilently()
    .then((accessToken) => {
      fetchLockSummary(accessToken, getDaysForFetching(logInterval))
      fetchLockReports(accessToken, getDaysForFetching(logInterval))
    })
  },[selected_organisations])

  useEffect(() => {
    let nextId = -1
    let preparedData = []

    const logs = vehicles_locks?.history
    if (logs && logs.length >= 0) {
      setReportLogs(logs.map(log => {
        nextId++
        preparedData.push({
          id: nextId,
          boxId: log.mainboxId,
          timestamp_locked: log.lockedAt ? DateTime.fromISO(log.lockedAt) : null,
          timestamp_unLockedAt: log.unLockedAt ? DateTime.fromISO(log.unLockedAt) : null,
          vehicle: log.vehicleName,
          vin: log.vin,
          endTimeString: log.unLockedAt ? dateTimeToString(DateTime.fromISO(log.unLockedAt), 'time-only') : null,
          startTimeString: log.lockedAt ? dateTimeToString(DateTime.fromISO(log.lockedAt), 'time-only') : null,
          status: 
          {
            value: log.isLocked ? 'Locked' : 'Unlocked',
            guard: log.isLocked
          }
        })
      }))
    }

    if (preparedData && preparedData.length > 0) {
      setData(getDataByDay(preparedData))
    } else {
      setData({})
    }

  },[vehicles_locks])

  const getDataByDay = (data) => {

    let dividedByDay = {}
    let dailyList = []

    let prevTimestamp = ''

    if (data?.length) {
      data.forEach(log => {

        if (prevTimestamp && (log.timestamp_locked.startOf("day").toMillis() !== prevTimestamp.startOf("day").toMillis())) {

          dividedByDay[prevTimestamp] = [...dailyList]
          dailyList = []
        }
        dailyList.push(log)
        prevTimestamp = log.timestamp_locked
      })
    }
    if (dailyList.length > 0) {

      dividedByDay[prevTimestamp] = [...dailyList]
    }
    return dividedByDay
  }

  const onIntervalChanged = (newInterval) => {
    setLogInterval(newInterval)
      getAccessTokenSilently()
      .then((accessToken) => {
        fetchLockSummary(accessToken, getDaysForFetching(newInterval))
        fetchLockReports(accessToken, getDaysForFetching(newInterval))
      })
    
  }

  const tables = (
    Object.keys(data)?.length ? Object.keys(data).map(key => {
      const log = data[key]
      return <TableWidgetSeparated
        tableTitle={getDayName(DateTime.fromISO(key))}
        key={key}
        data={log}
        loadingStatus={loading.fetchLockReports}
        totalItems={reportData}
        columns={log_columns}
        themes={themes}
        isSortable={false}
        pagination={false}
        rowsOptions={[log?.length]}
      />
    }) : []
  )

  return(
    <ReportPage
      disabled={disabled}
      noPadding
      noBackground
      noTimePickerBar
      noSearch
      kpi={<LocksStatusGraph 
          data={vehicles_locks?.summary} 
          logInterval={logInterval}
          onIntervalChanged={onIntervalChanged}
          isLoading={loading.fetchLockSummary ===  'loading'}
          fetchingDataFailed={loading.fetchLockSummary === 'failed'}
          />}
      title={"Reports"}
      noDataMessage={"All the vehicles have been locked."}
      tables={tables}
      isLoading={loading.fetchLockReports ===  'loading'}
      fetchingDataFailed={loading.fetchLockReports === 'failed'}
    >
  </ReportPage>
  )
}

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  fetchLockSummary: (accessToken, days) => dispatch(fetchLockSummary(accessToken, days)),
  fetchLockReports: (accessToken, days) => dispatch(fetchLockReports(accessToken, days))
});

export default connect(mapStateToProps,mapDispatchToProps)(LockReportPage);
