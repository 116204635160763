import React from 'react'
import { connect } from 'react-redux'
import { P } from '@modul-connect/shared/components/atoms/text';
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import DrivingLogWeightKPI from './components/drivingLogWeightKPI';
import ReportPage from '../../reportPage.v2';
import RouteDetailsSubpage from '../../drivingLog/singleDrivingLogDetails.js/routeDetailsSubpage';
import PaddedContainer from '@modul-connect/shared/components/atoms/view/PaddedContainer'

const WeightReportDetails = ({
  vehicle,
  isLoading,
  drivingLog,
  drivingLogDetails,
  themes,
  warningTime,
  warningLog,
  disabled,
  loading
}) => {

  const isOngoing = !drivingLog?.endTime
  //  const hasAllData = vehicle && drivingLog && drivingLogDetails && warningLog
  const pageIsReady = !isLoading && vehicle && warningLog

  const weightInfo = warningLog ? {
    maxGross: warningLog.gross?.max,
    maxFront: warningLog.front?.max,
    maxRear: warningLog.rear?.max,

    weightGross: warningLog.weightGross,
    weightFront: warningLog.weightFront,
    weightRear: warningLog.weightRear,

    netWeight: warningLog.netWeight,

    ratioGross: warningLog.gross?.ratio,
    ratioFront: warningLog.front?.ratio,
    ratioRear: warningLog.rear?.ratio,

    hasOverweightGross: warningLog.hasOverweightGross || warningLog.gross?.ratio > 1,
    hasOverweightFront: warningLog.hasOverweightFront || warningLog.front?.ratio > 1,
    hasOverweightRear: warningLog.hasOverweightRear || warningLog.rear?.ratio > 1,
  } : undefined

  const objectName= vehicle?.vehicleName

  return (
      <ReportPage noSearch disabled={disabled} noFilterBar noTimePickerBar title={'Route Details' + (isLoading ? '' : ( ', ' + objectName))}>
        <PaddedContainer>
        {
          pageIsReady ?
            <DrivingLogWeightKPI
              weightInfo={weightInfo}
              drivingLog={drivingLog}
              warningLog={warningLog}
              isLoading={isLoading || !pageIsReady} />
            : null
        }
        {
          pageIsReady ?
            <RouteDetailsSubpage
              log={warningLog}
              isOngoing={isOngoing}
              themes={themes}
              route={drivingLogDetails?.route?.logs}
              loading={loading}
              highestWeightPercentage={Math.max(
                !isNaN(weightInfo?.ratioGross) ? weightInfo.ratioGross * 100 : null,
                !isNaN(weightInfo?.ratioFront) ? weightInfo.ratioFront * 100 : null,
                !isNaN(weightInfo?.ratioRear) ? weightInfo.ratioRear * 100 : null
              )}
            />
            : null
        }
        {
          isLoading ? <BubbleLoadingWidget text={'Loading ...'} /> : null
        }
        {
          !isLoading && !pageIsReady ? <P> Could not fetch data. </P> : null
        }
        </PaddedContainer>
      </ReportPage>
  )
}
const mapStateToProps = props => props;

export default connect(mapStateToProps)(WeightReportDetails);