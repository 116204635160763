import React from "react";
import View from "../view";
import theme from "../../../theme";
import { MdTune } from "react-icons/md";
import { Icon } from "@modul-connect/shared/components/atoms/icon/icon";
import { ClickableWrapper } from "@modul-connect/shared/components/atoms/clickableWrapper";

const IconButton = ({
  children,
  extend,
  cancel,
  disabled,
  warning,
  noBg,
  noPadding,
  device,
  issue,
  color,
  icon,
  iconComponent,
  v2,
  onClick,
  size,
  ...props
}) => {
  if (v2) return (
    <ClickableWrapper onClick={onClick}>
      {iconComponent ? iconComponent : <Icon noPadding={noPadding} size={size} icon={icon} />}
    </ClickableWrapper>
  );

  return (
    <View
      as="button"
      {...props}
      disabled={disabled}
      extend={[
        styles.button,
        extend,
        noBg && styles.noBg,
        cancel && noBg && styles.cancel_noBg,
        issue && noBg && styles.issue_noBg,
        warning && styles.warning,
        noPadding && styles.noPadding,
        color && { color: color },
        disabled && noBg && styles.disabled_noBg,
        device == "tablet" && styles.tablet,
        device == "mobile" && styles.mobile,
      ]}
    >
      <View extend={styles.icon}>
        {icon === "tune" ? (
          <MdTune size={18} color={color ?? theme.colors.primary} />
        ) : null}
      </View>
      {children}
    </View>
  );
};

const styles = {
  button: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.primary,
    textTransform: "uppercase",
    fontSize: "12px",
    textAlign: "center",
    color: colors.white,
    border: "none",
    cursor: "pointer !important",
    transition: "color 200ms ease-in-out",
    "&:hover": {
      filter: "brightness(110%)",
    },
    borderRadius: layout.borderRadius,

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  }),
  icon: ({ theme: { colors, layout } }) => ({
    paddingRight: "5px",
    marginBottom: "2px",
    display: "flex",
    justifyContent: "center",
  }),
  warning: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.orange,
  }),
  noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: "inherit",
    color: theme.colors.primary,
    "&:hover": {
      filter: "brightness(110%)",
    },
  }),
  cancel_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: "inherit",
    color: theme.colors.grey,
  }),
  disabled_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: "inherit",
    color: theme.colors.disabledGrey,
  }),
  issue_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: "inherit",
    color: theme.colors.error,
  }),
  noPadding: {
    padding: 0,
  },
  tablet: {
    fontSize: "14px",
  },
  mobile: {
    fontSize: "14px",
    padding: "10px",
    marginTop: "15px",
  },
};

export default IconButton;
