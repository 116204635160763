import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';
import { connect } from "react-redux";
import theme from "@modul-connect/shared/theme";

const ChartBar = ({
  data, 
  lines ,
  width,
  maxValue,
  tickXaxis,
  tickYaxis
}) => {
  return <BarChart
        width={width - 40}
        height={250}
        data={data}
      >
        <CartesianGrid  vertical={false} horizontal={false}/>
        <XAxis dataKey="name" tick={tickXaxis} interval={0}/>
        <YAxis axisLine={false} ticks={tickYaxis} domain={[0, maxValue]} style={{ fontSize: '12px', fontFamily: 'Inter',}} tickFormatter={(tick) => {
          return  tick.toFixed(2) + ' kWh' 
          }}/>
        <Tooltip/>
        {
          lines.map((obj, index) => <ReferenceLine y={obj.value} stroke={obj.colorLine} key={index}/>)
        }
        <Bar dataKey="Solar" fill={theme.colors.solarGreen} barSize={16}/>
        <Bar dataKey="AC" fill={theme.colors.acGrey} barSize={16}/>
        <Bar dataKey="DC" fill={theme.colors.dcBlack} barSize={16} />
  </BarChart> 
}


const mapStateToProps = (props) => props;

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ChartBar);
