export const summaryColumns = [
  {
    id: 'day',
    label: 'Day',
    width: 220,
    hide: []
  },
  {
    id: 'count',
    label: 'Routes with overweight',
    minWidth: 200,
    hide: []
  }
]

export const logColumns = [
  {
    id: 'startEndTime',
    hasValues: true,
    isSortable: false,
    width: 75,
  },
  /* {
    id: 'startEndAddress',
    hasValues: true,
    isSortable: false,
    minWidth: 200
  }, */
  {
    id: 'distance',
    hasValues: true,
    label: "Distance",
    isSortable: true,
    minWidth: 150
  },
  {
    id: 'vehicle',
    hasValues: true,
    label: "Vehicle",
    isSortable: true,
    minWidth: 150
  },
  {
    id: 'grossWeight',
    hasValues: true,
    label: "Gross weight",
    isSortable: true,
    minWidth: 130
  },
  {
    id: 'front',
    hasValues: true,
    label: "Front",
    isSortable: true,
    minWidth: 130
  },
  {
    id: 'rear',
    hasValues: true,
    label: "Rear",
    isSortable: true,
    minWidth: 130
  },
]