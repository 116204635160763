import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import View from '@modul-connect/shared/components/atoms/view'
import { P } from '@modul-connect/shared/components/atoms/text';
import theme from "@modul-connect/shared/theme";

function DropzoneComponent({
  customChildComponent,
  onFileDropped,
  imageOnly,
  pdfOnly
}) {
  const [file, setFile] = useState(null);

  const [invalidCharacter, setInvalidCharacter] = useState(false)

  const onDropAccepted = useCallback(acceptedFiles => {
    setFile(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, []);

  useEffect(() => {
    if (file && onFileDropped) onFileDropped(file)
  }, [file])

  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  function fileNameValidator(file) {
    if (file.name.includes('%')) {
      setInvalidCharacter(true)
      return {
        code: "name-contains-invalid-character",
        message: `Name contains invalid character: %`
      };
    }
    else setInvalidCharacter(false)
  
    return null
  }
  

  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    /* borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed', */
    backgroundColor: 'white',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out', 
    cursor: 'pointer'
  };
    
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    validator: fileNameValidator,
    onDropAccepted,
    accept: imageOnly ? {
      'image/jpeg': ['.jpeg','.jpg', '.heif'],
      'image/png': ['.png'],
    } : pdfOnly ? {'application/pdf': ['.pdf']} : {},
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
  }), [
    isDragActive,
    isDragAccept
  ]);

  return (
    <div {...getRootProps({style})}>
      <input {...getInputProps()} />
      {isDragActive && !isDragAccept ? <P noPadding color={theme.colors.error} >{'Invalid file type.'}</P> : null}
      {invalidCharacter ? <P noPadding color={theme.colors.error} >{'File name contains invalid character: %.'}</P>  : null}
      <View>
        {!customChildComponent ? 'Drag and drop your images here.' : customChildComponent}
      </View>
    </div>
  )
}

export default DropzoneComponent;