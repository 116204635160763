import { getBatteryWarningTagColorName } from "../../../../utils/vehicleUtils";

export const log_columns = [
    {
        id: 'startTimeString',
        label: 'From',
        width: 100,
        hide: [],
        align: 'left'
    },
    {
        id: 'endTimeString',
        label: 'To',
        width: 100,
        hide: [],
        align: 'left'
    },
    {
        id: 'vehicle',
        label: 'Vehicle',
        hide: [],
        align: 'center'
    },
    // {
    //     id: 'batteryId',
    //     label: 'Battery ID',
    //     hide: []
    // },
    {
        id: 'voltage',
        label: 'Voltage',
        isTag: true,
        hasGuard: true,
        tagColor: getBatteryWarningTagColorName,
        textColor: 'black',
        hide: []
    },
]