import React, { Fragment } from "react";
import { ticks_24_hours, ticks_30_days, ticks_7_days } from "../../fleet/vehicles/vehicle/logGraphs/ticks";
import { connect } from "react-redux"
import Paper from '@modul-connect/shared/components/atoms/paper'
import LogChart from "@modul-connect/shared/components/organisms/logChart";
import moment from "moment";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { P } from "@modul-connect/shared/components/atoms/text";

export const intervals = {
  LAST_24_H: "24 hours",
  LAST_WEEK: "7 days",
  LAST_MONTH: "30 days"
}

const LocksStatusGraph = ({
  logInterval,
  data,
  onIntervalChanged,
  isLoading,
  fetchingDataFailed
}) => {

  const getTicks = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return ticks_24_hours()
      case intervals.LAST_WEEK:
        return ticks_7_days()
      case intervals.LAST_MONTH:
        return ticks_30_days()
      default:
        return []
    }
  }

  const logs = data?.logs
  return (
    <Paper title={"Status"}>
      {
        isLoading
        ? 
          <BubbleLoadingWidget text={'Loading ...'} />
        : 
        <Fragment>
          <LogChart
              intervalOptions={Object.values(intervals)}
              selectedInterval={logInterval}
              onIntervalChanged={onIntervalChanged}
              data={logs ? logs.map(log => {
                return {
                  timestamp: moment(log.timeStamp).unix(),
                  vehiclesLocked: log.vehiclesLocked,
              }}) : []}
              data_key="vehiclesLocked"
              data_name="Locks Locked"
              title={"Locked Vehicles"}
              unit=" "
              line_type={"stepAfter"}
              domain={[
                0,
                data?.numberOfLocks
              ]}
              displayTimeInHours={logInterval === intervals.LAST_24_H ? true : false}
              ticks={getTicks(logInterval)}
              noYDecimals
            /> 
          {
          logs?.length 
            ? null 
            : fetchingDataFailed 
              ? <P>There was an issue trying to fetch the data from the server.</P> 
              :   <P>There are no lock data for this period</P>}
        </Fragment>
      }
    </Paper>)
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LocksStatusGraph);