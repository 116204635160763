const columns = [
  {
    id: 'time',
    labelAlign: 'left',
    label: 'Time',
    maxWidth: 250,
    hide: []
  },
  {
    id: 'area',
    label: 'Area',
    minWidth: 120,
    hide: [],
    align: 'left'
  },
  {
    id: 'station',
    label: 'Station',
    maxWidth: 120,
    align: 'right',
    hide: []
  },
  {
    id: 'charge',
    label: 'Charge',
    align: 'right',
    maxWidth: 120,
    hide: []
  },
  {
    id: 'currency',
    label: 'Currency',
    align: 'right',
    maxWidth: 120,
    hide: []
  },
  {
    id: 'driver',
    label: 'Driver',
    minWidth: 120,
    hide: []
  },
  {
    id: 'purpose',
    label: 'Purpose',
    maxWidth: 120,
    // isTag: true,
    tagColor: (purpose) => {
      return purpose
    },
    hide: []
  }
]

export default columns