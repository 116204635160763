import React, { Fragment } from 'react'
import View from "@modul-connect/shared/components/atoms/view";
import { H5, P } from "@modul-connect/shared/components/atoms/text";

export const InfoSection = ({ header, textLines, children }) => {
  let nextKey = -1
  return (
    <View extend={styles.info_section}>
      <H5 extend={ [styles.sidebar_title, {marginBottom: 0}] }>{header}</H5>
      <Fragment>
        {
          textLines?.map(line => {
            if (line) {
              nextKey++
              return <P key={nextKey}>{line}</P>
            }
            return null
          })
        }
      </Fragment>
      {
        children
      }
    </View>
  )
}

const styles = {
  sidebar_title: {
    marginBottom: 8,
    paddingTop: 12
  },
  info_section: ({ theme: { colors } }) => ({
    paddingBottom: 10
  }),
}