import React from 'react'
import { Fade, Modal } from '@mui/material'
import View from '@modul-connect/shared/components/atoms/view'
import Button from '@modul-connect/shared/components/atoms/button'
import TextField from '@modul-connect/shared/components/molecules/textField'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import { useEffect } from 'react'
import modal from '@modul-connect/shared/components/atoms/modal'
import { DateTime } from 'luxon'

const ChangeMileageModal = ({ 
    openModal,
    onClose,
    vehicleId,
    changeMileage,
    disabled,
    saving,
    updateFailed
}) => {
    const [newMileage, setNewMileage] = React.useState(null)
    const [date, setDate] = React.useState(DateTime.local())
    const [error, setError] = React.useState(false)

    const [errorText, setErrorText] = React.useState("")

    useEffect(() => {
        if(openModal) {
            setNewMileage(null)
            setDate(DateTime.local())
            setError(false)
        }
    }, [openModal])

    useEffect(() => {
        if(updateFailed) {
            setError(true)
        }
    })

    useEffect(() => {
        if(saving && saving.changeVehicleMileage === 'failed') {
            setError(true)
            setErrorText(' - Update failed.')
        }
        if (saving && saving.changeVehicleMileage === null) {
            closeModal()
        }
    }, [saving])

    const isValidMileage = (mileage) => {
        if (mileage === 0){
            return true
        }
        
        if (!mileage) {
            return false
        }
        
        if(mileage < 0) {
            return false
        }

        return true
    }

    const onSave = () => {
        let hasErrors
        setErrorText('')
        setError(false)

        if(!isValidMileage(newMileage)) {
            setError(true)
            hasErrors = true
            setErrorText(" - Mileage must be a positive value.")
        }

        if(hasErrors) {
            return
        }

        const data = {
            mileage: parseFloat(newMileage),
            date: date,
            vehicleId: vehicleId
        }

        changeMileage(data)
    }

    const closeModal = () => {
        setErrorText('')
        setError(false)
        onClose() 
    }

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (disabled && reason === 'backdropClick') {
                return
              }
              onClose()
            }}            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Change Current Vehicle Mileage"}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name="New Mileage"
                                placeholder={''}
                                disabled={ disabled }
                                onBlur={ (e) => setError(!isValidMileage(e.target.value)) }
                                error={error}
                                errorText={ errorText }
                                onChange={e => setNewMileage(e.target.value)}
                            />
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg disabled={ disabled } cancel onClick={ closeModal }>
                                Cancel
                            </Button>
                            <Button noBg disabled={ disabled || !newMileage } noPadding onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ChangeMileageModal
