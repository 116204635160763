import React, { Fragment } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { H3, P } from "@modul-connect/shared/components/atoms/text";
import Card from "@modul-connect/shared/components/atoms/card/card";
import PaperWithHeader from "@modul-connect/shared/components/organisms/paperWithHeader/paperWithHeader"
import PaddedContainer from "@modul-connect/shared/components/atoms/view/PaddedContainer";
import { createContext } from 'react';

export const ReportContext = createContext({
  startDate: null,
  endDate: null,
  isLoading: false,
  useOldDatePickers: false
});

// TODO: handle smaller screen sizes better
// TODO: abstract more things and build them here rather than defining them in the report pages
const ReportPage = ({
  title,
  isPreparingFile,
  isLoading,
  noDataMessage,
  downloadFailed,
  tables,
  fetchingDataFailed,
  kpi,
  reportGraph,
  disabled,
  groupingOptionSelector,
  timeSelectors,
  useOldDatePickers,
  startDate,
  endDate,
  onTimeframePicked,
  onStartDatePicked,
  onEndDatePicked,
  children,
  noHeader,
  noFilterBar,
  noTimePickerBar,
  noSearch,
  onDownload,
  onSearchChange,
  filterDropdowns,
  searchValue,
  button,
  sideFitleringComponent
}) => {  
  return (
    <Fragment>
      {disabled ? <MissingServiceAllert /> : null}
      <View extend={disabled && styles.pageCover}>
        {kpi ? kpi : null}
        <ReportContext.Provider value={{startDate: startDate, endDate: endDate, isLoading: isLoading, useOldDatePickers: useOldDatePickers}}>
          <PaperWithHeader
            extend={{marginTop: 42, minHeight: 350}}
            title={title}
            noFilterBar={noFilterBar}
            noTimePickerBar={noTimePickerBar}
            noHeader={noHeader}
            groupingOptionSelector={groupingOptionSelector}
            timeSelectors={timeSelectors}
            onTimeframePicked={onTimeframePicked}
            onStartDatePicked={onStartDatePicked}
            onEndDatePicked={onEndDatePicked}
            noSearch={noSearch}
            onDownload={onDownload}
            onSearchChange={onSearchChange}
            filterDropdowns={filterDropdowns}
            searchValue={searchValue}
            button={button}
            sideFitleringComponent={sideFitleringComponent}
          >
            {isPreparingFile ? (
              <BubbleLoadingWidget text={"Preparing file ..."} />
            ) : (
              <View extend={{ width: "100%" }}>
                {reportGraph ? (
                  <PaddedContainer>
                    {" "}
                    <Card> {reportGraph} </Card>{" "}
                  </PaddedContainer>
                ) : null}

                {tables ? <ReportTables 
                  isLoading={isLoading}
                  fetchingDataFailed={fetchingDataFailed}
                  downloadFailed={downloadFailed}
                  disabled={disabled}
                  tables={tables}
                  noDataMessage={noDataMessage}
                /> : null}
              </View>
            )}
            {children}
          </PaperWithHeader>
        </ReportContext.Provider>
      </View>
    </Fragment>
  );
};

const ReportTables = ({ isLoading, fetchingDataFailed, downloadFailed, disabled, tables, noDataMessage }) => {
  return (
    <View extend={styles.tableContainer}>
      {downloadFailed ? (
        <MessageDisplay message={'Something went wrong when trying to download the report.'} />
      ) : null}

      {isLoading ? (
        <BubbleLoadingWidget text={"Loading ..."} />
      ) : fetchingDataFailed ? (
        disabled ? (
          <DisabledMessage />
        ) : (
          <CouldNotFetchMessage />
        )
      ) : tables?.length || tables?.length === 0 ? (
        tables?.length ? (
          tables.map((table, index) => (
            <React.Fragment key={index}>{table}</React.Fragment>
          ))
        ) : (
          <MessageDisplay message={noDataMessage} />
        )
      ) : tables ? (
        tables
      ) : (
        <MessageDisplay message={noDataMessage} />
      )}
    </View>
  );
};

const CouldNotFetchMessage = () => {
  return (
    <MessageDisplay
      message={"There was an issue trying to fetch the data from the server."}
    />
  );
};

const DisabledMessage = () => {
  return (
    <MessageDisplay
      message={"You have no active subscription for this page."}
    />
  );
};

const MessageDisplay = ({ message }) => {
  return <H3 extend={{ paddingLeft: 20 }}>{message}</H3>;
};

const kpiMargin = "15px";
const styles = {
  kpiDisplay: {
    marginTop: kpiMargin,
    // marginBottom: kpiMargin,
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10
  },
  pageCover: {
    opacity: 0.3,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    position: "absolute",
    pointerEvents: "none",
  },
};

const MissingServiceAllert = () => {
  return (
    <View
      style={{
        minWidth: 180,
        minHeight: 100,
        maxWidth: 180,
        backgroundColor: "black",
        display: "flex",
        flex: 1,
        width: "15%",
        zIndex: 2,
        borderRadius: 10,
        margin: "auto",
        position: "relative",
        top: "40vh",
      }}
    >
      <P
        extend={{
          color: "white",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        There is no active subscription for this service
      </P>
    </View>
  );
};

export default ReportPage;
