import { WarningIcon } from "@modul-connect/shared/components/atoms/icons/warningIcon";
import { P } from "@modul-connect/shared/components/atoms/text";
import TextBox from "@modul-connect/shared/components/atoms/text/textBox";
import View from "@modul-connect/shared/components/atoms/view";
import { Title } from "@modul-connect/shared/components/molecules/dropdown/dropdown";
import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as  GreenBullet } from '@modul-connect/shared/img/green_bullet.svg'
import { ReactComponent as  RedBullet } from '@modul-connect/shared/img/red_bullet.svg'
import { ReactComponent as  YellowBullet } from '@modul-connect/shared/img/yellow_bullet.svg'
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import { DefectHistoryDisplay } from "./defectHistoryDisplay";
import TextInputBox from "@modul-connect/shared/components/atoms/textInput/textInputBox";
import { Divider } from "@mui/material";
import demo_image from '@modul-connect/shared/img/broken_glass.jpeg';
import { DateTime } from "luxon";

const statusOptions = {
  resolved: "Resolved",
  ongoing: "In progress",
  pending: "Pending",
}

const dummyData = [
  {
    comment: 'The issue has been reported. Waiting for available technician to investigate.',
    user: 'Automatic message',
    timestamp: '2024-05-05T07:15:00.000Z',
    issueClosed: false
  },
  {
    comment: 'Starting to investigate.',
    user: 'Dave Hodge',
    timestamp: '2024-05-07T08:52:00.000Z',
    issueClosed: false
  },
  {
    comment: 'Issue identified, ordering parts for repair',
    user: 'Dave Hodge',
    timestamp: '2024-05-07T10:31:00.000Z',
    issueClosed: false
  },
  {
    comment: 'Starting repair',
    user: 'Dave Hodge',
    timestamp: '2024-05-12T14:02:00.000Z',
    issueClosed: false
  },
  {
    comment: 'Repair finished.',
    user: 'Dave Hodge',
    timestamp: '2024-05-12T14:47:00.000Z',
    issueClosed: false
  },
  {
    comment: 'Vehicle passed safety tests. Issue closed.',
    user: 'Dave Hodge',
    timestamp: '2024-05-13T08:30:00.000Z',
    issueClosed: true
  },
  {
    comment: 'The issue has been closed.',
    user: 'Automatic message',
    timestamp: '2024-05-13T08:30:40.000Z',
    issueClosed: true
  }
]

export const DefaultDetails = ({ defect }) => {
  const isResolved = defect.solved === 'Yes'
  const status = isResolved ? statusOptions.resolved 
    : defect.informed ? statusOptions.ongoing
    : statusOptions.pending

  const [comment, setComment] = useState()
  const [commentHistory, setCommentHistory] = useState([])

  useEffect(() => {
    let history = [...dummyData]
    if (!isResolved) history = history.slice(0, -4)
    if (status === statusOptions.pending) history = [dummyData[0]]
    setCommentHistory(history)
  }, [])

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: 10,
        padding: 10,
        cursor: "default",
        maxHeight: 500
      }}
    >
      <View style={{ display: "flex", flexDirection: "column", flex: 1, gap: 10 }}>
      <Title>{"Details"}</Title>
        <FaultImage />

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Title>{"Defect description:"}</Title>
          {defect.danger ? (
            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: 5 }}>
              <WarningIcon />
              <Title>{"Dangerous!"}</Title>
            </View>
          ) : null}
        </View>
        <TextBox>{defect.description}</TextBox>

        <StatusDisplay status={status} />
        
        <ListItem label={'Reported by:'} value={defect.user}/>

        <ListItem label={'Reported to:'} value={defect.informed ?? '-'}/>

      </View>

      <View style={{ display: "flex", flexDirection: "column", flex: 1, gap: 10 }}>
        <Title>{"History"}</Title>
        <View style={{ height: '50%' }}>
          <DefectHistoryDisplay commentHistory={commentHistory}/>
        </View>

        <Divider />
        
        <View style={{ flexGrow: 1 }}>
          <View
            extend={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: 10
            }}
          >
            <TextInputBox
              disabled={isResolved}
              rows={4}
              placeholder={isResolved ? "The issue has been closed." : "Enter your comment here."}
              value={comment}
              onChange={setComment}
              label={''}
            />
            <Button
              disabled={!comment}
              extend={{ width: "25%", marginBottom: "20px" }}
              onClick={() => {
                const newHistory = [...commentHistory]
                newHistory.push({
                  comment: comment,
                  user: 'You',
                  timestamp: DateTime.local().toISO(),
                  issueClosed: false
                })
                setCommentHistory(newHistory)
                setComment('')
              }}
            >{"Save"}</Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const bulletSize = 18

const StatusDisplay = ({ status }) => {

  const statusBullet = status === statusOptions.resolved ? <GreenBullet height={bulletSize} width={bulletSize} /> 
    : status === statusOptions.ongoing ? <YellowBullet height={bulletSize} width={bulletSize} /> 
    : <RedBullet height={bulletSize} width={bulletSize} />

  return (
    <Fragment>
      <View style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', fontSize: 16 }}>
        <Label noPadding>{'Status:'}</Label>

        {statusBullet}

        <P noPadding>{status}</P>
      </View>

      {
        status === statusOptions.ongoing ?
        <ListItem label={"Time estimate:"} value={"4 days"}/>
        : null
      }
      </Fragment>
  )
}

const ListItem = ({ label, value }) => {
  return <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 16, gap: 10 }}>
    <Label noPadding>{label}</Label>
    <P noPadding>{value}</P>
  </View>
}

const Label = ({ children, noPadding }) => {
  return (
    <P noPadding extend={{ fontWeight: 500, marginRight: noPadding ? 0 : 10 }}>{children}</P>
  )
}

const width = 300
const FaultImage = () => {
  return (
    <View
      style={{
        height: width,
        width: width * 499/333,
        backgroundSize: 'contain',
        border: "1px solid grey",
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${demo_image})`,
      }}
    />
  );
};
