import React, { Fragment, useEffect, useCallback, useState, } from "react";
import ReportPage from "../../../reportPage.v2";
import { GroupingOptionSelector } from "../../../tollPassages/summary/groupingOptionSelector";
import { groupingOptions } from "../trackers/assetTrackerStatusPage";
import { useNavigate } from 'react-router-dom';
import HomebaseViewTable from "./homebaseViewTable";
import { useLocation } from 'react-router-dom';
import { fetchMaintenanceTasks, fetchMaintenanceTasksSummary, fetchMaintenanceHomebases, uploadImage, clearUploadedImages, clearUploadedDocuments, uploadDocument } from "../../../../../state/actions/vehicles";
import { connect } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import ViewAllTaskTable from "./viewAllTable";
import AddMaintenanceModal from "../../modals/addMaintenanceModal";
import AddHomeBaseModal, { CustomHomebaseSelector } from "../../modals/addHomeBaseModal";
import MaintenanceDetailsModal from "../../modals/detailsModal/maintenanceDetailsModal";
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import UploadFileModal from "../../modals/uploadFileModal";
import AddOrUpdateMaintenancePeriodModal from "../../modals/addOrUpdateMaintenancePeriodModal";
import { fetchAssetCategories } from "../../../../../state/actions/assets";


const TrackerMaintenance = ({
  selected_organisations,
  disabled,
  fetchMaintenanceTasks,
  fetchMaintenanceTasksSummary,
  fetchAssetCategories,
  fetchMaintenanceHomebases,
  uploadImage,
  clearUploadedImages,
  clearUploadedDocuments,
  uploadDocument,
  loading,
  saving,

  maintenance_summary,
  maintenance_tasks
}) => {
  const [groupBy, setGroupBy] = useState(useQuery().get('groupby') ?? groupingOptions.byHomebase);
  const [filter, setFilter] = useState('')
  const [openAddOrUpdateMaintenancePeriodModal, setOpenAddOrUpdateMaintenancePeriodModal] = useState(false)
  const [openAddEquimentMaintenance, setOpenAddEquimentMaintenance] = useState(false)
  const [openAddNewHomebase, setOpenAddNewHomebase] = useState(false)
  const [openUploadImage, setOpenUploadImage] = useState(false)
  const [openUploadPDF, setOpenUploadPDF] = useState(false)
  const [modalForUploadingImage, setModalForUploadingImage] = useState(false)
  const [openMaintenanceDetailsForPlanId, setOpenMaintenanceDetails] = useState(false)
  const [homebaseInput, setHomebaseInput] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(null)
  const [vehicleIconSelected, setVehicleIconSelected] = useState(true)
  const [workshopIconSelected, setWorkshopIconSelected] = useState(true)
  const [onSiteIconSelected, setOnSiteIconSelected] = useState(true)

  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0();

  const viewSelector = (
    <GroupingOptionSelector
      onChange={(selection) => {
        changeGroupBy(selection);
      }}
      options={groupingOptions}
      value={groupBy}
    />
  )

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  function initCreateNewHomebase() {
    setOpenAddNewHomebase(true)
  }


  const isLoading = loading.fetchVehiclesData === 'loading' || loading.fetchMaintenanceSummary === 'loading' ||
    loading.fetchMaintenanceCategories === 'loading' || loading.fetchMaintenanceHomebases === 'loading'
  const loadingFailed = loading.fetchVehiclesData === 'failed' || loading.fetchMaintenanceSummary === 'failed' ||
    loading.fetchMaintenanceCategories === 'failed' || loading.fetchMaintenanceHomebases === 'failed'

  const isMissingDataNeededforAddModal = (
    loading.fetchMaintenanceHomebases === 'loading' || loading.fetchMaintenanceHomebases === 'failed' ||
    loading.fetchMaintenanceCategories === 'loading' || loading.fetchMaintenanceCategories === 'failed' ||
    loading.fetchMaintenancePlan === 'loading' || loading.fetchMaintenancePlan === 'failed'
  )

  const button = <Button noBg small extend={{minWidth: 310}} boxShadow disabled={isMissingDataNeededforAddModal} onClick={() => setOpenAddEquimentMaintenance(true)}>
      {`+ Add Equipment for maintenance`}
    </Button>

  const changeGroupBy = (group) => {
    setGroupBy(group);
    navigate({ search: "?groupby=" + group }, {replace: true});
  };

  useEffect(() => {
    setFilter('')
  }, [groupBy])

  const sendUploadImage = (image) =>
  {
    if(!image) return

    let formData = new FormData()
        formData.append("image", image)
        console.log("imageUpload", image)
        console.log("formdata", formData.get("image"))
    getAccessTokenSilently()
    .then((accessToken) =>
    {
      uploadImage(accessToken, formData, modalForUploadingImage)
    })
  }

  useEffect(() => {
    if (groupBy === groupingOptions.ViewAll)
      getAccessTokenSilently().then((accessToken) => {
        fetchMaintenanceTasks(accessToken)
        fetchAssetCategories(accessToken)
        fetchMaintenanceHomebases(accessToken)
      })
    else
      getAccessTokenSilently().then((accessToken) => {
        fetchMaintenanceTasksSummary(accessToken)
        fetchAssetCategories(accessToken)
        fetchMaintenanceHomebases(accessToken)
      })
  }, [groupBy, selected_organisations])

  const [selectedMaintenanceTask, setSelectedMaintenanceTask] = useState(null)

  useEffect(() => {
    if (!openMaintenanceDetailsForPlanId) {
      setSelectedMaintenanceTask(null)
      return
    }

    if (groupBy === groupingOptions.byHomebase) {
      // find in maintenance_summary
      maintenance_summary.some(homebase => {
        let found = homebase?.tasks?.find(t => t.maintenancePlanId === openMaintenanceDetailsForPlanId)
        setSelectedMaintenanceTask(found)
        if (found) return true
      })
    }
    else {
      // find in maintenance_tasks
      const found = maintenance_tasks?.tasks?.find(t => t.maintenancePlanId === openMaintenanceDetailsForPlanId)
      setSelectedMaintenanceTask(found)
      return
    }
  }, [openMaintenanceDetailsForPlanId, maintenance_summary, maintenance_tasks, groupBy])

  const table = 
    groupBy === groupingOptions.ViewAll 
      ? <ViewAllTaskTable // TODO: make table less long ...
          openAssetMaintenanceTaskModal={setOpenMaintenanceDetails}
          searchFilter={filter}
          showVehicleHomebases={vehicleIconSelected}
          showWorkshopHomebases={workshopIconSelected}
          showOnSiteHomebases={onSiteIconSelected}
        /> 
      : <HomebaseViewTable
          openAssetMaintenanceTaskModal={setOpenMaintenanceDetails}
          searchFilter={filter} 
          showVehicleHomebases={vehicleIconSelected}
          showWorkshopHomebases={workshopIconSelected}
          showOnSiteHomebases={onSiteIconSelected}
        />

  return (
    <Fragment>
      <ReportPage
        useOldDatePickers
        disabled={disabled}
        isLoading={isLoading}
        fetchingDataFailed={loadingFailed}
        noPadding
        noBackground
        noTimePickerBar
        onSearchChange={setFilter}
        groupingOptionSelector={viewSelector}
        searchValue={filter}
        title={"Maintenance"}
        noDataMessage={"There are no assets to maintain."}
        tables={[table]}
        button={button}
        sideFitleringComponent={
          <CustomHomebaseSelector
            vehicleIconSelected={vehicleIconSelected}
            setVehicleIconSelected={setVehicleIconSelected}
            workshopIconSelected={workshopIconSelected}
            setWorkshopIconSelected={setWorkshopIconSelected}
            onSiteIconSelected={onSiteIconSelected}
            setOnSiteIconSelected={setOnSiteIconSelected}
            onEnd
          />
        }
      ></ReportPage>
      {useCallback(<AddMaintenanceModal
        open={openAddEquimentMaintenance}
        onClose={() => setOpenAddEquimentMaintenance(false)}
        onSave={() => { setOpenAddEquimentMaintenance(false) }}
        openUploadImage={() => {/* setOpenAddEquimentMaintenance(false);  */setOpenUploadImage(true)}}
        initCreateNewHomebase={initCreateNewHomebase}
        setModalForUploadingImage={setModalForUploadingImage}
        homebaseInput={homebaseInput}
        imageUploaded={imageUploaded}
      />, [openAddEquimentMaintenance, homebaseInput, imageUploaded])}
      
      {useCallback(<AddHomeBaseModal
        open={openAddNewHomebase}
        onClose={() =>{ setOpenAddNewHomebase(false); /* setOpenAddEquimentMaintenance(true) */}}
        onSave={() => { setOpenAddNewHomebase(false); /* setOpenAddEquimentMaintenance(true) */ }}
        setHomebaseInput={setHomebaseInput}
      />, [openAddNewHomebase])}

      {useCallback(<UploadFileModal
        imageOnly
        open={openUploadImage}
        title={'Upload image'}
        textForAcceptedFileTypes={'Supported file types: JPEG,JPG,PNG'}
        onClose={() =>{ 
          setOpenUploadImage(false); 
          /* setOpenAddEquimentMaintenance(true);  */
        }}
        onSave={() => { 
          setOpenUploadImage(false); 
          /* setOpenAddEquimentMaintenance(true); */
        }}
        onFileUploaded={(image) => {
          setImageUploaded(image); 
          sendUploadImage(image)
        }}
        uploadingStatus={saving.uploadImage}
      />, [openUploadImage, imageUploaded, saving.uploadImage])}

      {useCallback(<UploadFileModal
        pdfOnly
        open={openUploadPDF}
        textForAcceptedFileTypes={'Supported file types: PDF'}
        acceptedFileTypes={'.pdf'}
        onClose={() =>{ 
          setOpenUploadPDF(false);
        }}
        onSave={(file) => { 
          setOpenUploadPDF(false);
        }}
        uploadingStatus={saving.uploadDocument}
      />, [openUploadPDF, saving.uploadDocument])}
      
      {useCallback(<MaintenanceDetailsModal
        open={selectedMaintenanceTask ? true : false}
        maintenanceTask={selectedMaintenanceTask}
        openUploadImage={() => {setOpenUploadImage(true)}}
        onClose={() =>{ setOpenMaintenanceDetails(false); clearUploadedImages(); clearUploadedDocuments()}}
        onMarkasDone={() =>{ clearUploadedImages(); clearUploadedDocuments()}}
        onDelete={(toRemove) => { 
          if (toRemove?.maintenancePlanId === openMaintenanceDetailsForPlanId) setOpenMaintenanceDetails(false)

          clearUploadedImages()
          clearUploadedDocuments()
        }}
        setModalForUploadingImage={setModalForUploadingImage}
        modalForUploadingImage={modalForUploadingImage}
        homebaseInput={homebaseInput}
        initCreateNewHomebase={initCreateNewHomebase}
        setOpenAddOrUpdateMaintenancePeriodModal={setOpenAddOrUpdateMaintenancePeriodModal}
        imageUploaded={imageUploaded}
        setOpenUploadPDF={setOpenUploadPDF}
        />, [selectedMaintenanceTask, openMaintenanceDetailsForPlanId, homebaseInput, imageUploaded])}

      {useCallback(<AddOrUpdateMaintenancePeriodModal
        open={openAddOrUpdateMaintenancePeriodModal}
        setOpenUploadPDF={setOpenUploadPDF}
        openUploadPDF={openUploadPDF}
        maintenanceTask={selectedMaintenanceTask}
        onClose={() =>{ setOpenAddOrUpdateMaintenancePeriodModal(false); clearUploadedDocuments() }}
        onSave={() => { setOpenAddOrUpdateMaintenancePeriodModal(false); }}
      />, [openAddOrUpdateMaintenancePeriodModal, openMaintenanceDetailsForPlanId])}
    </Fragment>
  );
};

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  fetchMaintenanceTasks: (acceessToken) => dispatch(fetchMaintenanceTasks(acceessToken, null)),
  fetchMaintenanceTasksSummary: (acceessToken) => dispatch(fetchMaintenanceTasksSummary(acceessToken)),
  fetchAssetCategories: (accessToken) => dispatch(fetchAssetCategories(accessToken)),
  fetchMaintenanceHomebases: (accessToken) => dispatch(fetchMaintenanceHomebases(accessToken)),
  uploadImage: (accessToken, image, typeOfImage) => dispatch(uploadImage(accessToken, image, typeOfImage)),
  clearUploadedImages: () => dispatch(clearUploadedImages()),
  clearUploadedDocuments: () => dispatch(clearUploadedDocuments()),
  uploadDocument: (accessToken, file) => dispatch(uploadDocument(accessToken, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackerMaintenance);
