import React from 'react'
import { H3 } from '@modul-connect/shared/components/atoms/text';
import SelectableBarChart from '@modul-connect/shared/components/molecules/barCharts/selectableBarChart';
import View from "@modul-connect/shared/components/atoms/view";

const TopFiveDisplay = ({
  title,
  data,
  unit,
  tooltipText,
  onSelect,
  onDeselect
}) => {

  return (
    <React.Fragment>
      <View style={{ paddingLeft: 30 }}>
        <H3 >{title}</H3>
        <SelectableBarChart
          onSelect={onSelect}
          onDeselect={onDeselect}
          data={data}
          unit={unit}
          tooltipText={tooltipText}
        />
      </View>
    </React.Fragment>
  )
}

export default TopFiveDisplay;