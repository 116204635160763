import React from "react"
import View from "@modul-connect/shared/components/atoms/view"
import { checkListResponseOptions, checklistSteps, defectOptions } from "./dummyData"
import { pickRandomlyFromArray } from "../../../utils/array"
import TableWidgetSeparated from "@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2"
import { checklist_columns } from "./checklist_report_columns"
import { connect } from 'react-redux'

const generateFakeData = (checklistResult) => {
  const nrIssues = checklistResult.issues
  const nrDangerousIssues = checklistResult.dangerous
  const nrOfSteps =  checklistSteps.length

  const indicesOfIssues = []
  while (indicesOfIssues?.length < nrIssues) {
    const index = Math.floor(Math.random() * nrOfSteps)
    if (!indicesOfIssues.includes(index)) indicesOfIssues.push(index)
  }

  const indicesOfDangerousIssues = []
  while (indicesOfDangerousIssues?.length < nrDangerousIssues) {
    const index = pickRandomlyFromArray(indicesOfIssues)
    if (!indicesOfDangerousIssues.includes(index)) indicesOfDangerousIssues.push(index)
  }

  return checklistSteps.map((step, index) => { 
    const defectComments = defectOptions?.filter(c => c.relatedToTitle === step.title)
    console.log('defectComments', defectComments)
    const defectComment = indicesOfIssues.includes(index) ? !defectComments?.length ? "Something is damaged" : defectComments?.length === 1 ? defectComments[0].description
      : defectComments[Math.floor(Math.random * defectComments?.length)]?.description
      : ""

    return {
      stepNr: index + 1,
      title: step.title,
      title_tooltip: step.instruction,
      result: indicesOfIssues.includes(index) ? checkListResponseOptions.FAIL : checkListResponseOptions.OK,
      dangerous: indicesOfDangerousIssues.includes(index) ? true : false,
      comment: defectComment ?? '', // TODO: also respect danger tags, and that there might be multiple per title (search by title), if none, type something here
      attachments: indicesOfIssues.includes(index) ? 'Attached image' : null,
    }
  })
}

const prepareData = (data) => {
  return data.map((d) => {
    return {
      ...d,
      id: d.stepNr,
      title: d.stepNr + '. ' + d.title,
      title_sort: d.stepNr,
      result: d.dangerous ? 'Dangerous issue' : d.result === checkListResponseOptions.FAIL ? 'Issue' : 'OK'
    }
  })
}

const ChecklistDetails = ({
  checklist,
  themes
}) => {
  const data = generateFakeData(checklist)
  return (
    <View>
      <TableWidgetSeparated 
        data={prepareData(data)}
        loadingStatus={null}
        columns={checklist_columns}
        themes={themes}
        isSortable
        pagination={false}
        //defaultSort={'title'}
        //defaultSortOrder={'asc'}
      />
    </View>
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDetails);