import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated'; import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { DateTime } from "luxon";
import StartEndDatePicker from '../../datePickers/startEndDatePicker/startEndDatePicker'
import { log_columns } from './log_columns'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import KpiRow from '@modul-connect/shared/components/molecules/kpiRow/kpiRow';
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import DateSelectionWidget from "@modul-connect/shared/components/molecules/dateSelectionWidget"
import ReportPage from '../../reportPage';
import NoIncidents from "@modul-connect/shared/components/molecules/noIncidents";
import { getDayName } from '@modul-connect/shared/utils/text'

// TODO: create common component for these report pages, so we don't have copy-pasted code everywhere ...
const AssetTrackerReportDetails = ({
  startDate,
  endDate,
  onStartDatePicked,
  onEndDatePicked,
  themes,
  loading,
  asset_tracker_report,
  vehicles_data,
  onVehicleSelected,
  disabled
}) => {
  let [reportData, setReportLogs] = useState(null)
  let [kpiData, setKpiData] = useState(null)
  let [selectedVehicleId, setSelectedVehicleId] = useState(null)
  let [data, setData] = useState({})

  const getTimeStr = (timestamp) => {
    return dateTimeToString(DateTime.fromISO(timestamp), 'time-only')
  }

  const getDataByDay = (data) => {

    let dividedByDay = {}
    let dailyList = []

    let prevTimestamp = ''

    if (data?.length) {
      data.forEach(log => {

        if (prevTimestamp && (log.timestamp.startOf("day").toMillis() !== prevTimestamp.startOf("day").toMillis())) {

          dividedByDay[prevTimestamp] = [...dailyList]
          dailyList = []
        }
        dailyList.push(log)
        prevTimestamp = log.timestamp
      })
    }
    if (dailyList.length > 0) {

      dividedByDay[prevTimestamp] = [...dailyList]
    }
    return dividedByDay
  }

  /* const totalHours = (endDate - startDate) / 60 / 60 / 1000
  const top_five_data = asset_tracker_report?.data?.byVehicle ? asset_tracker_report?.data?.byVehicle.map(d => {
    return {
      vehicle: d.vehicle,
      assetTrackerName: Math.min((d.hours / totalHours * 100).toFixed(2), 100),
      additionalInfo: 'Number of warnings: ' + d.occasions,
      vehicleId: d.id
    }
  }) : [] */

  useEffect(() => {
    let nextId = 0
    let preparedData = []

    const logs = asset_tracker_report?.data?.logs
    if (logs && logs.length >= 0) {
      setReportLogs(logs.map((log) => {
        const vehicle = vehicles_data?.data?.length
          ? vehicles_data?.data?.find(v => v.mainbox_id === log.boxId)
          : null
        nextId++
        preparedData.push({
          key: nextId,
          id: nextId,
          boxId: log.boxId,
          category: log.beaconCategory,
          assetTrackerName: log.beaconName,
          timestamp: log.startTime ? DateTime.fromISO(log.startTime) : null,
          timestamp_end: log.endTime ? DateTime.fromISO(log.endTime) : null,
          vehicle: log.vehicle ?? vehicle?.vehicleName ?? vehicle?.vehicle?.baseVehicleName,
          endTimeString: log.endTime ? getTimeStr(log.endTime) : null,
          startTimeString: log.startTime ? getTimeStr(log.startTime) : null,
        })
      }))
    }

    /* setKpiData([
      {
        description: 'Incidents',
        value: temperature_report?.data?.count ?? '-'
      },
      {
        description: 'Hours of warnings',
        value: temperature_report?.data?.totalHours?.toFixed(0) ?? '-'
      },
      {
        description: 'Average warning time',
        value: temperature_report?.data ? toPercentage(temperature_report.data.ratio ?? 0) : '-',
        unit: temperature_report?.data ? '%' : '',
        color: percentageColor(temperature_report?.data?.ratio ?? 0)
      }
    ]) */

    if (preparedData && preparedData.length > 0) {
      setData(getDataByDay(preparedData))
    } else {
      setData({})
    }

  }, [asset_tracker_report, vehicles_data])

  const datePickerComponent = <DateSelectionWidget
    datePickerComponent={
      <StartEndDatePicker
        startDate={startDate}
        endDate={endDate}
        onStartDatePicked={onStartDatePicked}
        onEndDatePicked={onEndDatePicked}
        disabled={loading.fetchAssetTrackerReport === 'loading'}
      />
    }
    rightHandSide={null}
  />

  const tables = (
    Object.keys(data)?.length ? Object.keys(data).map(key => {
      const log = data[key]
      return <TableWidgetSeparated
        tableTitle={getDayName(DateTime.fromISO(key))}
        key={key}
        data={log}
        loadingStatus={loading.fetchAssetTrackerReport || loading.fetchVehiclesData === 'loading'}
        totalItems={reportData}
        columns={log_columns}
        themes={themes}
        isSortable={false}
        pagination={false}
        rowsOptions={[log?.length]}
      />
    }) : null
  )

/*   const kpiSection = kpiData ? <KpiRow title={'Status'} width={'100%'} noPadding bgColor={'white'} data={kpiData} isLoading={loading.fetchAssetTrackerReport} />
    : loading.fetchAssetTrackerReport === null &&
      (!reportData || reportData.length === 0) ?
      <NoIncidents />
      : null */

  return (
    <ReportPage
      useOldDatePickers
      disabled={disabled}
      title={'Asset Tracker Report'}
      dateSelectionComponent={datePickerComponent}
      tablesInOwnSubPage
      noDataMessage={"There are no asset tracker warnings for the selected time period."}
      isLoading={loading.fetchAssetTrackerReport === 'loading' || loading.fetchVehiclesData === 'loading'}
      fetchingDataFailed={loading.fetchAssetTrackerReport === 'failed'}
      subpageTitle={"Asset Tracker Warnings"/*  +
        (selectedVehicleId ?
          (' for ' + top_five_data.find(v => v.vehicleId === selectedVehicleId)?.name)
          : '') */}
      //kpi={(loading.fetchAssetTrackerReport === 'loading') ? <BubbleLoadingWidget text={'Loading ...'} /> : kpiSection}
      tables={tables}
      /* reportGraph={top_five_data?.length > 0 && !loading.fetchAssetTrackerReport &&
        <TopFiveDisplay
          title={'Top 5 vehicles with asset tracker warnings'}
          unit={'%'}
          data={top_five_data}
          tooltipText={'Time spent with asset tracker warning'}
          onSelect={(selectedId) => {
            setSelectedVehicleId(selectedId)
            onVehicleSelected(selectedId)
          }}
          onDeselect={() => {
            setSelectedVehicleId(null)
            onVehicleSelected(null)
          }}
        />} */
    />
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps, {})(AssetTrackerReportDetails);