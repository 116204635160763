import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { H3 } from "@modul-connect/shared/components/atoms/text";
import View from '@modul-connect/shared/components/atoms/view';
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import './widget.css';
import { RadioButtonSelector } from '../../molecules/radioButtonSelector';

const Widget = ({show, title, children, leftWidth, intervalOptions, onOptionSelected, selectedInterval}) => {
  const [showLoading, setShowLoading] = useState(false)
  useLoadingTimer(setShowLoading)

  return (
      <View extend={ styles.widget }>
        {
          showLoading && !show && (
            <H3 children={'Loading...'} />
          )
        }
        <CSSTransition
          in={ show }
          timeout={300}
          classNames="widget"
          unmountOnExit
        >
          <View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}>
              <H3 children={ title } extend={{ display: 'inline-block', paddingRight: '25px'}} />
              {
                intervalOptions && intervalOptions.length &&
                <RadioButtonSelector onChange={onOptionSelected} options={intervalOptions} value={selectedInterval} disabled={false}/>
                
              }
            </View>
            <View extend={ styles.content }>
              {
                children.map((child) => {
                  return (
                    <View key={child.name} extend={ styles[child.name] }>
                      { child.body }
                    </View>
                  )
                })
              }
            </View>
          </View>
        </CSSTransition>
      </View>
    )
}

const styles = {
  widget: ({ theme: { layout, colors } }) => ({
    borderRadius: `${ 1 * layout.grid}px`,
    // backgroundColor: colors.white,
    padding: `${ 2 * layout.grid}px ${ 3 * layout.grid}px`,
    width: '100%',
    minHeight: '300px',
    margin: `0 0 ${2 * layout.grid}px 0`,
  }),
  content: ({ theme: { layout, colors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: `${layout.grid}px`,
    // width: '100%',
  }),
  optionButtons: ({ theme: { layout, colors } }) => ({
    display: 'inline-flex',
    flexDirection: 'row',
    marginLeft: 12
  }),
  left: {
    minWidth: '70px',
    paddingRight: '30px'
  },
  right: {
    width: '100%',
    height: '220px'
  }
};

export default Widget;
