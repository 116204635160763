import { DateTime } from "luxon"
import { getUrgencyTagsFromDueDateISO } from "../../utils/assets"

export const assets = (state = [], action) => {
  let newState = []
  switch (action.type) {
    case 'ASSET_TRACKERS_FETCHED':
      return action.data
    case 'UPDATE_TRACKER_INFO':
      if (action.groupBy === 'Homebase') {
        newState = [...state]
        var vehicle = newState.filter(v => v?.mainbox?.boxId === action.boxId)?.[0]
        let specificTracker = vehicle?.assets?.filter(asset => asset.tracker.beaconId === action.beaconId)?.[0]
        specificTracker.name = action.name
        specificTracker.category = action.category
        return newState;
      }
      else {
        newState = [...state]
        var toUpdateTracker = newState.filter(t => t.tracker.beaconId === action.beaconId)?.[0]
        toUpdateTracker.name = action.name
        toUpdateTracker.category = action.category
        return newState
      }
    case 'UPDATE_TRACKER_FAILED':
      if (action.groupBy === 'Homebase') {
        newState = [...state]
        var specificVehicle = newState.filter(v => v?.mainbox?.boxId === action.boxId)?.[0]
        let tracker = specificVehicle?.assets?.filter(asset => asset.tracker.beaconId === action.beaconId)?.[0]
        tracker.name = action?.oldName
        tracker.category = action?.oldCategory
        return newState;
      }
      else {
        newState = [...state]
        var toUpdateTracker = newState.filter(t => t.tracker.beaconId === action.beaconId)?.[0]
        toUpdateTracker.name = action?.oldName
        toUpdateTracker.category = action?.oldCategory
        return [...newState]
      }
    case 'UPDATE_TRACKER_OWNER':
      if (!action.groupBy || action.groupBy === 'Homebase') {
        newState = [...state]
        var oldOwner = newState.filter(v => v?.mainbox?.boxId === action.oldOwner.boxId)?.[0]
        var newOwner = newState.filter(v => v?.mainbox?.boxId === action.boxId)?.[0]
        var trackersData = oldOwner.assets.filter(asset => asset.tracker.beaconId === action.beaconId)?.[0]
        oldOwner.assets = oldOwner.assets.filter(asset => asset.tracker.beaconId !== action.beaconId)
        newOwner?.assets?.push(trackersData)
        return [...newState]
      }
      else {
        newState = [...state]
        var trackerToUpdate = newState.filter(t => t.tracker.beaconId === action.beaconId)?.[0]
        trackerToUpdate.name = action.name
        trackerToUpdate.category = action.category
        trackerToUpdate.vehicle.mainbox.boxId = action.vehicle.boxId
        trackerToUpdate.vehicle.mainbox.telematicsId = action.vehicle.telematicsId
        trackerToUpdate.vehicle.vehicleName = action.vehicle.vehicleName
        return [...newState]
      }
    case 'UPDATE_TRACKER_OWNER_FAILED':
      if (!action.groupBy || action.groupBy === 'Homebase') {
        newState = [...state]
        var newOwner = newState.filter(v => v?.mainbox?.boxId === action.oldOwner.boxId)?.[0]
        var oldOwner = newState.filter(v => v?.mainbox?.boxId === action.boxId)?.[0]
        var trackersData = oldOwner.assets.filter(asset => asset.tracker.beaconId === action.beaconId)?.[0]
        newOwner.assets.push(trackersData)
        oldOwner.assets = oldOwner.assets.filter(asset => asset.tracker.beaconId === action.beaconId)
        return [...newState]
      }
      else {
        newState = [...state]
        var trackerToUpdate = newState.filter(t => t.tracker.beaconId === action.beaconId)?.[0]
        trackerToUpdate.name = action.name
        trackerToUpdate.category = action.category
        trackerToUpdate.vehicle.mainbox.boxId = action.vehicle.boxId
        trackerToUpdate.vehicle.mainbox.telematicsId = action.vehicle.telematicsId
        trackerToUpdate.vehicle.vehicleName = action.oldOwner.vehicleName
        return [...newState]
      }
    case 'UPDATE_NOTIFICATIONS_ON_TRACKERS':
      newState = [...state]
      action.trackers.map(t => {
        var beaconToUpdate = newState.filter(s => s.tracker.beaconId === t)?.[0]
        beaconToUpdate.notificationEnabled = action.isEnabled
      })
      return newState
    case 'CLEAR_ASSET_TRACKERS':
      return []
    default:
      return state;
  }
}

export const asset_addresses = (state = {}, action) => {
  switch (action.type) {
    case 'TRACKER_ADDRESS_FETCHED':
      var newState = state
      newState[action.beaconId] = action.data[0]
      return { ...newState }
    default:
      return state
  }
}

export const vehicle_assets = (state = {}, action) => {
  switch (action.type) {
    case 'ASSET_TRACKER_ADDRESSES_FETCHED':
      var newState = state
      newState[action.boxId] = action.data
      return {
        ...newState
      }
    case 'UPDATE_TRACKER_OWNER':
      if (action.groupBy === 'Homebase') {
        var newState = state
        newState[action.oldOwner.boxId] = newState[action.oldOwner.boxId].filter(asset => asset.beaconId !== action.beaconId)
        return {
          ...state
        }
      } else return state
    default:
      return state
  }
}


export const maintenance_tasks = (state = {}, action) => {
  switch (action.type) {
    case 'MAINTENANCE_PERIOD_DONE': {
      if (!state?.tasks?.length) return state

      if (action.noRepeatTask) return state

      const idx = state.tasks.findIndex(t => t.assetId === action.assetId)
      const tasksToReturn = [...state.tasks]
      tasksToReturn[idx].dueDate = action.newDueDate

      // add newly started period
      tasksToReturn[idx].periodHistory = [
        ...(tasksToReturn[idx].periodHistory ?? []),
        {
          assetId: action.assetId,
          comment: null,
          document: null,
          endTime: null,
          fileName: null,
          maintenancePeriodId: action.newMaintenancePeriodId,
          maintenancePlanId: action.maintenancePlanId,
          startTime: action.newMaintenancePeriodStartTime
        }
      ]

      // update the period that was ended
      const oldPeriodIndex = tasksToReturn[idx].periodHistory?.findIndex(p => p.maintenancePeriodId === action.periodId)
      if (oldPeriodIndex >= 0) {
        tasksToReturn[idx].periodHistory[oldPeriodIndex].comment = action.comment
        tasksToReturn[idx].periodHistory[oldPeriodIndex].document = action.documentBlob
        tasksToReturn[idx].periodHistory[oldPeriodIndex].endTime = action.endDate
        tasksToReturn[idx].periodHistory[oldPeriodIndex].fileName = action.documentName
      }
      tasksToReturn[idx].startDate = action.newMaintenancePeriodStartTime

      return {
        ...state,
        tasks: tasksToReturn
      }
    }
    case 'FETCHING_MAINTENANCE_TASKS_SUCCESSFUL':
      if (action.homebase) return state
      return {
        ...state,
        tasks: [...action.data]
      }
    case 'REMOVE_MAINTENANCE_TASK': {
      if (!state?.tasks?.length) return state

      const taskToRemove = state.tasks.find(task => task.periodId === action.periodId)
      if (!taskToRemove) return state

      const updatedTasks = state.tasks.filter(task => task.periodId !== action.periodId)
          
      return {
        ...state,
        tasks: updatedTasks
      }
      }
    case 'REMOVE_ASSET_AND_MAINTENANCE_PLAN':
      if (!state?.tasks?.length) return state

      const backupList = state.tasks_deleted ?? []
      
      let tasksWithout = state?.tasks ?? []
      const deletedTaskIndex = state.tasks.findIndex(t => t.assetId === action.assetId && t.maintenancePlanId === action.maintenancePlanId)
      if (deletedTaskIndex >= 0) {
        // put in backup list
        backupList.push(state?.tasks[deletedTaskIndex])
        // remove from real list of tasks
        tasksWithout = tasksWithout.filter(t => t.assetId !== action.assetId || t.maintenancePlanId !== action.maintenancePlanId)
      }
      
      return {
        ...state,
        tasks: [...tasksWithout],
        tasks_deleted: [...backupList]
      }
    case 'ASSET_AND_MAINTENANCE_PLAN_REMOVED':
      if (!state?.tasks?.length) return state

      // remove from backup list
      const backupListWithout = (state.tasks_deleted ?? []).filter(b => b.assetId !== action.assetId || b.maintenancePlanId !== action.maintenancePlanId)
      
      return {
        ...state,
        tasks_deleted: [...backupListWithout]
      }
    case 'REMOVING_ASSET_AND_MAINTENANCE_PLAN_FAILED':
      if (!state?.tasks?.length) return state

      // put backup back in list, remove from backup
      const backupItem = (state.tasks_deleted ?? []).find(b => b.assetId === action.assetId && b.maintenancePlanId === action.maintenancePlanId)
      const tasksWithBackup = state.tasks ?? []
      tasksWithBackup.push(backupItem)

      return {
        ...state,
        tasks: [...tasksWithBackup],
        tasks_deleted: (state.tasks_deleted ?? []).filter(b => b.assetId !== action.assetId || b.maintenancePlanId !== action.maintenancePlanId)
      }
    case 'CREATE_ASSET_FOR_MAINTENANCE_SUCCEEDED':
      const listOfAssets = state?.tasks?.length ? [...state.tasks] : []
      listOfAssets.push(action.newAsset)
      return{
        ...state,
        tasks: [...listOfAssets]
      }
    case 'UPDATING_ASSET_SUCCEEDED': {
      const tasks = [...(state?.tasks ?? [])]
      const taskIndex = tasks.findIndex(t => t.assetId === action.updatedAsset.assetId)
      if(taskIndex > -1) {
        tasks[taskIndex] = {
          ...tasks[taskIndex],
          ...action.updatedAsset,
          homebase: action.updatedAsset.homebaseName
          }
        }
      return {...state, tasks: tasks}
    }
    case 'UPDATE_MAINTENANCE_PLAN_AND_TASK': { // TODO: handle updating and failure the same for updating asset
      if (!state?.tasks?.length) return state

      const differenceTags = action.newDueDate ? getUrgencyTagsFromDueDateISO(action.newDueDate) : undefined

      // TODO: use taskID, not maintenancePlanId to set the dueDate and urgency tags
      const newTasks = state.tasks.map(task => {
        if (task.maintenancePlanId === action.maintenancePlanId) {
          return {
            ...task,
            daysFrequency: ((action.newFrequencyInDays || action.newFrequencyInDays === 0) && action.newFrequencyInDays != task.daysFrequency) ? '' + action.newFrequencyInDays : task.daysFrequency,
            dueDate: (action.newDueDate && action.newDueDate != task.dueDate) ? action.newDueDate : task.dueDate,
            isInOneWeek: (action.newDueDate && action.newDueDate != task.dueDate) ? differenceTags.isInOneWeek : task.isInOneWeek,
            isInThreeWeeks: (action.newDueDate && action.newDueDate != task.dueDate) ? differenceTags.isInThreeWeeks : task.isInThreeWeeks,
            isOverdue: (action.newDueDate && action.newDueDate != task.dueDate) ? differenceTags.isOverdue : task.isOverdue,
            periodId: action.newMaintenancePeriodId ?? task.periodId
          }
        }
        else return task
      })

      return {
        ...state,
        tasks: newTasks
      } 
    }
    case 'UPDATE_MAINTENANCE_PERIOD_AND_DUE_DATE': {
      if (!state?.tasks?.length) return state

      const tags = getUrgencyTagsFromDueDateISO(action.newDueDate)

      const newTasks = state.tasks.map(task => {
        if (task.periodId === action.maintenancePeriodId) {
          return {
            ...task,
            dueDate: action.newDueDate,
            isInOneWeek: tags.isInOneWeek,
            isInThreeWeeks: tags.isInThreeWeeks,
            isOverdue: tags.isOverdue,
            periodId: action.newMaintenancePeriodId
          }
        }
        else return task
      })

      return {
        ...state,
        tasks: newTasks
      }
    }
    case "FETCHING_PERIODS_WITH_DOCUMENTS_SUCCESSFUL": { // TODO: also listen to action telling us that a period was ended. should add it to the list of pastPeriods.
      if (!state?.tasks?.length) return state

      const updatedTasks = [...state.tasks]
      
      const assetIndex = updatedTasks.findIndex(t => t.assetId === action.assetId)
      if (assetIndex >= 0) {
        updatedTasks[assetIndex].periodHistory = action.data
      }
      return {
        ...state,
        tasks: updatedTasks
      }
    }
    case "UPDATING_MAINTENANCE_PERIOD_COMMENT_SUCCEEDED": {
      if (!state?.tasks?.length) return state
      
      const updatedTasksWithNewComment = [...state.tasks]
      updatedTasksWithNewComment.forEach((task, index) => {
        const periodHistoryItemIndex = task.periodHistory?.findIndex(p => p.maintenancePeriodId === action.periodId)
        if (periodHistoryItemIndex >= 0) {
          updatedTasksWithNewComment[index].periodHistory[periodHistoryItemIndex].comment = action.newComment
          return false // makes it break out of the loop
        }
      })

      return {
        ...state,
        tasks: updatedTasksWithNewComment
      }
    }
    case "UPDATING_DOCUMENT_OF_PERIOD_SUCCEEDED": {
      if (!state?.tasks?.length) return state
      
      const updatedTasksWithNewDocument = [...state.tasks]
      updatedTasksWithNewDocument.forEach((task, index) => {
        const periodHistoryItemIndex = task.periodHistory?.findIndex(p => p.maintenancePeriodId === action.periodId)
        if (periodHistoryItemIndex >= 0) {
          updatedTasksWithNewDocument[index].periodHistory[periodHistoryItemIndex].document = action.documentUrl
          updatedTasksWithNewDocument[index].periodHistory[periodHistoryItemIndex].fileName = action.fileName
          return false // makes it break out of the loop
        }
      })

      return {
        ...state,
        tasks: updatedTasksWithNewDocument
      }
    }
    case "REMOVING_DOCUMENT_FROM_PERIOD_SUCCEEDED": {
      if (!state?.tasks?.length) return state
      
      const updatedTasksWithNewDocument = [...state.tasks]
      updatedTasksWithNewDocument.forEach((task, index) => {
        const periodHistoryItemIndex = task.periodHistory?.findIndex(p => p.maintenancePeriodId === action.periodId)
        if (periodHistoryItemIndex >= 0) {
          updatedTasksWithNewDocument[index].periodHistory[periodHistoryItemIndex].document = null
          updatedTasksWithNewDocument[index].periodHistory[periodHistoryItemIndex].document = null
          return false // makes it break out of the loop
        }
      })

      return {
        ...state,
        tasks: updatedTasksWithNewDocument
      }
    }
    case 'UPDATING_MAINTENANCE_PLAN_AND_TASK_FAILED': {
      if (!state?.tasks?.length) return state
      
      const newTasks = state.tasks.map(task => {
        if (task.maintenancePlanId === action.maintenancePlanId) {
          return {
            ...task,
            daysFrequency: action.oldMaintenancePlan.daysFrequency,
            dueDate: action.oldMaintenancePlan.dueDate,
            isInOneWeek: action.oldMaintenancePlan.isInOneWeek,
            isInThreeWeeks: action.oldMaintenancePlan.isInThreeWeeks,
            isOverdue: action.oldMaintenancePlan.isOverdue
          }
        }
        else return task
      })

      return {
        ...state,
        tasks: newTasks
      }
    }
    default:
      return state
  }
}

export const maintenance_summary = (state = [], action) => {
  switch (action.type) {
    case 'FETCHING_MAINTENANCE_TASKS_SUMMARY_SUCCESSFUL':
      return [...action.data]
    case 'UPDATE_MAINTENANCE_PERIOD_AND_DUE_DATE': {
      if (!state?.length) return state
  
      const tags = getUrgencyTagsFromDueDateISO(action.newDueDate)

      const homebasesAfterUpdate = [...state]
      const homebaseIdxToUpdate = state.findIndex(h => h?.tasks?.find(t => t.periodId === action.maintenancePeriodId))


      if (homebaseIdxToUpdate >= 0) {
        const taskIndex = homebasesAfterUpdate[homebaseIdxToUpdate].tasks.findIndex(t => t.periodId === action.maintenancePeriodId)

        
        if (taskIndex >= 0) {
          homebasesAfterUpdate[homebaseIdxToUpdate].tasks[taskIndex] = {
            ...homebasesAfterUpdate[homebaseIdxToUpdate].tasks[taskIndex],
            dueDate: action.newDueDate,
            isInOneWeek: tags.isInOneWeek,
            isInThreeWeeks: tags.isInThreeWeeks,
            isOverdue: tags.isOverdue,
            periodId: action.newMaintenancePeriodId
          }
        }
      }

      return homebasesAfterUpdate
    }
    case 'FETCHING_MAINTENANCE_TASKS_SUCCESSFUL':
      if (!action.homebase) return state

      const specificHomeBaseIndex = state.findIndex(s => s.homeBaseId === action.homebase)
      if (specificHomeBaseIndex <= -1) return state

      state[specificHomeBaseIndex] = {
        ...state[specificHomeBaseIndex],
        tasks: [...action.data]
      }
      return [...state]

      case 'REMOVE_ASSET_AND_MAINTENANCE_PLAN':
        if (!state?.length) return state

        const homebasesWithout = [...state]
        const homebaseToRemoveFromIndex = state.findIndex(h => h?.tasks?.find(t => t.assetId === action.assetId && t.maintenancePlanId === action.maintenancePlanId))

        if (homebaseToRemoveFromIndex >= 0) {
          const taskIndex = homebasesWithout[homebaseToRemoveFromIndex].tasks.findIndex(t => t.assetId === action.assetId && t.maintenancePlanId === action.maintenancePlanId)
          if (taskIndex >= 0) {
            const taskToRemove = homebasesWithout[homebaseToRemoveFromIndex].tasks[taskIndex]

            const backupList = homebasesWithout[homebaseToRemoveFromIndex].tasks_deleted ?? []
            
            // put in backup list
            backupList.push(taskToRemove)
            homebasesWithout[homebaseToRemoveFromIndex].tasks_deleted = [...backupList]

            // remove from real list of tasks
            homebasesWithout[homebaseToRemoveFromIndex].tasks.splice(taskIndex, 1)

            // update overdueTasks etc
            if (taskToRemove.isOverdue) homebasesWithout[homebaseToRemoveFromIndex].overdueTasks -= 1
            if (taskToRemove.isInOneWeek) homebasesWithout[homebaseToRemoveFromIndex].withinOneWeekTasks -= 1
            if (taskToRemove.isInThreeWeeks) homebasesWithout[homebaseToRemoveFromIndex]. withinThreeWeekTasks -= 1
            
            // update equipments
            homebasesWithout[homebaseToRemoveFromIndex].equipments -= 1
          }
        }
        
        return [...homebasesWithout]
      case 'ASSET_AND_MAINTENANCE_PLAN_REMOVED':
        if (!state?.length) return state

        // remove from backup list
        const homebasesWithoutBackup = [...state]
        const index = state.findIndex(h => h?.tasks_deleted?.find(t => t.assetId === action.assetId && t.maintenancePlanId === action.maintenancePlanId))
        if (index >= 0) {
          homebasesWithoutBackup[index].tasks_deleted = homebasesWithoutBackup[index].tasks_deleted.filter(t => t.assetId !== action.assetId || t.maintenancePlanId !== action.maintenancePlanId)
        }
        
        return [...homebasesWithoutBackup]
      case 'REMOVING_ASSET_AND_MAINTENANCE_PLAN_FAILED':
        if (!state?.length) return state
        
        // put backup back in list, remove from backup
        const restoredHomebases = [...state]
        const homebaseIndex = state.findIndex(h => h?.tasks_deleted?.find(t => t.assetId === action.assetId && t.maintenancePlanId === action.maintenancePlanId))

        if (homebaseIndex >= 0) {
          const backupItem = (restoredHomebases[homebaseIndex].tasks_deleted ?? []).find(b => b.assetId === action.assetId && b.maintenancePlanId === action.maintenancePlanId)
          // put item back
          restoredHomebases[homebaseIndex].tasks.push(backupItem)
          // remove item
          restoredHomebases[homebaseIndex].tasks_deleted = restoredHomebases[homebaseIndex].tasks_deleted.filter(t => t.assetId !== backupItem.assetId || t.maintenancePlanId !== backupItem.maintenancePlanId)

          // update overdueTasks etc
          if (backupItem.isOverdue) restoredHomebases[homebaseIndex].overdueTasks += 1
          if (backupItem.isInOneWeek) restoredHomebases[homebaseIndex].withinOneWeekTasks += 1
          if (backupItem.isInThreeWeeks) restoredHomebases[homebaseIndex]. withinThreeWeekTasks += 1
          
          // update equipments
          restoredHomebases[homebaseIndex].equipments += 1
        }
  
        return [...restoredHomebases]

    case 'CREATE_NEW_HOMEBASE_SUCCEEDED':
      const existingHBs = [...state]
      existingHBs.push({
        equipments: 0,
        homeBaseId: action.response.data.id,
        homeBaseName: action.response.data.name,
        homeBaseType: action.response.data.type,
        overdueTasks: 0,
        withinOneWeekTasks: 0,
        withinThreeWeekTasks: 0
      })
      return [...existingHBs]
    case 'CREATE_ASSET_FOR_MAINTENANCE_SUCCEEDED':
      const listOfHomebases = [...state]
      const specificHomebase = state.findIndex(h => h.homeBaseId === action.response.command.homebase)
      if(specificHomebase <= -1)
        listOfHomebases.push({
          equipments: 1,
          homeBaseId: action.response.command.homebase,
          homeBaseName: action.response.data.homebase,
          homeBaseType: action.newAsset.homebaseType,
          overdueTasks: action.response.data.isOverdue === 'True' ? 1 : 0,
          withinOneWeekTasks: action.response.data.isInOneWeek === 'True' ? 1 : 0,
          withinThreeWeekTasks: action.response.data.isInThreeWeeks === 'True' ? 1 : 0,
          tasks: [action.newAsset]
        })
      else
      {
        listOfHomebases[specificHomebase].equipments += 1
        listOfHomebases[specificHomebase].overdueTasks += action.response.data.isOverdue === 'True' ? 1 : 0
        listOfHomebases[specificHomebase].withinOneWeekTasks += action.response.data.isInOneWeek === 'True' ? 1 : 0
        listOfHomebases[specificHomebase].withinThreeWeekTasks += action.response.data.isInThreeWeeks === 'True' ? 1 : 0
        listOfHomebases[specificHomebase].tasks = (listOfHomebases[specificHomebase].tasks ?? []).concat([action.newAsset])
      }
      return[...listOfHomebases]
    case "FETCHING_PERIODS_WITH_DOCUMENTS_SUCCESSFUL": { // TODO: also listen to action telling us that a period was ended. should add it to the list of pastPeriods.
      if (!state?.length) return state

      const updatedHomebases = [...state]
      updatedHomebases.forEach(h => {
        const assetIndex = h?.tasks?.findIndex(t => t.assetId === action.assetId)
        if (assetIndex >= 0) {
          h.tasks[assetIndex].periodHistory = action.data
        }
      })
      return updatedHomebases
    }
    case "UPDATING_ASSET_SUCCEEDED":
      const updatedAsset = {
        ...action.oldAssetAndTask,
        ...action.updatedAsset
      }

      if (!state?.length) return state

      const listOfHomebasesForUpdating = [...state]
      const specificHomebaseForUpdatingIndex = state.findIndex(h => h.homeBaseId === action.updatedAsset.homebaseId)
      const targetHomebaseExists = specificHomebaseForUpdatingIndex > -1
      if(targetHomebaseExists) {
        const tasks = listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex]?.tasks
        const assetToUpdateIndex = tasks?.findIndex(t => t.assetId === action.updatedAsset.assetId)

        const targetHomebaseContainsAsset = assetToUpdateIndex > -1
        if (targetHomebaseContainsAsset) {
            listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].tasks[assetToUpdateIndex] = updatedAsset 
        }
        else {
          // remove from old homebase
          const oldHomebaseIndex = state.findIndex(h => h.homeBaseId === action.oldAssetAndTask.homebaseId)
          listOfHomebasesForUpdating[oldHomebaseIndex].tasks = listOfHomebasesForUpdating[oldHomebaseIndex].tasks?.filter(t => t.assetId !== updatedAsset.assetId)

          // add to new homebase
          const existingTasks = listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].tasks
          listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].tasks = existingTasks?.length ? [
            ...existingTasks,
            updatedAsset
          ] : [
            updatedAsset
          ]
          // update properties of new homebase summary
          listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].equipments += 1
          if (updatedAsset.isOverdue) {
            listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].overdueTasks += 1
          } else if (updatedAsset.isInOneWeek) {
            listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].withinOneWeekTasks += 1
          } else if (updatedAsset.isInThreeWeeks) {
            listOfHomebasesForUpdating[specificHomebaseForUpdatingIndex].withinThreeWeekTasks += 1
          }

          if (listOfHomebasesForUpdating[oldHomebaseIndex]?.tasks?.length < 1) { // remove from summaries if no tasks left
            listOfHomebasesForUpdating.splice(oldHomebaseIndex, 1);
          }

          else {
            // update properties of old homebase summary
            listOfHomebasesForUpdating[oldHomebaseIndex].equipments -= 1
            if (updatedAsset.isOverdue) {
              listOfHomebasesForUpdating[oldHomebaseIndex].overdueTasks -= 1
            } else if (updatedAsset.isInOneWeek) {
              listOfHomebasesForUpdating[oldHomebaseIndex].withinOneWeekTasks -= 1
            } else if (updatedAsset.isInThreeWeeks) {
              listOfHomebasesForUpdating[oldHomebaseIndex].withinThreeWeekTasks -= 1
            }
          }
        }
      }
      else { // need to create the homebase in the summary
        // remove from old homebase
        const oldHomebaseIndex = state.findIndex(h => h.homeBaseId === action.oldAssetAndTask.homebaseId)

        // remove from summaries if no tasks left
        listOfHomebasesForUpdating[oldHomebaseIndex].tasks = listOfHomebasesForUpdating[oldHomebaseIndex].tasks?.filter(t => t.assetId !== updatedAsset.assetId)
        if (listOfHomebasesForUpdating[oldHomebaseIndex].tasks?.length < 1) { 
          listOfHomebasesForUpdating.splice(oldHomebaseIndex, 1);
        }
        else {
          // update properties of old homebase summary
          listOfHomebasesForUpdating[oldHomebaseIndex].equipments -= 1
          if (updatedAsset.isOverdue) {
            listOfHomebasesForUpdating[oldHomebaseIndex].overdueTasks -= 1
          } else if (updatedAsset.isInOneWeek) {
            listOfHomebasesForUpdating[oldHomebaseIndex].withinOneWeekTasks -= 1
          } else if (updatedAsset.isInThreeWeeks) {
            listOfHomebasesForUpdating[oldHomebaseIndex].withinThreeWeekTasks -= 1
          }
        }

        return [
          ...state,
          {
            equipments: 1,
            homeBaseId: updatedAsset.homebaseId,
            homeBaseName: updatedAsset.homebaseName,
            homeBaseType: updatedAsset.homebaseType,
            overdueTasks: updatedAsset.isOverdue ? 1 : 0,
            withinOneWeekTasks: updatedAsset.isInOneWeek ? 1 : 0,
            withinThreeWeekTasks: updatedAsset.isInThreeWeeks ? 1 : 0,
            tasks: [updatedAsset]
          }
        ]
      }
      return [...listOfHomebasesForUpdating]

      case 'UPDATE_MAINTENANCE_PLAN_AND_TASK': {
        if (!state?.length) return state

        // TODO: once we separate maintenance plan creation from asset creation: use taskID, not maintenancePlanId to set the dueDate and urgency tags ()action.maintenancePeriodId)
        const differenceTags = action.newDueDate ? getUrgencyTagsFromDueDateISO(action.newDueDate) : undefined
  
        // find all tasks inside the summaries that use this
        const updatedSummaries = state.map(summary => {
          if (!summary.tasks?.length) return summary
  
          let overdueTasks = summary.overdueTasks
          let withinOneWeekTasks = summary.withinOneWeekTasks
          let withinThreeWeekTasks = summary.withinThreeWeekTasks
  
          const updatedTasks = summary.tasks.map(task => {
            if (task.maintenancePlanId === action.maintenancePlanId) {            
              const updatedTask = {
                ...task,
                daysFrequency: ((action.newFrequencyInDays || action.newFrequencyInDays === 0) && action.newFrequencyInDays != task.daysFrequency) ? '' + action.newFrequencyInDays : task.daysFrequency,
                dueDate: (action.newDueDate && action.newDueDate != task.dueDate) ? action.newDueDate : task.dueDate,
                isInOneWeek: (action.newDueDate && action.newDueDate != task.dueDate) ? differenceTags.isInOneWeek : task.isInOneWeek,
                isInThreeWeeks: (action.newDueDate && action.newDueDate != task.dueDate) ? differenceTags.isInThreeWeeks : task.isInThreeWeeks,
                isOverdue: (action.newDueDate && action.newDueDate != task.dueDate) ? differenceTags.isOverdue : task.isOverdue,
                periodId: action.newMaintenancePeriodId ?? task.periodId
              }
  
              if (task.isInOneWeek && !updatedTask.isInOneWeek) {
                withinOneWeekTasks -= 1
              }
              if (!task.isInOneWeek && updatedTask.isInOneWeek) {
                withinOneWeekTasks += 1
              }
              if (task.isInThreeWeeks && !updatedTask.isInThreeWeeks) {
                withinThreeWeekTasks -= 1
              }
              if (!task.isInThreeWeeks && updatedTask.isInThreeWeeks) {
                withinThreeWeekTasks += 1
              }
              if (task.isOverdue && !updatedTask.isOverdue) {
                overdueTasks -= 1
              }
              if (!task.isOverdue && updatedTask.isOverdue) {
                overdueTasks += 1
              }
  
              return updatedTask
            }
            else return task
          })
  
          return {
            ...summary,
            tasks: updatedTasks,
            overdueTasks: overdueTasks,
            withinOneWeekTasks: withinOneWeekTasks,
            withinThreeWeekTasks: withinThreeWeekTasks,
          }
        })
        
        return updatedSummaries
      }
      case 'UPDATING_MAINTENANCE_PLAN_AND_TASK_FAILED': {
        if (!state?.length) return state
  
        // find all tasks inside the summaries that use this
        const updatedSummaries = state.map(summary => {
          if (!summary.tasks?.length) return summary
  
          let overdueTasks = summary.overdueTasks
          let withinOneWeekTasks = summary.withinOneWeekTasks
          let withinThreeWeekTasks = summary.withinThreeWeekTasks

          let wasSetToInOneWeek, wasSetToInThreeWeeks, wasSetToOverdue
  
          const updatedTasks = summary.tasks.map(task => {
            if (task.maintenancePlanId === action.maintenancePlanId) {   
              wasSetToInOneWeek = task.isInOneWeek
              wasSetToInThreeWeeks = task.isInThreeWeeks
              wasSetToOverdue = task.isOverdue
              const updatedTask = {
                ...task,
                daysFrequency: action.oldMaintenancePlan.daysFrequency,
                dueDate: action.oldMaintenancePlan.dueDate,
                isInOneWeek: action.oldMaintenancePlan.isInOneWeek,
                isInThreeWeeks: action.oldMaintenancePlan.isInThreeWeeks,
                isOverdue: action.oldMaintenancePlan.isOverdue,
                periodId: action.oldMaintenancePlan.periodId
              }
  
              if (wasSetToInOneWeek && !updatedTask.isInOneWeek) {
                withinOneWeekTasks -= 1
              }
              if (!wasSetToInOneWeek && updatedTask.isInOneWeek) {
                withinOneWeekTasks += 1
              }
              if (wasSetToInThreeWeeks && !updatedTask.isInThreeWeeks) {
                withinThreeWeekTasks -= 1
              }
              if (!wasSetToInThreeWeeks && updatedTask.isInThreeWeeks) {
                withinThreeWeekTasks += 1
              }
              if (wasSetToOverdue && !updatedTask.isOverdue) {
                overdueTasks -= 1
              }
              if (!wasSetToOverdue && updatedTask.isOverdue) {
                overdueTasks += 1
              }
  
              return updatedTask
            }
            else return task
          })
  
          return {
            ...summary,
            tasks: updatedTasks,
            overdueTasks: overdueTasks,
            withinOneWeekTasks: withinOneWeekTasks,
            withinThreeWeekTasks: withinThreeWeekTasks,
          }
        })
        
        return updatedSummaries
      }

      case 'REMOVE_MAINTENANCE_TASK': {
        if (!state?.length) return state

        const tasksAfterDone = state.map(summary => {
          if (!summary.tasks?.length) return summary

          const taskToRemove = summary.tasks.find(task => task.periodId === action.periodId)
          if (!taskToRemove) return summary

          const updatedTasks = summary.tasks.filter(task => task.periodId !== action.periodId)
            
          return{
            ...summary,
            tasks: [...updatedTasks],
            overdueTasks: taskToRemove.isOverdue ? summary.overdueTasks - 1 : summary.overdueTasks,
            withinOneWeekTasks: taskToRemove.isInOneWeek ? summary.withinOneWeekTasks - 1 : summary.withinOneWeekTasks,
            withinThreeWeekTasks: taskToRemove.isInThreeWeeks ? summary.withinThreeWeekTasks - 1 : summary.withinThreeWeekTasks,
            equipments: summary.equipments - 1
          }
        })
        return tasksAfterDone
      }
    case "UPDATING_MAINTENANCE_PERIOD_COMMENT_SUCCEEDED": {
      if (!state?.length) return state

      const newSummaries = [...state]
      let taskIdx = -1
      let historyIdx = -1
      const homebaseIdx = newSummaries.findIndex(summary => {
        taskIdx = summary?.tasks?.findIndex(t => {
          historyIdx = t.periodHistory?.findIndex(h => h.maintenancePeriodId === action.periodId)
          if (historyIdx >= 0) return true
        })
        if (taskIdx >= 0) return true
      })

      if (homebaseIdx < 0) return state

      newSummaries[homebaseIdx].tasks[taskIdx].periodHistory[historyIdx].comment = action.newComment
    
      return newSummaries
    }
    case "UPDATING_DOCUMENT_OF_PERIOD_SUCCEEDED": {
      if (!state?.length) return state

      const newSummaries = [...state]
      let taskIdx = -1
      let historyIdx = -1
      const homebaseIdx = newSummaries.findIndex(summary => {
        taskIdx = summary?.tasks?.findIndex(t => {
          historyIdx = t.periodHistory?.findIndex(h => h.maintenancePeriodId === action.periodId)

          if (historyIdx >= 0) return true
        })
        if (taskIdx >= 0) return true
      })

      if (homebaseIdx < 0) return state

      newSummaries[homebaseIdx].tasks[taskIdx].periodHistory[historyIdx].document = action.documentUrl
      newSummaries[homebaseIdx].tasks[taskIdx].periodHistory[historyIdx].fileName = action.fileName
    
      return newSummaries
    }
    case "REMOVING_DOCUMENT_FROM_PERIOD_SUCCEEDED": {
      if (!state?.length) return state

      const newSummaries = [...state]
      let taskIdx = -1
      let historyIdx = -1
      const homebaseIdx = newSummaries.findIndex(summary => {
        taskIdx = summary?.tasks?.findIndex(t => {
          historyIdx = t.periodHistory?.findIndex(h => h.maintenancePeriodId === action.periodId)
          if (historyIdx >= 0) return true
        })
        if (taskIdx >= 0) return true
      })

      if (homebaseIdx < 0) return state

      newSummaries[homebaseIdx].tasks[taskIdx].periodHistory[historyIdx].document = null
      newSummaries[homebaseIdx].tasks[taskIdx].periodHistory[historyIdx].fileName = null
    
      return newSummaries
    }
    case "MAINTENANCE_PERIOD_DONE": {
      if (!state?.length) return state
      if (action.noRepeatTask) return state

      const newSummariesWithHistory = [...state]
      let taskIndex = -1
      const homebaseIndex = newSummariesWithHistory.findIndex(summary => {
        taskIndex = summary?.tasks?.findIndex(t => t.assetId === action.assetId)

        if (taskIndex >= 0) return true
        else return false
      })

      if (homebaseIndex < 0) return state

      newSummariesWithHistory[homebaseIndex].dueDate = action.newDueDate

      // add newly started period
      newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory = [
        ...(newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory ?? []),
        {
          assetId: action.assetId,
          comment: null,
          document: null,
          endTime: null,
          fileName: null,
          maintenancePeriodId: action.newMaintenancePeriodId,
          maintenancePlanId: action.maintenancePlanId,
          startTime: action.newMaintenancePeriodStartTime
        }
      ]

      // update the period that was ended
      const oldPeriodIndex = newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory?.findIndex(p => p.maintenancePeriodId === action.periodId)
      if (oldPeriodIndex < 0) return newSummariesWithHistory

      newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory[oldPeriodIndex].comment = action.comment
      newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory[oldPeriodIndex].document = action.documentBlob
      newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory[oldPeriodIndex].endTime = action.endDate
      newSummariesWithHistory[homebaseIndex].tasks[taskIndex].periodHistory[oldPeriodIndex].fileName = action.documentName

      newSummariesWithHistory[homebaseIndex].tasks[taskIndex].startDate = action.newMaintenancePeriodStartTime

      return newSummariesWithHistory

    }
    default:
      return state
  }
}

export const maintenance_plans = (state = [], action) => {
  switch (action.type) {
    case 'FETCHING_MAINTENANCE_PLANS_SUCCESSFUL':
      return {
        ...state,
        plans: [...action.data]
      }
    //case 'FETCHING_MAINTENANCE_CATEGORIES_SUCCESSFUL':
    case 'FETCHING_ASSET_CATEGORIES_SUCCESSFUL':
      return {
        ...state,
        categories: [...action.data]
      }
    case 'FETCHED_ORGANISATION_NAME':
      const newOrganisationState = state?.organisations ?? []
      newOrganisationState.push({
        organisationId: 'organisation/' + action.organisation.uuid,
        organisationName: action.organisation.translated?.[0]
      })
      return {
        ...state,
        organisations: newOrganisationState
      }
    case 'FETCHING_MAINTENANCE_HOMEBASES_SUCCESSFUL':
      return{
        ...state,
        homebases: [...action.data]
      }
    case 'CREATE_ASSET_FOR_MAINTENANCE_SUCCEEDED':
      const newPlanList = state.plans?.length ? [...state.plans] : []
      newPlanList.push({
        frequency: action.command.frequency,
        instuctions: action.command.instuctions,
        name: action.command.assetName,
        organisationId: action.command.organisationId,
      })
      return {
        ...state,
        plans: [...newPlanList],
        images: []
      }
    case 'CREATE_NEW_HOMEBASE_SUCCEEDED':
      const homebases = [...state.homebases]
      homebases.push({
        id: action.response.data.id,
        name: action.response.data.name,
        organisationsId: [action.response.data.organisationId],
        type: action.response.data.type,
        locationType: "Location" // TODO: get this from the server response, eventually
      })
      return {
        ...state,
        homebases: [...homebases]
      }
    case 'UPLOAD_IMAGE':
      let imagesToUpload = state.images?.length ? [...state.images] : []
      if(imagesToUpload.length)
        imagesToUpload = imagesToUpload.filter(img => img.typeOfImage !== action.typeOfImage)
      imagesToUpload.push({fileData: action.image, typeOfImage: action.typeOfImage})
      return{
        ...state,
        images: [...imagesToUpload]
      }
    case 'UPLOADING_IMAGE_SUCCESSFULLY':
      let imagesUploaded = state.images?.length ? [...state.images] : []
      if(imagesUploaded.length)
        imagesUploaded = imagesUploaded.filter(img => img.typeOfImage !== action.typeOfImage)
      imagesUploaded.push({fileData: action.data, typeOfImage: action.typeOfImage})
      return{
        ...state,
        images: [...imagesUploaded]
      }
    case 'UPLOADING_IMAGE_FAILED':
      let imagesAlreadyUploaded = state.images?.length ? [...state.images] : []
      if(imagesAlreadyUploaded.length)
        imagesAlreadyUploaded = imagesAlreadyUploaded.filter(img => img.typeOfImage !== action.typeOfImage)
      return{
        ...state,
        images: [...imagesAlreadyUploaded]
      }  
    case 'REMOVING_IMAGE_SUCCESSFULLY':
      var filteredImages = state.images.filter(img => img.fileData.imageId !== action.imageId)
      return{
        ...state,
        images: [...filteredImages]
      }
    case 'UPDATING_MAINTENANCE_PLAN_AND_TASK_SUCCEEDED': {
      const updatedPlans = state?.length ? state.map(plan => {
        if (plan.planId === action.maintenancePlanId) {
          return {
            ...plan,
            daysFrequency: action.newFrequencyInDays
          }
        }
        else return plan
      }) : state

      return updatedPlans
    }
    case 'CLEAR_UPLOADED_IMAGES':
      return{
        ...state,
        images: []
      }
    default:
      return state
  }
}

export const asset_documents = (state = {}, action) => {
  switch (action.type) {
    case 'ASSET_DOCUMENTS_FETCHED': {
      const addedDocuments = {...state}
      addedDocuments[action.assetId] = action.data ?? []
      return addedDocuments
    }
    case 'DOCUMENT_ADDED_TO_ASSET': {
      const withNewDocument = {...state}
      withNewDocument[action.assetId] = [
        ...withNewDocument[action.assetId],
        {
          assetId: action.assetId,
          comment: action.comment,
          documentId: action.documentId,
          documentUrl: action.documentUrl,
          fileName: action.fileName,
          uploadedAt: DateTime.local().toISO()
        }
      ]
      return withNewDocument
    }
    case 'REMOVING_ASSET_DOCUMENT_SUCCEEDED':
      let assetDocuments = state[action.assetId]
      assetDocuments = assetDocuments?.filter(doc => doc.documentId !== action.documentId)
      
      const newState = {...state}
      newState[action.assetId] = assetDocuments
      return newState
    default: return state
  }
}