import { DateTime } from "luxon";

export const map_settings = (state = {
  filter: {
    mustHaveWeightWarning: false
  },

  date: DateTime.local().toISODate(),

  startClockTime: DateTime.local().startOf('day'),
  endClockTime: DateTime.local().endOf('day'),

  isLive: true,
}, action) => {
  switch (action.type) {
    case 'SET_MAP_FILTER':
      return {
        ...state,
        filter: {
          mustHaveWeightWarning: action.filter?.mustHaveWeightWarning
        }
      }
    case 'SET_MAP_START_TIME_O_CLOCK':
      return {
        ...state,
        startClockTime: action.startTime,
      }
    case 'SET_MAP_END_TIME_O_CLOCK':
      return {
        ...state,
        endClockTime: action.endTime,
      }
    case 'SET_MAP_LIVE_MODE':
      return {
        ...state,
        isLive: action.isLive
      }
    case 'SET_MAP_DATE':
      return {
        ...state,
        date: action.date
      }
    default:
      return state;
  }
}

export const user_settings = (state = {
  notificationSound: false
}, action) => {
  switch (action.type){
    case 'TOGGLE_SOUND':
      return {
        ...state,
        notificationSound: action.isSound
      }
    default: return state
  }
}
