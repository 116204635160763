const vehicle_columns = [
    { 
      id: 'name',
      label: 'Vehicle Name',
      maxWidth: 170,
      minWidth: 150,
      hide: []
    },
    { 
      id: 'translatedTags',
      label: 'Organisation',
      maxWidth: 170,
      minWidth: 140,
      hide: []
    },
    { 
      id: 'licenseNumber',
      label: 'Lic. No.',
      maxWidth: 170,
      minWidth: 115,
      hide: ['mobile']
    },
    {
      id: "brand_model",
      label: 'Model',
      minWidth: 170,
      align: 'left',
      format: value => value.toFixed(2),
      hide: ['tablet', 'mobile']
    },
    {
      id: "year",
      label: 'Year',
      maxWidth: 120,
      minWidth: 90,
      align: 'left',
      hide: ['tablet', 'mobile']
    },
    {
      id: 'driver',
      label: 'Current Driver',
      maxWidth: 170,
      align: 'left',
      hide: ['mobile']
    }
];

export default vehicle_columns;