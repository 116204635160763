import React from 'react'
import Input from '@modul-connect/shared/components/atoms/input';
import theme from '../../../theme';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// TODO: add icon
// TODO: change font. Maybe use MUI?
export const SearchField = ({v2, extend, onChange, placeholder, initialValue, boxShadow, flexWidth, value}) => {
  const styles = {
    input: {
      width: flexWidth ? '100%' : 250,
      height: 33,
      fontStyle: 'italic',
      backgroundColor: theme.colors.white,
      borderRadius: '4px',
      paddingLeft: 2.5, paddingRight: 2.5,


      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '17px',

      color: '#222222'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignSelf: 'center',
    },
    boxShadow: {
      ...theme.inputFieldBoxShadow
    }
  }

  if (v2) {
    return <InputBase
      fullWidth
      onChange={onChange ? event => onChange(event) : null}
      autoComplete='off'
      endAdornment={<SearchIcon fontSize='inherit' />}
      placeholder={placeholder}
      value={value ?? undefined}
      style={{
        ...styles.input, ...(boxShadow ? styles.boxShadow : []), ...(extend ?? []), paddingLeft: 20, paddingRight: 20, minWidth: 120
      }}
      
      >
    </InputBase>
  }
  else return (
    <Input 
      extend={[styles.input, boxShadow && styles.boxShadow, extend && extend]} 
      type="text" 
      placeholder={ placeholder } 
      defaultValue={ initialValue }
      value={value ?? undefined}
      
      onChange={onChange ? event => onChange(event) : null}
    />
  )
}