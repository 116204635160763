import React from "react";
import { Box } from "@mui/material";
import theme from "../../../theme";
import chroma from 'chroma-js'

const TextBox = ({ reference, children, extend }) => {
  return (
      <Box
        ref={reference}
        component="form"
        sx={{
          width: "100%",
          fontWeight: 400,
          fontSize: '1rem',
          color: theme.colors.text,
          boxSizing: 'border-box',
          alignItems: 'center',
          borderRadius: '4px',
          padding: '16.5px 14px',
          border: '1px solid ' + theme.colors.greyLine,
          backgroundColor: chroma(theme.colors.white).alpha(1).hex(),
          ...extend,
        }}
        noValidate
        autoComplete="off"
      >
        {children}
      </Box>
  );
};

export default TextBox;
