import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import { P, H4 } from "@modul-connect/shared/components/atoms/text";
import theme from '@modul-connect/shared/theme';
import { ThemeProvider } from '@mui/material/styles';
import View from '@modul-connect/shared/components/atoms/view'

const header = {
  marginTop: '15px',
  marginBottom: '5px'
}

const MapFilterPicker = ({
  isMobile,
  filter,
  setFilter,
}) => {
  return (
    <ThemeProvider theme={theme.mui}>
      <View style={header} >
        <H4>Show only routes with: </H4>
      </View>
      <FilterCheckboxItem 
        label={'Weight Warnings'} 
        checked={filter.mustHaveWeightWarning} 
        onChange={(newValue) => setFilter({
          mustHaveWeightWarning: newValue
        })} 
        isMobile={isMobile} />
    </ThemeProvider>
  )
}

const FilterCheckboxItem = ({ label, checked, onChange, isMobile }) => {
  return (
    <View extend={[{ 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
      height: isMobile ? '45px' : '30px',
      '&:hover': {
        cursor: 'pointer'} 
      }]}
      onClick={() => onChange(!checked)}>
      <View style={{ }}>
        <Checkbox
          size="small"
          checked={checked}
          onChange={(event) => onChange(event.target.checked)} />
      </View>
      <View style={{ }}>
        <P strong={checked}>{label}</P>
      </View>
    </View>
  )
}

export default MapFilterPicker