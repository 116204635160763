import React from 'react'
import { connect } from 'react-redux'
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2'
import columnsByVehicle from './columns/tollPassageColumnsByVehicle'
import columnsByDriver from './columns/tollPassageColumnsByDriver'
import '../tollPassage.css'

const TollPassageTables = ({
  toll_reports,
  themes,
  boxId,
  tableData,
  tableTitle,
}) => {
  return (
    toll_reports?.total &&
    <TableWidgetSeparated
      tableTitle={tableTitle}
      data={tableData ?? []}
      totalItems={tableData?.filter(entry => !entry.isDivider).length ?? 0}
      columns={boxId ? columnsByVehicle : columnsByDriver}
      themes={themes}
      isSortable={false}
      pagination={false}
      rowsOptions={[tableData?.length ?? 0]}
    />
  )
}

const mapStateToProps = ({
  themes
}) => ({
  themes
})

export default connect(mapStateToProps)(TollPassageTables)