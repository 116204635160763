import { takeEvery, put, select } from 'redux-saga/effects'
import { customerServerUrl } from '@modul-connect/shared/config'
import { get, post } from '../../utils/ajax'
import { authenticatedUserFetched } from "../actions/customer"

 function* getUsersFromServer({ accessToken, options }) {
  const url = customerServerUrl() + '/customer/user' + 
    '?count=' + options.rowsPerPage + 
    '&page=' + options.currentpage + 
    '&sortby=' + options.sort + 
    '&namefilter=' + options.searchStr
  const result = yield get(accessToken, url)

  if (result?.status === 'no_content') {
    yield put({ type: 'USERS_FETCHED', users: [], total: 0 })
  }
  else if (result?.status === 'ok') {
    const data = yield result.data
    yield put({ type: 'USERS_FETCHED', users: data.users, total: data.total })
  }
  else {
    yield put({ type: 'FETCHING_USERS_FAILED' })
  }
}

function* postAddUser({ accessToken, firstName, lastName, email, tags }) {
  const url = customerServerUrl() + '/customer/createuser'

  const userBody = {
    'email': email,
    'user_metadata': {
      'info': {
        'tags': [
          {
            'tags': tags
          }
        ]
      }
    },
    'firstName': firstName,
    'lastName': lastName,
  }

  const result = yield post(accessToken, url, userBody)

  if (result && result.status) {
    switch(result.status) {
      case 'ok':
        yield put({type: 'USER_ADDED', data: { firstName: firstName, lastName: lastName, email: email }})
        return
      case 'no_content':
        yield put({type: 'USER_ADDED', data: { firstName: firstName, lastName: lastName, email: email }})
        return
      case 'bad_request':
        yield put({ type: 'INCORRECT_EMAIL', data: { firstName: firstName, lastName: lastName, email: email }})
        return
      case 'conflict':
        yield put({ type: 'USER_ALREADY_EXISTS', data: { firstName: firstName, lastName: lastName, email: email }})
        return
      default:
        yield put({ type: 'ADDING_USER_FAILED', data: { firstName: firstName, lastName: lastName, email: email }})
    }
  }
  else {
    yield put({ type: 'ADDING_USER_FAILED', data: { firstName: firstName, lastName: lastName, email: email }})
  }
}

function* postRemoveUser({ accessToken, email, tags}) {
  const url = customerServerUrl() + '/customer/removeuser'

  const userBody = {
    'email': email,
    'organisations': [...tags]
  }

  const result = yield post(accessToken, url, userBody)

  if (result?.status === 'no_content' || result?.status === 'ok') {
    yield put({ type: 'USER_REMOVED', data: result.statusText, email: email })
  }
  else {
    yield put({ type: 'REMOVING_USER_FAILED', data: result?.statusText, email: email })
  }
}

export function* getAuthenticatedUserByEmail({ token, email }) {
  const url = customerServerUrl() + '/customer/user/' + email

  const result = yield get(token, url)

  if (result && result.status === 'ok') {
    const user = yield result.data

    const currentUser = yield select(state => state.authenticated_user)
    let isNewUser = currentUser?.email !== user.email

    yield put(authenticatedUserFetched( token, user, isNewUser ))
  }
}

export function* getDisturbances({ accessToken}) {
  const url = customerServerUrl() + '/disturbance'

  const result = yield get(accessToken, url)

  if (result && result.status === 'ok') {
    yield put({ type: 'SHOW_DISTURBANCE_NOTIFICATION', message: result?.data.description?.english})
  }
}

export function* userSagas() {
  yield takeEvery('FETCH_USERS', getUsersFromServer)
  yield takeEvery('ADD_USER', postAddUser)
  yield takeEvery('REMOVE_USER', postRemoveUser)
  yield takeEvery('FETCH_AUTHENTICATED_USER', getAuthenticatedUserByEmail)
  yield takeEvery('FETCH_USER_TERMS', getDisturbances)
}