import React, { Fragment } from "react";
import LogChart from "@modul-connect/shared/components/organisms/logChart";
import KpiRow from "@modul-connect/shared/components/molecules/kpiRow/kpiRow";
import { ticks_24_hours, ticks_7_days } from "./ticks";
import theme from "@modul-connect/shared/theme"
import { getBatteryWarningColor } from "../../../../../utils/vehicleUtils";

const LpsLogGraph = ({
  vehicle,
  logs,
  log_loading,
  showBatteryId,
  onBatteryIntervalChanged,
  intervals,
  batteryLogInterval,
  batteryId
}) => {

  const defaultBatteryGuard = 50

  const getTicks = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return ticks_24_hours()
      case intervals.LAST_WEEK:
        return ticks_7_days()
      default:
        return []
    }
  }
  let lps = vehicle?.currentStatus?.lithiumPowerSupply
  let kpiData_battery = lps ? 
    [{
      title: 'Current Percentage' ,
      value: (lps?.batteryPercentage || lps?.batteryPercentage === 0) ? lps?.batteryPercentage : '-',
      unit: '%',
      color: (!lps?.batteryPercentage && lps?.batteryPercentage !== 0) ? theme.colors.text : getBatteryWarningColor(lps.batteryPercentage, defaultBatteryGuard)
    }] : {} 

  return (
    <Fragment>
      {
        (vehicle?.currentStatus?.batteries?.length || log_loading.fetchBatteryLogs !== 'loading') &&
        <KpiRow title={'Status'} width={'100%'} data={kpiData_battery} />
      }
      {
        <LogChart
          intervalOptions={Object.values(intervals)}
          selectedInterval={batteryLogInterval}
          onIntervalChanged={onBatteryIntervalChanged}
          data={logs ? logs.map(log => {
            return { 
              boxId: log.boxId,
              id: log.boxId,
              telematicsId: log.telematicsId,
              timestamp: log.timestamp,
              percentage: log.batteryPercentage,
          }}) : []}
          data_key="percentage"
          data_name="Battery Charge"
          //max_name="Min. Lps Percentage"
          title={"Lps Percentage"}
          unit="%"
          line_type={"stepAfter"}
          domain={[
            0,
            100
          ]}
          displayTimeInHours={batteryLogInterval === intervals.LAST_24_H ? true : false}
          ticks={getTicks(batteryLogInterval)}
        />
      }
    </Fragment>
  )
}

export default LpsLogGraph;


