export const sortFunction_alphabetical = (a, b) => {
  var nameA = a.name.toUpperCase()
  var nameB = b.name.toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

export const shortenStringByEllipsis = (str, maxCharacters) => {
  if (!str || maxCharacters < 4) return str

  let shortenedStr = str
  if (str?.length > maxCharacters) {
    shortenedStr = str?.slice(0, maxCharacters - 3) + '...'
  }

  return shortenedStr
}