import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchDrivingLogSummaries, fetchVehiclesData, fetchBatteryReport } from '../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import { DateTime } from "luxon";
import BatteryReportDetails from './batteryReportDetails/batteryReportDetails';
import { getServiceList } from '../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';

const BatteryReportPage = ({ 
  selected_organisations,
    vehicles_data, 
    loading,
    fetchVehiclesData,
    fetchBatteryReport,
    subtrees
}) => {    
    const { getAccessTokenSilently } = useAuth0()

    const disabled = !getServiceList({subtrees}).hasService(ServiceType.BatteryWarnings)

    let [startDateDetails, setStartDateDetails] = useState(DateTime.local().startOf('month'))
    let [endDateDetails, setEndDateDetails] = useState(DateTime.local())
    let [selectedVehicle, setSelectedVehicle] = useState(null)

    useEffect(() => {
        getAccessTokenSilently().then(accessToken => {
            const start = startDateDetails ? startDateDetails : null
            const end =  endDateDetails ? endDateDetails : null
            fetchBatteryReport(accessToken, start, end, selectedVehicle)
        })
    }, [startDateDetails, endDateDetails, selectedVehicle, selected_organisations])

    useEffect(() => {
        if ((!vehicles_data || !vehicles_data.data || vehicles_data.data.length === 0)) {
            getAccessTokenSilently().then(fetchVehiclesData)
        }
    }, [selected_organisations])
    
    return (
      <BatteryReportDetails 
        startDate={startDateDetails} 
        disabled={disabled}
        endDate={endDateDetails} 
        onStartDatePicked={(date) => setStartDateDetails(date.startOf('day'))}
        onEndDatePicked={(date) => setEndDateDetails(date.endOf('day'))}
        onVehicleSelected={setSelectedVehicle}
      />
    )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
    fetchDrivingLogSummaries: (accessToken, start, end) => dispatch(fetchDrivingLogSummaries(accessToken, start, end)),
    fetchVehiclesData: (accessToken) => dispatch(fetchVehiclesData(accessToken)),
    fetchBatteryReport: (accessToken, start, end, boxId) => dispatch(fetchBatteryReport(accessToken, start, end, boxId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BatteryReportPage);