import { default as TreeView } from '@modul-connect/shared/components/atoms/dropdownTree/dropdownTree'
import React from 'react'

const OrgFilterTreeView = ({ data, onSelect, onToggle, mobile }) => {
  return (
    <TreeView
      mobile={mobile}
      title={'View'}
      inlineSearchInput
      data={data}
      onSelect={onSelect}
      onToggle={onToggle}
    />
  )
}

export default OrgFilterTreeView