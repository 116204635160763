import React from 'react';
import theme from "@modul-connect/shared/theme";
import View from "@modul-connect/shared/components/atoms/view";
import { P, Span } from "@modul-connect/shared/components/atoms/text";
import { MdFiberManualRecord } from "react-icons/md";
import { Fragment } from 'react';

const TreeWidget = ({
  tree,
  title,
  onSelect,
  selected,
  error,
  disabled,
  hideRoot
}) => {
  // const [selected, setSelected] = React.useState(null)

  let depth = -1

  const generateTreeContent = (root, tag = root.uuid, translated = root.name) => {
    depth++
    let component = (<View key={root.uuid}>
     { (hideRoot && depth === 0) ? null : <View
        extend={[
          styles.treeLevelHeader, 
          disabled && styles.disabled, 
          { paddingLeft: `${(1 + (depth - (hideRoot ? 1 : 0))) * theme.layout.grid}px` },
          (selected == tag) && styles.selected]}
        onClick={() => {
          if (disabled) return
          onSelect(tag, translated)
        }}
      >
        {/* <P noPadding>{ root.name }</P> */}
        <Span extend={[styles.span]}>{root.name}</Span>
      </View>}
      {Array.isArray(root.children) ?
        <View>
          {root.children.map(child =>
            generateTreeContent(child, child.uuid, translated + ' > ' + child.name)
          )}
        </View>
        : null
      }
    </View>
    )
    depth--
    return component
  }

  return (
    <View extend={error && styles.error}>
      <Span extend={[styles.span]}>{title}</Span>
      <View extend={styles.container}>
        {
          generateTreeContent(tree)
        }
        
      </View>
    </View>
  )
}

const styles = {
  container: {
    borderRadius: theme.layout.borderRadius,
    // backgroundColor: theme.colors.primaryLight,
    //padding: `${theme.layout.grid}px ${1 * theme.layout.grid}px`,
    // border: `1px solid ${ theme.colors.white }`
  },
  span: {
    fontSize: 12,
    color: theme.colors.darkGrey
  },
  treeLevelHeader: {
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.layout.borderRadius,
    padding: `${theme.layout.grid}px ${1 * theme.layout.grid}px`,
    ":hover": {
      backgroundColor: theme.colors.primaryLight,
    },
    "> svg": {
      position: "absolute",
      left: 0,
      fontSize: 8,
      top: 12,
    },
    "> p": {
      // marginLeft: `${ theme.layout.grid }px !important`,
    }
  },
  selected: {
    backgroundColor: theme.colors.primaryLight,
    // color: theme.colors.white
  },
  error: {
    "& span": {
      color: theme.colors.error,
    },
    "> div": {
      border: `1px solid ${theme.colors.error}`
    }
  },
  disabled: {
    cursor: "inherit !important",
    backgroundColor: "inherit !important",
    color: theme.colors.grey,
    "> p": {
      color: theme.colors.grey,
    }
  },
  header: {
    cursor: "inherit !important",
    backgroundColor: "inherit !important",
    color: theme.colors.grey,
    "> p": {
      color: theme.colors.grey,
    },
    padding: 0
  }
}

export default TreeWidget;