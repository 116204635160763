import React from 'react';
import View from '@modul-connect/shared/components/atoms/view';

const Page = ({ children, headerSection }) => {
  
  const styles = {
    containerCSS:
    ({ theme: { layout, colors } }) => ({
      borderRadius: `${0.5 * layout.grid}px`,
      backgroundColor: 'transparent',
      width: '100%',
    }),
  }

  return (
    <View style={{ marginTop: '20px' }}>
      <View extend={styles.containerCSS}>
        {headerSection}
        {children}
      </View>
    </View>
  )
};

export default Page;
