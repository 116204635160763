import React, { Fragment } from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import theme from '@modul-connect/shared/theme';
import { Span } from '@modul-connect/shared/components/atoms/text';
import chroma from 'chroma-js';

const StatusDisplay = ({
  value,
  noValueText = null,
  unit,
  title,
  subTitle,
  dark = false,
  maxValue = null,
  big = false,
  warning,
  issue,
}) => {
  const showTextInstead = !value && noValueText

  return (
    <View extend={getContainerStyle(big, dark, warning, issue)}>
      {
        showTextInstead ?
          <View extend={{
            ...getValueStyle(big, dark, warning, issue),
            fontSize: 14,
          }}>
            {noValueText}
          </View>
          : <Fragment>
            <Span>{unit}</Span>
            <View extend={getValueStyle(big, dark, warning, issue)}>
              {maxValue ?
                (value ? value : '-') + ' / ' + maxValue
                : value ? value : '-'}
            </View>
            <Span>{title}</Span>
            {subTitle ? <Span>{subTitle}</Span> : null}
          </Fragment>
      }
    </View>
  )
};


const getContainerStyle = (big, dark, warning, issue) => {
  return ({ theme: { layout, colors } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    marginTop: 4,
    marginRight: 25,
    textAlign: 'center',
    "> span": {
      fontSize: big ? 14 : 10,
      color: chroma(getColor({ warning, issue, dark })).alpha(0.8).hex(),
    }
  })
}

const getColor = ({ warning, issue, dark }) => {
  if (issue) {
    return theme.colors.error
  }

  if (warning) {
    return theme.colors.tag['warning']
  }

  if (dark) {
    return theme.colors.text
  }

  return theme.colors.white
}

const getValueStyle = (big, dark, warning, issue) => {
  return ({ theme: { layout, colors } }) => ({
    fontSize: big ? 32 : 18,
    color: getColor({ warning, issue, dark }),
  })
}

export default StatusDisplay;
