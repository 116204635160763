import { takeEvery, select } from 'redux-saga/effects'

function* closeEarlyPopups() {
  const mapPopups = yield select(state => state.mapPopups)
  if (mapPopups.length > 1)
    mapPopups[0].popup.remove()
}

function* closeAllPopups() {
  const mapPopups = yield select(state => state.mapPopups)
  mapPopups.forEach(popup => popup.popup.remove())
}

export function* mapSagas() {
  yield takeEvery('OPENED_MAP_POPUP', closeEarlyPopups)
  yield takeEvery('ROUTE_SELECTED', closeAllPopups)
}