import React from 'react'
import './timespanPickers.css'
import './dayPicker.css'
import { DateCalendar } from '@mui/x-date-pickers'

export const DayPicker = ({ startTime, setSelectedStartTime, setSelectedEndTime, shouldDisableDate }) => {
  return (
    <DateCalendar shouldDisableDate={shouldDisableDate} disableFuture value={startTime} onChange={(value, selectionState) => {

      if (selectionState === 'finish') {
        const beginningOfDay = value.startOf('day')
        const endOfDay = value.endOf('day')

        setSelectedStartTime && setSelectedStartTime(beginningOfDay)
        setSelectedEndTime && setSelectedEndTime(endOfDay)  
      }
    }} />
  )
}