import React from "react";
import { useAuth0 } from '@auth0/auth0-react'
import { H3, P, A, Span } from "@modul-connect/shared/components/atoms/text";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import { designateCurrentDriver, fetchMileage, setLicenseNumber, updateMileage } from "../../../../state/actions/vehicles";
import { MdDone, MdEdit } from "react-icons/md";
import ChangeMileageModal from "./changeMileageModal";
import EditLicenseNumberModal from "./editLicenseNumberModal";
import { convertToSelectedUnit } from "../../../../utils/unitConverter";

const getNoDataText = (vehicle_loading, noDataStr) => {
  return vehicle_loading.fetchVehicle === 'loading' ? 'Loading ...' : noDataStr ?? '-'
}

const VehicleInfo = ({
  vehicle,
  vehicleId,
  designateCurrentDriver,
  fetchMileage,
  updateMileage,
  current_mileage,
  setLicenseNumber,
  saving,
  loading,
  vehicle_loading
}) => {  
  const { getAccessTokenSilently, user } = useAuth0()

  let [showChangeMileageModal, setShowChangeMileageModal] = React.useState(false)
  const [showEditLicenseNoModal, setShowEditLicenseNoModal] = React.useState(false)

  const changeVehicleMileageWithAccessToken = (data) => {
    getAccessTokenSilently()
      .then(accessToken => {
        updateMileage(accessToken, data.vehicleId, data.mileage)
      })
  }

  React.useEffect(() => {
    if (current_mileage?.boxId && current_mileage?.boxId === vehicleId || loading.fetchMileage === 'loading') {
      return
    }
    if (saving && saving.changeVehicleMileage == null) {
      getAccessTokenSilently()
        .then(accessToken => {
          fetchMileage(accessToken, vehicleId)
        })
    }
  }, [saving.changeVehicleMileage])


  // If we bring this code back in, need to make sure it also updates the vehicles reducer
  const postNewDriverWithAccesToken = () => {
    getAccessTokenSilently()
      .then(accessToken => {
        designateCurrentDriver(accessToken, vehicleId, user.sub)
      })
  }
  return (
    <View extend={styles.main}>
      <View extend={styles.child}>
        <H3>About</H3>
        <P>VIN: {vehicle?.vehicle?.vin ?? getNoDataText(vehicle_loading)}</P>
        <View extend={styles.switchWrapper}>
          <P>Lic. No: {vehicle?.vehicle?.licenseNumber ?? getNoDataText(vehicle_loading)}
            <A
              small
              extend={styles.iconLink}
              onClick={() => setShowEditLicenseNoModal(true)}
            >
              <MdEdit /><Span>EDIT LIC. NO</Span>
            </A>
          </P>
        </View>
        <P>Brand: {vehicle?.vehicle?.brandName ? vehicle?.vehicle?.brandName : getNoDataText(vehicle_loading)}</P>
        <P>Model: {vehicle?.vehicle?.modelName ? vehicle?.vehicle?.modelName : getNoDataText(vehicle_loading)}</P>
        <P>Year: {vehicle?.vehicle?.year ? vehicle?.vehicle?.year : getNoDataText(vehicle_loading)}</P>
        <P>Organisations: {vehicle.translatedTags && vehicle.translatedTags.length > 0 ? vehicle.translatedTags.join(', ') 
          : getNoDataText(vehicle_loading)}
        </P>
      </View>
      <View>
        <H3>Driving logs</H3>
        <P>Current Driver: {vehicle.currentDriver ?? getNoDataText(vehicle_loading, "None")}
          {/*                 { 
                  <A  
                      small
                      extend={ styles.iconLink }
                      onClick={ postNewDriverWithAccesToken }
                  >
                      <MdDone /><Span>ASSIGN ME</Span>
                  </A> } */}
        </P>

        <P>{'Milage: '}
          {
            loading.fetchMileage === 'loading' ? 'Loading ...' :
              ((current_mileage.boxId === vehicleId && (current_mileage.mileage || current_mileage.mileage === 0))
                ? convertToSelectedUnit(current_mileage.mileage, 2, 'distance') : `Couldn't fetch Mileage`)
          }

        </P>

        {/* <View extend={ styles.switchWrapper }>
                    <P strong>
                        Driver */}
        {/* { currentDriver.toLowerCase() !== user.name && 
                            <A  
                                small
                                extend={ styles.iconLink }
                                onClick={ postNewDriverWithAccesToken }
                            >
                                <MdDone /><Span>ASSIGN ME</Span>
                            </A>
                        } */}
        {/* </P>
                </View>
                <P extend={styles.upgradeStatus} noPadding>
                    { currentDriver }
                </P>
                
                <View extend={ styles.switchWrapper }>
                    <P strong>
                        Mileage
                            <A  
                                small
                                extend={ styles.iconLink } */}
        {/* //     onClick={ () => setShowChangeMileageModal(true) }
                            > */}
        {/*    <MdEdit /><Span>EDIT MILEAGE</Span> */}
        {/* </A>
                    </P>
                </View>
                {current_mileage.boxId === vehicleId ?
                  <P extend={styles.upgradeStatus} noPadding>
                      { convertToSelectedUnit(current_mileage.mileage, 2, 'distance') }
                  </P>
                  : <P>Loading ...</P>
                } */}
      </View>

      <EditLicenseNumberModal
        openModal={showEditLicenseNoModal}
        onClose={() => setShowEditLicenseNoModal(false)}
        vehicle={vehicle}
        vehicleId={vehicleId}
        saving={saving}
        commit={async (data) => {
          const accessToken = await getAccessTokenSilently()
          await setLicenseNumber(accessToken, data.vehicleId, data.licenseNumber)
        }}
        disabled={saving && saving.setLicenseNumber === 'saving'}
      />
      <ChangeMileageModal
        openModal={showChangeMileageModal}
        onClose={() => setShowChangeMileageModal(false)}
        vehicle={vehicle}
        vehicleId={vehicleId}
        saving={saving}
        changeMileage={(data) => {
          changeVehicleMileageWithAccessToken(data)
        }}
        disabled={saving && saving.changeVehicleMileage === 'saving'}
      />
    </View>
  )
}

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    "& div": {
      marginRight: 80,
      width: 350
    }
  },
  child: {
    float: 'left'
  },
  withButton: {
    '> p, button': {
      float: 'left'
    }
  },
  button: {
    height: 20,
    padding: 4,
    fontSize: 12,
    margin: 12
  },
  switchWrapper: {
    "& p": {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '0 !important',
      "& a": {
        marginLeft: 8
      }
    }
  },
  upgradeStatus: {
    width: '60%'
  },
  iconLink: ({ theme: { layout, colors } }) => ({
    position: 'relative',
    "> svg": {
      fontSize: 21,
      position: 'absolute',
      left: -3,
      top: -1
    },
    "> span": {
      paddingLeft: 17,
      paddingTop: 3
    }
  }),
}

const mapStateToProps = ({
  current_mileage,
  saving,
  loading,
  vehicle_loading
}) => ({
  current_mileage,
  saving,
  loading,
  vehicle_loading
})

const mapDispatchToProps = dispatch => ({
  designateCurrentDriver: (accessToken, boxId, driver) => dispatch(designateCurrentDriver(accessToken, boxId, driver)),
  fetchMileage: (accessToken, boxId) => dispatch(fetchMileage(accessToken, boxId)),
  updateMileage: (accessToken, boxId, mileage) => dispatch(updateMileage(accessToken, boxId, mileage, null)),
  setLicenseNumber: (accessToken, boxId, licenseNumber) => dispatch(setLicenseNumber(accessToken, boxId, licenseNumber))
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);
