import mapboxgl from "mapbox-gl";
import {addDataLayers} from "./map_dataLayers"
import { updateMapHandler } from './updateMapHandler'
import { getStartEndTimeFromMapsettings, getVehiclesToRender } from "./utils";
import { getStore } from "../../../state/configureStore";
import { fetchVehicleRoutes, fetchVehicleRoutesDetailed, unselectSecurityAlarmIncidentOnMap } from "../../../state/actions/vehicles";
import { sec } from '@modul-connect/shared/auth0/auth0Helper';
import { DateTime } from "luxon";

const style_basic_map = 'ckay0s52d15bq1iofu8gmkrpy'
const style_satellite = 'ckaxyomdc13bl1imxu6q3ol5k'

export function getMapStyle(map) {
  let style = map.getStyle()

  if(!style) {
    return false
  }

  if(style.sprite.search(style_basic_map) !== -1) {
    return 'style_basic_map'
  }

  if(style.sprite.search(style_satellite) !== -1) {
    return 'style_satellite'
  }

  return false
}

export const createMap = (options) => {
    const { center, zoom, onSelectVehicle, onSelectRoute, self } = options

    mapboxgl.accessToken = "pk.eyJ1Ijoic2VlcmVmaW5lIiwiYSI6ImNrYXh5OGcxYTBhdmkyeW81NGM3ODhlaXMifQ.JK353QD6jUa7g1J_RnGPVw";

    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: 'mapbox://styles/seerefine/' + style_basic_map,
      center: center,
      zoom: zoom || 11
    })

    // disable map rotation using right click + drag
    map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'bottom-right');

    document.getElementById('switchMapsControl').onclick = function () {
      if(getMapStyle(map) === 'style_basic_map') {
        map.setStyle("mapbox://styles/seerefine/" + style_satellite);
      } else {
        map.setStyle("mapbox://styles/seerefine/" + style_basic_map);
      }
    }

    const vehicles_to_render = getVehiclesToRender({ 
      vehicles: self.props.vehicles,
      map_settings: self.props.map_settings,
      date: self.props.date,
    })

    map.on('style.load', function() {
      addDataLayers(map, onSelectVehicle, onSelectRoute)
      updateMapHandler({
        vehicles: self.props.vehicles,
        vehicles_to_render: vehicles_to_render,
        beacons: self.props.beacons,
        self: self, 
        map: map, 
        themes: self.props.themes, 
        date: self.props.date, 
        selectedVehicle: self.props.selectedVehicle,
        selectedAssetTracker: self.props.selectedAssetTracker,
        selectedSecurityAlarm: self.props.selectedSecurityAlarm,
        unselectSecurityAlarmIncidentOnMap: () => getStore().dispatch(unselectSecurityAlarmIncidentOnMap()),
        action: 'mapStyle_changed',
        onSelectVehicle: onSelectVehicle,
        onSelectRoute: onSelectRoute,
        selectedRoute: self.props.selectedRoute,
        map_settings: self.props.map_settings,
        store: self.props.store
      })
    });

    // for fetching the more detailed routes on zooming in. Not needed now, since fetching the detailed ones is actually faster than the simpler ones ...
    /* map.on('zoom', function() {
      const currentZoom = map.getZoom();
      const store = getStore()
      const state = store.getState()
      const map_settings = state?.map_settings

      const {startTime, endTime} = getStartEndTimeFromMapsettings(map_settings)

      try{
      sec.getAccessTokenSilently().then((accessToken) => {
      if(currentZoom > 11){
        const key = startTime.toSeconds().toFixed(0) + '_' + endTime.toSeconds().toFixed(0) 
        const loadingStatus = state.loading?.fetchVehicleRoutesDetailed && state.loading?.fetchVehicleRoutesDetailed[key]
        
        if(!loadingStatus || map_settings.isLive && loadingStatus !== "loading"){ // if it is either "loading", "failed" or "fetched", don't do it again, unless live, then fetch again if not already loading
          store.dispatch(
            fetchVehicleRoutesDetailed(
              accessToken,
              startTime, 
              endTime
            ))
          }
        }}
      )
      } catch (error) {
        console.log(error)
      }      
  }) */
    
    return map
}