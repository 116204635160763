export const log_columns_collapsable = [
  {
    id: "vehicleName",
    label: null,
    align: "flex-start",
    hasValues: true,
    width: 220,
    isSortable: true,
  },
  {
    id: "warning",
    label: null,
    align: "center",
    icon: true,
    width: 65,
    isSortable: true,
  },
  {
    id: "name",
    label: null,
    align: "flex-start",
    width: 135,
    hasValues: true,
    isSortable: true,
  },
  {
    id: "category",
    label: null,
    width: 210,
    align: "flex-start",
    icon: false,
    hasValues: true,
    isSortable: true,
  },
  {
    id: "batteryWarning",
    label: null,
    align: "flex-start",
    icon: true,
    hasValues: false,
    width: 120,
    isSortable: true,
  },
  {
    id: "geoLocation",
    label: null,
    align: "flex-start",
    icon: true,
    minWidth: 250,
    hasValues: true,
    isSortable: true,
  },
  {
    id: "notification",
    label: null,
    align: "center",
    icon: true,
    hasValues: false,
    isSortable: true,
  },
  {
    id: "details",
    label: null,
    align: "flex-end",
    icon: false,
    hasValues: true,
    width: 100 / 8 + "%",
    isSortable: false,
  },
];
