import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { Menu } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import theme from "@modul-connect/shared/theme";
import Button from "@modul-connect/shared/components/atoms/button/button";
import ArrowDownIcon from "@modul-connect/shared/img/ArrowDown.svg";
import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
import { WeekPicker } from "./pickers/weekPicker";
import { DayPicker } from "./pickers/dayPicker"
import { MonthPicker } from "./pickers/monthPicker";
import { CustomStartEndPicker } from "./pickers/customPicker";
import StartEndDatePicker from "../startEndDatePicker/startEndDatePicker";

const getTimespanPickerTitle = (timespan) => {
  return timespan === timespanOptions.day
    ? "DAY"
    : timespan === timespanOptions.month
    ? "MONTH"
    : timespan === timespanOptions.week
    ? "WEEK"
    : timespan === timespanOptions.year
    ? "YEAR"
    : "CUSTOM";
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.grey[300],
    ...theme.applyStyles("light", {
      color: "rgb(55, 65, 81)"
    }),
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const buttonStyle = {
  borderRadius: 0,
  backgroundColor: theme.colors.barBackground,

  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "15px",

  textTransform: "uppercase",

  color: theme.colors.labelText,

  "&:hover": {
    filter: "brightness(95%)",
  },
};

const selectedStyle = {
  backgroundColor: theme.colors.selectedGrey,
};

export const timespanOptions = {
  day: "day",
  week: "week",
  month: "month",
  year: "year",
  custom: "custom",
};

export const TimespanPicker = React.memo(({
  startTime,
  endTime,
  setSelectedStartTime,
  setSelectedEndTime,
  disabled,
  timespan,
  extendButton,
  hide,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };

  if (hide) {
    return (
      <Button
        extend={{
          ...buttonStyle,
          ...extendButton,
          cursor: 'normal',
          "&:hover": {
            filter: "brightness(100%)",
          },
        }}
        id="demo-customized-button"
        variant="contained"
      />
    );
  }

  if (timespan === timespanOptions.custom) { // old component, remove this once real one properly implemented
    return (
      <View>
        <StartEndDatePicker 
          noTitle
          startDate={startTime}
          endDate={endTime}
          onStartDatePicked={(date) => {
            setSelectedStartTime(date.startOf("day"))
            setSelectedEndTime(endTime.endOf("day")) // need to set end time, too, to trigger fetch
          }}
          onEndDatePicked={(date) => setSelectedEndTime(date.endOf("day"))}
        />
      </View>
    );
  }

  return (
    <React.Fragment>
      <Button
        extend={{
          ...buttonStyle,
          ...(open && selectedStyle),
          ...extendButton,
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={hide ? null : handleClick}
      >
        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          {getTimespanPickerTitle(timespan)}
          <img src={ArrowDownIcon}></img>
        </View>
      </Button>
      {
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {getCalendarComponent(timespan, startTime, endTime, setSelectedStartTime, setSelectedEndTime)}
        </StyledMenu>
      }
    </React.Fragment>
  );
});

const getCalendarComponent = (option, startTime, endTime, setSelectedStartTime, setSelectedEndTime) => {
  if (option === timespanOptions.year) {
    return <YearCalendar 
      value={startTime}
      disableFuture
      onChange={(value) => {
        const beginningOfYear = value.startOf('year')
        const endOfYear = value.endOf('year')
        setSelectedStartTime(beginningOfYear)
        setSelectedEndTime(endOfYear)
      }} 
    />;
  }
  if (option === timespanOptions.month) {
    return <MonthPicker
      startTime={startTime}
      setSelectedStartTime={(date) => setSelectedStartTime(date.startOf('month'))}
      setSelectedEndTime={(date) => setSelectedEndTime(date.endOf('month'))} />
  }
  if (option === timespanOptions.week) {
    return (
      <WeekPicker
        startDate={startTime}
        setSelectedStartTime={(date) => setSelectedStartTime(date.startOf('week'))}
        setSelectedEndTime={(date) => setSelectedEndTime(date.endOf('week'))} />
    );
  }

  if (option === timespanOptions.day) {
    return <DayPicker
      startTime={startTime}
      setSelectedStartTime={(date) => setSelectedStartTime(date.startOf('day'))}
      setSelectedEndTime={(date) => setSelectedEndTime(date.endOf('day'))}/>
  }
  
  /* if (option === timespanOptions.custom) {
    // new component (not entirely finished yet - put this back in once it is)
    return <CustomStartEndPicker
      startTime={startTime}
      endTime={endTime}
      setSelectedStartTime={(date) => setSelectedStartTime(date.startOf('day'))}
      setSelectedEndTime={(date) => setSelectedEndTime(date.endOf('day'))}/>
  } */
  return null;
};

