import { all } from 'redux-saga/effects'
// import {requestsHandler} from './requests';
import { themesHandler } from './themes'
import { userSagas } from './users'
import { appSagas } from './app'
import { vehicleSagas } from './vehicles'
import { customerSagas } from './customer'
import { mapSagas } from './map'
import { notificationsSagas } from './notifications'

export function* rootSaga() {
  yield all([
    // requestsHandler(),
    appSagas(),
    themesHandler(),
    userSagas(),
    vehicleSagas(),
    customerSagas(),
    mapSagas(),
    notificationsSagas()
  ])
}
