import React from 'react'
import OldButton from './button'
import theme from '../../../theme';
import { CircularProgress } from '@mui/material';
import { Icon } from '../icon/icon';

const styles = (noBg) => {return {
  basic: { 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '10px',
    textTransform: 'none',

    height: '40px',

    borderRadius: '4px',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',

    letterSpacing: '0.04em'
  },
  small: {
    //justifyContent: 'flex-start',
    //maxWidth: 'fit-content',
    height: 31,
    margin: 3,
    alignSelf:'center',
    color: theme.colors.midDarkGrey,
  },
  boxShadow: {
    boxShadow: '0px 0px 15px 0px #00000014'
  },
  disabled: {
    color: theme.colors.disabledText
  }
}}

const Button = ({
  children,
  extend,
  disabled,
  onClick,
  noBg,
  boxShadow,
  small,
  border,
  icon,
  processing,
  color
}) => {
  return (
    <OldButton
      onClick={onClick}
      backgroundColor={noBg ? 'transparent' : (color ?? theme.colors.buttonBackground)}
      color={noBg ? theme.colors.midDarkGrey : theme.colors.textLight}
      extend={{
        ...styles(noBg).basic,
        ...(small ? styles(noBg).small : {}),
        ...(boxShadow ? styles(noBg).boxShadow : {}),
        ...((disabled || processing) ? styles(noBg).disabled : {}),
        ...(border ? { border: `1px solid #404040` } : {}),
        ...extend
      }}
      disabled={disabled || processing}
      noBg={noBg}
      noPadding>
        {processing ? <CircularProgress size={ small ? 20 : 25 } color='inherit'  /> : null}
        {children}
        {icon ? <Icon icon={icon}/> : null}
    </OldButton>
  )
}

export default Button