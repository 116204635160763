import React, {Component} from 'react';
import { useFela } from "react-fela";
import theme from "@modul-connect/shared/theme";

const Input = ({
  children,
  className,
  innerRef,
  as: As = "input",
  extend,
  autoFocus,
  ...props
}) => {

  const { css } = useFela(props);

  let defaultStyle = {
    backgroundColor: theme.colors.lightGrey,
    borderRadius: theme.layout.borderRadius,
    borderWidth: 0,
    padding: `${ theme.layout.grid }px ${ 1 * theme.layout.grid }px`,
    width: '100%',
    height: 35,
  };

  return (
    <As
      {...props}
      ref={innerRef}
      className={css(defaultStyle, extend) + (className ? " " + className : "")}
      type={props.type || 'text'}
      autoComplete="new-password"
    >
      {children}
    </As>
  )
}

export default Input;