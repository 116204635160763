import React from 'react'
import { connect } from 'react-redux'
import '../tollPassage.css'
import { DateTime } from 'luxon'
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';

const TollPassageTables = ({
  themes,
  onDownloadTollReport,
  onSummaryClicked,
  tableData,
  columns,
  tableTitle
}) => {
  const downloadTollReport = async (id) => {
    const [boxOrDriverId, date] = id.split('-')
    const [month, year] = date.split('_')
    const startDate = new Date(year, month - 1, 1)
    const endDate = new Date(year, month, 0, 23, 59, 59)
    onDownloadTollReport(boxOrDriverId, DateTime.fromJSDate(startDate), DateTime.fromJSDate(endDate))
  }

  return (
    <TableWidgetSeparated
      tableTitle={tableTitle}
      data={tableData}
      totalItems={tableData?.filter(entry => !entry.isDivider).length ?? 0}
      columns={columns}
      themes={themes}
      isSortable={false}
      pagination={false}
      onDownload={(_, id) => downloadTollReport(id)}
      onClickRow={onSummaryClicked}
      rowsOptions={tableData?.length}
    />

  )
}

const mapStateToProps = ({
  themes
}) => ({
  themes
})


export default connect(mapStateToProps)(TollPassageTables)