import React from 'react'
import View from "@modul-connect/shared/components/atoms/view";
import { DateTime } from "luxon";
import KpiRow from '@modul-connect/shared/components/molecules/kpiRow/kpiRow';
import { convertToSelectedUnit } from '../../../../../utils/unitConverter';
import { getWeightWarningColor } from '../../../../../utils/vehicleUtils';

const DrivingLogWeightKPI = ({
  drivingLog,
  isLoading,
  weightInfo,
}) => {

  return (
    <View style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '30px',
    }}>

        <KpiRow
          title={'Status'}
          bgColor={'white'}
          data={getGeneralKPIData(drivingLog, weightInfo)}
          isLoading={isLoading}
        />

        <KpiRow
          title={'Status'}
          bgColor={'white'}
          data={getOverweightKPIData1(weightInfo)}
          isLoading={isLoading}
        />
    </View >
  )
}

const getGeneralKPIData = (drivingLog, weightInfo) => {
  const drivingDistStr = convertToSelectedUnit(drivingLog?.distance, 1, 'distance')?.split(' ')
  const newStartTime = drivingLog?.startTime ? DateTime.fromISO(drivingLog.startTime) : null
  const newEndTime = drivingLog?.endTime ? DateTime.fromISO(drivingLog.endTime) : DateTime.now()
  const timeDiff = newStartTime && newEndTime ? newEndTime.diff(newStartTime, ['hours', 'minutes']) : null

  let data = []

  if (drivingDistStr[0] !== "") {
    data.push({
      title: "Driving distance",
      value: drivingDistStr[0] ? drivingDistStr[0] : '-',
      unit: drivingDistStr[1] ?? ''
    })
  }

  if (timeDiff) {
    data.push({
      title: "Duration",
      value: timeDiff ? timeDiff.toFormat("hh") + ':' +
        (timeDiff.minutes.toFixed(0) >= 10 ? timeDiff.minutes.toFixed(0) : '0' + timeDiff.minutes.toFixed(0)) : '-',
      unit: 'h'
    })
  }

  if (!weightInfo)
    return data

  data.push({
    title: "Total weight",
    value: weightInfo?.weightGross ?? '-',
    unit: 'kg'
  })

  data.push({
    title: "Total load",
    value: weightInfo?.netWeight ?? '-',
    unit: 'kg'
  })

  const capacityLeft = ((weightInfo?.maxGross ?? weightInfo?.maxGross === 0) && (weightInfo?.weightGross || weightInfo?.weightGross === 0)) ?
    weightInfo.maxGross - weightInfo.weightGross : null

  if (capacityLeft > 0) {
    data.push({
      title: "Capacity left",
      value: capacityLeft > 0 ? capacityLeft : '-',
      unit: capacityLeft > 0 ? 'kg' : null
    })
  }

  return data
}

const getOverweightKPIData1 = (weightInfo) => {
  let data = []

  if (!weightInfo)
    return data

  if (!isOverWeight(weightInfo)) {
    return data
  }

  const grossOverweight = ((weightInfo?.weightGross || weightInfo?.weightGross === 0) && (weightInfo?.maxGross || weightInfo?.maxGross === 0)) ?
    weightInfo.weightGross - weightInfo.maxGross
    : '-'

  const frontOverweight = ((weightInfo?.weightFront || weightInfo?.weightFront === 0) && (weightInfo?.maxFront || weightInfo?.maxFront === 0)) ?
    weightInfo.weightFront - weightInfo.maxFront
    : '-'

  const rearOverweight = ((weightInfo?.weightRear || weightInfo?.weightRear === 0) && (weightInfo?.maxRear || weightInfo?.maxRear === 0)) ?
    weightInfo.weightRear - weightInfo.maxRear
    : '-'

  data.push({
    title: 'Total overweight',
    header: (weightInfo?.ratioGross || weightInfo?.ratioGross === 0) ?
      (weightInfo.ratioGross * 100).toFixed() + "%" : '-',
    value: (weightInfo?.hasOverweightGross || weightInfo.gross?.ratio > 1) ? grossOverweight?.toFixed() : '-',
    unit: (weightInfo?.hasOverweightGross || weightInfo.gross?.ratio > 1) ? 'kg' : null,
    color: getWeightWarningColor(weightInfo.ratioGross * 100)
  })

  data.push({
    title: 'Front overweight',
    header: (weightInfo?.ratioFront || weightInfo?.ratioFront === 0) ? (weightInfo.ratioFront * 100).toFixed() + "%" : '-',
    value: (weightInfo?.hasOverweightFront || weightInfo.front?.ratio > 1) ? frontOverweight?.toFixed() : '-',
    unit: (weightInfo?.hasOverweightFront || weightInfo.front?.ratio > 1) ? 'kg' : null,
    color: getWeightWarningColor(weightInfo.ratioFront * 100)
  })

  data.push({
    title: 'Rear overweight',
    header: (weightInfo?.ratioRear || weightInfo?.ratioRear === 0) ? (weightInfo.ratioRear * 100).toFixed() + "%" : '-',
    value: (weightInfo?.hasOverweightRear || weightInfo.rear?.ratio > 1) ? rearOverweight?.toFixed() : '-',
    unit: (weightInfo?.hasOverweightRear || weightInfo.rear?.ratio > 1) ? 'kg' : null,
    color: getWeightWarningColor(weightInfo.ratioRear * 100)
  })

  return data
}

let isOverWeight = (weightInfo) => {
  if (weightInfo?.hasOverweightGross || weightInfo?.hasOverweightFront || weightInfo?.hasOverweightRear ||
    weightInfo.gross?.ratio > 1 || weightInfo.front?.ratio > 1 || weightInfo.rear?.ratio > 1) return true

  return false
}


export default DrivingLogWeightKPI