import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import theme from '@modul-connect/shared/theme';
import View from '@modul-connect/shared/components/atoms/view'
import { MobileTimePicker } from '@mui/x-date-pickers';

const StartEndTimePicker = ({ startTime, endTime, setStartTime, setEndTime }) => {
  // need these seperately to startTime and endTime so errors will be reported on the input field even if actual (reducer) time value not changed
  const [selectedStartTime, setSelectedStartTime] = React.useState(startTime)
  const [selectedEndTime, setSelectedEndTime] = React.useState(endTime)

  React.useEffect(() => {
    setSelectedStartTime(startTime)
  }, [startTime])

  React.useEffect(() => {
    setSelectedEndTime(endTime)
  }, [endTime])

  return (
    <ThemeProvider theme={theme.mui}>
        <MobileTimePicker
          ampm={false}
          label="Start Time"
          value={selectedStartTime}
          maxTime={endTime}
          onAccept={(value) => {
            setSelectedStartTime(value) // set value of TimePicker
            setStartTime(value)
          }}
        />
        <View style={{ height: '15px' }} />
        <MobileTimePicker
          ampm={false}
          label="End Time"
          value={selectedEndTime}
          minTime={startTime}
          onAccept={(value) => {
            setSelectedEndTime(value)
            setEndTime(value)
          }
          }
        />
    </ThemeProvider>
  )
}

export default StartEndTimePicker