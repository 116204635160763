import React, { Fragment } from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import theme from '../../../theme';
import { CustomTooltip } from '../../molecules/tooltip';
import chroma from 'chroma-js';
import DownloadFromCloud from '@modul-connect/shared/img/cloud_download.svg'

export const styles = {
  h1: ({ theme: { colors, layout } }) => ({
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "27px",
    color: colors.titleDarkGrey,
    padding: "0px",
    margin: 0,
  }),
  statusTitle: ({ theme: { colors, layout } }) => ({
    fontSize: 16,
    fontWeight: 300,
    fontFamily: "Inter",
    lineHeight: "19px",
    color: colors.midDarkGrey,
    margin: 0,
    paddingBottom: 10,
  }),
  statusValue: ({ theme: { colors, layout } }) => ({
    fontFamily: 'Inter',
    //textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: "19px",
    color:  colors.midDarkGrey,
    margin: 0,
    paddingTop: 10,
  }),
  simpleTableValue:({theme: { colors, layout }})=>({
    fontFamily: 'Inter',
    color: colors.tableText,
    fontStyle: 'normal',
    fontWeight: 400, // TODO: this looks MUCH thinner on the figma ...
    fontSize: '16px',
    lineHeight: '19px',
    marginTop:0,
    marginBottom:0,
  }),
  tableValue: ({theme: { colors, layout }})=>({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '22px',
    color: colors.text
  }),
  paperTitle: ({ theme: { colors, layout } }) => ({
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '19px',

    color: colors.titleDarkGrey,
    padding: 0,
    fontStyle: 'normal',
    margin: 0,
  }),
  tableLink: ({ theme: { colors } }) => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#3D5DAF',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }),
  reportTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: "Inter",
    lineHeight: "31.88px",
    color: "#000000",
    letterSpacing: 1,
    paddingLeft: "0px",
  }),
  SubTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "16px",
    fontWeight: 50,
    fontFamily: "Inter",
    lineHeight: "20px",
    color: theme.colors.midDarkGrey,
    paddingTop: "8px",
    paddingBottom: "6px",
    fontStyle: "normal",
  }),
  CardTitle: ({ theme: { colors, layout } }) => ({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: '29px',
    color: theme.colors.black,
  }),
  DatePickerTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 200,
    color: colors.midDarkGrey,
  }),
  noBorder: ({ theme: { colors, layout } }) => ({
    borderBottom: "0 !important",
    paddingBottom: "10%",
  }),
  h3: ({ theme: { colors, layout } }) => ({
    fontSize: "14px",
    color: colors.midDarkGrey,
    fontWeight: 400,
    padding: "0px",
  }),
  h4: ({ theme: { colors, layout } }) => ({
    fontSize: "14px",
    color: colors.text,
    fontWeight: 400,
    padding: "0px",
    margin: 0,
  }),
  h5: ({ theme: { colors, layout } }) => ({
    fontSize: "10px",
    textTransform: "uppercase",
    color: colors.grey,
    padding: "0px",
    margin: 0,
  }),
  p: ({ theme: { colors, layout, fontSize } }) => ({
    // fontSize: fontSize ? fontSize :'14px',
  }),
  p_divider: ({ theme: { colors, layout } }) => ({
    fontSize: "14px",
    marginTop: 10,
    lineHeight: 0,
    color: "#E9E9E9",
    textAlign: "left",
  }),
  p_small: ({ theme: { colors, layout } }) => ({
    fontSize: "12px",
  }),
  p_xlarge: ({ theme: { colors, layout } }) => ({
    fontSize: "18px",
  }),
  light_p: ({ theme: { colors, layout } }) => ({
    color: colors.white,
  }),
  highlight_p: ({ theme: { colors, layout } }) => ({
    color: colors.error,
  }),
  link: ({ theme: { colors, layout } }) => ({
    transition: "color 200ms ease-in-out",
    "&:hover": {
      color: colors.primary,
    },
  }),
  link_v2: ({ theme: { colors, layout } }) => ({
    color: colors.blue,
    transition: "color 200ms ease-in-out",
    padding: 0,
    margin: 0,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    height: 'fit-content',
    overflowWrap: 'break-word',
    maxWidth: '100%',

    "&:hover": {
      cursor: 'pointer',
      color: chroma(colors.blue).brighten().hex(),
    },
  }),
  link_v2_normalTextColor: ({ theme: { colors, layout } }) => ({
    color: colors.text_v2,
    "&:hover": {
      cursor: 'pointer',
      color: colors.text_v2,
    },
  }),
  disabled: ({ theme: { colors, layout } }) => ({
    color: colors.disabledText,
    transition: "none",
    "&:hover": {
      cursor: 'default',
      color: colors.disabledText,
    },
  }),
  titleWrapper: ({ theme: { layout, colors } }) => ({
    display: "flex",
    flexDirection: "row",
    "> div": {
      marginLeft: 4,
      marginTop: 2,
      display: "flex",
      flexDirection: "row",
      color: colors.primary,
      cursor: "pointer",
      "> p": {
        color: colors.primary,
        fontSize: 12,
      },
    },
  }),
  light: ({ theme: { colors, layout } }) => ({
    color: colors.white,
  }),
  underline: ({ theme: { colors, layout } }) => ({
    textDecorationLine: 'underline',
    textUnderlineOffset: '3px'
  }),
  a: ({ theme: { colors, layout, color } }) => ({
    fontSize: "14px",
    cursor: "pointer",
  }),
  large_text: {
    fontSize: "16px",
  },
  small_text: {
    fontSize: "11px",
  },
  noPadding: {
    padding: "0 !important",
    margin: "0 !important",
  },
  strong: {
    fontWeight: 800,
  },
  listItemTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.04em',
    color: theme.colors.listItemText,
    padding: 0,
    margin: 0,
  },
  inputText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: theme.colors.text
  },
  listItemValue: ({ theme: { colors, layout, color } }) => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.04em',
    padding: 0,
    margin: 0,
    color: colors.listItemText,
  }),
  modalSectionTitle: ({ theme: {colors} }) => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    color: colors.modalSectionTitle,
    margin: 0
  })
};

export const ModalSectionTitle = ({
  children,
  extend
}) => (
  <View as="h1" extend={[styles.modalSectionTitle, extend]}>{children}</View>
)

export const PaperTitle = ({
  text,
  noPadding,
  bold,
  extend,
}) => (
  <View as="h1" extend={[styles.paperTitle, noPadding && styles.noPadding, bold && { fontWeight: 500 }, extend]}>{text}</View>
)

export const StatusTitle = ({
  text,
}) => (
  <View as='h1' extend={[styles.statusTitle]}>{text}</View>
)
export const StatusValue = ({
  color,
  text,
}) => (
  <View as='h1' extend={[styles.statusValue, color && {color: color}]}>{text}</View>
)

export const TableLink = ({
  onClick,
  extend,
  children,
}) => {
  return <View as='h1' onClick={onClick} extend={[styles.tableLink, extend]}>{children}</View> 
}

export const SimpleTableValue = ({
  tooltip,
  noTooltip,
  text,
  maxOneLine,
  maxTwoLines,
  extend
}) => {
  const content = maxOneLine ? <div className={"text-ellipsis--1"}>{text}</div>
  : maxTwoLines ? <div className={"text-ellipsis--2"}>{text}</div> : text
  return (
      !noTooltip
      ? 
      (
        <CustomTooltip title={tooltip ?? text}>
          <View as='h1' extend={[styles.simpleTableValue, extend]}>{content}</View>
        </CustomTooltip>
      )
      :
        <View as='h1' extend={[styles.simpleTableValue, extend]}>{content}</View>
  )
}

export const TableValue = ({
  text,
}) => (
  <View as='h1' extend={[styles.tableValue]}>{text}</View>
)

export const InfoText = ({
  children,
  color,
  extend
}) => (
  <View style={{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
    color: color ?? theme.colors.midDarkGrey,
    display: 'flex',
    flexWrap: "wrap",
    ...extend
  }}>
  {children}
  </View>
)

export const H1 = ({
  text,
  extend,
  noPadding
}) => (
  <View as="h1" extend={[styles.h1, noPadding && styles.noPadding, extend]}>{text}</View>
)

export const ReportTitle = ({
  extend,
  children,
  noPadding
}) => (
  <View extend={[styles.reportTitle, extend, noPadding && styles.noPadding]}>{children}</View>
)

export const SubTitle = ({
  extend,
  children,
  title,
  noBorder,
  noPadding
}) => (
  <View extend={[styles.SubTitle, extend, noBorder && styles.noBorder, noPadding && styles.noPadding]}>
    <View style={{ flex: 1 }}>{title}</View>
    {children}
  </View>
)

export const CardTitle = ({extend, noPadding, children}) => {
  return (
    <View extend={[styles.CardTitle, extend, noPadding && styles.noPadding]}>{children}</View>
  )
}

export const DatePickerTitle = ({
  extend,
  children,
  title1,
  title2,
  noBorder,
  noPadding
}) => (
  <View extend={[styles.DatePickerTitle, extend, noBorder && styles.noBorder, noPadding && styles.noPadding]}>
    <View style={{ flex: 1 }}>{title1}<br/> {title2}</View>
    {children}
  </View>
)

export const H3 = ({
  children,
  noPadding,
  light,
  linkTitle,
  linkIcon,
  onClickLink,
  color,
  extend,
}) => (
  <View as="h3" extend={[
    styles.h3,
    noPadding && styles.noPadding,
    light && styles.light,
    linkTitle && styles.titleWrapper,
    color && { color: color },
    extend
  ]}>
    {children}
    {linkTitle && (
      <View onClick={() => onClickLink()}>
        {linkIcon}
        <P strong noPadding>{linkTitle.toUpperCase()}</P>
      </View>
    )}
  </View>
)

export const H4 = ({
  children,
  extend
}) => (
  <View as="h4" extend={[styles.h4, extend]}>
    {children}
  </View>
)

export const H5 = ({
  noPadding,
  children,
  extend
}) => (
  <View as="h5" extend={[styles.h5, noPadding && styles.noPadding, extend]}>
    {children}
  </View>
)

export const P = ({
  children,
  extend,
  large,
  light,
  strong,
  style,
  link,
  link_v2,
  noPadding,
  highlight,
  divider,
  small,
  color
}) => (
  <View as="p" extend={[
    small && styles.p_small,
    divider && styles.p_divider,
    styles.p,
    light && styles.light_p,
    large && styles.large_text,
    link && styles.link, 
    link_v2 && styles.link_v2,
    highlight && styles.highlight_p,
    strong && styles.strong,
    noPadding && styles.noPadding,
    color && { color: color },
    style,
    extend
  ]} children={children} />
)

export const Link = ({
  children,
  onClick,
  extend,
  strong,
  underline,
  icon,
  iconPosition='before',
  disabled,
  gap,
  normalTextColor
}) => {
  const textComponent = <View as="a" onClick={!disabled ? onClick : () => {}} extend={[styles.link_v2, strong && styles.strong, underline && styles.underline, normalTextColor && styles.link_v2_normalTextColor, disabled && styles.disabled, extend]}>{children}</View>

  if (!icon) return textComponent

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: gap ?? 10,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: 'fit-content',
      }}
      onClick={onClick}
    >
      {iconPosition === 'before' ? icon : null}
      {textComponent}
      {iconPosition === 'after' ? icon : null}
    </View>
  )
}

export const ListItemTitle = ({
  extend,
  children
}) => (
  <View as="p" extend={[styles.listItemTitle, extend]} children={children} />
)

export const ListItemValue = ({
  extend,
  children
}) => (
  <View as="p" extend={[styles.listItemValue, extend]} children={children} />
)

export const A = ({
  text,
  children,
  large,
  small,
  extend,
  ...props
}) => (
  <View as="a" {...props} extend={[styles.a, extend, large && styles.large_text, small && styles.small_text]}>{text || children}</View>
)

export const Span = ({
  extend,
  children,
  fontFamily,
  ...props
}) => (
  <View as="span" {...props} extend={[extend, fontFamily]} >{children}</View>
)

export const DownloadLink = ({ url, text, noIcon }) => {
  return (
    <a
      href={url}
      style={{	cursor: 'pointer', maxWidth: '100%'}}
    >
      <View style={{display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'space-between'}}>
        <Link v2>{text}</Link>
        {noIcon ? null : <img src={DownloadFromCloud} style={{maxWidth: 24, maxHeight: 24,}}/>}
      </View>
    </a>
  )
}

const bulletPoint = '\u2022 '
const style_bulletPointLine = { display: 'flex', flexDirection: 'row' }
export const TextWithBulletPoints = ({textColor, text, bulletPoints}) => {

  return <View style={{ display: 'flex', flexDirection: 'column' }}>
    <P color={textColor}>{text}</P>
    {
      bulletPoints?.map(bp => {
        if (bp) return (
          <View style={style_bulletPointLine}>
            <P color={textColor}>{bulletPoint}</P>
            <P color={textColor}>{bp}</P>
        </View>
        )
      })
    }
  </View>
}