const user_columns = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 100,
      hide: []
    },
    {
      id: 'translatedTags',
      label: 'Organisation',
      minWidth: 100,
      hide: ['mobile']
    },
    {
      id: 'email',
      label: 'Email',
      minWidth: 100,
      hide: ['tablet', 'mobile']
    },
  ];

  export default user_columns;