import React from "react"
import View from '@modul-connect/shared/components/atoms/view';

export const ClickableWrapper = ({children, onClick}) => {
  return <View extend={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }} onClick={onClick}>
    {children}
  </View>
}