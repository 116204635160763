import View from "../view";
import React from "react";
import { CSSTransition } from "react-transition-group";
import useComponentVisible from "@modul-connect/shared/hooks/useComponentVisible";
import './dropdown.css'

const Dropdown = ({ styles, data, title }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(null);
  
  return (
    <View extend={[styles]}>
      <div ref={ref}>
        <a onClick={() => setIsComponentVisible(!isComponentVisible)}>{ title }</a>

        <CSSTransition
          in={ isComponentVisible }
          timeout={100}
          unmountOnExit
          classNames="dropdown"
        >
          <View extend={style.dropdownContainer}>
            {
              data.map((item, index) =>
                <View key={index} onMouseDown= {item.onClick}>
                  <View extend={ style.item }>
                    {item.label}
                  </View>
                </View>
              )
            }
          </View>
        </CSSTransition>
      </div>
    </View>
  );
};

const style = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  dropdownContainer: ({ theme: { colors, layout } }) => ({
    position: 'absolute',
    top: layout.navBarHeight,
    right: 0,
    width: 120,
    backgroundColor: 'rgba(0,0,0,0)',
    zIndex: 100
  }),
  containerSmall: {
    top: 39,
  },
  item: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'rgba(0,0,0,0.73)',
    borderBottomLeftRadius: layout.borderRadius,
    borderBottomRightRadius: layout.borderRadius,
    color: colors.white,
    padding: `${1 * layout.grid }px ${2 * layout.grid }px`,
    cursor: 'pointer',
    transition: "color 200ms ease-in-out",
    "&:hover": {
      filter: 'brightness(110%)'
    }
  }),
}

export default Dropdown;