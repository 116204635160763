import { useEffect } from "react";

export const useLoadingTimer = (onSuccess) => {
  useEffect(() => {
    let isMounted = true

    setTimeout(() => {
      if(isMounted) {

        onSuccess(true)
      }
    }, 2000)

    return () => { isMounted = false }
  }, [onSuccess])
};
