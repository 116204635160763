export const log_columns = [
  {
    id: "homebase",
    label: "Homebase",
    hide: [],
    align: "left",
  },
  {
    id: "equipments",
    label: "Equipments",
    hide: [],
    align: "left",
  },
  {
    id: "maintenance",
    label: "Maintenance",
    hide: [],
    align: "left",
  },
];

