import { getLocStatusTagColorName } from "../../../utils/vehicleUtils";

export const log_columns = [
  {
    id: 'endTimeString',
    label: 'Unlocked',
    width: 120,
    hide: [],
    align: 'left'
  },
  {
      id: 'startTimeString',
      label: 'Locked',
      width: 120,
      hide: [],
      align: 'left'
  },
  {
      id: 'vehicle',
      label: 'Vehicle',
      width: 250,
      hide: [],
      align: 'center'
  },
  {
      id: 'status',
      label: 'Status',
      isTag: true,
      hasGuard: true,
      tagColor: getLocStatusTagColorName,
      textColor: 'black',
      hide: []
  },
]