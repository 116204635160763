import { getWeightWarningTagColorName } from "../../../../utils/vehicleUtils"

export const log_columns = [
    {
        id: 'startTimeString',
        label: '',
        width: 100,
        hide: ['mobile', 'tablet'],
        align: 'left'
    },
    {
        id: 'startAddress',
        label: 'From',
        labelAlign: 'left',
        minWidth: 100,
        hide: ['mobile', 'tablet'],
        colSpan: 2,
    },
    {
        id: 'endTimeString',
        label: '',
        width: 100,
        hide: ['mobile'],
        align: 'left'
    },
    {
        id: 'endAddress',
        label: 'To',
        labelAlign: 'left',
        minWidth: 100,
        hide: ['mobile'],
        colSpan: 2,
    },
    {
        id: 'distance',
        label: 'Distance',
        hide: [],
        align: 'center'
    },
    {
        id: 'vehicle',
        label: 'Vehicle',
        hide: [],
        align: 'center'
    },
    {
        id: 'purpose',
        label: 'Purpose',
        // isTag: true,
        tagColor: (purpose) => {
          return purpose
        },
        //minWidth: 100,
        hide: []
    },
    {
        id: 'weight_kg',
        label: 'Overweight (kg)',
        // isTag: true,
        // tagColor: (weight_percent) => {
        //   if(typeof(weight_percent) === 'undefined') return false

        //   if(weightString.indexOf('%') !== -1) {
        //     try {
        //       let percent_overweight = parseInt(weightString.split(' ')[2].substring(1, bob.length - 1))
              
        //       if(percent_overweight >= 107) return 'issue'
        //       if(percent_overweight >= 10) return 'warning'
        //       if(percent_overweight >= 107) return 'primary'
        //     } catch {
        //       return false
        //     }
        //   }
        // },
        minWidth: 120,
        hide: []
    },
    {
        id: 'weight_percent',
        label: 'Overweight (%)',
        isTag: true,
        textColor: 'black',
        tagColor: (weight_percent) => {
          if(typeof(weight_percent) === 'undefined') return false

          if(weight_percent.indexOf('%') !== -1) {
            try {
              let percent_overweight = parseInt(weight_percent.split(' ')[0])

              return getWeightWarningTagColorName(percent_overweight)

            } catch {
              return false
            }
          }
        },
        minWidth: 150,
        hide: []
    },
    // {
    //     id: 'startMileage',
    //     label: 'Mileage (Start)',
    //     minWidth: 100,
    //     hide: ['mobile', 'tablet']
    // },
    // {
    //     id: 'endMileage',
    //     label: 'Mileage (End)',
    //     minWidth: 100,
    //     hide: ['mobile']
    // },
]