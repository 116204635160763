
import React from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import SideBar from "@modul-connect/shared/components/organisms/side-bar";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "../dashboard.css";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { showFilterBar } from "../../../utils/app"

const sidebarWidth = 336
const transitionType = "out-in";

const HideableSidebar = ({
  children,
  themes,
  showSidebar,
  setShowSidebar,
  depth,
  hideOpenCloseButton
}) => {
  const isMobile = themes.device === 'mobile'
  const getStyles = () => { return styles(depth, isMobile) }

  return (
    <SwitchTransition mode={transitionType} >
    <CSSTransition
      key={showSidebar}
      addEndListener={(node, done) => {
        node.addEventListener('transitionend', done, false);
      }}
      timeout={100}
      classNames={isMobile ? 'fade-mobile' : 'fade'}
    >
    <SideBar
      showFilterBar={showFilterBar}
      themes={themes}
      extend={
        showSidebar ?
          (isMobile ? getStyles().sidemenu_showing_mobile : getStyles().sidemenu_showing) :
          (isMobile ? getStyles().sidemenu_hiding_mobile : getStyles().sidemenu_hiding)
      }
    >
      { hideOpenCloseButton ? null :
        <View
          extend={[
            getStyles().button_hideSidebar, isMobile ?
              getStyles().button_mobile : 
              getStyles().button
          ]}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          {
            showSidebar ?
              (isMobile ? <MdKeyboardArrowDown /> : <MdKeyboardArrowLeft />) :
              (isMobile ? <MdKeyboardArrowUp /> : <MdKeyboardArrowRight />)
          }
        </View>
      }
      {
        children
      }
    </SideBar>
    </CSSTransition>
    </SwitchTransition>

  )
}

const styles = (depth, isMobile) => {
  const zIndex = isMobile ? 
    10 + (depth ?? 0) : 
    10 - (depth ?? 0)
  return {
    sidemenu_showing: ({ theme: { layout } }) => ({
      width: depth ? sidebarWidth * 1.1 : sidebarWidth,
      left: (depth ?? 0) * sidebarWidth,
      paddingRight: 24,
      paddingLeft: 24,
      zIndex: zIndex,
      "> .sideMenuContainer": {
        // paddingTop: layout.navBarHeight + layout.navigationBarHeight,
        height: '100%',
        paddingBottom: 40,
        display: 'flex',
        flexDirection: 'column'
      }
    }),
    sidemenu_hiding: {
      width: 0,
      "> .sideMenuContainer": {
        display: 'none'
      },
      zIndex: zIndex
    },
    sidemenu_showing_mobile: ({ theme: { layout } }) => ({
      width: '100%',
      height: '50%',
      paddingRight: 16,
      paddingLeft: 16,

      padding: '20px 20px',
      borderRadius: '10px 10px 0px 0px',
      zIndex: zIndex,
      "> .sideMenuContainer": {
        height: '100%',
       // paddingBottom: 40,
        display: 'flex',
        flexDirection: 'column'
      }
    }),
    sidemenu_hiding_mobile: {
      top: '100%',
      height: 0,
      width: '100%',
      zIndex: zIndex,
      "> .sideMenuContainer": {
        display: 'none'
      }
    },
    button_hideSidebar: ({ theme: { colors, layout } }) => ({
      paddingRight: 2,
      paddingLeft: 2,
      display: 'flex',
      alignItems: 'center',
      color: colors.grey,
      backgroundColor: colors.white,
      position: 'absolute',
      cursor: 'pointer',
      borderTop: '1px solid rgb(225, 225, 225)',
      borderRight: '1px solid rgb(225, 225, 225)'
    }),
    button: ({ theme: { colors, layout } }) => ({
      height: '70px',
      width: 20,
      borderRadius: '0px 4px 4px 0px',
      left: '100%',
      top: isMobile ? '10%' : ((showFilterBar() ? layout.filterBarHeight : 0) + 15 + (depth * 15)) + '%',
      // boxShadow: '8px 2px 5px 3px rgba(0,0,0,.05)',
      borderBottom: '1px solid rgb(225, 225, 225)',
    }),
    button_mobile: {
      width: '60%',
      height: '20px',
      borderRadius: '10px 10px 0px 0px',
      top: '-20px',
      left: '20%',
      justifyContent: 'center'
    },
  };
}

const mapStateToProps = ({
  themes,
}) => ({
  themes,
})

export default connect(mapStateToProps)(HideableSidebar);