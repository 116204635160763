import React, { useRef } from 'react'
import View from '@modul-connect/shared/components/atoms/view'
import { DateTime } from "luxon";
import { DayPickerInput } from "@modul-connect/shared/components/molecules/daySelector/DayPickerInput"
import theme from '@modul-connect/shared/theme';
import { Title } from '@modul-connect/shared/components/molecules/dropdown/dropdown'
import './daySelector-bottom.css'
import './daySelector-top.css'
import './daySelector-middle.css'


export const DateSelector = ({
  disableDaysBefore,
  disableDaysAfter,
  placeholder,
  date,
  onDayPicked,
  disabled,
  isRequired,
  title,
  opensOn='top'
}) => {
  const inputRef = useRef(null);


  return (
    <View style={{ display: 'flex', flexDirection: 'column' }}>
      <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        {isRequired ? <View style={{ color: theme.colors.inputTextGrey }}>*</View> : null}
        <Title>{title}</Title>
      </View>
      <View 
        className={
          opensOn === 'top' ? "DaySelector-top" 
          : opensOn === 'bottom' ? "DaySelector-bottom" 
          : "DaySelector-middle"
        }
        style={{
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          width: '100%',
          justifyContent: 'space-between',
          border: `1px solid ${theme.colors.uiBorderColor}`,
          borderRadius: 4,
          padding: '0px 10px' }}
        onClick={() => { 
          if (inputRef?.current) inputRef.current.focus()
        }}>
          <DayPickerInput 
            disableDaysBefore={disableDaysBefore}
            disableDaysAfter={disableDaysAfter}
            disabled={disabled}
            date={date}
            onDayPicked={onDayPicked}
            placeholder={placeholder}
          />
      </View>
    </View>
  )
}

