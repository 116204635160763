import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { convertToSelectedUnit } from "../../../utils/unitConverter";
import { selectVehicle } from "../../../state/actions/vehicles";
import { VehicleListItemStatusRowV2 } from "./vehicleListItemStatusRowV2";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { ListItemV2 } from "@modul-connect/shared/components/molecules/listitem/listItemV2";
import View from "@modul-connect/shared/components/atoms/view";
import { H3, P } from "@modul-connect/shared/components/atoms/text";
import theme from "@modul-connect/shared/theme";
import {ArmedVehicle} from "@modul-connect/shared/components/molecules/armedVehicle";

export const VehicleListItem = ({
  vehicle,
  vehicle_animation_status,
  selectVehicle,
  selectedVehicle,
  map_settings,
  beacons,
  loading,
  securitySystemStatus,
  currentDriver,
}) => {
  const animationStatus = vehicle
    ? vehicle_animation_status[vehicle.boxId]
    : null;
  const speed =
    (animationStatus?.speed ?? 0) < 2
      ? ""
      : `${convertToSelectedUnit(parseFloat(animationStatus.speed), 1, "speed")}`;

  const scrollRef = useRef(null);

  useEffect(() => {
    if (
      selectedVehicle.boxId === vehicle.boxId &&
      selectedVehicle.shouldScroll
    ) {
      executeScroll();
    }
  }, [selectedVehicle]);

  const executeScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };
  const stringToColour = (str) => {
    let hash = 0;
    str.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }

    return colour;
  };
  return (
    <ListItemV2
      extend={{display: "flex"}}
      key={vehicle.boxId}
      onClick={() => {
        if (vehicle.disabled) return;
        selectVehicle(
          vehicle.boxId,
          false, // scroll to selected list item
          true, // deselect if already selected
          false,
          true // zoomToVehicle,
        );
      }}
      isSelected={!vehicle.disabled && selectedVehicle?.boxId === vehicle.boxId}
      isDisabled={vehicle.disabled}
      rightSideComponent={
          loading.fetchBeaconSnapshots !== "loading" ? (
            <View extend={{ display: "flex", flex: 1, flexDirection: "row", height: "100%" }}>
              <View
                extend={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <H3 noPadding>{vehicle.name}</H3>
                <VehicleListItemStatusRowV2
                  vehicle={vehicle}
                  beacons={beacons}
                  map_settings={map_settings}
                  securitySystemStatus={securitySystemStatus}
                />
              </View>
              <View
                extend={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <View
                  extend={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <H3 noPadding extend={{ textAlign: "right" }}>
                    {(speed && map_settings.isLive)
                      ? speed
                      : ""}
                  </H3>
                </View>
                <ArmedVehicle map_settings={map_settings} securitySystemStatus={securitySystemStatus} />
              </View>
            </View>
          ) : (
            <BubbleLoadingWidget horizontal={true} extend={{ height: 20 }} />
          )
      }
      userIcon={
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: 24,
            width: 24,
            borderRadius: 12,
            backgroundColor: !currentDriver
              ? "#6C6C6C"
              : currentDriver === "ME"
              ? "#542E5E"
              : stringToColour(currentDriver.toString()),
          }}
        >
          {" "}
          <P
            small
            extend={{
              display: "flex",
              flex: 1,
              color: theme.colors.white,
              textAlign: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
            noPadding
          >
            {currentDriver ? currentDriver : "?"}
          </P>
        </div>
      }
      scrollRef={scrollRef}
    />
  );
};

const mapStateToProps = ({
  vehicle_animation_status,
  selectedVehicle,
  map_settings,
  beacons,
  loading,
}) => ({
  vehicle_animation_status,
  selectedVehicle,
  map_settings,
  beacons,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  selectVehicle: (
    boxId,
    shouldScroll,
    deselectIfAlreadySelected,
    zoomToRoutes,
    zoomToVehicle
  ) =>
    dispatch(
      selectVehicle(
        boxId,
        shouldScroll,
        deselectIfAlreadySelected,
        zoomToRoutes,
        zoomToVehicle
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleListItem);
