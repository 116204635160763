import { call, takeEvery, put, take, fork, select, takeLatest, delay, race } from 'redux-saga/effects'
import { eventChannel, END } from "redux-saga"
import { customerCommandServerUrl, customerServerUrl, assetsCommandServerUrl, endUserServerUrl } from '@modul-connect/shared/config'
import * as signalR from '@microsoft/signalr';
import { get, post, patch, upload} from '../../utils/ajax';
import { DateTime } from 'luxon';
import fileDownload from 'js-file-download';
import { climateSensorReportTypeOptions, fetchVehicleRoutes, fetchWarningLogs } from '../actions/vehicles';
import { getStore } from '../configureStore';
import { sec } from "@modul-connect/shared/auth0/auth0Helper"
import { homebaseIdIsUnknown } from '../../utils/assets';
import { shortenStringByEllipsis } from '../../utils/text';

function* fetchLPSReportSummaries ({ accessToken, start, end }) {
  if (!start || !end) {
    yield put({ type: 'FETCHING_LPS_REPORT_SUMMARIES_FAILED', reason: 'missing properties' })
    return
  }

  if (!accessToken) {
    yield put({ type: 'FETCHING_LPS_REPORT_SUMMARIES_FAILED', reason: 'missing access token' })
    return
  }

  let url = `${customerServerUrl()}/report/lps/v2/${start}/${end}`
  const response = yield get(accessToken, url)

  const responseData = response?.data

  if (response?.status === 'no_content' || responseData && !responseData?.length) {
    yield put({ type: 'LPS_REPORT_SUMMARIES_FETCHED', data: [] })
    return
  }
  else if(!response) {
    yield put({ type: 'FETCHING_LPS_REPORT_SUMMARIES_FAILED' })
    return
  }
  else yield put({ type: 'LPS_REPORT_SUMMARIES_FETCHED', data: responseData })
}

function* fetchLPSChargingData ({ accessToken, boxId, start, end }) {
  if (!start || !end) {
    yield put({ type: 'FETCHING_LPS_CHARGING_DATA_FAILED', reason: 'missing properties' })
    return
  }

  if (!start) {
    yield put({ type: 'FETCHING_LPS_CHARGING_DATA_FAILED', reason: 'missing properties' })
    return
  }

  if (!accessToken) {
    yield put({ type: 'FETCHING_LPS_CHARGING_DATA_FAILED', reason: 'missing access token' })
    return
  }

  let url = `${customerServerUrl()}/report/lps/v2/chargingData/${boxId}/${start}/${end}`
  const response = yield get(accessToken, url)

  if (response?.status === 'no_content' || response?.data && !response.data?.length) {
    yield put({ type: 'LPS_CHARGING_DATA_FETCHED', boxId: boxId, data: [] })
  }
  else if(!response) {
    yield put({ type: 'FETCHING_LPS_CHARGING_DATA_FAILED' })
    return
  }
  else yield put({ type: 'LPS_CHARGING_DATA_FETCHED', boxId: boxId, data: response?.data })
}


function* fetchLPSFootprint ({ accessToken, start, end }) {
  if (!start || !end) {
    yield put({ type: 'FETCHING_LPS_FOOTPRINT_FAILED', reason: 'missing properties' })
    return
  }

  if (!accessToken) {
    yield put({ type: 'FETCHING_LPS_FOOTPRINT_FAILED', reason: 'missing access token' })
    return
  }

  let url = `${customerServerUrl()}/report/lps/v2/footprint/${start}/${end}`
  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_LPS_FOOTPRINT_FAILED' })
    return
  }
  else yield put({ type: 'LPS_FOOTPRINT_FETCHED', data: response?.data })
}

//////////////////////////////       helper functions       ///////////////////////////////
function* fetchBatteryReportFromServer ({ accessToken, start, end, boxId }) {
  let params = []

  let url = `${customerServerUrl()}/report/battery/v2`
  if (start || end || boxId) {
    url += '?'
    if(start)
      params.push('start=' + start)
    if(end)
      params.push('end=' + end)
    if(boxId)
      params.push('boxId=' + boxId)
    url += params.join('&')
  }

  return yield get(accessToken, url)
}

function* fetchTemperatureReportFromServer ({ accessToken, start, end, boxId }) {
  let params = []

  let url = `${customerServerUrl()}/report/temperature/permissions-filtered`
  if (start || end || boxId) {
    url += '?'
    if(start)
      params.push('start=' + start)
    if(end)
      params.push('end=' + end)
    if(boxId)
      params.push('boxId=' + boxId)
    url += params.join('&')
  }

  return yield get(accessToken, url)
}

function* fetchAssetTrackerReportFromServer ({ accessToken, start, end, boxId }) {
  let params = []

  let url = `${customerServerUrl()}/BeaconsMissing` // TODO: replace with asset tracker warning report enpoint, once implemented
  if (start || end || boxId) {
    url += '?'
    if(start)
      params.push('start=' + start)
    if(end)
      params.push('end=' + end)
    if(boxId)
      params.push('boxId=' + boxId)
    url += params.join('&')
  }

  return yield get(accessToken, url)
}

function* fetchHumidityReportFromServer ({ accessToken, start, end, boxId }) {
  let params = []

  let url = `${customerServerUrl()}/report/humidity/permissions-filtered`
  if (start || end || boxId) {
    url += '?'
    if(start)
      params.push('start=' + start)
    if(end)
      params.push('end=' + end)
    if(boxId)
      params.push('boxId=' + boxId)
    url += params.join('&')
  }

  return yield get(accessToken, url)
}


function* fetchSecuritySummaryFromServer ({ accessToken, start, end }) {
  let params = []

  let url = `${customerServerUrl()}/report/security/summary`
  if (start || end ) {
    url += '?'
    if(start)
      params.push('start=' + start)
    if(end)
      params.push('end=' + end)
    url += params.join('&')
  }

  return yield get(accessToken, url)
}

function* fetchVehiclesSecurityFromServer ({ accessToken, start, end, boxId }) {
  let params = []

  let url = `${customerServerUrl()}/report/security`
  if (start || end ) {
    url += '?'
    if(start)
      params.push('start=' + start)
    if(end)
      params.push('end=' + end)
    if(boxId)
      params.push('boxId=' + boxId)
    url += params.join('&')
  }

  return yield get(accessToken, url)
}

function* fetchOverweightReportFromServer ({ accessToken, start, end, boxId }) {
  const urlParams = boxId ? 
    (end ? {start, end, boxId} : {start, boxId} ) :
    (end ? {start, end} : {start} )
  const url = `${customerServerUrl()}/report/overweight/v2/?${
      Object.keys(urlParams).map(k => `${k}=${urlParams[k]}`).join('&')
  }`

  return yield get(accessToken, url)
}

function* fetchTyrePressureReportFromServer ({ accessToken, start, end, boxId }) {
  const urlParams = boxId ? 
    (end ? {start, end, boxId} : {start, boxId} ) :
    (end ? {start, end} : {start} )
  const url = `${customerServerUrl()}/report/tyre-pressure/?${
      Object.keys(urlParams).map(k => `${k}=${urlParams[k]}`).join('&')
  }`

  return yield get(accessToken, url)
}

function* fetchRouteFromServer ({ accessToken, start, end, boxId, skip }) {
  const map_settings = yield select(state => state.map_settings)

  let url = customerServerUrl() + '/driving-log-lab-2/geo-location-v3'
  
  if (boxId) {
    url += '?box=' + boxId
    url += '&start=' + start
    url += !end ? '' : '&end=' + end
  }
  else if (start) {
    url += '?start=' + start
    url += !end ? '' : '&end=' + end
  }
  else {
    return null
  }
  if (skip) {
    url += '&skip=' + skip
  }
  const fetchResult = yield get(accessToken, url)
  const fetchedData = fetchResult?.data?.displayedLogs ?? []

  try {
    return {
      ...fetchedData,
      data: fetchedData?.map(d => {
        return {
          ...d,
          logs: d.logs?.map(log => {return {
            longitude: log[0],
            latitude: log[1]
          }})
        }
      })
    }
  }
  catch {
    console.error('Could not convert the fetched data. fetchResult:', fetchResult)
  }
}

function* fetchRouteDetailedFromServer ({ accessToken, start, end, boxId, skip }) {
  let url = customerServerUrl() + '/driving-log/route-logs'
  if (boxId) {
    url += '?box=' + boxId
    url += '&start=' + start
    url += !end ? '' : '&end=' + end
  }
  else if (start) {
    url += '?start=' + start
    url += !end ? '' : '&end=' + end
  }
  else {
    return null
  }
  if (skip) {
    url += '&skip=' + skip
  }
  return yield get(accessToken, url)
}

//////////////////////////////////////////////////////////////////////////////////////////

function* getVehicle({ accessToken, boxId }) {
  if (!accessToken || !boxId) {
    console.error('Cannot get vehicle from server. Missing info. AccessToken:', accessToken, ', boxId:', boxId)
    yield put({ type: 'FETCHING_VEHICLE_FAILED' }) // TODO: handle this somewhere
    return
  }

  let url = customerServerUrl() + '/telematics/filtered_vehicles?boxId=' + boxId
  const response = yield get(accessToken, url)

  let url_snapshot = customerServerUrl() + '/telematics/snapshot/allowed_vehicle?boxId=' + boxId

  const snapshotResponse = yield get(accessToken, url_snapshot)

  if (response?.status === 'no_content' || !response.data?.total || response?.status === 'forbidden') {
    yield put({ type: 'VEHICLE_FETCHED', data: null, status: response?.status })
    if(response?.status === 'forbidden')
      yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'You are not allowed to view this vehicle.'})
  }
  else if(!response || !response.data || !response.data?.boxes?.length) {
    yield put({ type: 'FETCHING_VEHICLE_FAILED' })
    return
  }

  const responseObj = {
    ...response.data?.boxes?.[0],
    boxId: response.data?.boxes?.[0].mainbox_id,
    name: response.data?.boxes?.[0].vehicleName
  }
  yield put({ type: 'VEHICLE_FETCHED', data: snapshotResponse?.data?.length ? {
    ...snapshotResponse?.data[0],
    ...responseObj,
  } : responseObj, status: response?.status })
}

function* getVehicles({ accessToken }) {
  let url = customerServerUrl() + '/telematics/snapshot/allowed_vehicle'

  const response = yield get(accessToken, url)

  if (response?.status === 'no_content') {
    yield put({ type: 'VEHICLES_FETCHED', data: [] })
    return
  }
  if(!response || !response.data) {
    yield put({ type: 'FETCHING_VEHICLES_FAILED' })
    return
  }

  yield put({ type: 'VEHICLES_FETCHED', data: response.data })
}

function* getBeaconSnapshot({ accessToken }) {
  let url = customerServerUrl() + '/telematics/snapshot/allowed-beacons'

  const response = yield get(accessToken, url)

  if(!response || !response.data || response.status !== 'ok') {
    yield put({ type: 'FETCHING_BEACON_SNAPSHOTS_FAILED' })
    return
  }

  yield put({ type: 'BEACON_SNAPSHOTS_FETCHED', data: response.data })
}

function* getVehiclesData({ accessToken }) {
  let url = customerServerUrl() + '/vehicles'
  const response = yield get(accessToken, url)

  if(!response || !response.data) {
    yield put({ type: 'FETCHING_VEHICLES_DATA_FAILED' })
    return
  }

  yield put({ type: 'VEHICLES_DATA_FETCHED', data: response.data })
}

function* updateVehicleName ({ accessToken, vehicleId, newName }) {
  let url = customerCommandServerUrl() + '/cmd/vehicle/updatename'
  
  const command = {
    'vehicleName': newName,
    'id': 'vehicle/' + vehicleId 
  }

  let result = yield post (accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      yield put({ type: 'VEHICLE_NAME_UPDATED', vehicleId: vehicleId })
    }
    else {
      yield put({ type: 'VEHICLE_NAME_UPDATE_FAILED', vehicleId: vehicleId })
    }
  }

}

function* getVehiclesSorted({ accessToken, options }) {
  let url = customerServerUrl() + '/telematics/filtered_vehicles?count=' + options.rowsPerPage +
    "&page=" + options.currentpage +
    "&sortby=" + options.sort +
    (options.boxId ? "&boxId=" + options.boxId : '') +
    (options.searchStr ? "&filter=" + options.searchStr : '')
  const response = yield get(accessToken, url)

  if (response?.status === 'no_content') {
    response.data = {total: 0, boxes: []}
  }
  else if(!response || !response.data) {
    yield put({ type: 'FETCHING_VEHICLES_SORTED_FAILED' })
    return
  }
  yield put({ type: 'VEHICLES_FETCHED_SORTED', data: response.data })
}

function* getVehicleRoutes({ accessToken, startEpoch, endEpoch, start, end, skip, boxId }) {
  const response = yield fetchRouteFromServer({ accessToken: accessToken, start: startEpoch, end: endEpoch, boxId: boxId, skip })
  
  if(!response) {
    yield put({ type: 'FETCHING_VEHICLE_ROUTES_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_VEHICLE_ROUTES_FAILED' })
    return
  }
  yield put({ type: 'FETCHED_VEHICLE_ROUTES', data: response.data, start, end, total: response.data.length, skip })
}

function* getVehicleRoutesDetailed({ accessToken, startEpoch, endEpoch, start, end, skip }) {
  const response = yield fetchRouteDetailedFromServer({ accessToken: accessToken, start: startEpoch, end: endEpoch, boxId: null})  
  if(!response) {
    yield put({ type: 'FETCHING_VEHICLE_ROUTES_DETAILED_FAILED', startEpoch, endEpoch })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_VEHICLE_ROUTES_DETAILED_FAILED', startEpoch, endEpoch})
    return
  }
  
  if(response && response?.data) {
    yield put({
      type: 'FETCHED_VEHICLE_ROUTES_DETAILED',
      data: response.data,
      start,
      end,
      startEpoch,
      endEpoch,
      total: response.data.length,
      skip,
      isDetailed: true
    })
  }
  
}

function* getVehiclePositions({ accessToken, startEpoch, endEpoch, start, end }) {
  let url = customerServerUrl() + '/log/allowed-geo-location'
  if (startEpoch) {
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
  }

  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_VEHICLE_POSITIONS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_VEHICLE_POSITIONS_FAILED' })
    return
  }

  yield put({ type: 'FETCHED_VEHICLE_POSITIONS', data: response.data, start, end, total: response.data.length })

}

function* getLatestVehiclePositions({ accessToken, start, end, startEpoch, endEpoch }) {
  let url = customerServerUrl() + '/driving-log-lab-2/allowed-last-known-geo-location-fast'
  if (startEpoch) {
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
  }

  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_LAST_KNOWN_POSITIONS_FAILED' })
    return
  }

  let responseData = {}

  if(response && response.status === 'no_content') {
    // do nothing, responseData can remain {}
  } else if(!response.data?.latestLogs) {
    yield put({ type: 'FETCHING_LAST_KNOWN_POSITIONS_FAILED' })
    return
  }
  else responseData = response.data.latestLogs

  const preparedData = {}
  Object.keys(responseData).map(logBoxId => {
    preparedData[logBoxId] = {
      boxId: logBoxId,
      course: responseData[logBoxId]?.c,
      isPrivate: responseData[logBoxId]?.isPriv,
      latitude: responseData[logBoxId]?.lat,
      longitude: responseData[logBoxId]?.lon,
      telematicsId: responseData[logBoxId]?.["t-Id"],
      timestamp: responseData[logBoxId]?.ts,
      hasWeightWarning: responseData[logBoxId]?.["ov-we"]
    }
  })

  yield put({ type: 'FETCHED_LAST_KNOWN_POSITIONS', data: preparedData, end: end })
}

function* getVehicleWeights({ accessToken, startEpoch, endEpoch, start, end, id, samplingInterval }) {
  let url = customerServerUrl() + '/log/weight-system'
  if (startEpoch) {
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
    url += (!id ? '' : '&box=' + id)
    url += !samplingInterval ? '' : '&samplingInterval=' + samplingInterval
  }

  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_VEHICLE_WEIGHTS_FAILED', boxId: id })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_VEHICLE_WEIGHTS_FAILED', boxId: id })
    return
  }

  yield put({ type: 'VEHICLE_WEIGHTS_FETCHED', samplingInterval, data: response.data, boxId: id, start, end, total: response.data.length })
}

function* getVehicleBatteryLogs({ accessToken, startEpoch, endEpoch, start, end, id, samplingInterval }) {
  let url = customerServerUrl() + '/log/battery'
  if (startEpoch) {
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
    url += !id ? '' : '&box=' + id
    url += !samplingInterval ? '' : '&samplingInterval=' + samplingInterval
  }

  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_VEHICLE_BATTERY_LOGS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_VEHICLE_BATTERY_LOGS_FAILED' })
    return
  }

  yield put({ type: 'VEHICLE_BATTERY_LOGS_FETCHED', data: response.data, start, end, total: response.data.length })
}

function* getVehicleTemperatureLogs({accessToken, startEpoch, endEpoch, start, end, id, samplingInterval}){
  let url = customerServerUrl() + '/log/thermometer'
  if(startEpoch){
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
    url += !id ? '' : '&box=' + id
    url += !samplingInterval ? '' : '&samplingInterval=' + samplingInterval
  }

  const response = yield get(accessToken, url)

  if(!response){
    yield put({ type: 'FETCHING_VEHICLE_TEMPERATURE_LOGS_FAILED'})
    return
  }

  if( response && response.status === 'no_content'){
    response.data = []
  }else if(!response.data){
    yield put({ type: 'FETCHING_VEHICLE_TEMPERATURE_LOGS_FAILED'})
    return
  }

    yield put({ type: 'VEHICLE_TEMPERATURE_LOGS_FETCHED', data: response.data, start, end, total: response.data.length})
}

function* getVehicleHumidityLogs({accessToken, startEpoch, endEpoch, start, end, id, samplingInterval}){
  let url = customerServerUrl() + '/log/humidity'
  if(startEpoch){
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
    url += !id ? '' : '&box=' + id
    url += !samplingInterval ? '' : '&samplingInterval=' + samplingInterval
  }

  const response = yield get(accessToken, url)

  if(!response){
    yield put({ type: 'FETCHING_VEHICLE_HUMIDITY_LOGS_FAILED'})
    return
  }

  if( response && response.status === 'no_content'){
    response.data = []
  }else if(!response.data){
    yield put({ type: 'FETCHING_VEHICLE_HUMIDITY_LOGS_FAILED'})
    return
  }

    yield put({ type: 'VEHICLE_HUMIDITY_LOGS_FETCHED', data: response.data, start, end, total: response.data.length})
}

function* getLpsBatteryLogs({ accessToken, startEpoch, endEpoch, start, end, id, samplingInterval }) {
  let url = customerServerUrl() + '/log/lithiumPowerSupply'
  if (startEpoch) {
    url += '?start=' + startEpoch
    url += !endEpoch ? '' : '&end=' + endEpoch
    url += !id ? '' : '&box=' + id
    url += !samplingInterval ? '' : '&samplingInterval=' + samplingInterval
  }

  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_LPS_BATTERY_LOGS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_LPS_BATTERY_LOGS_FAILED' })
    return
  }

  yield put({ type: 'LPS_BATTERY_LOGS_FETCHED', data: response.data, start, end, total: response.data.length })
}

function* getBatteryReport({ accessToken, start, end, boxId }) {
  const response = yield fetchBatteryReportFromServer({ accessToken, start, end, boxId })

  if(!response) {
    yield put({ type: 'FETCHING_BATTERY_REPORT_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_BATTERY_REPORT_FAILED' })
    return
  }

  yield put({ type: 'BATTERY_REPORT_FETCHED', data: response.data, start, end, boxId })

}

function* getAssetTrackerReport({ accessToken, start, end, boxId }) {
  const response = yield fetchAssetTrackerReportFromServer({ accessToken, start, end, boxId })

  if(!response) {
    yield put({ type: 'FETCHING_ASSET_TRACKER_REPORT_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_ASSET_TRACKER_REPORT_FAILED' })
    return
  }

  yield put({ type: 'ASSET_TRACKER_REPORT_FETCHED', data: response.data, start, end, boxId })
}

function* getTemperatureReport({ accessToken, start, end, boxId })
{
  const response = yield fetchTemperatureReportFromServer({ accessToken, start, end, boxId })

  if(!response) {
    yield put({ type: 'FETCHING_TEMPERATURE_REPORT_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_TEMPERATURE_REPORT_FAILED' })
    return
  }

  yield put({ type: 'TEMPERATURE_REPORT_FETCHED', data: response.data, start, end, boxId })
}

function* getHumidityReport({ accessToken, start, end, boxId })
{
  const response = yield fetchHumidityReportFromServer({ accessToken, start, end, boxId })

  if(!response) {
    yield put({ type: 'FETCHING_HUMIDITY_REPORT_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_HUMIDITY_REPORT_FAILED' })
    return
  }

  yield put({ type: 'HUMIDITY_REPORT_FETCHED', data: response.data, start, end, boxId })
}

function* updateDrivingLogNote({ accessToken, boxId, driverId, logId, telematicsId, startTime, note, previousNote, purpose }) {
  const body = {
    'telematicsId': telematicsId,
    'startTime': DateTime.fromSeconds(startTime),
    'note': note
  }
 
  const result = yield post(accessToken, customerCommandServerUrl() + '/cmd/log/add-note-to-driving-log', body)

  if (result?.status === 'ok') {
    yield put({ type: 'DRIVING_LOG_NOTE_UPDATED', telematicsId: telematicsId, note: note, logId: logId, boxId: boxId, driverId: driverId, purpose: purpose })

    if (!previousNote && note) yield put({ type: 'NOTE_ADDED_TO_LOG', telematicsId: telematicsId, note: note, logId: logId, boxId: boxId, driverId: driverId, purpose: purpose })
    if (previousNote && !note) yield put({ type: 'NOTE_REMOVED_FROM_LOG', telematicsId: telematicsId, note: note, logId: logId, boxId: boxId, driverId: driverId, purpose: purpose })

    yield put({ type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Note updated'})
  } else {
    yield put({ type: 'DRIVING_LOG_NOTE_UPDATE_FAILED', telematicsId: telematicsId, logId: logId, boxId: boxId, driverId: driverId, purpose: purpose })
    yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to update'})
  }
}

function* getDrivingLogDetails({ accessToken, start, end, boxId }) {
  const route_response = yield fetchRouteFromServer({ accessToken, start, end, boxId })

  if(!route_response) {
    yield put({ type: 'FETCHING_DRIVING_LOG_DETAILS_FAILED', start, end, boxId })
    return
  }

  if(route_response.status === 'no_content') {
    if (route_response.status === 'no_content')
    route_response.data = []  
  } else if(!route_response.data) {
    yield put({ type: 'FETCHING_DRIVING_LOG_DETAILS_FAILED', start, end, boxId })
    return
  }
  yield put({ 
    type: 'DRIVING_LOG_DETAILS_FETCHED', 
    data: route_response.data,
    start, 
    end ,
    boxId,
  })
}

function* getOverweightReport({ accessToken, start, end, boxId }) {
  const response = yield fetchOverweightReportFromServer({ accessToken, start, end, boxId })

  if(!response) {
    yield put({ type: 'FETCHING_OVERWEIGHT_REPORT_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_OVERWEIGHT_REPORT_FAILED' })
    return
  }

  yield put({ type: 'OVERWEIGHT_REPORT_FETCHED', data: response.data, start, end })
}

function* getTyrePressureReport({ accessToken, start, end, boxId }) {
  const response = yield fetchTyrePressureReportFromServer({ accessToken, start, end, boxId })

  if(!response) {
    yield put({ type: 'FETCHING_TYRE_PRESSURE_REPORT_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_TYRE_PRESSURE_REPORT_FAILED' })
    return
  }

  yield put({ type: 'TYRE_PRESSURE_REPORT_FETCHED', data: response.data, start, end })
}

function* getDrivingLogs({ accessToken, boxId, driverId, deviceType, start, end, }) {

  let combined_driving_logs = []

  let url = customerServerUrl() + '/driving-log/logs'
  url += !boxId ? '' : '?box=' + boxId
  url += !driverId ? '' : `${boxId ? '&' : '?' }driver=` + driverId
  url += !deviceType ? '' : '&deviceType=' + deviceType
  url += !start ? '' : '&start=' + start
  url += !end ? '' : '&end=' + end
  url += '&portal=true'

  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_DRIVING_LOGS_FAILED', boxId: boxId })
    yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'There was an issue fetching the driving logs from the server.' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_DRIVING_LOGS_FAILED', boxId: boxId })
    return
  }

  combined_driving_logs.push(...response.data)
  

  combined_driving_logs = combined_driving_logs.sort((a, b) => {
    let dt_a = DateTime.fromISO(a.startTime)
    let dt_b = DateTime.fromISO(b.startTime)

    return dt_b.toMillis() - dt_a.toMillis()
  })

  yield put({ type: 'DRIVING_LOGS_FETCHED', boxId: boxId, driverId: driverId, data: combined_driving_logs, start, end, total: combined_driving_logs.length })
}

function* downloadDrivingLogs({accessToken, boxId, driverId, deviceType, start, end, groupBy}) {
  if (!driverId && !boxId) yield put({ type: 'DOWNLOADING_DRIVING_LOGS_FAILED', boxId, start, end })
  let url = customerServerUrl() + '/driving-log/download-logs'
  url += !boxId ? (!driverId ? '' : '?driver=' + driverId) : '?box=' + boxId
  url += !start ? '' : '&start=' + start
  url += !end ? '' : '&end=' + end
  url += !groupBy ? '' : '&groupBy=' + groupBy
  url += !driverId ? '' : '&driver=' + driverId

  const response = yield download(url, accessToken, `driving-logs${groupBy === 'vehicle' ? (boxId ? `-${boxId}` : '') : (driverId ? `-${driverId}` : '')}.xlsx`)

  if(!response) {
    yield put({ type: 'DOWNLOADING_DRIVING_LOGS_FAILED', boxId, start, end })
    yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'Download failed'})
    return
  }

  yield put({ type: 'DRIVING_LOGS_DOWNLOADED', boxId, start, end})
}

function* getDrivingLogSummaries({ accessToken, start, end, groupBy}) {  
  let url = customerServerUrl() + '/driving-log/summary-logs'
  url += (!start || !end) ? '' : '?start=' + start + '&end=' + end
  url += !groupBy ? '' : '&groupBy=' + groupBy
  
  const response = yield get(accessToken, url)
        
  if(!response) {
    yield put({ type: 'FETCHING_DRIVING_LOG_SUMMARIES_FAILED' })
    return
  }
  
  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_DRIVING_LOG_SUMMARIES_FAILED' })
    return
  }

  yield put({ type: 'DRIVING_LOG_SUMMARIES_FETCHED', data: response.data, start, end })
}

function* getOverweightReportSummaries({ accessToken, start, end, desc }) {
  let url = customerServerUrl() + '/overweight/summary/v2'
  url += (!start || !end) ? '' : '?start=' + start + '&end=' + end
  if (desc) url += '&desc=true'

  const response = yield get(accessToken, url)
        
  if(!response) {
    yield put({ type: 'FETCHING_OVERWEIGHT_REPORT_SUMMARIES_FAILED' })
    return
  }
  
  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_OVERWEIGHT_REPORT_SUMMARIES_FAILED' })
    return
  }

  yield put({ type: 'OVERWEIGHT_REPORT_SUMMARIES_FETCHED', data: response.data, start, end })
}

function* downloadClimateReports({accessToken, vehicle, start, end, samplingInterval, avgOverMinutes, typeOfReport}) {
  var type = typeOfReport === climateSensorReportTypeOptions.temperature ? 'thermometer': 'humidity'
  let url = customerServerUrl() + '/log/'+ type +'/download'
  url += '?box=' + (vehicle?.boxId ?? null)
  url += '&start=' + start
  url += '&end=' + end
  url += !samplingInterval ? '' : '&samplingInterval=' + samplingInterval 
  url += !avgOverMinutes ? '' : '&avgOverMinutes=' + avgOverMinutes 

  var boxId = vehicle?.boxId
  var nameOfFile = typeOfReport === climateSensorReportTypeOptions.temperature ? 'Temprature-logs': 'Humidity-logs'
  var startTime = new Date(0); 
  startTime.setUTCSeconds(start);
  var from = startTime.getFullYear().toString() + '/' + startTime.getMonth().toString() + '/' + startTime.getDate().toString()

  var endTime = new Date(0); 
  endTime.setUTCSeconds(end);
  var to = endTime.getFullYear().toString() + '/'+ endTime.getMonth().toString() + '/' + endTime.getDate().toString()
  
  const response = yield download(url, accessToken, `${nameOfFile}` + `${vehicle.name ? `-${vehicle.name}` : ''}` + `-${from}` + `-${to}.xlsx`)

  if(!response) {
    yield put({ type: 'DOWNLOADING_CLIMATE_LOGS_FAILED', boxId, start, end, typeOfReport })
    return
  }

  yield put({ type: 'CLIMATE_LOGS_DOWNLOADED', boxId, start, end, typeOfReport })

}

function* getWarningLogs({ accessToken, boxId, start, end, warning_type }) {
  const response = yield fetchWarningLogsFromServer({accessToken, boxId, start, end, warning_type})
    
  if(!response || (response.status !== 'ok' && response.status !== 'no_content' )) {
    yield put({ type: 'FETCHING_WARNING_LOGS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = {
      overweightWarnings: [],
      batteryGuardWarnings: [],
      temperatureWarnings: [],
      humidityWarnings: []
    }
  } else if(!response.data) {
    yield put({ type: 'FETCHING_WARNING_LOGS_FAILED' })
    return
  }

  if (!response.data.overweightWarnings) response.data.overweightWarnings = []
  if (!response.data.batteryGuardWarnings) response.data.batteryGuardWarnings = []
  if (!response.data.temperatureWarnings) response.data.temperatureWarnings = []
  if (!response.data.humidityWarnings) response.data.humidityWarnings = []

  const total_length = (
    response.data.overweightWarnings.length +
    response.data.batteryGuardWarnings.length + 
    response.data.temperatureWarnings.length +
    response.data.humidityWarnings.length
  )

  yield put({ type: 'WARNING_LOGS_FETCHED', data: response.data, start, end, total: total_length, boxId: boxId })
}

function* fetchWarningLogsFromServer({ accessToken, boxId, start, end, warning_type }) {
  let url = customerServerUrl() + '/warning-log/allowed'
  url += !boxId ? '' : '?box=' + boxId
  url += !start ? '' : (boxId ? '&' : '?' ) + 'start=' + start
  url += !end ? '' : (boxId || start ? '&' : '?' ) + 'end=' + end
  url += !warning_type ? '' : (boxId || start || end ? '&' : '?') + 'type=' + warning_type
  return yield get(accessToken, url)
}

function* fetchTollReports({accessToken, boxId, driverId, start, end}) {

  const response = yield fetchTollReportsFromServer({ accessToken, boxId, driverId, start, end })
    
  if(!response || response.status !== 'ok') {
    yield put({ type: 'FETCHING_TOLL_REPORTS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_TOLL_REPORTS_FAILED' })
    return
  }

  yield put({ type: 'TOLL_REPORTS_FETCHED', data: response.data, start, end, total: response.data.length, boxId, driverId })
}

function* fetchTollReportsFromServer({ accessToken, boxId, driverId, start, end }) {
  let url = customerServerUrl() + '/toll-passage/v2'
  url += '?newestFirst=' + true
  url += !start ? '' : '&start=' + start
  url += !end ? '' : '&end=' + end
  url += !boxId ? '' : '&box=' + boxId
  url += !driverId ? '' : '&user=' + driverId
  
  return yield get(accessToken, url)
}

function* fetchTollReportSummaries({accessToken, boxIds, start, end, groupBy}) {
  if (boxIds) {
    console.warn('fetching warning logs for specific boxIDs not implemented yet')
  }
  if (!start || !end) {
    console.warn('fetching warning logs without start/end timestamps not properly implemented yet')
  }
  else {
    let url = customerServerUrl() + '/toll-passage/v2/monthly-summary'
    url += '?newestFirst=' + true
    url += !start ? '' : '&start=' + start
    url += !end ? '' : '&end=' + end
    url += !groupBy ? '' : '&groupBy=' + groupBy
    const response = yield get(accessToken, url)
    
    if(!response) {
      yield put({ type: 'FETCHING_TOLL_REPORT_SUMMARIES_FAILED' })
      return
    }

    if(response && response.status === 'no_content') {
      response.data = []
    } else if(!response.data) {
      yield put({ type: 'FETCHING_TOLL_REPORT_SUMMARIES_FAILED' })
      return
    }

    yield put({ type: 'TOLL_REPORT_SUMMARIES_FETCHED', data: response.data, start, end })
  }
}

function* downloadTollReport({accessToken, boxId, userId, start, end, groupBy}) {
  let url = customerServerUrl() + '/toll-passage/v2/download'
  url += !boxId ? '' : '?box=' + boxId
  url += !start ? '' : (boxId ? '&' : '?' ) + 'start=' + start
  url += !end ? '' : ((start || boxId) ? '&' : '?' ) + 'end=' + end
  url += !userId ? '' : ((boxId || start || end) ? '&' : '?') + 'user=' + userId
  url += !groupBy ? '' : ((boxId || start || end || userId) ? '&' : '?') + 'groupBy=' + groupBy

  const response = yield download(url, accessToken, `toll-report${boxId ? `-${boxId}` : userId ? `-${userId}` : ''}.xlsx`)

  if(!response) {
    yield put({ type: 'DOWNLOADING_TOLL_REPORTS_FAILED', boxId, start, end })
    return
  }

  yield put({ type: 'TOLL_REPORTS_DOWNLOADED', boxId, start, end })
}

function* download (url, accessToken, name) {
  return yield new Promise((accept, reject) => {
    var req = new XMLHttpRequest()
    req.open('GET', url, true)

    req.responseType = 'arraybuffer'
    req.setRequestHeader('Authorization', 'Bearer ' + accessToken)

    req.onload = _ => {
        var resp = req.response
        if (req.statusText !== 'OK') reject('Server response: ' + req.status +  ' ' + req.statusText)
        if(resp) accept(resp)
    };

    req.onerror = event => {
      console.warn(event)
      reject(event)
    }

    req.send(null);
  })
  .then(data => {
    if (data.byteLength === 0) return
    fileDownload(data, name)
    return data
  })
  .catch(err => console.warn('catcherrorGetImage', err))
}

function* getMileage({ accessToken, boxId }) {
  let url = customerServerUrl() + '/driving-log/current-mileage/'
  url += !boxId ? '' : '?box=' + boxId;
  
  const response = yield get(accessToken, url)

  if(!response) yield put({ type: 'FETCHING_MILEAGE_FAILED' })
  else if (response.status === 'no_content') yield put({ type: 'NO_MILEAGE', boxId: boxId })
  else yield put({ type: 'MILEAGE_FETCHED', data: response.data, boxId: boxId })
}

function* updateMileage({ accessToken, boxId, mileage, timestampEpochs }) {
  let url = customerServerUrl() + '/driving-log/calibrate-mileage/'

  const body = {
      'boxId': boxId,
      'mileage': mileage,
      'timestampEpochs': timestampEpochs,
    }
  
  let result = yield post(accessToken, url, body)

  if (result && result.status) {
    if (result.status === 'ok'){
      yield put({ type: 'MILEAGE_UPDATED' })
    }
    else {
      yield put({ type: 'MILEAGE_UPDATE_FAILED' })
    }
  }
}

function* setLicenseNumber({ accessToken, boxId, licenseNumber }) {
  let url = `${customerCommandServerUrl()}/cmd/vehicle/${boxId}/licenseNumber`

  const body = {
      'licenseNumber': licenseNumber,
  }
  
  let result = yield post(accessToken, url, body)

  if (result && result.status) {
    if (result.status === 'ok'){
      yield put({ type: 'LICENSE_NUMBER_UPDATED', boxId: boxId })
    }
    else {
      yield put({ type: 'LICENSE_NUMBER_UPDATE_FAILED', boxId: boxId })
    }
  }
}

function* getCurrentDrivers({ accessToken, boxId }) {
  let url = customerServerUrl() + '/driving-log/get-allowed-current-driver'
  url += !boxId ? '' : '?' + boxId;
  
  const response = yield get(accessToken, url)
        
  if(!response) {
    yield put({ type: 'FETCHING_CURRENT_DRIVERS_FAILED' })
    return
  }
  
  if(response && response.status === 'no_content') {
    response.data = {}
  } else if(!response.data) {
    yield put({ type: 'FETCHING_CURRENT_DRIVERS_FAILED' })
    return
  }

  yield put({ type: 'CURRENT_DRIVERS_FETCHED', data: response.data, total: Object.keys(response.data).length })
}

function* getAssetTrackers({accessToken, byVehicle}) {
  let url = customerServerUrl() + '/report/asset/with-no-address'
  url += byVehicle ? '?byVehicle=true' : '?byVehicle=false'
  
  const response = yield get(accessToken, url)

  if(!response) {
    yield put({type: 'FETCHING_ASSET_TRACKERS_FAILED', response: response})
    return
  }

  if(response && response.status === 'no_content'){
    response.data = []
  }
  else if (!response?.data){
    yield put({type:'FETCHING_ASSET_TRACKERS_FAILED', data: []})
    return
  }

  yield put({type: 'ASSET_TRACKERS_FETCHED', data: response.data})
}

function* getAssetTrackerAddresses({accessToken, boxId})
{
  let url = customerServerUrl() + '/report/asset/address-for-beacons/' + boxId

  const response = yield get(accessToken, url)

  if(!response){
    yield put({type: 'FETCHING_ASSET_TRACKER_ADDRESSES_FAILED', response: response, boxId: boxId})
    return
  }

  if(response && response.status === 'no_content'){
    response.data = []
  }
  else if(!response.data){
    yield put({type: 'FETCHING_ASSET_TRACKER_ADDRESSES_FAILED', response: response, boxId: boxId})
    return
  }

  yield put({type: 'ASSET_TRACKER_ADDRESSES_FETCHED', data: response.data.beacons, boxId: boxId})
}

function* getTrackerAddress({accessToken, beaconId})
{
  let url = customerServerUrl() + '/report/asset/address/' + beaconId

  const response = yield get(accessToken, url)

  if(!response){
    yield put({type: 'FETCHING_TRACKER_ADDRESS_FAILED', response: response, beaconId: beaconId})
  }

  if(response && response.status === 'no_content'){
    response.data = []
  }
  else if(!response.data){
    yield put({type: 'FETCHING_TRACKER_ADDRESS_FAILED', response: response, beaconId: beaconId})
    return
  }

  yield put({type: 'TRACKER_ADDRESS_FETCHED', data: response.data, beaconId: beaconId})
}

function* designateCurrentDriver ({ accessToken, boxId, dates = null, startTimeUTC = null, endTimeUTC = null, driver }) {
  let url = customerCommandServerUrl() + '/cmd/vehicle/designatedriver'
  
  const command = {
    'vehicleId': boxId,
    'dates': dates,
    'driver': driver,
    'startTimeUTC': startTimeUTC,
    'endTimeUTC': endTimeUTC,
    'id': 'vehicle/' + boxId 
  }

  let result = yield post (accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      yield put({ type: 'CURRENT_DRIVER_DESIGNATED' })
    }
    else {
      yield put({ type: 'CURRENT_DRIVER_DESIGNATION_FAILED' })
    }
  }
}

function* updateTrackerInfo({accessToken, assetId, name, oldName, category, oldCategory, boxId, vin, locationType, beaconId, groupBy}) {
  let url = customerCommandServerUrl() + '/cmd/beacon/' + beaconId
  let body = {
    name: shortenStringByEllipsis(name, 15),
    category: category,
    boxId: boxId
  }

  const resutlt = yield patch(accessToken, url, body)

  if(resutlt.status !== 'ok')
  {
    yield put({type: 'UPDATE_TRACKER_FAILED', beaconId: beaconId, name: name, oldName:oldName, category: category, oldCategory: oldCategory, boxId: boxId, groupBy: groupBy})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to update'})
    return
  }

  let urlAsset = assetsCommandServerUrl() + '/cmd/2/UpdateAsset'

  if (assetId) {
    const commandAsset = {
      "assetName": name,
      "category": category,
      "homebaseId": vin,
      "id": assetId,
      "locationType": locationType,
      "trackerId": beaconId
    }
    
    let result = yield post(accessToken, urlAsset, commandAsset)

    if (result.status != 'ok') {
      yield put({type: 'UPDATE_TRACKER_FAILED', beaconId: beaconId, name: name, oldName:oldName, category: category, oldCategory: oldCategory, boxId: boxId, groupBy: groupBy})
      yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to update'})
      return
    }
  }

  yield put({ type: 'UPDATE_TRACKER_SUCCEEDED', beaconId: beaconId, name: name, oldName: oldName, category: category, oldCategory: oldCategory, boxId: boxId,  groupBy: groupBy})
}

export function* updateTrackersOwner({accessToken, name, category, boxId, vin, oldOwner, beaconId, groupBy, vehicle, assetId, locationType}) {
  if (assetId) {
    let urlAsset = assetsCommandServerUrl() + '/cmd/2/UpdateAsset'

    const commandAsset = {
      "assetName": name,
      "category": category,
      "homebaseId": vin,
      "id": assetId,
      "locationType": locationType,
      "trackerId": beaconId,
    }

    let result = yield post(accessToken, urlAsset, commandAsset)

    if (result.status != 'ok') {
      yield put({type: 'UPDATE_TRACKER_OWNER_FAILED', beaconId: beaconId, name: name, oldOwner: oldOwner, category: category, boxId: boxId, groupBy: groupBy, vehicle: vehicle})
      yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to update'})
      return
    }
  }

  // else do the old thing from when we only had beacons

  let url = customerCommandServerUrl() + '/cmd/beacon/' + beaconId
  let body = {
    name: shortenStringByEllipsis(name, 15),
    category: category,
    boxId: boxId
  }

  const resutlt = yield patch(accessToken, url, body)

  if(resutlt.status !== 'ok')
  {
    yield put({type: 'UPDATE_TRACKER_OWNER_FAILED', beaconId: beaconId, name: name, oldOwner:oldOwner, category: category, boxId: boxId, groupBy: groupBy, vehicle: vehicle}) 
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to update'})
    return
  }

  yield put({ type: 'UPDATE_TRACKER_OWNER_SUCCEEDED', beaconId: beaconId, name: name, oldOwner: oldOwner, category: category, boxId: boxId, groupBy:groupBy, vehicle: vehicle})

}

function* updateTrackerNotification({accessToken, trackers, isEnabled}) {
  let url = customerCommandServerUrl() + '/cmd/beacon/notification' 

  let body = {
    beaconIds: trackers,
    isEnabled: isEnabled
  }

  const result = yield post(accessToken, url, body)
  if (result.status !== 'ok')
    yield put({type: 'UPDATE_NOTIFICATIONS_ON_TRACKERS_FAILED', beaconIds: trackers, isEnabled: isEnabled})

  yield put({type: 'UPDATE_NOTIFICATIONS_ON_TRACKERS_SUCCEEDED', beaconIds: trackers, isEnabled: isEnabled}) 
}

function* fetchMaintenanceTasks({accessToken, homebase})
{
  let url 
  if(homebase)
    url = customerServerUrl() + '/asset-maintenance/2/tasks?homebase=' + homebase 
  else
    url = customerServerUrl() + '/asset-maintenance/2/tasks'

  const result = yield get(accessToken, url)
  
  if(!result || (result.status !== 'ok' && result.status != 'no_content'))
  {
    yield put({type: 'FETCHING_MAINTENANCE_TASKS_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch maintenance tasks'})
    return
  }
  else if(  result.status === 'no_content')
  {
    yield put({type: 'FETCHING_MAINTENANCE_TASKS_SUCCESSFUL', data: [], homebase: homebase})
    return
  }

  yield put({type: 'FETCHING_MAINTENANCE_TASKS_SUCCESSFUL', data: result.data?.filter(d => !homebaseIdIsUnknown(d?.homebaseId)), homebase: homebase})
}

function* fetchMaintenanceTasksSummary({accessToken}) {
  const url = customerServerUrl() + '/asset-maintenance/2/tasks-summary'
  const result = yield get(accessToken, url)
 
  if(!result || (result.status !== 'ok' && result.status != 'no_content'))
  {
    yield put({type: 'FETCHING_MAINTENANCE_TASKS_SUMMARY_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch maintenance tasks'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'FETCHING_MAINTENANCE_TASKS_SUMMARY_SUCCESSFUL', data: []})
    return
  }

  yield put({type: 'FETCHING_MAINTENANCE_TASKS_SUMMARY_SUCCESSFUL', data: result.data?.filter(d => !homebaseIdIsUnknown(d.homeBaseId))})
}

function* fetchMaintenancePlans({accessToken})
{
  const url = customerServerUrl() + '/asset-maintenance/plans'

  const result = yield get(accessToken, url)
  
  if(!result || (result.status !== 'ok' && result.status != 'no_content'))
  {
    yield put({type: 'FETCHING_MAINTENANCE_PLANS_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch maintenance plans'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'FETCHING_MAINTENANCE_PLANS_SUCCESSFUL', data: []})
    return
  }
 
  yield put({type: 'FETCHING_MAINTENANCE_PLANS_SUCCESSFUL', data: result.data})
}

function* fetchMaintenanceCategories({accessToken})
{
  const url = customerServerUrl() + '/asset-maintenance/categories'

  const result = yield get(accessToken, url)
  
  if(!result || (result.status !== 'ok' && result.status != 'no_content'))
  {
    yield put({type: 'FETCHING_MAINTENANCE_CATEGORIES_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch maintenance categories'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'FETCHING_MAINTENANCE_CATEGORIES_SUCCESSFUL', data: []})
    return
  }
 
 
  yield put({type: 'FETCHING_MAINTENANCE_CATEGORIES_SUCCESSFUL', data: result.data})
}

function* fetchAssetCategories({accessToken}) {
  const url = endUserServerUrl() + '/beacon-category/assets-categories'

  const result = yield get(accessToken, url)
  
  if(!result || (result.status !== 'ok' && result.status != 'no_content'))
  {
    yield put({type: 'FETCHING_ASSET_CATEGORIES_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch asset categories'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'FETCHING_ASSET_CATEGORIES_SUCCESSFUL', data: []})
    return
  }
 
 
  yield put({type: 'FETCHING_ASSET_CATEGORIES_SUCCESSFUL', data: result.data})
}


function* fetchHomebases({accessToken})
{
  const url = customerServerUrl() + '/asset-maintenance/2/homebases'

  const result = yield get(accessToken, url)
  
  if(!result || (result.status !== 'ok' && result.status != 'no_content'))
  {
    yield put({type: 'FETCHING_MAINTENANCE_HOMEBASES_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch maintenance homebases'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'FETCHING_MAINTENANCE_HOMEBASES_SUCCESSFUL', data: []})
    return
  }
 
 
  yield put({type: 'FETCHING_MAINTENANCE_HOMEBASES_SUCCESSFUL', data: result.data})
}

function* updateMaintenancePeriodComment({ accessToken, assetId, periodId, planId, newComment, oldComment }) {
  let url = assetsCommandServerUrl() + '/cmd/UpdateMaintenancePeriod'
  const command = {
    "assetId": assetId,
    "maintenancePlanId": planId,
    "maintenancePeriodId": periodId,
    "comment": newComment && newComment !== '' ? newComment : null,
  }

  const result = yield post(accessToken, url, command)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'UPDATING_MAINTENANCE_PERIOD_COMMENT_FAILED', accessToken, periodId, planId, newComment, oldComment})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Comment could not be saved.'}) 
    return
  }

  yield put({type: 'UPDATING_MAINTENANCE_PERIOD_COMMENT_SUCCEEDED', accessToken, periodId, planId, newComment, oldComment})
  yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Comment saved.'})

}

function* fetchAssetDocuments ({ accessToken, assetId }) {
  if (!assetId) {
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch documents'})
    yield put({type: 'FETCHING_ASSET_DOCUMENTS_FAILED', assetId: assetId})
    return
  }

  let url = customerServerUrl() + '/asset-maintenance/asset-documents?assetId=' + assetId

  const result = yield get(accessToken, url)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'FETCHING_ASSET_DOCUMENTS_FAILED', assetId: assetId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch documents'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'ASSET_DOCUMENTS_FETCHED', data: [], assetId: assetId})
    return
  }

  yield put({type: 'ASSET_DOCUMENTS_FETCHED', data: result.data, assetId: assetId})


}

function* addDocumentToAsset({ accessToken, assetId, documentUrl, fileName, comment }) {
  let url = assetsCommandServerUrl() + '/cmd/AddDocumentToAsset'
  const command = {
    "blobUri": documentUrl,
    "fileName": fileName,
    "assetId": assetId,
    "comment": comment
  }

  const result = yield post(accessToken, url, command)


  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'ADDING_DOCUMENT_TO_ASSET_FAILED', documentUrl, fileName, assetId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Document could not be attached.'}) 

    yield put({type: 'CLEAR_UPLOADED_DOCUMENTS', message: 'Document could not be attached.'}) 
    return
  }

  const uploaded_file = yield select(state => state.uploaded_file)

  yield put({type: 'DOCUMENT_ADDED_TO_ASSET', documentUrl, fileName, assetId, comment, documentId: uploaded_file?.documentId})
  yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Document has been added.'})
  
  yield put({type: 'CLEAR_UPLOADED_DOCUMENTS', message: 'Document could not be attached.'}) 
}

function* updateAssetDocument({ accessToken, assetId, documentUrl, fileName, existingDocumentId }) {
  let url = assetsCommandServerUrl() + '/cmd/UpdateAssetDocument-v2'
  const command = {
    "blobUri": documentUrl,
    "assetId": assetId,
    "oldDocumentId": existingDocumentId
  }
  
  const result = yield post(accessToken, url, command)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'UPDATING_DOCUMENT_OF_ASSET_FAILED', documentUrl, fileName, assetId, existingDocumentId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Document could not be updated.'}) 
    return
  }

  yield put({type: 'UPDATING_DOCUMENT_OF_ASSET_SUCCEEDED', documentUrl, fileName, assetId, existingDocumentId})
  if (existingDocumentId) {
    yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Document has been updated.'})
  }
  else yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Document has been added.'})
}

function* updateDocumentOfPeriod({ accessToken, documentUrl, fileName, periodId, planId }) {

  let url = assetsCommandServerUrl() + '/cmd/UpdateMaintenanceDocument'
  const command = {
    "blobUri": documentUrl,
    "fileName": fileName,
    "maintenancePlanId": planId,
    "maintenancePeriodId": periodId
  }

  const result = yield post(accessToken, url, command)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'UPDATING_DOCUMENT_OF_PERIOD_FAILED', documentUrl, fileName, periodId, planId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Document could not be attached.'}) 
    return
  }

  yield put({type: 'UPDATING_DOCUMENT_OF_PERIOD_SUCCEEDED', documentUrl, fileName, periodId, planId})
  yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Document has been updated.'})

}

function* removeDocumentFromPeriod({ accessToken, periodId, assetId }) {

  let url = assetsCommandServerUrl() + '/cmd/RemoveMaintenanceDocument'
  const command = {
    "assetId": assetId,
    "maintenancePeriodId": periodId
  }

  const result = yield post(accessToken, url, command)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'REMOVING_DOCUMENT_FROM_PERIOD_FAILED', periodId, assetId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Document could not be removed.'}) 
    return
  }

  yield put({type: 'REMOVING_DOCUMENT_FROM_PERIOD_SUCCEEDED', periodId, assetId})
  yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Document has been removed.'})

}

function* removeAssetDocument({ accessToken, documentId, assetId }) {
  let url = assetsCommandServerUrl() + '/cmd/RemoveAssetDocument'
  const command = {
    "id": documentId,
    "assetId": assetId
  }

  const result = yield post(accessToken, url, command)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'REMOVING_ASSET_DOCUMENT_FAILED', documentId, assetId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Document could not be removed.'}) 
    return
  }

  yield put({type: 'REMOVING_ASSET_DOCUMENT_SUCCEEDED', documentId, assetId})
  yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Document has been removed.'})
}

function* fetchPeriodsWithDocuments({accessToken, assetId}) {
  
  let url = customerServerUrl() + '/asset-maintenance/documents?assetId=' + assetId

  const result = yield get(accessToken, url)

  if(!result || (result.status !== 'ok' && result.status !== 'no_content'))
  {
    yield put({type: 'FETCHING_PERIODS_WITH_DOCUMENTS_FAILED', assetId: assetId})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch documents'})
    return
  }
  else if( result.status === 'no_content')
  {
    yield put({type: 'FETCHING_PERIODS_WITH_DOCUMENTS_SUCCESSFUL', data: [], assetId: assetId})
    return
  }

  
  yield put({type: 'FETCHING_PERIODS_WITH_DOCUMENTS_SUCCESSFUL', data: result.data, assetId: assetId})
}

function* uploadImage({accessToken, image, typeOfImage}) 
{
  if (!image) return
  const url = assetsCommandServerUrl() + '/UploadImage'

  const result = yield call(upload, url,{body: image, method: 'POST'}, accessToken)

  if(!result || result.status !== 'ok')
  {
    yield put({type: 'UPLOADING_IMAGE_FAILED' , typeOfImage: typeOfImage})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to upload image'})
    return
  }

  yield put({type: 'UPLOADING_IMAGE_SUCCESSFULLY', data: result.data, file: image, typeOfImage: typeOfImage })
}

function* uploadDocument({accessToken, document}) 
{
  if (!document) return
  const url = assetsCommandServerUrl() + '/UploadDocument'

  const result = yield call(upload, url,{body: document, method: 'POST'}, accessToken)

  if(!result || result.status !== 'ok')
  {
    yield put({type: 'UPLOADING_DOCUMENT_FAILED' , document: document})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to upload document'})
    return
  }

  yield put({type: 'UPLOADING_DOCUMENT_SUCCESSFUL', data: result.data, file: document })
}

function* removeImage({accessToken, imageId}) 
{
  //If we want to remove from the blob storage too
 /*  let body = {
    id: imageId,
  }

  const url = assetsCommandServerUrl() + '/cmd/RemoveImage'

  const result = yield post(accessToken, url, body)

  if(!result || result.status !== 'ok')
  {
    yield put({type: 'REMOVING_IMAGE_FAILED'})
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to remove image'})
    return
  } */

  yield put({type: 'REMOVING_IMAGE_SUCCESSFULLY', imageId: imageId })
}

function* removeDocument({ accessToken, documentId })
{
  yield put({type: 'DOCUMENT_REMOVED', documentId: documentId })
  
}


function* createAssetForMaintenance({  
  accessToken, 
  organisationId,
  assetName,
  category,
  homebase,
  homebaseName,
  homebaseType,
  brand,
  serialNumber,
  frequency,
  dueDate,
  instructions,
  maintenanceName,
  assetImages,
  serialImages}) 
{
  let url = assetsCommandServerUrl() + '/cmd/AddMaintenanceAndAsset'
  const assetIds = []
  const serialIds = []
  const images = []
  assetImages.map(img => img.typeOfImage === 'Asset' ? assetIds.push(img.fileData.imageId) : null)
  serialImages.map(img => img.typeOfImage === 'Serial_id' ? serialIds.push(img.fileData.imageId) : null)
  assetImages.map(img => img.typeOfImage === 'Asset' ? images.push({
    imageId: img.fileData.imageId,
    imageUrl: img.fileData.blobUrl,
    organisationId: organisationId,
    typeOfImage: "Asset"
  }) : null)

  const locationType = getStore()?.getState()?.maintenance_plans?.homebases?.find(x => x.id === homebase)?.locationType

  const command = {
      "organisationId": organisationId,
      "assetName": assetName,
      "category": category,
      "homebase": homebase,
      "brand": brand,
      "serialNumber": serialNumber,
      "frequency": (!frequency) ? null : '' + frequency, // needs to be sent as string
      "dueDate": dueDate,
      "instructions": instructions,
      "maintenanceName": maintenanceName,
      "assets": assetIds,
      "serialIds": serialIds,
      "locationType": locationType
  }
  let result = yield post(accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      const categories = getStore().getState().maintenance_plans?.categories ?? []
      const data = result.data?.data
      yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Equipment added'})
      yield put({ type: 'CREATE_ASSET_FOR_MAINTENANCE_SUCCEEDED', command: command, response: result.data, newAsset: {
        assetId: data.assetId,
        beaconId: null,
        brand: command.brand,
        category:{
          name: command.category,
          iconUrl: categories.find(c => c.name === command.category)?.iconUrl
        },
        comment: null,
        daysFrequency: Number.parseInt(data.daysFrequency),
        documentId: null,
        dueDate: command.dueDate,
        homebase: homebaseName,
        homebaseId: data.homebase,
        id: command.assetId,
        images: images,
        isInOneWeek: data.isInOneWeek === 'True',
        isInThreeWeeks: data.isInThreeWeeks === 'True',
        isOverdue: data.isOverdue === 'True',
        assetName: data.assetName,
        organisationId: command.organisationId,
        serialNumber: command.serialNumber,
        status: 'upcoming',
        homebaseType: homebaseType,
        maintenancePlanId: data.maintenancePlanId,
        periodId: data.maintenancePeriodId
      } })
      return
    }
  }
  yield put({ type: 'CREATE_ASSET_FOR_MAINTENANCE_FAILED', command: command, response: result?.response })
  yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Equipment could not be added'})
}

function* removeAssetAndMaintenancePlan({
  accessToken,
  assetId,
  maintenancePlanId
}) {
  let url = assetsCommandServerUrl() + '/cmd/RemoveAssetAndMaintenancePlan'
  const command = {
    "maintenancePlanId": maintenancePlanId,
    "assetId": assetId
  }

  let result = yield post(accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Equipment removed'})
      yield put({ type: 'ASSET_AND_MAINTENANCE_PLAN_REMOVED', assetId: assetId, maintenancePlanId: maintenancePlanId, response: result.data })
      return result
    }
  }
  yield put({ type: 'REMOVING_ASSET_AND_MAINTENANCE_PLAN_FAILED', command: command, response: result?.response, assetId: assetId, maintenancePlanId: maintenancePlanId })
  yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Equipment could not be removed'})
  return result

}

function* markMaintenancePeriodAsDone({
  accessToken,
  periodId,
  endDate,
  document,
  comment,
  maintenancePlanId,
  assetId,
  fileName,
}) {
  let url = assetsCommandServerUrl() + '/cmd/MaintenancePerformed'
  const command = {
    "id": periodId,
    "endDate": endDate,
    "document": document,
    "comment":   comment,
    "maintenancePlanId": maintenancePlanId,
    "assetId": assetId,
    "fileName": fileName,
  }

  let result = yield post(accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      const updatedData = result.data?.data

      const noRepeatTask = !updatedData.dueDate

      yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: noRepeatTask ? 'Maintenance task finished.' : 'Maintenance period marked as done.'})
      
      if (noRepeatTask) {
        yield put ({ type: 'REMOVE_MAINTENANCE_TASK', periodId: periodId })
      }

      else {
        yield put({
          type: 'UPDATE_MAINTENANCE_PERIOD_AND_DUE_DATE',
          newDueDate: updatedData.dueDate,
          maintenancePeriodId: periodId,
          newMaintenancePeriodId: updatedData.id
        })
      }

      const uploaded_file = yield select(state => state.uploaded_file)

      yield put({
        type: 'MAINTENANCE_PERIOD_DONE',
        noRepeatTask,

        assetId: assetId,
        periodId: periodId,
        newDueDate: updatedData.dueDate,
        endDate: endDate,
        documentId: uploaded_file?.documentId,
        documentBlob: uploaded_file?.blobUrl,
        documentName: uploaded_file?.fileName,
        comment: comment,
        maintenancePlanId: maintenancePlanId ,
        newMaintenancePeriodId: updatedData.id,
        newMaintenancePeriodStartTime: endDate,
      })

      return result
    }
  }
  yield put({ type: 'MAINTENANCE_PERIOD_DONE_FAILED', periodId: periodId, endDate: endDate, comment: comment, maintenancePlanId: maintenancePlanId })
  yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'The maintenance period could not be marked as done.'})
  return result

}

function* sendUpdateMaintenancePlanToServer (
  accessToken,
  assetId,
  oldMaintenancePlan,
  newFrequencyInDays,
  newDueDate,
  maintenancePlanId,
  maintenancePeriodId,

  noNotification = false
) {
  let url = assetsCommandServerUrl() + '/cmd/UpdateMaintenancePlan'
  const command = {
    "assetId": assetId,
    "frequency": (newFrequencyInDays && newFrequencyInDays !== '0') ? newFrequencyInDays : null,
    "dueDate": newDueDate,
    "id": maintenancePlanId,
    "maintenancePeriodId": maintenancePeriodId
  }

  const result = yield post(accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      if (!noNotification) yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Maintenance plan updated'})

      yield put({
        type: 'UPDATING_MAINTENANCE_PLAN_AND_TASK_SUCCEEDED',
        newFrequencyInDays: newFrequencyInDays,
        newDueDate: newDueDate,
        maintenancePlanId: maintenancePlanId,
        maintenancePeriodId: maintenancePeriodId,
        oldMaintenancePlan: oldMaintenancePlan
      })

      return result
    }
  }

  if (!noNotification) yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Could not update maintenance plan'})

  yield put({ type: 'UPDATING_MAINTENANCE_PLAN_AND_TASK_FAILED', command: command, response: result?.response, oldMaintenancePlan: oldMaintenancePlan, maintenancePlanId: maintenancePlanId })

  return result
}

function* updateMaintenancePlan({
  accessToken,
  assetId,
  oldMaintenancePlan,
  newFrequencyInDays,
  newDueDate,
  maintenancePlanId,
  maintenancePeriodId,
}) {
  return yield sendUpdateMaintenancePlanToServer(
    accessToken,
    assetId,
    oldMaintenancePlan,
    newFrequencyInDays,
    newDueDate,
    maintenancePlanId,
    maintenancePeriodId,
    false)  
}

function* updateAssetAndMaintenance({
  accessToken, 
  organisationId,
  assetName,
  category,
  homebaseId,
  brand,
  serialNumber,
  assetImages,
  serialImages,
  assetId,
  oldAssetAndTask,
  trackerId,

  newFrequencyInDays,
  newDueDate,
  maintenancePlanId,
  maintenancePeriodId
}) {
  var mainbox_id = getStore().getState().vehicles_data?.data?.find(x => x.vehicle?.vin === homebaseId).mainbox_id

  let beaconUpdateFailed = false
  let resultAsset = {status: 'not ok'}
  if(trackerId && mainbox_id) {
    yield put({ 
      type: 'UPDATE_BEACON', 
      beaconId: trackerId,
      name: assetName,
      category: category,
      boxId: mainbox_id 
    })

    const assetUpdateAction = yield take(['BEACON_UPDATED', 'BEACON_UPDATE_FAILED'])
    if (assetUpdateAction.type === "BEACON_UPDATE_FAILED") beaconUpdateFailed = true
  }

  if(!beaconUpdateFailed){
    resultAsset = yield sendUpdateAssetToServer(
      accessToken, 
      organisationId,
      assetName,
      category,
      homebaseId,
      brand,
      serialNumber,
      assetId,
      oldAssetAndTask,
      assetImages,
      serialImages,
      true)
  }
  else{
    yield put({ type: 'UPDATING_ASSET_FAILED', assetId: assetId, response: 'Beacon update failed', oldAssetAndTask: oldAssetAndTask})
  }

  let resultMaintenancePlan = yield sendUpdateMaintenancePlanToServer(
    accessToken,
    assetId,
    oldAssetAndTask,
    newFrequencyInDays,
    newDueDate,
    maintenancePlanId,
    maintenancePeriodId,
    true)

  if (resultMaintenancePlan?.status === 'ok' && resultAsset?.status === 'ok') {
    yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Asset maintenance updated'})
    return
  }
  else if (resultMaintenancePlan?.status !== 'ok' && resultAsset?.status !== 'ok') {
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Asset maintenance could not be updated'})
    return
  }
  else yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Some changes could not be saved'}); return
}

function* sendUpdateAssetToServer (
  accessToken, 
  organisationId,
  assetName,
  category,
  homebaseId,
  brand,
  serialNumber,
  assetId,
  oldAssetAndTask,
  assetImages,
  serialIdImages,
  trackerId,
  
  noNotification = false
) {
  let url = assetsCommandServerUrl() + '/cmd/2/UpdateAsset'

  const locationType = getStore().getState().maintenance_plans?.homebases?.find(x => x.id === homebaseId)?.locationType

  const command = {
    "organisationId": organisationId,
    "assetName": assetName,
    "category": category,
    "homebaseId": homebaseId,
    "brand": brand,
    "serialNumber": serialNumber,
    "id": assetId,
    "assetImages": assetImages,
    "serialIdImages": serialIdImages,
    "locationType": locationType,
    "trackerId": trackerId
  }
  
  let result = yield post(accessToken, url, command)
  
  const categories = getStore().getState().maintenance_plans?.categories ?? []
  const homebases = getStore().getState().maintenance_plans?.homebases ?? []
  const selectedHomebase = homebases?.find(hb => hb.id === homebaseId)
  let images = oldAssetAndTask?.images ?? []

  if(assetImages?.[0]?.newImageId)
    assetImages.map(asImg => {
      images = images?.filter(img => img?.typeOfImage !== 'Asset')
      const updatedImage = getStore().getState().maintenance_plans?.images?.find(i => i?.typeOfImage === 'Asset')
      if(updatedImage) 
        images.push({
          imageId: asImg.newImageId,
          imageUrl: updatedImage.fileData.blobUrl,
          typeOfImage: 'Asset'
        })
    })
  
  if(serialIdImages?.[0]?.newImageId)
    serialIdImages.map(serImg => {
      images = images?.filter(img => img?.typeOfImage !== 'SerialNumber')
      const updatedImage = getStore().getState().maintenance_plans?.images?.find(i => i?.typeOfImage === 'SerialNumber')
      if(updatedImage) 
        images.push({
          imageId: serImg?.newImageId,
          imageUrl: updatedImage.fileData.blobUrl,
          typeOfImage: 'SerialNumber'
        })
    })

  if (result && result.status) {
    if (result.status === 'ok'){
      const data = result.data?.data
      if (!noNotification) yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'Equipment updated'})
      yield put({ type: 'UPDATING_ASSET_SUCCEEDED', command: command, assetId: assetId, updatedAsset: {
        organisationId,
        assetName,
        category: {
          name: command.category,
          iconUrl: categories.find(c => c.name === command.category)?.iconUrl
        },
        homebaseId,
        brand,
        serialNumber,
        assetId,
        images: images,
        homebaseName: selectedHomebase?.name,
        homebaseType: selectedHomebase?.type,
      }, oldAssetAndTask: oldAssetAndTask })

      return result
    }
  }
  yield put({ type: 'UPDATING_ASSET_FAILED', command: command, assetId: assetId, response: result?.response, oldAssetAndTask: oldAssetAndTask })
  if (!noNotification) yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Equipment could not be updated'})

  return result
}

export function* updateBeacon({beaconId, name, category, boxId}) {
  let shortenedName = shortenStringByEllipsis(name, 15) // the beacon endpoint doesn't allow names longer than 15 characters ...
  const body = {
      name: shortenedName,
      category: category,
      boxId: boxId,
    }

  try {
    
    const accessToken = yield sec?.getAccessTokenSilently()
    const response = yield patch( accessToken, customerCommandServerUrl() + '/cmd/beacon/' + beaconId, body )

    if (response.status === 'ok') {
      yield put({
        type: 'BEACON_UPDATED',
        beaconId: beaconId,
        name: shortenedName,
        category: category,
        boxId: boxId
      })

    } else {
      throw new Error(response)
    }
  } catch (error) {
    yield put({ type: 'BEACON_UPDATE_FAILED' })
    yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'There was an issue updating this assets tracker. Please contact support.'})
  }
}


function* updateAsset({
  accessToken, 
  organisationId,
  assetName,
  category,
  homebaseId,
  brand,
  serialNumber,
  assetId,
  oldAssetAndTask,
  assetImages,
  serialIdImages,
  trackerId
}) {

  var mainbox_id = getStore().getState().vehicles_data?.data?.find(x => x.vehicle?.vin === homebaseId)?.mainbox_id

  if(trackerId && mainbox_id) {
    yield put({ 
      type: 'UPDATE_BEACON', 
      beaconId: trackerId,
      name: assetName,
      category: category,
      boxId: mainbox_id 
    })

    const assetUpdateAction = yield take(['BEACON_UPDATED', 'BEACON_UPDATE_FAILED'])

    if(assetUpdateAction.type === 'BEACON_UPDATE_FAILED'){
      yield put({ type: 'UPDATING_ASSET_FAILED', assetId: assetId, response: 'Beacon update failed', oldAssetAndTask: oldAssetAndTask})
      return
    }
  }

  return yield sendUpdateAssetToServer(accessToken, 
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetId,
    oldAssetAndTask,
    assetImages,
    serialIdImages,
    trackerId,
    false
  )
}

function* createNewHomebase({  
  accessToken, 
  organisationId,
  homebaseName,
  homebaseType
  }) 
{
  let url = assetsCommandServerUrl() + '/cmd/CreateHomebase'
  
  const command = {
    "organisationId": organisationId,
    "name": homebaseName,
    "type": homebaseType,
    "locationType": "Location"
  }
  let result = yield post(accessToken, url, command)

  if (result && result.status) {
    if (result.status === 'ok'){
      yield put({ type: 'CREATE_NEW_HOMEBASE_SUCCEEDED', command: command, response: result.data })
      yield put({type: 'SHOW_SUCCESS_NOTIFICATION', message: 'New homebase created'})
      return
    }
    else {
      yield put({ type: 'CREATE_NEW_HOMEBASE_FAILED', command: command })
      yield put({type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to create new homebase'})
      return
    }
  }
}

function* startSignalRConnection({accessToken}) {
  const existing = yield select(state => state.signalRConnection)
  if (existing != null) {
    yield put({ type: 'SIGNALR_CONNECTION_ALREADY_ACTIVE'})
    return
  }
  const url = customerServerUrl() + '/live-telemetry'

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, { accessTokenFactory: () => accessToken })
    .configureLogging(signalR.LogLevel.None)
    .build()

  try {
    yield connection.start()
    yield fork(setupSignalRListeners, connection)
    yield put({ type: 'SIGNALR_CONNECTION_STARTED', data: connection })
  } catch (error) {
    yield put({ type: 'SIGNALR_CONNECTION_FAILED', data: error })
  }
}

function* setupSignalRListeners(connection) {
  if (connection != null) {
    try {
      const channel = eventChannel(listener => {
        connection.on('startup-done', _ => {
          connection.invoke('StartSubscription')
        })
        connection.on('positions', (boxId, data) => {
          listener({type: 'positions', boxId, data})
        })
        connection.on('start-animation-positions', (boxId, data) => {
          listener({type: 'start-animation-positions', boxId, data})
        })
        connection.on('batteries', (boxId, data) => {
          listener({type: 'batteries', boxId, data})
        })
        connection.on('thermometers', (boxId, data) => {
          listener({type: 'thermometers', boxId, data})
        })
        connection.on('humidity-sensors', (boxId, data) => {
          listener({type: 'humidity-sensors', boxId, data})
        })
        connection.on('weight-reports', (boxId, data) => {
          listener({type: 'weight-reports', boxId, data})
        })
        connection.on('lps-reports', (boxId, data) => {
          listener({type: 'lps-reports', boxId, data})
        })


        connection.on('NameChange', (boxId, data) => {
          var type = 'NameChange'
          listener({type, data})
        })

        connection.on('CategoryChange', (boxId, data) => {
          var type = 'CategoryChange'
          listener({ type , data})
        })

        connection.on('BeaconRemoved', (boxId, data) => {
          var type = 'BeaconRemoved'
          listener({type, data})
        })

        connection.on('VehicleAssigned', (boxId, data) => {
          var type = 'VehicleAssigned'
          listener({type, data})
        })

        connection.on('AssetFound', (boxId, data) => {
          var type = 'AssetFound'
          listener({type, data})
        })

        connection.on('AssetMissing', (boxId, data) => {
          var type = 'AssetMissing'
          listener({type, data})
        })

        connection.onclose(() => listener(END))

        return () => {}
      })


      while (connection.state === signalR.HubConnectionState.Connected) {
        const status = yield take(channel)
        if(status?.type === 'AssetMissing'){
          yield put({type: 'ASSET_TRACKER_IS_MISSING', status})
        }
        if(status?.type === 'AssetFound'){
          yield put({type: 'ASSET_TRACKER_FOUND', status})
        }
        if(status?.type === 'VehicleAssigned'){
          yield put({type: 'ASSET_TRACKER_ASSIGNED', status})
        }
        if(status?.type === 'BeaconRemoved'){
          yield put({type: 'ASSET_TRACKER_REMOVED', status})
        }
        if(status?.type === 'NameChange'){
          yield put({type: 'ASSET_TRACKER_NAME_CHANGED', status})
        }
        if(status?.type === 'CategoryChange'){
          yield put({type: 'ASSET_TRACKER_CATEGORY_CHANGED', status})
        }
        if (status?.boxId) {
          if (status.type === 'positions') {
            yield put({type: 'VEHICLE_POSITIONS_UPDATED', status})
          }
          if (status.type === 'start-animation-positions') {
            yield put({type: 'VEHICLE_INITIAL_POSITIONS_UPDATED', status})
          }
          else if (status.type === 'batteries') {
            yield put({type: 'VEHICLE_BATTERIES_UPDATED', status})
          }
          else if (status.type === 'thermometers') {
            yield put({type: 'VEHICLE_TEMPERATURES_UPDATED', status})
          }
          else if (status.type === 'humidity-sensors') {
            yield put({type: 'VEHICLE_HUMIDITY_UPDATED', status})
          }
          else if (status.type === 'weight-reports') {
            yield put({type: 'VEHICLE_WEIGHT_UPDATED', status})
          }
          else if (status.type === 'lps-reports') {
            yield put({type: 'VEHICLE_LPS_STATUS_UPDATED', status})
          }
          else yield put({type: 'VEHICLE_STATUS_UPDATED', status})
        } else {
          if (!status?.type)
            yield put({type: 'VEHICLE_STATUS_UPDATE_FAILED'})
        }
      }
    } catch (error) {
      console.warn('SignalR connection error: ', error)
      connection.stop()
    }
  }
}

function* stopSignalRConnection({ connection }) {
  if (connection != null) {
    yield connection.stop()
    yield put({ type: 'SIGNALR_CONNECTION_STOPPED', data: null })
  } else {
    yield put({ type: 'NO_SIGNALR_CONNECTION_TO_STOP' })
  }
}

function* clearImageUploads() {
  yield delay(500) // needs that tiny bit of delay, otherwise reducer may not have updated with new image yet
  yield put({ type: 'CLEAR_UPLOADED_IMAGES' })
}

function* fetchRoutesOnVehicleSelected({
  boxId,
  shouldScroll,
  deselectIfAlreadySelected,
  zoomToRoutes,
  zoomToVehicle,
}) {
  const map_settings = getStore()?.getState()?.map_settings
  const vehicles = getStore()?.getState()?.vehicles
  const selectedVehicle = getStore()?.getState()?.selectedVehicle
  if (!selectedVehicle?.boxId) // -> vehicle has actually been deselected (the map is complicated and sometimes uses VEHICLE_SELECTED for both selecting and unselecting)

  if (!map_settings) return

  const vehicle = vehicles?.find(v => v.boxId === boxId)
  const hasDataAlready = vehicle?.geoJSON?.routes && vehicle.geoJSON.routes[map_settings?.date]
  if (!map_settings.isLive && hasDataAlready) return // for live data, want to be able to refetch because it may have changed

  const accessToken = yield sec?.getAccessTokenSilently()

  
  yield call(fetchRoutesForVehicle, accessToken, boxId, map_settings)
}

function* fetchRoutesForVehicle(accessToken, boxId, map_settings) {
  if (map_settings.isLive && (!map_settings.date || map_settings.date  === DateTime.local().toISODate())) {
    const rightNow = DateTime.fromJSDate(new Date())

    let start = DateTime.local(rightNow.year, rightNow.month, rightNow.day).startOf('day')
    let end = DateTime.local(rightNow.year, rightNow.month, rightNow.day).endOf('day')

    yield put(fetchVehicleRoutes(
      accessToken,
      start,
      DateTime.fromSeconds(rightNow.toSeconds() - 60) > end ? end : DateTime.fromSeconds(rightNow.toSeconds() - 60),
      null,
      boxId
    ))
    yield put(fetchWarningLogs(accessToken, [boxId], start, end))
  }
  else {
    const selectedDate = DateTime.fromISO(map_settings?.date)
    const startTime = selectedDate?.startOf('day')
    const endTime = selectedDate?.endOf('day')

    let start = DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, startTime.hour, startTime.minute)
    let end = DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, endTime.hour, endTime.minute)

    yield put(fetchVehicleRoutes(
      accessToken,
      start,
      end,
      null,
      boxId
    ))
    yield put(fetchWarningLogs(accessToken, [boxId], start, end))
  }
}

function* fetchRouteIfVehicleSelected ({accessToken, date}) {
  const map_settings = getStore()?.getState()?.map_settings
  const selectedVehicle = getStore()?.getState()?.selectedVehicle
  
  if (!map_settings) return
  
  if (selectedVehicle?.boxId) yield call(fetchRoutesForVehicle, accessToken, selectedVehicle.boxId, map_settings)
}


function* getSecuritySummary({ accessToken, start, end, })
{
  const response = yield fetchSecuritySummaryFromServer({ accessToken, start, end })

  if(!response) {
    yield put({ type: 'FETCHING_SECURITY_REPORTS_SUMMARY_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_SECURITY_REPORTS_SUMMARY_FAILED' })
    return
  }

  yield put({ type: 'SECURITY_REPORTS_SUMMARY_FETCHED', data: response.data, start, end })
}

function* getVehiclesSecuritySummary({ accessToken, start, end, boxId, vin })
{
  const response = yield fetchVehiclesSecurityFromServer({ accessToken, start, end, boxId: boxId })

  if(!response) {
    yield put({ type: 'FETCHING_VEHICLES_SECURITY_REPORTS_FAILED', vin: vin })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_VEHICLES_SECURITY_REPORTS_FAILED', vin: vin })
    return
  }

  yield put({ type: 'VEHICLES_SECURITY_REPORTS_FETCHED', data: response.data, start: start, end: end, boxId: boxId, vin: vin  })
}



function* fetchScuritySystemStatus({ accessToken})
{
  const url = `${customerServerUrl()}/vehicles/security-system-status`
  const response = yield get(accessToken, url)

  if(!response) {
    yield put({ type: 'FETCHING_SECURITY_SYSTEM_STATUS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_SECURITY_SYSTEM_STATUS_FAILED' })
    return
  }

  yield put({ type: 'FETCHING_VEHICLES_SECURITY_SYSTEM_STATUS_SUCCEEDED', data: response.data})
}

function* fetchLockStatusSummary({accessToken, days}){
  const url = `${customerServerUrl()}/lock/summary?daysOfHistory=${days}`
  const response = yield get(accessToken, url)


  if(!response) {
    yield put({ type: 'FETCHING_LOCK_SUMMARY_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_LOCK_SUMMARY_FAILED' })
    return
  }

  yield put({ type: 'FETCHING_LOCK_SUMMARY_SUCCEEDED', data: response.data})
}

function* fetchLockReports({accessToken, days}){
  const url = `${customerServerUrl()}/lock/history?daysOfHistory=${days}`
  const response = yield get(accessToken, url)


  if(!response) {
    yield put({ type: 'FETCHING_LOCK_REPORTS_FAILED' })
    return
  }

  if(response && response.status === 'no_content') {
    response.data = []
  } else if(!response.data) {
    yield put({ type: 'FETCHING_LOCK_REPORTS_FAILED' })
    return
  }

  yield put({ type: 'FETCHING_LOCK_REPORTS_SUCCEEDED', data: response.data})

}

export function* vehicleSagas() {
  yield takeEvery('FETCH_VEHICLE', getVehicle)
  yield takeLatest('FETCH_VEHICLES', getVehicles)
  yield takeLatest('FETCH_BEACON_SNAPSHOTS', getBeaconSnapshot)
  yield takeLatest('FETCH_VEHICLES_DATA', getVehiclesData)
  yield takeEvery('FETCH_VEHICLE_ROUTES_DETAILED', getVehicleRoutesDetailed)
  yield takeEvery('VEHICLE_SELECTED', fetchRoutesOnVehicleSelected)
  yield takeEvery('SET_MAP_DATE', fetchRouteIfVehicleSelected)
  yield takeEvery('FETCH_VEHICLE_WEIGHTS', getVehicleWeights)
  yield takeLatest('FETCH_VEHICLES_SORTED', getVehiclesSorted)
  yield takeLatest('FETCH_VEHICLE_ROUTES', getVehicleRoutes)
  yield takeLatest('FETCH_VEHICLE_POSITIONS', getVehiclePositions)
  yield takeLatest('FETCH_LAST_KNOWN_POSITIONS', getLatestVehiclePositions)
  yield takeEvery('FETCH_VEHICLE_BATTERY_LOGS', getVehicleBatteryLogs)
  yield takeEvery('FETCH_LPS_BATTERY_LOGS', getLpsBatteryLogs)
  yield takeEvery('FETCH_VEHICLE_TEMPERATURE_LOGS', getVehicleTemperatureLogs )
  yield takeEvery('FETCH_VEHICLE_HUMIDITY_LOGS', getVehicleHumidityLogs )
  yield takeEvery('START_SIGNALR_CONNECTION', startSignalRConnection)
  yield takeEvery('STOP_SIGNALR_CONNECTION', stopSignalRConnection)
  yield takeEvery('CHANGE_VEHICLE_NAME', updateVehicleName)
  yield takeLatest('FETCH_BATTERY_REPORT', getBatteryReport)
  yield takeLatest('FETCH_TEMPERATURE_REPORT', getTemperatureReport)
  yield takeLatest('FETCH_HUMIDITY_REPORT', getHumidityReport)
  yield takeLatest('FETCH_ASSET_TRACKER_REPORT', getAssetTrackerReport)
  yield takeEvery('FETCH_DRIVING_LOG_DETAILS', getDrivingLogDetails)
  yield takeLatest('FETCH_OVERWEIGHT_REPORT', getOverweightReport)
  yield takeEvery('FETCH_TYRE_PRESSURE_REPORT', getTyrePressureReport)
  yield takeLatest('FETCH_DRIVING_LOGS', getDrivingLogs)
  yield takeLatest('FETCH_DRIVING_LOG_SUMMARIES', getDrivingLogSummaries)
  yield takeLatest('FETCH_OVERWEIGHT_REPORT_SUMMARIES', getOverweightReportSummaries)
  yield takeLatest('FETCH_WARNING_LOGS', getWarningLogs)
  yield takeLatest('FETCH_TOLL_REPORT', fetchTollReports)
  yield takeLatest('FETCH_TOLL_REPORT_SUMMARIES', fetchTollReportSummaries)
  yield takeEvery('DOWNLOAD_TOLL_REPORT', downloadTollReport)
  yield takeEvery('DOWNLOAD_DRIVING_LOGS', downloadDrivingLogs)
  yield takeEvery('DOWNLOAD_CLIMATE_LOGS', downloadClimateReports)
  yield takeEvery('FETCH_MILEAGE', getMileage)
  yield takeEvery('UPDATE_MILEAGE', updateMileage)
  yield takeEvery('SET_LICENSE_NUMBER', setLicenseNumber)
  yield takeLatest('FETCH_CURRENT_DRIVERS', getCurrentDrivers)
  yield takeEvery('DESIGNATE_CURRENT_DRIVER', designateCurrentDriver)
  yield takeLatest('FETCH_ASSET_TRACKERS', getAssetTrackers)
  yield takeEvery('FETCH_ASSET_TRACKER_ADDRESSES', getAssetTrackerAddresses)
  yield takeEvery('FETCH_TRACKER_ADDRESS', getTrackerAddress)
  yield takeEvery('UPDATE_TRACKER_INFO', updateTrackerInfo)
  yield takeEvery('UPDATE_TRACKER_OWNER', updateTrackersOwner)
  yield takeEvery('UPDATE_NOTIFICATIONS_ON_TRACKERS', updateTrackerNotification)
  yield takeLatest('FETCH_LPS_REPORT_SUMMARIES', fetchLPSReportSummaries)
  yield takeLatest('FETCH_LPS_FOOTPRINT', fetchLPSFootprint)
  yield takeLatest('FETCH_LPS_CHARGING_DATA', fetchLPSChargingData)
  yield takeEvery('UPDATE_DRIVING_LOG_NOTE', updateDrivingLogNote)
  yield takeLatest('FETCH_MAINTENANCE_TASKS', fetchMaintenanceTasks)
  yield takeLatest('FETCH_MAINTENANCE_TASKS_SUMMARY', fetchMaintenanceTasksSummary)
  yield takeLatest('FETCH_MAINTENANCE_PLANS', fetchMaintenancePlans)
  yield takeLatest('FETCH_MAINTENANCE_CATEGORIES', fetchMaintenanceCategories)
  yield takeLatest('FETCH_ASSET_CATEGORIES', fetchAssetCategories)
  yield takeEvery('CREATE_ASSET_FOR_MAINTENANCE', createAssetForMaintenance)
  yield takeEvery('REMOVE_ASSET_AND_MAINTENANCE_PLAN', removeAssetAndMaintenancePlan)
  yield takeEvery('CREATE_NEW_HOMEBASE', createNewHomebase)
  yield takeEvery('FETCH_MAINTENANCE_HOMEBASES', fetchHomebases)
  yield takeEvery('UPLOAD_IMAGE', uploadImage)
  yield takeEvery('REMOVE_IMAGE', removeImage)
  yield takeEvery('UPLOAD_DOCUMENT', uploadDocument)
  yield takeEvery('REMOVE_DOCUMENT', removeDocument)
  yield takeEvery('UPDATE_ASSET', updateAsset)
  yield takeEvery('UPDATE_BEACON', updateBeacon)
  yield takeEvery('UPDATE_MAINTENANCE_PLAN_AND_TASK', updateMaintenancePlan)
  yield takeEvery('UPDATE_ASSET_AND_MAINTENANCE_PLAN', updateAssetAndMaintenance)
  yield takeEvery('MARK_MAINTENANCE_PERIOD_AS_DONE', markMaintenancePeriodAsDone)
  yield takeEvery('FETCH_PERIODS_WITH_DOCUMENTS', fetchPeriodsWithDocuments)
  yield takeEvery('UPDATE_MAINTENANCE_PERIOD_COMMENT', updateMaintenancePeriodComment)
  yield takeEvery('UPDATE_DOCUMENT_OF_PERIOD', updateDocumentOfPeriod)
  yield takeEvery('REMOVE_DOCUMENT_FROM_PERIOD', removeDocumentFromPeriod)
  yield takeEvery('REMOVE_ASSET_DOCUMENT', removeAssetDocument)
  yield takeEvery('UPDATING_ASSET_SUCCEEDED', clearImageUploads)
  yield takeEvery('UPDATE_ASSET_DOCUMENT', updateAssetDocument)
  yield takeEvery('ADD_ASSET_DOCUMENT', addDocumentToAsset)
  yield takeEvery('FETCH_ASSET_DOCUMENTS', fetchAssetDocuments)
  yield takeLatest('FETCH_SECURITY_REPORTS_SUMMARY', getSecuritySummary)
  yield takeLatest('FETCH_VEHICLES_SECURITY_REPORTS', getVehiclesSecuritySummary)
  yield takeEvery('FETCH_VEHICLES_SECURITY_SYSTEM_STATUS', fetchScuritySystemStatus)
  yield takeEvery('FETCH_LOCK_SUMMARY', fetchLockStatusSummary) 
  yield takeEvery('FETCH_LOCK_REPORTS', fetchLockReports)
}