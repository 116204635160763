import React from 'react'
import View from '@modul-connect/shared/components/atoms/view'
import theme from "@modul-connect/shared/theme";

export const ContainerWithBorder = ({ children, extend }) => {
  return (
    <View style={{
      display: 'flex',
      padding: '8px 12px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '4px',
      flex: '1 0 0',
      alignSelf: 'stretch',

      borderRadius: '4px',
      border: `1px solid ${theme.colors.uiBorderColor}`,
      ...extend
    }}>
      {children}
    </View>
  )
}