import React, { useState } from "react"
import { InputField } from '@modul-connect/shared/components/atoms/textInput/inputField'
import View from "../atoms/view"
import { CircularProgress } from "@mui/material";
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import IconButton from "@modul-connect/shared/components/atoms/iconButton/iconButton";
import { P } from "@modul-connect/shared/components/atoms/text/text";

const checkLengthLimit = (value, characterLimit) => {
  return value?.length > characterLimit
}

export const EditableTextDisplay = ({
  characterLimit,
  value,
  placeholder,
  isSaving,
  onSave
}) => {
  const [edit, setEdit] = useState(false)
  const [editedValue, setEditedValue] = useState(value)

  const valueUpdated = editedValue !== value

  const stringTooLong = checkLengthLimit(editedValue, characterLimit)
  return (
    <View style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
    {!edit
      ? <P noPadding> {value && value !== "" ? value : placeholder} </P>
      : <InputField
        error={stringTooLong}
        helperText={characterLimit ? `(${(editedValue?.length ?? 0)}/${characterLimit})` : ''}
        noIcon
        noPadding
        value={editedValue}
        onChange={(newValue) => {
          if (!stringTooLong || newValue?.length < editedValue.length) setEditedValue(newValue)
        }}
        placeholder={placeholder}
      />
    }
    {isSaving ?
      <CircularProgress color={'primary'} size={20} />
      : edit ? <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        <Button
          extend={{ height: 40, alignSelf: 'flex-start' }}
          onClick={() => {
            if (valueUpdated && onSave) onSave(editedValue);
            setEdit(false)
          }}
          disabled={stringTooLong || !valueUpdated}
          >{'OK'}</Button>
          <Button
            extend={{ height: 40, alignSelf: 'flex-start' }}
            onClick={() => {
              setEdit(false)
              setEditedValue(value)
            }}
            noBg
          >{'Cancel'}</Button>
          </View>
        : <IconButton icon={'Pencil2'} onClick={() => setEdit(true)} v2 />}
    </View>
  )
}