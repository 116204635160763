import React, { Fragment } from "react";
import LogChart from "@modul-connect/shared/components/organisms/logChart";
import KpiRow from "@modul-connect/shared/components/molecules/kpiRow/kpiRow";
import { ticks_24_hours, ticks_7_days } from "./ticks";
import theme from "@modul-connect/shared/theme"
import { getBatteryWarningColor } from "../../../../../utils/vehicleUtils";

const BatteryLogGraph = ({
  vehicle,
  logs,
  log_loading,
  showBatteryId,
  onBatteryIntervalChanged,
  intervals,
  batteryLogInterval,
  batteryId
}) => {

  const defaultBatteryGuard = 11.7
  let lowerBatteryGuard = (logs?.length && vehicle?.vehicle?.batteryGuards?.length) ? 
    vehicle?.vehicle?.batteryGuards.find(guard => guard.batteryId === batteryId)?.lowGuard ?? defaultBatteryGuard 
    : defaultBatteryGuard

  const getTicks = (interval) => {
    switch (interval) {
      case intervals.LAST_24_H:
        return ticks_24_hours()
      case intervals.LAST_WEEK:
        return ticks_7_days()
      default:
        return []
    }
  }

  let kpiData_battery = vehicle?.currentStatus?.batteries.map(battery => {
    return {
      title: 'Current Voltage' + (showBatteryId && (battery?.id || battery.id === 0) ? ` — ID ${battery.id}` : ''),
      value: (battery.voltage || battery.voltage === 0) ? battery.voltage.toFixed(1) : '-',
      unit: 'V',
      color: (!battery.voltage && battery.voltage !== 0) ? theme.colors.text : getBatteryWarningColor(battery.voltage, lowerBatteryGuard)
    }
  })

  return (
    <Fragment>
      {
        (vehicle?.currentStatus?.batteries?.length || log_loading.fetchBatteryLogs !== 'loading') &&
        <KpiRow width={'100%'} data={kpiData_battery} />
      }
      {
        <LogChart
          intervalOptions={Object.values(intervals)}
          selectedInterval={batteryLogInterval}
          onIntervalChanged={onBatteryIntervalChanged}
          data={logs ? logs.map(log => {
            return { 
              boxId: log.boxId,
              id: log.id,
              telematicsId: log.telematicsId,
              timestamp: log.timestamp,
              voltage: log.voltage.toFixed(1),
              max: lowerBatteryGuard
          }}) : []}
          data_key="voltage"
          data_name="Battery Voltage"
          max_name="Min. Battery Voltage"
          title={"Battery Voltage" + (showBatteryId ? " (Id: " + batteryId + ")" : "")}
          unit="V"
          line_type={"stepAfter"}
          domain={[
            0,
            logs ? Math.max(
              lowerBatteryGuard + 1,
              Math.ceil(Math.max(...logs.map(log => log.voltage)) * 10) / 10) + 1
              : 15
          ]}
          displayTimeInHours={batteryLogInterval === intervals.LAST_24_H ? true : false}
          ticks={getTicks(batteryLogInterval)}
        />
      }
    </Fragment>
  )
}

export default BatteryLogGraph;


