import React from 'react'
import View from '../../atoms/view'


const ScrollView = ({ children, extend }) => {
  return (
    <View extend={[extend, styles.scroll]}>
      {children}
    </View>
  )
}
const styles = {
  scroll: {
    overflowY: 'scroll',
  }
}

export default ScrollView