import { Subtree } from "../state/types/customer"
import { ServiceType } from "@modul-connect/shared/utils/services"

export function getServiceList(state: {subtrees: {subtrees: Subtree[]}}) {
  return new ServiceList(state.subtrees.subtrees)
}

export class ServiceList {
  organisations: Subtree[]
  services: Set<ServiceType>

  constructor(organisations: Subtree[]) {
    this.services = new Set<ServiceType>(organisations.flatMap(org => org.services) as ServiceType[])
    this.organisations = organisations
  }

  hasService(service: ServiceType) {
    return this.services.has(service)
  }
}
