import { takeEvery, put } from 'redux-saga/effects'
import { customerServerUrl, customerCommandServerUrl } from '../../../../shared/config'
import { get, post } from '../../utils/ajax'
import { FETCH_API_KEYS, FETCH_SUBTREES, REQUEST_API_KEY, FORCE_EXPIRE_API_KEY, FETCH_ORGANISATION_NAME, AUTHENTICATED_USER_FETCHED } from '../types/customer'
import { expiredApiKeyByForce, fetchedApiKeys, fetchedOrganisationName, fetchOrganisationName, receivedNewApiKey, subtreesFetched } from '../actions/customer'

function* fetchTreeNames(accessToken, tree) {
  yield put(fetchOrganisationName(accessToken, tree.uuid))
  /*if (tree.children?.length)
    for (const subtree of tree.children)
      yield fetchTreeNames(accessToken, subtree)*/
}

function* getSubTreesFromServer(options) {
  const shouldIgnoreSelectedOrgs = true

  const url = customerServerUrl() + '/customer/tags/subtrees'
  const result = yield get(options.token, url, shouldIgnoreSelectedOrgs)
  if (result && result.status === 'ok') {
    const subtrees = yield result.data
    yield put(subtreesFetched(subtrees))
    for (const tree of subtrees)
      yield fetchTreeNames(options.token, tree)
  }
}

function* getOrganisationNameFromServer(options) {
  const shouldIgnoreSelectedOrgs = true

  const url = customerServerUrl() + '/customer/tags/uuid/translate/' + options.id
  const result = yield get(options.accessToken, url, shouldIgnoreSelectedOrgs)
  if (result && result.status === 'ok')
    yield put(fetchedOrganisationName(yield result.data))
}

function* getApiKeysFromServer(options) {
  let url = customerServerUrl() + '/api'
  url = options.includeExpired ? url + '?expired=true' : url
  const result = yield get(options.accessToken, url)
  if (result && result.status === 'ok') {
    yield put(fetchedApiKeys(yield result.data?.keys, yield result.data?.total))
  }
  else if (result?.status === 'no_content')
    yield put(fetchedApiKeys([], 0))
}

function* requestApiKeyFromServer(options) {
  const url = customerCommandServerUrl() + '/cmd/api/requestkey'
  const body = {
    expiresAt: options.expiresAt ?? null,
    organisations: options.organisations ?? null
  }
  const result = yield post(options.accessToken, url, body)
  if (result && result.status === 'ok') {
    const data = yield result.data
    yield put(receivedNewApiKey({
      public_id: data.public_id.value, 
      expires_at_utc: data.expires_at_utc,
      api_key: data.api_key
    }))
  }
}

function* removeApiKeyFromServer(options) {
  const url = customerCommandServerUrl() + '/cmd/api/removekey'
  const body = {
    id: options.id
  }
  const result = yield post(options.accessToken, url, body)
  if (result && result.status === 'ok')
    yield put(expiredApiKeyByForce(options.id))
}

export function* customerSagas() {
  yield takeEvery(FETCH_SUBTREES, getSubTreesFromServer)
  yield takeEvery(AUTHENTICATED_USER_FETCHED, getSubTreesFromServer)
  yield takeEvery(FETCH_ORGANISATION_NAME, getOrganisationNameFromServer)
  yield takeEvery(FETCH_API_KEYS, getApiKeysFromServer)
  yield takeEvery(REQUEST_API_KEY, requestApiKeyFromServer)
  yield takeEvery(FORCE_EXPIRE_API_KEY, removeApiKeyFromServer)
}