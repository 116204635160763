import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { 
  fetchDrivingLogSummaries, 
  fetchVehiclesData, 
  fetchDrivingLogs, 
  fetchDrivingLogDetails,
  fetchWarningLogs,
  fetchTollReport,
} from '../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import { DateTime } from "luxon";
import { useNavigate, useParams , useLocation} from 'react-router-dom';
import DrivingLogTable from '../drivingLog/drivingLogSummary/drivingLogTable';
import { getServiceList } from '../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';
import { createContext } from 'react';


export const groupingOptions = {
  byVehicle: 'vehicle',
  byDriver: 'driver'
}


export const GroupingContext = createContext(groupingOptions.byVehicle);


const DrivingLogPage = ({
  selected_organisations,
  vehicles_data,
  loading,
  fetchDrivingLogSummaries,
  fetchDrivingLogDetails,
  fetchVehiclesData,
  fetchDrivingLogs,
  fetchWarningLogs,
  driving_logs,
  fetchTollReport,
  subtrees
}) => {
  const disabled = !getServiceList({subtrees}).hasService(ServiceType.DrivingLogs)

  const navigate = useNavigate()
  const { summaryId } = useParams()
  const { detailsId } = useParams()

  const { getAccessTokenSilently } = useAuth0()

  const location = useLocation();
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [groupBy, setGroupBy] = useState( useQuery().get('groupby') ?? groupingOptions.byVehicle)


  const changeGroupBy = (group) => {
    setGroupBy(group)
    navigate('/reports/reports_driving-logs/?groupby=' + group)
  }

  let [startDate, setStartDate] = useState(DateTime.local().startOf('month'))
  let [endDate, setEndDate] = useState(DateTime.local().endOf('month'))
  const startDateRef = useRef(undefined);
  const endDateRef = useRef(undefined);
  startDateRef.current = startDate; // need the Refs, so the functions that are passed ot other components, like rowSelected, use the current state
  endDateRef.current = endDate;

  const logIdRef = useRef(null)

  const fetchDrivingLogSummariesWithAccessToken = () => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchDrivingLogSummaries(accessToken, startDateRef.current, endDateRef.current, groupBy)
      })
  }

  const fetchVehiclesDataWithAccessToken = () => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchVehiclesData(accessToken)
      })
  }

  const fetchDrivingLogsWithAccessToken = (boxId, driverId) => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchDrivingLogs(accessToken, boxId ? boxId : null, driverId, null, startDateRef.current, endDateRef.current)
      })
  }

  const fetchDrivingLogDetailsWithAccessToken = (start, end, boxId, driverId) => {
    if (!boxId && !driverId) {
      return
    }

    const logId = (boxId ?? driverId) + '_' + start

    logIdRef.current = logId

    if (Object.keys(loading.fetchDrivingLogDetails)?.length && loading.fetchDrivingLogDetails[logId] === 'loading' ||
      loading.fetchWarningLogs === 'loading' ||
      loading.fetchTollReport === 'loading' || loading.progress === 'loading' ||
      loading.fetchWeightLogs === 'loading' || loading.fetchWarningLogs === 'loading') {
      return
    }

    getAccessTokenSilently()
      .then(accessToken => {
        fetchDrivingLogDetails(accessToken, start, end, boxId ? boxId : null )
        fetchTollReport(accessToken, boxId, null, start, end) // for these purposes, doesn't matter whether we fetch by driver or by vehicle, it'll be the same log
        fetchWarningLogs(accessToken, boxId, start, end, null)
      })
  }

  useEffect(() => {
    if (summaryId) {
      return
    }
    fetchDrivingLogSummariesWithAccessToken()
  }, [endDate, summaryId, groupBy, selected_organisations])

  useEffect(() => {
    if ((!vehicles_data || !vehicles_data.data || vehicles_data.data.length === 0)) {
      fetchVehiclesDataWithAccessToken()
    }
  }, [selected_organisations])

  return (
    (<GroupingContext.Provider value={groupBy}>
      <DrivingLogTable
        startDate={startDate}
        endDate={endDate}
        onTimeframePicked={(start, end) => {
          setStartDate(start)
          setEndDate(end)
        }}
        setGroupBy={changeGroupBy}
        disabled={disabled}
        onRowSelected={fetchDrivingLogsWithAccessToken}
        fetchDrivingLogDetails={fetchDrivingLogDetailsWithAccessToken}
        isLoadingDetails={
          ((loading.fetchDrivingLogDetails[logIdRef.current] === 'loading')/*  || loading.fetchDrivingLogDetails === undefined */) || 
          ((loading.fetchWarningLogs === 'loading')/*  || loading.fetchWarningLogs === 'loading' === undefined */) ||
          ((loading.fetchTollReport === 'loading')/*  || loading.fetchTollReport === undefined */) ||
          ((loading.fetchBatteryReport === 'loading')/*  || loading.progress === undefined */) ||
          ((loading.fetchWeightLogs === 'loading')/*  || loading.fetchWeightLogs === undefined */) ||
          ((loading.fetchWarningLogs === 'loading')/*  || loading.fetchWarningLogs === undefined */)
        }
      />
    </GroupingContext.Provider>)
  );
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  fetchDrivingLogSummaries: (accessToken, start, end, groupBy) => dispatch(fetchDrivingLogSummaries(accessToken, start, end, groupBy)),
  fetchVehiclesData: (accessToken) => dispatch(fetchVehiclesData(accessToken)),
  fetchDrivingLogs: (accessToken, boxId, driverId, vehicleType, start, end) => dispatch(fetchDrivingLogs(accessToken, boxId, driverId, vehicleType, start, end)),
  fetchDrivingLogDetails: (accessToken,start, end, boxId) => dispatch(fetchDrivingLogDetails(accessToken, start, end, boxId )),
  fetchWarningLogs: (accessToken, boxId, start, end, type) => dispatch(fetchWarningLogs(accessToken, boxId, start, end, type)),
  fetchTollReport: (accessToken, boxId, driverId, start, end) => dispatch(fetchTollReport(accessToken, boxId, driverId, start, end)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrivingLogPage);