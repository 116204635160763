import React, { useCallback, useEffect, useRef, useState } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { H3, P } from "@modul-connect/shared/components/atoms/text";
import theme from "@modul-connect/shared/theme";
import Arrow from "@modul-connect/shared/img/arrow_triangle.svg";

export const ListItemV2 = ({
  onClick,
  isSelected,
  isDisabled,
  title,
  titleComponent,
  subtitle,
  subtitleComponent,
  rightSideComponent,
  scrollRef,
  extend,
  dontChangeBgColorOnSelection = false,
  userIcon,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const styles = getStyles(dontChangeBgColorOnSelection, height, isDisabled);

  useEffect(() => {
    setHeight(ref?.current?.offsetHeight);
    setWidth(ref?.current?.offsetWidth);
  }, [ref.current]);
  return (
    <View
      innerRef={ref}
      extend={[isSelected ? styles.selected : "", styles.wrapper, isDisabled && { opacity: 0.65 }, extend]}
    >
      <View
        className={"vehicleHeader"}
        innerRef={scrollRef}
        onClick={onClick}
        extend={[styles.container, extend]}
      >
        {isSelected && (
          <div
            style={{
              left: width + 21,
              position: "absolute",
              alignSelf: "center",
            }}
          >
            <img height={55} src={Arrow} alt="arrow" />
          </div>
        )}

        {userIcon && <View extend={styles.leftSide}>{userIcon}</View>}
        <View extend={styles.rightSide}>
          <View extend={{flex: 1}}>
          {titleComponent ? titleComponent : <H3 noPadding>{title}</H3>}
          {rightSideComponent ? rightSideComponent : null}
          </View>
        </View>
      </View>
    </View>
  );
};

const getStyles = (dontChangeBgOnSelection, height, isDisabled) => {
  return {
    container: {
      minHeight: "59px",
      borderRadius: "4px",
      gap: "12px",
      padding: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: theme.colors.lightgreyBackground,
      height: "100%",
      flex: 1,
    },
    leftSide: {
      paddingTop: "4px",
      display: "flex",
      alignSelf: "flex-start",
      width: "24px",
    },
    rightSide: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    wrapper: ({ theme: { colors, layout } }) => ({
      color: colors.text,
      "& h3": {
        // height: 27
      },
      "& .icon": {
        width: 24,
      },
      "&:hover": isDisabled ? null : {
        cursor: "pointer",
        "& .vehicleHeader": {
          opacity: 0.8,
        },
      },
    }),
    selected: ({ theme: { layout, colors } }) =>
      !dontChangeBgOnSelection
        ? {
            color: colors.primary,
            "& .vehicleHeader": {
              border: `2px solid ${colors.borderGrey}`,
              boxShadow: `0px 0px 10px 0px ${colors.borderGrey}`
              ,
              "& .icon": {
                color: colors.primary,
              },
            },
          }
        : {
            "& h3": {
              color: colors.primary,
            },
            "& h4": {
              color: colors.primary,
            },
            "& .icon": {
              color: colors.primary,
            },
            "& p": {
              color: colors.primary,
            },
          },
    disabled: ({ theme: { colors, layout } }) => ({
      "& h3": {
        color: colors.grey,
      },
      "& h4": {
        color: colors.grey,
      },
      "& p": {
        color: colors.grey,
      },
      "& .icon": {
        color: colors.grey,
      },
      "&:hover": {
        cursor: "default",
        "& h3": {
          color: colors.grey,
        },
        "& h4": {
          color: colors.grey,
        },
        "& .icon": {
          color: colors.grey,
        },
        "& p": {
          color: colors.grey,
        },
      },
    }),
  };
};
