import { put, fork, take, select } from 'redux-saga/effects';
import { eventChannel } from "redux-saga"

let device_types = [
    {
        name: 'desktop',
        minWidth: 1080
    },
    {
        name: 'tablet',
        minWidth: 801
    },
    {
        name: 'mobile',
        minWidth: 0
    }
]

export function* themesHandler() {
    yield fork(responsiveViewHandler, window.innerWidth)
    yield fork(responsiveViewListener)
}

export function* responsiveViewHandler(viewWidth, viewHight) {
    const themes = yield select(state => state.themes)

    for(var i = 0; i < device_types.length; i++) {
        let device_type = device_types[i]

        if(device_type.minWidth <= viewWidth) {

            if(!(themes && themes.device && themes.device === device_type.name)) {
                yield put({
                    type: 'VIEW_DIMENSION_UPDATED',
                    device: device_type.name,
                    orientation: viewWidth > viewHight ? 'landscape' : 'portrait'
                })
            }
            return
        }
    }
}

export function* responsiveViewListener() {
    const channel = eventChannel(listener => {
        const handleWindowResize = (windowSize) => {
            listener(windowSize);
        }

        window.addEventListener("resize", handleWindowResize)
        return () => window.removeEventListener("resize", handleWindowResize);
    });

    let updatedWindow
    while (true) {
        const windowInfo = yield take(channel);

        updatedWindow = windowInfo && windowInfo.srcElement ?
        {
            width: windowInfo.srcElement.innerWidth,
            height: windowInfo.srcElement.innerHeight,
        } : null

        if(updatedWindow) {
            yield fork(responsiveViewHandler, updatedWindow.width, updatedWindow.height)
        }

    }
}

