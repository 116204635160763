import React from "react";
import View from '@modul-connect/shared/components/atoms/view'
import { H3 } from '@modul-connect/shared/components/atoms/text'

const NoIncidents = () => {
  return (
    <View extend={{paddingLeft: 24}}>
      <H3>No incidents in the selected time period. </H3>
    </View>
  )
}

export default NoIncidents;
