

export const summary_columns = [
  {
    id: "vehicle",
    label: "Vehicle",
    align: "left",
    width: 200
  },
  {
    id: "incidents",
    label: "Incidents",
    align: "left",
    width: 200
  },
]

export const report_columns = [
  {
    id: "date",
    label: null,
    align: "flex-start",
    isSortable: true,
  },
  {
    id: "time",
    label: null,
    align: "flex-start",
    isSortable: true,
  },
  {
    id: "duration",
    label: null,
    align: "flex-start",
    isSortable: true,
  },
  {
    id: "address",
    label: null,
    align: "flex-start",
    icon: true,
    minWidth: 250,
    hasValues: true,
    isSortable: true,
  },
  {
    id: "clearedBy",
    label: null,
    align: "flex-start",
    icon: true,
    minWidth: 250,
    hasValues: true,
    isSortable: true,
  }
]