import React from 'react'
import Button from '@modul-connect/shared/components/atoms/button/button'
import View from '@modul-connect/shared/components/atoms/view';
import { connect } from "react-redux";

const ButtonRow = ({ themes, goToVehicle, goToDrivingLog }) => {
  return (
    <View extend={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: themes.device === 'mobile' ? 'space-evenly' : 'space-between',
      paddingTop: themes.device === 'mobile' ? 15 : 5,
    }}>
      {
        goToVehicle ?
        <View extend={{ paddingRight: '10px' }}>
          <Button
            extend={themes.device === 'mobile' && { height: 50 }}
            warning
            smaller={themes.device === 'mobile' ? false : true}
            onClick={goToVehicle}
          >
            Vehicle details
          </Button>
        </View> : null
      }

      {
        goToDrivingLog ?
        <View extend={{ paddingLeft: '10px' }}>
          <Button
            extend={themes.device === 'mobile' && { height: 50 }}
            smaller={themes.device === 'mobile' ? false : true}
            onClick={goToDrivingLog}
          >
            Driving Log Page
        </Button>
      </View> : null
      }
    </View>
  )
}

const mapStateToProps = ({
  themes
}) => ({
  themes
})

export default connect(mapStateToProps)(ButtonRow);