import ScrollView from "@modul-connect/shared/components/atoms/scroll-view";
import { P } from "@modul-connect/shared/components/atoms/text";
import TextBox from "@modul-connect/shared/components/atoms/text/textBox";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { DateTime } from "luxon";
import React, { useEffect, useRef } from "react"

export const DefectHistoryDisplay = ({ commentHistory }) => {
  const scrollRef = useRef(null)

  useEffect(() => {
    // leave a little time for the ref to be given a value and for the open animation to finish
    setTimeout(() => scrollRef?.current && executeScroll(executeScroll), 150)
  }, [scrollRef?.current])

  const executeScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
  }

  return (
    <ScrollView extend={{ 
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    }}>
     { 
        commentHistory.map((comment, index) => {
          const isLastElement = index === commentHistory?.length - 1
          return <CommentDisplay reference={isLastElement ? scrollRef : undefined} key={index} comment={comment} />
        })
      }
    </ScrollView>
  )
}

const CommentDisplay = ({reference, comment}) => {
  return (
    <TextBox reference={reference}>
      <P noPadding extend={{ fontSize: 14 }}>{dateTimeToString(DateTime.fromISO(comment.timestamp), "date-and-time")}</P>
      <P noPadding>{comment.comment}</P>
      <P noPadding extend={{ fontSize: 14, textAlign: 'right' }}>{' - ' + comment.user}</P>
    </TextBox>
  )
}