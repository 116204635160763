export const getTagString = (tags) => {
  if (!tags) {
    return null
  }
  let tagStrings = []

  let tagsArr = [...tags]
  tagsArr.forEach((tag) => {
    tagStrings.push([...tag].reverse().join(' > '))
  })
  
  return tagStrings.join(', ')
}