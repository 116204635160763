import SecurityAlarm from '@modul-connect/shared/img/security_alarm.svg'
import AssetWarning from '@modul-connect/shared/img/asset_drill.svg'
import WeightWarning from '@modul-connect/shared/img/weight_round.svg'
import ClimateWarning from '@modul-connect/shared/img/climate_round.svg'
import BatteryWarning from '@modul-connect/shared/img/battery_round.svg'

export type NotificationActionTypes =
  NotificationsFetched 
  | FetchNotifications 
  | NotificationsSeen
  | MarkNotificationsSeen
  | NewSecurityNotification


export const NEW_SECURITY_ALARM_NOTIFICATION = 'NEW_SECURITY_ALARM_NOTIFICATION' 
interface NewSecurityNotification {
  type: typeof NEW_SECURITY_ALARM_NOTIFICATION,
  data: NewSecurityNotificationData
}

export const NOTIFICATIONS_FETCHED = 'NOTIFICATIONS_FETCHED' 

interface NotificationsFetched {
  type: typeof NOTIFICATIONS_FETCHED,
  data: Array<NotificationDataFetched>
}

export const NOTIFICATIONS_SEEN = 'NOTIFICATIONS_SEEN' 
export const MARK_NOTIFICATIONS_SEEN = 'MARK_NOTIFICATIONS_SEEN' 

interface MarkNotificationsSeen {
  type: typeof MARK_NOTIFICATIONS_SEEN,
  accessToken: string,
  notificationType: string
  notificationIds: Array<string>
}

interface NotificationsSeen {
  type: typeof NOTIFICATIONS_SEEN,
  accessToken: string,
  notificationType: string
  notificationIds: Array<string>
}

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'

interface FetchNotifications {
  type: typeof FETCH_NOTIFICATIONS,
  accessToken: string,
}

export interface NewSecurityNotificationData {
  alarmId: string
  endedAt: string
  endingReason: string
  id: string
  latitude: number
  longitude: number,
  mainboxId: string
  startedAt: string
  telematicsId: string
  vehicleName: string
  vin: string
}
export interface NotificationDataFetched {
  deviceTriggered: string
  endedAt: string
  endedReason: string
  hasUserSeen: boolean
  mainboxId: string
  notificationId: string
  seenAt: string
  startedAt: string
  telematicsId: string
  userTerminated: string
  vin: string,
  notificationType: NotificationType,
  vehicleName: string,
}

export type NotificationType = "Asset" | "Security" | "Weight" | "Climate" | "Battery"

export interface NotificationData {
  group: NotificationType
  text?: string | undefined
  id?: string | undefined
  vehicle: string 
  seen?: boolean
  group_icon: Icon,
  time_stamp: string
}

type Icon = {}

const Drill:Icon = AssetWarning
const Alarm:Icon = SecurityAlarm
const Scale:Icon = WeightWarning
const Thermometer:Icon = ClimateWarning
const Battery:Icon = BatteryWarning

export const getNotificationIcon = (group: NotificationType): Icon => {
  switch(group) {
      case 'Asset':
        return Drill
      case 'Security':
        return Alarm
      case 'Weight':
        return Scale
      case 'Climate':
        return Thermometer
      case 'Battery':
        return Battery
  }
}

export const getNotificationType = (type: string): NotificationType | undefined => {
  switch(type) {
      case 'Asset warnings':
        return 'Asset'
      case 'Security warnings':
        return 'Security'
      case 'Weight warnings':
        return 'Weight'
      case 'Climate warnings':
        return 'Climate'
      case 'Battery warnings':
        return 'Battery'
  }
}