import React from "react"
import View from '@modul-connect/shared/components/atoms/view';
import { MdBatteryCharging30, MdCloudDownload, MdColorLens, MdFitnessCenter, MdLocalShipping, MdPets, MdSystemUpdateAlt, MdToll, MdTrackChanges, MdViewAgenda } from "react-icons/md";
import {
  MdSettings,
  MdDashboard,
  MdBusiness,
  MdBusinessCenter,
  MdSupervisorAccount,
  MdDescription,
  MdDriveEta,
  MdBuild,
  MdWarning
} from "react-icons/md";
import { FiLogIn } from "react-icons/fi";
import sfCar from '@modul-connect/shared/img/sf_car.svg';
import VWBlack from '@modul-connect/shared/img/vehicle-weighing-black.svg';
import BMBlack from '@modul-connect/shared/img/battery-monitoring-black.svg';
import DJBlack from '@modul-connect/shared/img/driving-journal-black.svg';
import TRBlack from '@modul-connect/shared/img/toll-reports-black.svg';
import Temp from '@modul-connect/shared/img/Temperature.svg'
import Hum from '@modul-connect/shared/img/Humidity.svg'
import LPS from '@modul-connect/shared/img/LPS.svg'
import SecuritySystem from '@modul-connect/shared/img/securitySystem.svg'
import LockIcon from '@modul-connect/shared/img/lock-icon.svg'
import DownloadCloud_v2 from '@modul-connect/shared/img/downloadCloud_v2.svg';
import Download from '@modul-connect/shared/img/downloadIcon.svg';
import DownloadCloud from '@modul-connect/shared/img/DownloadCloudIcon.svg';
import DownloadWhite from '@modul-connect/shared/img/downloadIconWhite.svg';
import AssetTracker from '@modul-connect/shared/img/asset_tracker.svg';
import Dashboard from '@modul-connect/shared/img/dashboard.svg';
import Dropdown from '@modul-connect/shared/img/dropdown.svg';
import Rectangle from '@modul-connect/shared/img/rectangle.svg';
import RectangleSmall from '@modul-connect/shared/img/rectangleSmall.svg';
import Pencil from '@modul-connect/shared/img/pencil.svg';
import Pencil2 from '@modul-connect/shared/img/pepicons-pencil_pen.svg';
import DeleteIcon from '@modul-connect/shared/img/delete_icon.svg';
import Clip from '@modul-connect/shared/img/clip.svg';
import Calendar from '@modul-connect/shared/img/calender.svg';
import MapIcon from '@modul-connect/shared/img/map_icon.svg'
import ApplicationsIcon from '@modul-connect/shared/img/applications_icon.svg'
import FleetIcon from '@modul-connect/shared/img/fleet_icon.svg'
import PurposeIcon_Private from '@modul-connect/shared/img/purpose_private.svg'
import PurposeIcon_Business from '@modul-connect/shared/img/purpose_business.svg'
import NewWindow from '@modul-connect/shared/img/new_window.svg'
import DeleteX from '@modul-connect/shared/img/deleteX.svg'
import { getPdfSVG } from "../../../img/pdf_file";
import Checklist from '@modul-connect/shared/img/check-box-ok.svg'

const styles = {
  default: ({ theme: { colors, layout } }) => ({
    fontSize: '22px',
    color: colors.white,
    fontWeight: 'bold',
    // paddingTop: '2px',
    display: 'flex',
    alignItems: 'center'
  }),
  link: ({ theme: { colors, layout } }) => ({
    transition: "color 200ms ease-in-out",
    "&:hover": {
      color: colors.primary
    }
  }),
  small_size: {
    fontSize: '16px'
  },
  dark: ({ theme: { colors, layout } }) => ({
    color: colors.iconBlack,
    "& img": {
      filter: 'invert(14%) sepia(5%) saturate(337%) hue-rotate(201deg) brightness(15%) contrast(91%);'
    },
    "& svg": {
      filter: 'invert(14%) sepia(5%) saturate(337%) hue-rotate(201deg) brightness(15%) contrast(91%);'
    }
  }),
  selected: ({ theme: { colors, layout } }) => ({
    // color: colors.primary,
    "& img": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)',
      // filter: 'invert(43%) sepia(12%) saturate(1971%) hue-rotate(76deg) contrast(91%);'
    },
    "& img.sfCar": {
      filter: 'grayscale(100%)'
    },
    "& svg": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)',
    }
    // 
  }),
}
export const Icon = ({
  link,
  icon,
  small,
  selected,
  dark,
  size,
  noPadding,
  color
}) => (
  <View extend={[styles.default, link && styles.link, small && styles.small_size, dark && styles.dark, selected && styles.selected]}>
    {
      icon == 'MdTrackChanges' && <MdTrackChanges />
    }
    {
      icon == 'MdSettings' && <MdSettings />
    }
    {
      icon == 'MdDirectionsCar' &&  <img src={sfCar} className='sfCar' width={size ?? '22px'} height={size ?? '22px'} color='black' style={{ paddingBottom: 2 }} alt="Vehicle" />
    }
    {
      icon == 'MdViewAgenda' && <MdViewAgenda />
    }
    {
      icon == 'MdDashboard' && <MdDashboard />
    }
    {
      icon == 'MdBusiness' && <MdBusiness color='black' />
    }
    {
      icon == 'MdBusinessCenter' && <MdBusinessCenter />
    }
    {
      icon == 'MdSupervisorAccount' && <MdSupervisorAccount size={size ?? '22px'} color='black' />
    }
    {
      icon == 'FiLogIn' && <FiLogIn />
    }
    {
      icon == 'MdDescription' && <MdDescription />
    }
    {
      icon == 'MdDriveEta' && <MdDriveEta />
    }
    {
      icon == 'MdTemplates' && <MdSettings color='black' />
      /*
        <MdPets />
        <MdSystemUpdateAlt />
      */
    }
    {
      icon == 'MdBatteryCharging30' && <MdBatteryCharging30 />
    }
    {
      icon == 'MdFitnessCenter' && <MdFitnessCenter />
    }
    {
      icon == 'MdLocalShipping' && <MdLocalShipping />
    }
    {
      icon == 'MdWarning' && <MdWarning />
    }
    {
      icon == 'MdBuild' && <MdBuild color='black' />
    }
    {
      icon == 'MdCloud' && <MdCloudDownload color='black' />
    }
    {
      icon == 'MdToll' && <MdToll />
    }
    {
      icon == 'VWBlack' && <img src={VWBlack} />
    }
    {
      icon == 'BMBlack' && <SVGIcon svgIcon={BMBlack} size={size} />
    }
    {
      icon == 'DJBlack' && <SVGIcon svgIcon={DJBlack} size={size} />
    }
    {
      icon == 'TRBlack' && <SVGIcon svgIcon={TRBlack} size={size} />
    }
    {  
      icon == 'Temp' && <SVGIcon size={size} svgIcon={Temp} />
    }
    {  
      icon == 'Hum' && <SVGIcon size={size} svgIcon={Hum} />
    }
    {
      icon === 'LPS' && <SVGIcon size={size} svgIcon={LPS} />
    }
    {
      icon === 'SecuritySystem' && <SVGIcon size={size} svgIcon={SecuritySystem} />
    }
    {
      icon === 'LockIcon' && <SVGIcon size={size} svgIcon={LockIcon} />
    }
    {
      icon == 'Download' && <img src={Download} />
    }
    {
      icon == 'DownloadCloud' && <img src={DownloadCloud} />
    }
    {
      icon == 'DownloadCloud_v2' && <img src={DownloadCloud_v2} />
    }
    {
      icon == 'Rectangle' && <img src={Rectangle} />
    }
    {
      icon == 'DownloadWhite' && <img src={DownloadWhite} />
    }
    {
      icon == 'Dropdown' && <img src={Dropdown} />
    }
    {
      icon == 'Pencil' && <img src={Pencil} />
    }
    {
      icon == 'Pencil2' && <img src={Pencil2} />
    }
    {
      icon == 'Delete' && <View style={{ 
        width: size ? (noPadding ? size : (size * (24/16))) : 24,
        height: size ? (noPadding ? size: (size * (24/16))) : 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}><SVGIcon size={size ?? 16} svgIcon={DeleteIcon} noContainer /></View>
    }
    {
      icon == 'Clip' && <SVGIcon size={size ?? 20} svgIcon={Clip} noContainer />
    }
    {
      icon == 'RectangleSmall' && <img src={RectangleSmall} />
    }
    {
      icon == 'Calendar' && <img src={Calendar} />
    }
    {
      icon == 'AssetTracker' && <SVGIcon size={size} svgIcon={AssetTracker} />
    }
    {
      icon === 'Dashboard' && <SVGIcon size={size ?? 18} svgIcon={Dashboard} />
    }
    {
      icon == 'MapIcon' && <img width={'34px'} height={'28px'} src={MapIcon} />
    }
    {
      icon == 'ApplicationsIcon' && <img width={'26px'} height={'26px'} src={ApplicationsIcon} />
    }
    {
      icon == 'FleetIcon' && <img width={'33px'} height={'30px'} src={FleetIcon} />
    }
    {
      icon == 'Purpose_Private' && <img src={PurposeIcon_Private} />
    }
    {
      icon == 'Purpose_Business' && <img style={{ backgroundColor: 'red' }} src={PurposeIcon_Business} />
    }
    {
      icon == 'NewWindow' && <img src={NewWindow} />
    }
    {
      icon === 'DeleteX' && <img src={DeleteX} />
    }
    {
      icon == 'PDF_File' && getPdfSVG(color)
    }
    {
      icon == 'Checklist' && <SVGIcon size={size} svgIcon={Checklist} />
    }
  </View>
)

const SVGIcon = ({ svgIcon, size, noContainer }) => {
  const image = <img width={size ?? 23} height={size ?? 23} src={svgIcon} />

  if (noContainer) return image
  return (
    <View style={{ 
      width: 44,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {image}
    </View>
  )
}