

export const vehicleOptions = [
  'Citroën Berlingo 2022',
  'MAN TGE 2025'
]

export const userOptions = [
  'Charlotte Boddien',
  'Emma Schöön',
  'Malin Ellersten'
]

export const technicianOptions = [
  'Dave Hodge',
  null
]

export const defectOptions = [
  {
    relatedToTitle: "Mirrors, cameras and glass",
    description: 'Rear view mirror broken',
    danger: true
  },
  {
    relatedToTitle: "Mirrors, cameras and glass",
    description: 'Crack in rear view mirror',
    danger: false
  },
  {
    relatedToTitle: "Windscreen wipers and washers",
    description: 'Windscreen wiper not working',
    danger: true
  },
  {
    relatedToTitle: "Front view (mirrors, cameras, and glass)",
    description: 'Crack in the windscreen',
    danger: false
  },
  {
    relatedToTitle: "Dashboard warning lights and gauges",
    description: 'Warning light not working',
    danger: true
  },
  {
    relatedToTitle: "Seatbelts",
    description: 'Seatbelt broken',
    danger: false
  },
  {
    relatedToTitle: "Warning Stickers",
    description: 'Warning sticker came partially loose',
    danger: false
  },
  {
    relatedToTitle: "Fuel and oil leaks",
    description: 'Oil leak',
    danger: true
  },
  {
    relatedToTitle: "Battery security and conditions",
    description: 'Battery drains quickly',
    danger: false
  },
  {
    relatedToTitle: "Excessive engine exhaust smoke",
    description: 'Excessive exhaust smoke',
    danger: false
  },
  {
    relatedToTitle: "Number plate",
    description: 'Number plate scratched up and hard to read',
    danger: false
  },
]

// TODO: generate more, automatically ...
export const dummyData = [
  {
    vehicle: 'Citroën Berlingo 2022',
    issues: 0,
    dangerous: 0,
    date: '2024-04-20',
    user: 'Charlotte Boddien',
    informed: null,
  },
  {
    vehicle: 'Citroën Berlingo 2022',
    issues: 1,
    dangerous: 0,
    date: '2024-04-19',
    user: 'Charlotte Boddien',
    informed: 'Dave Hodge',
  },
  {
    vehicle: 'Citroën Berlingo 2022',
    issues: 5,
    dangerous: 2,
    date: '2024-04-18',
    user: 'Charlotte Boddien',
    informed: 'Dave Hodge',
  },
  {
    vehicle: 'Citroën Berlingo 2022',
    issues: 0,
    dangerous: 0,
    date: '2024-04-17',
    user: 'Charlotte Boddien',
    informed: null,
  },
  {
    vehicle: 'Citroën Berlingo 2022',
    issues: 0,
    dangerous: 0,
    date: '2024-04-16',
    user: 'Charlotte Boddien',
    informed: null,
  },

  {
    vehicle: 'MAN TGE 2025',
    issues: 0,
    dangerous: 0,
    date: '2024-04-20',
    user: 'Emma Schöön',
    informed: null,
  },
  {
    vehicle: 'MAN TGE 2025',
    issues: 0,
    dangerous: 0,
    date: '2024-04-17',
    user: 'Emma Schöön',
    informed: null,
  },
  {
    vehicle: 'MAN TGE 2025',
    issues: 0,
    dangerous: 0,
    date: '2024-04-16',
    user: 'Emma Schöön',
    informed: null,
  },
  {
    vehicle: 'MAN TGE 2025',
    issues: 0,
    dangerous: 0,
    date: '2024-04-15',
    user: 'Emma Schöön',
    informed: null,
  },
  {
    vehicle: 'MAN TGE 2025',
    issues: 0,
    dangerous: 0,
    date: '2024-04-12',
    user: 'Emma Schöön',
    informed: null,
  },
  {
    vehicle: 'MAN TGE 2025',
    issues: 1,
    dangerous: 0,
    date: '2024-04-11',
    user: 'Emma Schöön',
    informed: null,
  }
]


export const checkListResponseOptions = {
  OK: 'OK',
  FAIL: 'FAIL'
}

export const checklistCategory = {
  outsideVehicle: 'Check outside the vehicle',
  insideVehicle: 'Check inside the vehicle'
}

export const checklistSteps = [
  /* {
    category: checklistCategory.insideVehicle,
    title: "Front view (mirrors, cameras, and glass)",
    instruction: "Check that no objects get in the way of your front view",
    moreInfo: [
      {
        textLines: [
          "As a general rule, there should be nothing in the swept area of the windscreen wipers.",
          "Some official stickers and road safety items are allowed, as long as they do not seriously block your view of the road, for example, operator licence disc."
        ],
        bulletPoints: []
      }
    ]
  },
  {
    category: checklistCategory.insideVehicle,
    title: "Mirrors, cameras and glass",
    instruction: "Check that the windscreen, windows, mirror and/or camera system are operational.",
    moreInfo: [
      {
        textLines: [
          "Check that the windscreen is not:"
        ],
        bulletPoints: ["cracked", "scratched", "discoloured"]
      },
      {
        textLines: [
          "Check that the windscreen and front side windows are not excessively tinted.",
          "Check that all mirrors are in place and not:"
        ],
        bulletPoints: ["damaged or missing glass", "obscured", "insecure"]
      },
      {
        textLines: ["If a camera system is used instead of a mirror, check that it works and the view is correct."]
      }
    ]
  }, */
  {
    category: checklistCategory.insideVehicle,
    title: "Windscreen wipers and washers",
    instruction: "Make sure the windscreen wipers and washers work.",
    moreInfo: [
      {
        textLines: ["Make sure the windscreen wipers work. Check that they are not:"],
        bulletPoints: ["missing", "damaged or worn"]
      },
      {
        textLines: ["Make sure the windscreen washer is working."],
        bulletPoints: []
      }
    ]
  },
  /* {
    category: checklistCategory.insideVehicle,
    title: "Dashboard warning lights and gauges",
    instruction: "Check that all of them are operational.",
    moreInfo: [
      {
        textLines: ["Check that all of these are working correctly:"],
        bulletPoints: ["instruments", "gauges", "warning lights - including the engine warning, emissions system, anti-lock braking system (ABS), electronic braking system (EBS) and lane guidance systems."]
      }
    ]
  },
  {
    category: checklistCategory.insideVehicle,
    title: "Steering",
    instruction: "Check that the steering wheel is operational.",
    moreInfo: [
      {
        textLines: ["Check that the steering wheel:"],
        bulletPoints: [
          "moves properly and that the power-assisted steering works correctly",
          "has no excessive play",
          "does not jam"
        ]
      },
      {
        textLines: ["Check that there’s no excessive lift or movement in the steering column."]
      }
    ]
  },
  {
    category: checklistCategory.insideVehicle,
    title: "Horn",
    instruction: "Check that the horn works and is easily accessible from the driver’s seat.",
    moreInfo: []
  }, */
  {
    category: checklistCategory.insideVehicle,
    title: "Brakes",
    instruction: "Check that the brakes are operational.",
    moreInfo: [
      {
        textLines: ["Check that:"],
        bulletPoints: [
          "the pedal offers resistance correctly",
          "the brake pedal does not have excessive side play or missing, loose or incomplete anti-slip tread",
          "the footwell is clear"
        ]
      }
    ]
  },
  /* {
    category: checklistCategory.insideVehicle,
    title: "Seatbelts",
    instruction: "Check that the seatbelts are operational.",
    moreInfo: [
      {
        textLines: ["Check that seatbelts:"],
        bulletPoints: [
          "do not have any cuts, damage or fraying that may stop them from working",
          "stay secure when you plug them in",
          "retract against you when fitted, and fully retract when you take them off"
        ]
      }
    ]
  },
  {
    category: checklistCategory.insideVehicle,
    title: "Security and condition of cab, doors and steps",
    instruction: "Make sure everything is secure and operational.",
    moreInfo: [
      {
        textLines: ["Check that:"],
        bulletPoints: [
          "body panels are secure and not likely to fall off",
          "all doors operate as required and secure when closed",
          "steps are secure and safe to use"
        ]
      }
    ] 
  },
  {
    category: checklistCategory.insideVehicle,
    title: "Warning Stickers",
    instruction: "Check that all warning stickers are present and visible.",
    moreInfo: [
      {
        textLines: ["Check that:"],
        bulletPoints: [
          "any warning sticker that is applied and adhered to",
          "‘no smoking’ signs are visible to all occupants",
        ]
      }
    ] 
  }, */
  {
    category: checklistCategory.insideVehicle,
    title: "Warning Stickers",
    instruction: "Check that all warning stickers are present and visible.",
    moreInfo: [
      {
        textLines: ["Check that:"],
        bulletPoints: [
          "any warning sticker that is applied and adhered to",
          "‘no smoking’ signs are visible to all occupants",
        ]
      }
    ] 
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Lights and indicators",
    instruction: "Check that all lights and indicators work correctly.",
    moreInfo: [
      {
        textLines: ["Check that:"],
        bulletPoints: [
          "all lights and indicators work correctly",
          "all lenses are fitted, clean and the right colour",
          "stop lamps come on when you apply the service brake and go out when you release it",
          "marker lights are fitted and work"
        ]
      }
    ] 
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Fuel and oil leaks",
    instruction: "Check that there are no leaks.",
    moreInfo: [
      {
        textLines: ["Turn on the engine and check underneath the vehicle for any fuel or oil leaks."],
        bulletPoints: []
      }
    ] 
  },
  /* {
    category: checklistCategory.outsideVehicle,
    title: "Security of body and wings",
    instruction: "Check that nothing is likely to fall off.",
    moreInfo: [
      {
        textLines: ["Check that:"],
        bulletPoints: ["all fastening devices work", "body panels are secure and not likely to fall off"]
      }
    ] 
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Battery security and conditions",
    instruction: "Check the battery is secure and in good condition.",
    moreInfo: [
      {
        textLines: ["Check that your battery is:"],
        bulletPoints: ["secure", "in good condition", "not leaking"]
      }
    ] 
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Diesel exhaust fluid (AdBlue)",
    instruction: "Check that your diesel vehicle has enough AdBlue diesel exhaust fluid and top up if necessary.",
    moreInfo: [
    ] 
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Excessive engine exhaust smoke",
    instruction: "Check that the exhaust does not emit an excessive amount of smoke.",
    moreInfo: [
    ] 
  }, */
  {
    category: checklistCategory.outsideVehicle,
    title: "EV’s",
    instruction: "Check the range of the vehicle fulfils your journey or you have planned charging stop/s.",
    moreInfo: [
    ] 
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Tyres and wheel fixing",
    instruction: "Check that all tyres meet safety regulations.",
    moreInfo: [{
      textLines: ["Check that:"],
      bulletPoints: [
        "the tyres and wheels are secure",
        "the tyres have a tread depth of at least 1.6mm across the central three-quarters of the tyre. By law, the tread depth must meet this minimum requirement across its complete circumference.",
        "the tyres are inflated correctly",
        "there are no deep cuts in the tyre’s sidewall",
        "there is no cord visible anywhere on the tyre",
        "there are no lumps in the side of the tyre.",
        "all wheel nuts are tight enough - you can check if wheel nut indicators (if fitted) have moved to do this"
      ]
    }]
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Number plate",
    instruction: "Check that the number plate is correct and readable.",
    moreInfo: [{
      textLines: ["Check that the number plate is correct and Check that the number plate is not:"],
      bulletPoints: [
        "broken or incomplete",
        "incorrect or spaced incorrectly",
        "dirty",
        "faded",
        "covered over by anything"
      ]
    }]
  },
  /* {
    category: checklistCategory.outsideVehicle,
    title: "Reflectors",
    instruction: "Check that the reflectors are operational.",
    moreInfo: [{
      textLines: ["Check that the reflectors are not:"],
      bulletPoints: [
        "missing",
        "broken",
        "insecure",
        "fitted incorrectly",
        "the wrong colour",
        "obscured by dirt or other objects"
      ]
    }]
  },
  {
    category: checklistCategory.outsideVehicle,
    title: "Other equipment",
    instruction: "You might need to check other items specific to the vehicle, for example, loading or specialised equipment.",
    moreInfo: []
  }, */
]