import React, { useState, useEffect, Fragment } from 'react';
import theme from "@modul-connect/shared/theme";
import View from "@modul-connect/shared/components/atoms/view";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel } from '@mui/material';
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import { H3, H5, P } from '../../atoms/text';
import Button from '../../atoms/button'
import { MdDelete, MdEdit, MdCheck, MdClose } from "react-icons/md";
import Tag from '../../atoms/tags/tag';
import { BubbleLoadingWidget } from '../bubbleLoadingWidget/bubbleLoadingWIdget';
import { Box, Divider, IconButton } from '@mui/material';
import "./tableWidgetSeparated.css"
import Download from '@modul-connect/shared/img/downloadIcon.svg';
import chroma from 'chroma-js';
import {WarningIcon} from '@modul-connect/shared/components/atoms/icons/warningIcon'
import {Collapse} from '@mui/material';
import SimpleTable from './simpleTable';
import ArrowDownIcon from '@modul-connect/shared/img/expand_arrow_down.svg'
import ArrowUpIcon from '@modul-connect/shared/img/expand_arrow_up.svg'

// like TableWidget, but has updated styling with header and separated table rows

// TODO: Component should use "Card" component for rows, if possible

const cellPadding = '6px 20px'
const cellHeight = '44px'
const dividerHeight = '28px' //cellHeight - (paddingBottom + paddingTop + margin-block-start + margin-block-end)

const headerCellHeight = 24

const hoverBackgroundColor = chroma(theme.colors.lightGrey).brighten(0.1).hex()

const TableWidgetSeparated = ({
  data,
  totalItems,
  columns,
  onFetchData,
  onClickRow,
  searchStr,
  themes,
  extend,
  error,
  selectedRows,
  onRemove,
  editableIds,
  removableIds,
  onEdit,
  onDownload,
  rowsOptions,
  tag,
  defaultSort,
  isSortable = true,
  pagination = true,
  fetchAgainOnChange,
  sidescroll,
  loadingStatus,
  hide,
  isSaving,
  tableTitle,
  collapsibleDataLoadingStatus,
  collapsibleLogColumns
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsOptions ? rowsOptions[0] : 10)
  const [sort, setSort] = useState(defaultSort ? defaultSort : 'created_datedesc')
  const [sortOrder, setSortOrder] = useState(
    defaultSort ? defaultSort.slice(-4) === 'desc' ? 'desc' : 'asc'
      : 'desc')
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false)
  const updateSearchStr = React.useRef(null);
  let currentpage = page + 1

  //const [showLoading, setShowLoading] = useState(false)
  //useLoadingTimer(setShowLoading)

  const isLoading = loadingStatus === 'loading'
  const loadingFailed = loadingStatus === 'failed'
  const noData = !data || data.length === 0

  const firstCellRef = React.useRef(undefined)
  const secondCellRef = React.useRef(undefined)
  const [titleHeaderWidth, setTitleHeaderWidth] = useState(0)

  let columns_filtered
  switch (themes.device) {
    case 'tablet':
      columns_filtered = columns.filter(x => { return x.hide.indexOf('tablet') === -1 })
      break
    case 'mobile':
      columns_filtered = columns.filter(x => { return x.hide.indexOf('mobile') === -1 })
      break
    default:
      columns_filtered = columns
  }

  const hasExpandableData = data?.some(d => d?.isExpandable)
  const columns_total = (columns_filtered?.length + (onDownload ? 1 : 0) + (hasExpandableData ? 1 : 0))

  const openDict = {}
  data.forEach(row => {
    openDict[row.id] = false
  });
  const [open, setOpen] = React.useState(openDict);

  useLoadingTimer(() => setShowTableWhileLoading(false))

  useEffect(() => {
    handleResize()
  })

  function handleResize() {
    setTitleHeaderWidth(
      firstCellRef?.current?.clientWidth + ((firstCellRef?.current?.clientWidth < 175) ? secondCellRef?.current?.clientWidth : 0)
    )
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

  useEffect(() => {
    setRowsPerPage(rowsOptions ? rowsOptions[0] : 10)
  }, [rowsOptions])

  useEffect(() => {
    if (!onFetchData) {
      return
    }

    onFetchData({ rowsPerPage, currentpage, sort, searchStr, tag })
  }, [rowsPerPage, currentpage, sort, searchStr, tag, fetchAgainOnChange])

  useEffect(() => {
    setShowTableWhileLoading(false)
  }, [rowsPerPage, searchStr, tag, fetchAgainOnChange])

  useEffect(() => {
    setShowTableWhileLoading(true)
  }, [sort, currentpage])

  useEffect(() => {
    if (!updateSearchStr.current !== searchStr) {
      setPage(0)
    }

    updateSearchStr.current = searchStr
  }, [searchStr]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [hoveringRow, setHoveringRow] = useState(null)

  return (
    hide ? null :
      <View extend={[{maxWeight: '100%'}, extend, error && styles.error, sidescroll && { overflowY: 'scroll' }, { width: '100%' }]}>
        {isSaving && <P>Saving ...</P>}
        {(!isLoading || showTableWhileLoading) && !noData ? (
          <Fragment>
            <Table sx={styles.separated_table} aria-label="sticky table">
              <TableWidgetHead 
                styles={styles}
                tableTitle={tableTitle}
                columns_filtered={columns_filtered}
                onEdit={onEdit}
                onRemove={onRemove}
                onDownload={onDownload}
                titleHeaderWidth={titleHeaderWidth}
                secondCellRef={secondCellRef}
                isSortable={isSortable}
                sortOrder={sortOrder}
                setSort={setSort}
                setSortOrder={setSortOrder}
                setPage={setPage}
                firstCellRef={firstCellRef}
                columns_total={columns_total}
                hasExpandableData={hasExpandableData}
              />
              <TableBody>
                {data.slice(0, rowsPerPage).map((row, index) => {
                  return (
                    <Fragment key={index}>
                      <TableWidgetRow
                        key={'row' + index}
                        selectedRows={selectedRows}
                        styles={styles}
                        row={row}
                        setHoveringRow={setHoveringRow}
                        hoveringRow={hoveringRow}
                        columns_filtered={columns_filtered}
                        onClickRow={onClickRow}
                        onEdit={onEdit}
                        onRemove={onRemove}
                        onDownload={onDownload}
                        columns_total={columns_total}
                        index={index}
                        editableIds={editableIds}
                        removableIds={removableIds}
                        hasExpandableData={hasExpandableData}
                        setOpen={setOpen}
                        open={open}
                      />
                      {row?.isExpandable ? 
                        <CollapsibleContent 
                          open={open} 
                          row={row} 
                          columns_total={columns_total} 
                          data={row.collapsibleData} 
                          loadingStatus={collapsibleDataLoadingStatus} 
                          columns={collapsibleLogColumns}
                        /> 
                        : null
                      }
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
            {pagination &&
              <TablePagination
                showFirstButton
                showLastButton
                rowsPerPageOptions={rowsOptions || [10, 25, 50, 100]}
                component="div"
                count={totalItems || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />}
          </Fragment>
        ) : (
          <Fragment>
            {
              isLoading ? <BubbleLoadingWidget text={"Loading ... "} />
                : loadingFailed ? <H3>Could not fetch data.</H3>
                  : (noData ? <H3>No data to display.</H3> : null)
            }
          </Fragment>
        )}
      </View>
  )
}

const TableWidgetHead = ({
  styles,
  tableTitle,
  columns_filtered,
  onEdit,
  onRemove,
  onDownload,
  titleHeaderWidth,
  secondCellRef,
  isSortable,
  sortOrder,
  setSort,
  setSortOrder,
  setPage,
  firstCellRef,
  columns_total,
  hasExpandableData
}) => {
  const firstLabelIndex = columns_filtered?.findIndex((c) => c.label);
  const secondLabelIndex = columns_filtered?.findIndex(
    (c, index) => index > firstLabelIndex && c.label
  );

  const handleSortBy = (sortValue) => {
    let order = "asc";
    if (sortValue === sort) {
      order = sortOrder === "asc" ? "desc" : "asc";
    }
    order === "asc" ? setSort(sortValue) : setSort(sortValue + "desc");
    setSortOrder(order);
    setPage(0);
  };

  const tableTitleStyle = {
    fontWeight: "normal",
    fontSize: 12,
    color: theme.colors.white,
    lineHight: "13.28px",
  }

  const downloadCellStyle = {
    borderBottom: "none",
    marginLeft: "100px",
    width: "500px",
    textAlign: "center",
    color: theme.colors.midDarkGrey,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: cellHeight,
    fontWeight: "normal",
    fontFamily: "Inter",
    minWidth: 125,
  }

  const columnHeaderStyle = (column) => { return {
    maxWidth: column.maxWidth,
    minWidth: column.minWidth,
    width: column.width,
    borderBottom: "none",
    padding: 0,
    fontFamily: "Inter",
  }}

  const ActionColumnHeader = ({title}) => {
    return (
      <TableCell
        className={"separatedTableWidgetHeadCell"}
        key={"downloadHeader"}
        sx={downloadCellStyle}
      >
        <LabelCell justifyContent="flex-end">
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingRight: 20,
            }}
          >
            {title}
          </View>
        </LabelCell>
      </TableCell>
    );
  };
  const TableTitle = () => {
    return (
      <View
        extend={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100%",
          width: titleHeaderWidth ? titleHeaderWidth : undefined,
        }}
      >
        <View extend={[styles.headerCell, { minHeight: 24 }]}>
          <H5 extend={tableTitleStyle}>{tableTitle}</H5>
        </View>

        <Divider sx={{ bgcolor: "white" }} orientation="vertical" flexItem />
      </View>
    );
  };

  return (
    <TableHead>
      {tableTitle ? (
        <TableRow tabIndex={-1} key={"header"}>
          <TableCell
            className={"separatedTableWidgetCell"}
            style={{
              ...styles.header,
            }}
            key={"header_text"}
            colSpan={
              columns_filtered.length +
              (onEdit || onRemove || onDownload ? 1 : 0) +
              (hasExpandableData ? 1 : 0)
            }
          >
            <TableTitle hasExpandableData={hasExpandableData}/>
          </TableCell>
        </TableRow>
      ) : null}
      <TableRow className={"separatedTableWidgetHead"}>
        {columns_filtered.map((column, index) => {
          return column?.label ? (
            <TableCell
              className={"separatedTableWidgetHeadCell"}
              ref={
                index === firstLabelIndex
                  ? firstCellRef
                  : index === secondLabelIndex
                  ? secondCellRef
                  : null
              }
              key={column.key || column.id}
              colSpan={column.colSpan ?? 1}
              align={column.align}
              style={columnHeaderStyle(column)}
              onClick={() => {
                if (isSortable) {
                  handleSortBy(column.id);
                }
              }}
              sx={{
                textAlign: column.isTag
                  ? "right"
                  : column?.labelAlign ?? "center",
                color: theme.colors.midDarkGrey,
                lineHeight: "13.28px",
                padding: 0,
              }}
            >
              <LabelCell
                className={"separatedTableWidgetHeadCell"}
                justifyContent={
                  column.isTag
                    ? "flex-end"
                    : index === columns_total - 1
                    ? "center"
                    : undefined
                }
              >
                <View style={{...styles.headerCell, alignSelf: "center", width: index < columns_total - 1 ? "100%" : "auto"}}>
                  {column.label}
                  {isSortable ? (
                    <TableSortLabel
                      active={sort === column.id || sort === column.id + "desc"}
                      direction={sortOrder === "asc" ? "asc" : "desc"}
                    ></TableSortLabel>
                  ) : null}
                </View>
                {index < columns_total - 1 ? (
                  <Divider orientation="vertical" flexItem />
                ) : null}
              </LabelCell>
            </TableCell>
          ) : null;
        })}
        {(onDownload || onEdit || onRemove) ? (
          <ActionColumnHeader title={onDownload ? 'Download Period' : ''}/>
        ) : null}
        {hasExpandableData ? (
          <ActionColumnHeader title={' '}/>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

const TableWidgetRow = ({
  selectedRows,
  styles,
  row,
  columns_total,
  setHoveringRow,
  hoveringRow,
  columns_filtered,
  onClickRow,
  onEdit,
  onRemove,
  onDownload,
  index,
  editableIds,
  removableIds,
  hasExpandableData,
  open,
  setOpen
}) => {
  let columnIndex = 0
  return (
    (<TableRow
      className={"separatedTableWidgetRow"}
      style={selectedRows && selectedRows.includes(row.id) ? styles.selected : null}
      role="checkbox"
      tabIndex={-1}
      key={row?.key || row?.id || row?.name || index}
      sx={{ height: cellHeight }}
      onMouseEnter={() => { setHoveringRow(row.id); }}
      onMouseLeave={() => { setHoveringRow(null); }}
    >
      {columns_filtered.map((column, index) => {
        columnIndex = index
        let value, guard;
        if (column.hasGuard) {
          value =
            row[column.id]?.value || row[column.id]?.value === 0
              ? row[column.id].value
              : "-";
          guard =
            row[column.id]?.guard || row[column.id]?.value === 0
              ? row[column.id].guard
              : null;
        } else
          value =
            row[column.id] || row[column.id] === 0
              ? row[column.id]
              : column.defaultValue ?? "-";

        var warning = column.warning ? column.warning(value) : null

        const align = column.align
        ? column.align === "left"
          ? "flex-start"
          : column.align === "right"
          ? "flex-end"
          : "center"
        : column.isTag
        ? "flex-end"
        : "flex-start"

        return (
          (<TableCell
            className={"separatedTableWidgetCell"}
            key={column.id}
            align={column.isTag ? "right" : column.align}
            onClick={(event) => {
              const wasClickOnBtn =
                event.target.tagName === "BUTTON" ||
                event.target.tagName === "IMG";
              if (onClickRow && !wasClickOnBtn) {
                onClickRow(event, row.id, row);
              }
            }}
            sx={[{
              ...styles.bodyCell,
              color:
                !row[column.id] && column.greyIfNoValue
                  ? theme.colors.disabledText
                  : theme.colors.text
            }, (onClickRow && hoveringRow === row.id ? { backgroundColor: hoverBackgroundColor } : {})]}
          >
            <TableCellWrapper align={align} index={index} columns_total={columns_total}>

                {column.isTag ? (
                  <Tag
                  textColor={column.textColor}
                  value={value}
                  tagColor={
                    column.tagColor ? column.tagColor(value, guard) : null
                  }
                  />
                  ) : column.isCheck && value === true ? (
                    <MdCheck />
                    ) : column.isCheck ? (
                      <MdClose />
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                        ) : (
                          <React.Fragment>
                            <div className="text-ellipsis--2">{value}</div> 
                            {
                              warning ? <WarningIcon size={'small'} extend={{paddingLeft: 5}}/> : null
                            }
                          </React.Fragment>
                          )}
                {column.showButton && column.showButton(value) && (
                  <Button
                  noBg
                  noPadding
                  smaller
                  extend={{
                    marginLeft: "15px",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  disabled={column.buttonDisabled(row)}
                  onClick={() =>
                    column.onClickButton && column.onClickButton(row)
                  }
                  >
                    {typeof column.buttonTitle === "function"
                      ? column.buttonTitle(row)
                      : column.buttonTitle}
                  </Button>
                )}

                {/* {
                  column.label === 'Note' ?
                  <Icon icon={'Dropdown'} style={{ marginLeft: -100 }} />
                  : null
                } */}
            </TableCellWrapper>
          </TableCell>)
        );
      })}
      <SpecialCells 
        onEdit={onEdit} 
        onRemove={onRemove}
        onDownload={onDownload} 
        onClickRow={onClickRow} 
        hoveringRow={hoveringRow} 
        editableIds={editableIds} 
        removableIds={removableIds}
        row={row} 
        hasExpandableData={hasExpandableData}
        index={columnIndex}
        columns_total={columns_total}
        setOpen={setOpen}
        open={open}
      />
    </TableRow>)
  );
}

const TableCellWrapper = ({ children, align, index, columns_total, onClick }) => {
  return (
    <View extend={{ display: "flex", fontSize: 12 }} onClick={onClick}>
      <View
        extend={{
          padding: cellPadding,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          maxHeight: cellHeight,
          alignItems: "center",
          justifyContent: align,
        }}
      >
        {children}
      </View>
      {index < columns_total - 1 ? (
        <Divider
          sx={{ height: dividerHeight }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
      ) : null}
    </View>
  );
};
const SpecialCells = ({
  onEdit,
  onRemove,
  onDownload,
  onClickRow,
  hoveringRow,
  editableIds,
  removableIds,
  row,
  hasExpandableData,
  index,
  columns_total,
  open,
  setOpen
}) => {
  let newIndex = index + 1

  const specialCells = []

  if (onEdit && (editableIds ? editableIds.includes(row.id) : true)) {
    specialCells.push(<IconButton
      key={newIndex}
      sx={styles.actionButton}
   //   onClick={(event) => onDownload(event, row.id)}
    >
      <MdEdit onClick={(event) => onEdit(event, row.id)} />
    </IconButton>)
    newIndex++
  }
  if (onRemove && (removableIds ? removableIds.includes(row.id) : true)) {
    specialCells.push(<IconButton
      key={newIndex}
      sx={styles.actionButton}
 //     onClick={(event) => onDownload(event, row.id)}
    >
      <MdDelete onClick={(event) => onRemove(event, row.id)} />
    </IconButton>)
    newIndex++
  }
  if (onDownload) {
    specialCells.push(<IconButton
      key={newIndex}
      sx={styles.actionButton}
      onClick={(event) => onDownload(event, row.id)}
    >
      <View style={{ display: "flex", justifyContent: "center" }}>
        <img src={Download} />
      </View>
    </IconButton>)
    newIndex++
  }



  if (row?.isExpandable) {
    specialCells.push(
      <TableCellWrapper
        key={newIndex}
        align={"right"}
        index={newIndex}
        columns_total={columns_total}
        onClick={() => {
          const newOpen = open
          newOpen[row.id] = !open[row.id]
          setOpen(newOpen)
          row?.onExpand ? row?.onExpand() : null
        }}>
        <View style={{height: '100%', width: '100%', display:'flex', flexDirection: "row", }}>
          <Fragment>
            <View style={{color: theme.colors.text}}>{'Show all'}</View> 
            <img style={{ paddingLeft: 5, width: '25%', }} src={open[row.id] ? ArrowUpIcon : ArrowDownIcon} /> 
          </Fragment>
        </View>
      </TableCellWrapper>
    )
    newIndex++
  }
  return (
    (onEdit || onRemove || onDownload || row?.isExpandable) ? (
      <TableCell
        className={"separatedTableWidgetCell"}
        key={"button"}
        style={{ ...styles.bodyCell, ...styles.action }}
        sx={
          onClickRow && hoveringRow === row.id
            ? { backgroundColor: hoverBackgroundColor }
            : {}
        }
        onClick={(event) => {
          const wasClickOnBtn =
            event.target.tagName === "BUTTON" ||
            event.target.tagName === "IMG";
          if (onClickRow && !wasClickOnBtn) {
            onClickRow(event, row.id, row);
          }
        }}
      >
        { specialCells?.length ? specialCells : null }
      </TableCell>
  ) : null)
}

const LabelCell = ({ children, justifyContent = 'space-between' }) => {
  return (
    <View extend={{
      display: 'flex',
      flexDirection: 'row',
      height: headerCellHeight,
      width: '100%',
      paddingTop: '5px',
      paddingBottom: '5px',
    }}>
      <View extend={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: justifyContent,
        maxHeight: cellHeight,
        fontWeight: 'normal',
      }}>
        {children}
      </View>
    </View>
  )
}

const CollapsibleContent = ({ row, columns_total, open, data, loadingStatus, columns }) => {
  return (
    <TableRow>
      <TableCell
        style={{...styles.bodyCell, paddingBottom: 0, paddingTop: 0, borderBottom: "none" }}
        colSpan={columns_total}
      >
        <Collapse in={open[row.id]} timeout="auto" unmountOnExit sx={{ margin: 0 }}>
          <Box sx={{ margin: 0 }}>
            <SimpleTable
              data={data}
              isLoading={loadingStatus}
              columns={columns}
            />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

const styles = {
  error: {
    border: `1px solid ${theme.colors.error}`
  },
  selected: {
    backgroundColor: theme.colors.primaryLight
  },
  bodyCell: {
    borderBottom: 'none',
    padding: 0,
    overflow: 'clip',
    fontFamily: 'Inter',
  },
  action: {
    height: cellHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    color: theme.colors.grey,
    fontSize: 18,
    borderBottom: 'none',
  },
  actionButton: {
    marginLeft: '5px',
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99, // so click on button does not register as click on row
  },
  header: {
    backgroundColor: '#888888',
    borderRadius: 4,
    padding: 4,
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'default',
    ...theme.shadowStyle
  },
  headerCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  separated_table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 5px',

    // TODO: should find a better solution for this
    marginBottom: '-10px', // ugly fix to reduce spacing between tables - the above is applied to the top and bottom of both otherwise, leading to double the distance between them 
  }
}

export default TableWidgetSeparated;