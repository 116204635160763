import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import AssetTrackerReportDetails from "./assetTrackerReportDetails/assetTrackerReportDetails";
import { fetchAssetTrackerReport, fetchVehiclesData } from "../../../state/actions/vehicles";
import { getServiceList } from "../../../utils/ServiceList";
import { ServiceType } from "@modul-connect/shared/utils/services";



// hidden, for debug purposes mostly
const AssetTrackerReportPage = ({
  vehicles_data,
  fetchVehiclesData,
  loading,
  fetchAssetTrackerReport,
  subtrees
}) => {
  const { getAccessTokenSilently } = useAuth0();

  let [startDateDetails, setStartDateDetails] = useState(DateTime.local().startOf("month"));
  let [endDateDetails, setEndDateDetails] = useState(DateTime.local());
  let [selectedVehicle, setSelectedVehicle] = useState(null);

  // TODO: put the proper check in once the asset tracker page is released to the customer
  const disabled = false//!getServiceList({subtrees}).hasService(ServiceType.AssetTrackers)

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => {
      const start = startDateDetails ? startDateDetails : null;
      const end = endDateDetails ? endDateDetails : null;
      fetchAssetTrackerReport(accessToken, start, end, selectedVehicle);
    });
  }, [startDateDetails, endDateDetails, selectedVehicle]);

  useEffect(() => {
    if ((!vehicles_data || !vehicles_data.data || vehicles_data.data.length === 0) && loading.fetchVehiclesData !== 'loading') {
        getAccessTokenSilently().then(fetchVehiclesData)
    }
  }, [])

  return (
    <React.Fragment>
      <AssetTrackerReportDetails
        disabled={disabled}
        startDate={startDateDetails}
        endDate={endDateDetails}
        onStartDatePicked={(date) => setStartDateDetails(date.startOf("day"))}
        onEndDatePicked={(date) => setEndDateDetails(date.endOf("day"))}
        onVehicleSelected={setSelectedVehicle}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  fetchAssetTrackerReport: (accessToken, start, end, boxId) => dispatch(fetchAssetTrackerReport(accessToken, start, end, boxId)),
  fetchVehiclesData: (accessToken) => dispatch(fetchVehiclesData(accessToken))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetTrackerReportPage);
