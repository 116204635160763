import { getStore } from "../state/configureStore"

export const showFilterBar = () => {
  return false
}

export const isAllowedToSeeDemoPages = () => {
  const user = getStore().getState()?.authenticated_user
  
  if (!user?.email) return false

  if (user.email === 'malin.ellersten@modul-system.com') return true
  if (user.email === 'emma.schoon@modul-system.com') return true
  if (user.email === 'dave.hodge@modul-system.co.uk') return true

  return user.email?.includes('@seerefine.se')
}