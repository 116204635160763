import Dashboard from "./pages/dashboard/dashboard";
import Fleet from "./pages/fleet";
import Reports from "./pages/reports/reports";
import { LogoutLandingPage } from "./pages/logout-callback/logoutCallback"

export const getRoutes = (geoLocationNotAllowed) => {
  const routes = [];

  if (geoLocationNotAllowed) {
    routes.push(
      {
        url: "/login_success",
        name: "Applications",
        component: Reports,
        icon: "MdDashboard",
      },
      {
        url: "/",
        name: "Applications",
        tooltip: "Applications overview and reports",
        component: Reports,
      }
    );
  } else {
    routes.push(
      {
        url: "/login_success",
        name: "Callback",
        component: Dashboard,
        icon: "MdDashboard",
      },
      {
        url: "/",
        name: "Map",
        tooltip: "Fleet overview",
        component: Dashboard,
        icon: "MapIcon",
        navbar: true,
      }
    );
  }

  routes.push(
    {
      url: "/logout/*",
      name: "Logout",
      public: true, 
      component: LogoutLandingPage,
    },
    {
      url: "/reports/dashboard",
      name: "Applications",
      tooltip: "Applications overview and reports",
      component: Reports,
      icon: "ApplicationsIcon",
      navbar: true,
    },
    {
      url: "/reports/:option?/:summaryId?/:detailsId?",
      name: "Reports",
      component: Reports,
    },
    {
      url: "/fleet/fleet_vehicles",
      name: "My Fleet",
      tooltip: "Vehicles and users overview",
      component: Fleet,
      icon: "FleetIcon",
      navbar: true,
    },
    {
      url: "/fleet/:option?/:vehicleId?",
      name: "Fleet option",
      component: Fleet,
    }
  )
  return routes
};
