import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import { log_columns } from './log_columns'
import { checkTrackersBatteryIssue } from "../../../../utils/assets";
import { fetchAssetTrackerAddresses } from "../../../../state/actions/vehicles";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from 'luxon'
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { log_columns_collapsable } from "./log_columns_collapsable";
import DisabledNotificationIcon from '@modul-connect/shared/img/disabled_notificaion.svg'
import EnabledNotificationIcon from '@modul-connect/shared/img/enabled_notification.svg'
import GeoPositionICon from '@modul-connect/shared/img/geo_point.svg'
import BatteryFullIcon from '@modul-connect/shared/img/battery_full.svg'
import BatteryWarningIcon from '@modul-connect/shared/img/battery_warning.svg'
import { formatAddressTrackers } from "../../../../utils/vehicleUtils";

const IconBatteryWarning = <img style={{ width: 20, maxHeight: '100%' }} src={BatteryWarningIcon} /> 
const IconBatteryNoWarning = <img style={{ width: 20, maxHeight: '100%' }} src={BatteryFullIcon} /> 

const AssetTrackerTable = ({
  assets,
  vehicle_assets,
  loading,
  themes,
  fetchAssetTrackerAddresses,
  setOpenModal,
  searchFilter,
  filterOption
}) => {
  let [data, setData] = useState({})  
  let [allData, setAllData] = useState({})

  useEffect(() => {
    if( data?.length){
      let filteredData = []
      filteredData = allData?.filter(d => d?.homebase?.toLowerCase()?.includes(searchFilter.toLowerCase()))
      setData(filteredData)
    }
    if (!searchFilter)
      setData(allData)

  }, [searchFilter])

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() =>{
    let preparedData = []
    let nextId = 0

     if(assets && assets?.length > 0){
      assets.map((vehicle) => {
        var missingTrackers = vehicle.assets?.filter(t => !t.isInside)
        var batteryIssueTrackers = vehicle.assets?.filter(t => checkTrackersBatteryIssue(t?.tracker?.currentStatus?.batteryLevel))?.length
        var boxId = vehicle.mainbox?.boxId
        
        var collapsibleData = Object.keys(vehicle_assets)?.length 
        ? 
          vehicle_assets[boxId]?.length
          ?  vehicle_assets[boxId].map(beacon => {
            var currentAsset = vehicle.assets.filter(t => t.tracker.beaconId === beacon.beaconId)?.[0]
            var address = beacon?.address?.formattedAddress ? formatAddressTrackers(beacon?.address) : {address: beacon?.isMoving ? 'Vehicle is driving' : 'Unknown address'} 
            var timeStamp = currentAsset?.tracker?.currentStatus?.timeStamp ? currentAsset?.tracker?.currentStatus?.timeStamp : currentAsset?.tracker?.location?.timeStamp
            var date = dateTimeToString(DateTime.fromISO(timeStamp), 'date-and-time') === 'Invalid DateTime' 
              ? '-' 
              : dateTimeToString(DateTime.fromISO(timeStamp), 'date-and-time')?.replace(", ", "-")

            const addressStr = currentAsset?.isInside ? 'In the vehicle': address?.address ? address?.address + (address?.postCodeCity ?? '') : 'Unknown addres'

            const hasBatteryIssue = checkTrackersBatteryIssue(currentAsset?.tracker?.currentStatus?.batteryLevel)
              
            return [
              {value: vehicle?.vehicleName},
              {warning: !currentAsset?.isInside, value: !currentAsset?.isInside, tooltip: currentAsset?.isInside ? undefined : "The asset is missing" },
              {value: currentAsset?.name},
              {value: currentAsset?.category},              
              {icon: hasBatteryIssue ? BatteryWarningIcon : BatteryFullIcon, value: hasBatteryIssue, tooltip: hasBatteryIssue ? "Battery is low" : "No battery warning"},
              {icon: GeoPositionICon, value1: addressStr, value2: currentAsset?.isInside ? '' : date ?? '' }, 
              {icon: 
                currentAsset?.notificationEnabled 
                ? EnabledNotificationIcon
                : DisabledNotificationIcon , 
                value: currentAsset?.notificationEnabled,
                tooltip: currentAsset?.notificationEnabled ? 'Notifications enabled' : 'Notifications disabled'
              }, 
              {value:'Details', onClick: () => setOpenModal(beacon.beaconId) }]
            }
          )
          : []
        : []
        nextId++
          preparedData.push({
            key: nextId,
            id: nextId,
            boxId: boxId,
            homebase: vehicle.vehicleName,
            assetTrackers: vehicle.assets?.length,
            missing: missingTrackers?.length > 0 ? missingTrackers.length : '-',
            missing_tooltip: `${missingTrackers?.length ?? 'No'} asset trackers are missing`,
            battery: batteryIssueTrackers > 0 ? IconBatteryWarning : IconBatteryNoWarning,
            battery_tooltip: `${batteryIssueTrackers ?? 'No'} asset trackers with battery warnings`,
            isExpandable: true,
            onExpand:() => {
              getAccessTokenSilently().then((accessToken) => {
                fetchAssetTrackerAddresses(accessToken, boxId);
              })
            },
            collapsibleData: collapsibleData.sort((a,b) => (b?.[0].warning > a?.[0].warning) ? -1 : 1)
          }
        )
      }
    )
   }

    if (preparedData && preparedData.length > 0) {
      var sortedData = preparedData.sort(function(a, b) {
        var textA = a.homebase?.toUpperCase();
        var textB = b.homebase?.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      setData(sortedData)
      setAllData(sortedData)
    } else {
      setData({})
      setAllData({})
    }
  }, [assets, vehicle_assets])
   
  return Object.keys(data)?.length ? 
      <TableWidgetSeparated
        data={data}
        loadingStatus={loading.fetchAssetTrackers}
        columns={log_columns}
        themes={themes}
        isSortable
        defaultSort={'missing'}
        defaultSortOrder={'desc'}
        pagination={false}
        rowsOptions={[assets?.length]}
        collapsibleDataLoadingStatus={loading.fetchAssetTrackersAddresses}
        collapsibleLogColumns={log_columns_collapsable}
        collapsibleDefaultSortIndex={1}
        collapsibleDefaultSortOrder={'desc'}
        onelineOnly
      />
     : null
}


const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  fetchAssetTrackerAddresses: (accessToken, boxId) => dispatch(fetchAssetTrackerAddresses(accessToken, boxId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetTrackerTable);