import React from "react";
import LogChart from "@modul-connect/shared/components/organisms/logChart";
import View from "@modul-connect/shared/components/atoms/view";
import KpiRow from "@modul-connect/shared/components/molecules/kpiRow/kpiRow";
import theme from "@modul-connect/shared/theme"
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { P } from '@modul-connect/shared/components/atoms/text';
import { H3 } from '@modul-connect/shared/components/atoms/text'
import { MdFileDownload } from "react-icons/md";
import Paper from '@modul-connect/shared/components/atoms/paper'
import { intervals } from "./logGraphs";

export const ClimateSensorGraph = ({
  vehicle,
  vehicleId,
  title,
  loadingStatus,
  onDownload,
  isDownloadingReport,
  kpiData,
  downloadStatus,
  logs,
  type, // 'temperature' or 'humidity'
  logInterval,
  onLogIntervalChanged,
  getTicks,
  loadingMessage,
  loadingFailedMessage
}) => {
  return (
    <Paper
      title={title}
      linkTitle={loadingStatus !== 'loading' ? 'Download Report (last 30 days)' : null}
      onClickLink={onDownload}
      linkIcon={<MdFileDownload color={isDownloadingReport ? theme.colors.disabledGrey : undefined} />}
      linkDisabled={isDownloadingReport}
    >
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>

        <KpiRow width={'100%'} data={kpiData} />

        {
          downloadStatus?.status === 'working' && downloadStatus?.boxId === vehicleId ?
            <BubbleLoadingWidget text={'Preparing file ...'} />
            :
            downloadStatus?.status === 'failed' && downloadStatus?.boxId === vehicleId ?
              <H3>Something went wrong when trying to download the report</H3>
              : null
        }

        {
          loadingStatus !== 'loading' ?
            (type === 'temperature' ? 
              vehicle?.currentStatus?.thermometers 
              : vehicle?.currentStatus?.humiditySensors
            ).map((sensor, index) => {
              const cs = vehicle?.climateSensors?.find(s => s.sensor_id === sensor.id)

              const array = cs ? logs?.find(log => log?.length && log[0].id === cs.sensor_id) : null
              return <LogChart
                key={cs?.name ? cs?.name : cs?.sensor_id ?? sensor.id}
                intervalOptions={!index ? Object.values(intervals) : null}
                selectedInterval={logInterval}
                onIntervalChanged={onLogIntervalChanged}
                data={array ?? []}
                data_key={type === 'temperature' ? "celcius" : "humidity"}
                data_name={type === 'temperature' ? "Temperature" : "Humidity"}
                title={type === 'temperature' ?
                  `Temperature — ${cs?.name ? cs?.name : cs?.sensor_id ?? sensor.id}`
                  : `Humidity — ${cs?.name ? cs?.name : cs?.sensor_id ?? sensor.id}`
                }
                max_name="High limit"
                min_name="Low limit"
                warning_max_name="Warning"
                warning_min_name="Warning"
                unit={type === 'temperature' ? '°C' : '%'}
                domain={type === 'temperature' ? [
                  array ? Math.floor(Math.min(...array.map(log => (log?.min || log?.min === 0) ? Math.min(log.min, log.celcius) : log.celcius))) : 0,
                  array ? Math.ceil(Math.max(...array.map(log => (log?.max || log?.max === 0) ? Math.max(log.max, log.celcius) : log.celcius))) : 30
                ] : [
                  0,
                  100
                ]}
                yAxisPadding={{ top: 5, bottom: 5 }}
                displayTimeInHours={logInterval === intervals.LAST_24_H ? true : false}
                ticks={getTicks(logInterval)}
              />}) : loadingStatus === 'loading' ? <BubbleLoadingWidget text={loadingMessage} /> : <P>{loadingFailedMessage}</P>
        }
      </View>
    </Paper>
  )
}