import { NotificationActionTypes, NOTIFICATIONS_FETCHED, FETCH_NOTIFICATIONS, MARK_NOTIFICATIONS_SEEN } from "../types/notifications"

export const fetchNotifications = (accessToken: string) : NotificationActionTypes => ({
  type: FETCH_NOTIFICATIONS,
  accessToken
})


export const notificationsFetched = (data: any) : NotificationActionTypes => ({
  type: NOTIFICATIONS_FETCHED,
  data
})



export const markNotificationsSeen = (accessToken: string, notificationType: string, notificationIds: Array<string>) : NotificationActionTypes => ({
  type: MARK_NOTIFICATIONS_SEEN,
  accessToken,
  notificationType,
  notificationIds
})
