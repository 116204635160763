import { ServiceType } from '@modul-connect/shared/utils/services';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getServiceList } from '../../../utils/ServiceList';
import TollPassagesDetailedPage from './detailed/TollPassagesDetailedPage';
import TollPassagesSummaryPage from './summary/tollPassagesSummaryPage';

const TollPassagePage = ({subtrees,selected_organisations}) => {

  const groupingOptions = {
    byVehicle: 'vehicle',
    byDriver: 'driver'
  }

  const disabled = !getServiceList({subtrees}).hasService(ServiceType.TollPassages)
  const navigate = useNavigate()
  const { summaryId } = useParams()

  const location = useLocation();
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [groupBy, setGroupBy] = useState( useQuery().get('groupby') ?? groupingOptions.byVehicle)


  const changeGroupBy = (group) => {
    setGroupBy(group)
    navigate('/reports/reports_toll-reports/' + '?groupby=' + group)
  }

  const [id, date] = summaryId?.split('-') ?? []
  const [month, year] = date?.split('_') ?? []

  let boxId, driverId
  if (groupBy === groupingOptions.byDriver) {
    driverId = id
    boxId = null
  } else {
    driverId = null
    boxId = id
  }

  let monthYearDT 
  if (month && year && !isNaN(month) && !isNaN(year)) {
    monthYearDT = DateTime.local().set({month: month, year: year})
  }

  let [showDetailed, setShowDetailed] = useState(summaryId)
  let [detailedName, setDetailedName] = useState('')

  const onSummaryClicked = (event, id, summary) => {
    navigate('/reports/reports_toll-reports/' + id);
    setDetailedName(summary.vehicleName ?? summary.driver)
    setShowDetailed(id)
  }

  useEffect(() => {
    setShowDetailed(summaryId)
  }, [summaryId,selected_organisations])
            
  return showDetailed ?
   <TollPassagesDetailedPage
    groupBy={groupBy}
     name={detailedName}
     boxId={boxId}
     driverId={driverId}
     disabled={disabled}
     selectedMonth={monthYearDT}/>
      : <TollPassagesSummaryPage 
       groupBy={groupBy}
       disabled={disabled}
       setGroupBy={changeGroupBy}
       onSummaryClicked={onSummaryClicked}/>
    }

    const mapStateToProps = props => props;


export default connect(mapStateToProps) (TollPassagePage);