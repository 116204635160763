import {
  useId,
  useState,
  useContext,
  createContext,
} from "react";
import {
  DateRange,
  DayEventHandler,
  DayPicker,
  Matcher,
  ModifiersClassNames,
} from "react-day-picker";
import View from "../../atoms/view";
import { dateTimeToString } from "../../../utils/dateTimeUtils";
import { DateTime } from "luxon";
import Dialog from "@mui/material/Dialog";
import { Paper } from "@mui/material";
import Calendar from "@modul-connect/shared/img/callendar_black.svg";
import theme from "@modul-connect/shared/theme";
import { P } from "@modul-connect/shared/components/atoms/text";
import Button from "@modul-connect/shared/components/atoms/button";

const CalendarIcon = <img src={Calendar} />;

// The skeleton for this code has been taken from: https://daypicker.dev/v8/advanced-guides/input-fields (16.09.2024)

const DayPickerContext = createContext<
  PickerCalendarProps & DayCalendarProps & { toggleDialog: () => void }
>({
  defaultMonth: undefined,
  disableDaysBefore: undefined,
  disableDaysAfter: undefined,
  modifiers: undefined,
  onDayMouseEnter: (date, modifiers, e) => {},
  onDayMouseLeave: (date, modifiers, e) => {},
  month: new Date(),
  range: undefined,
  onMonthChange: (month: Date) => {},
  modifiersClassNames: undefined,
  toggleDialog: () => {},
});

type DatePickerProps = {
  disabled?: boolean;
  placeholder?: string;
  mode: "single" | "range"; // | "multiple" can be implemented later
  selectionUnit: "days" | "months";
  onDayPicked: (day: DateTime) => void
};

type DayCalendarProps = {
  date?: DateTime;
  handleDayPickerSelect?: (date: Date) => void;
};

type PickerCalendarProps = {
  defaultMonth?: Date;
  disableDaysBefore?: DateTime;
  disableDaysAfter?: DateTime;
  modifiers: Record<string, undefined | Matcher | Matcher[]>;
  onDayMouseEnter?: DayEventHandler<React.MouseEvent>;
  onDayMouseLeave?: DayEventHandler<React.MouseEvent>;
  modifiersClassNames?: ModifiersClassNames;
  month: Date;
  range?: DateRange;
  onMonthChange: (month: Date) => void;
};

type RangePickerProps = {
  rangeProps?: {
    selected: DateRange;
    disabled: DateTime[];
    onSelectionMade: (range: DateRange) => void;
  };
  onRangePicked: (newRange: DateRange) => void;
};

export function DayPickerInput({
  defaultMonth,
  disableDaysBefore,
  disableDaysAfter,
  disabled,
  date,
  onDayPicked,
  placeholder,
  mode = "single",
  selectionUnit = "days",
  modifiers,
  rangeProps,
  onDayMouseEnter,
  onDayMouseLeave,
  modifiersClassNames,
}: DatePickerProps &
  PickerCalendarProps &
  RangePickerProps &
  DayCalendarProps) {

  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(defaultMonth ?? new Date());
  const [range, setRange] = useState(rangeProps?.selected);

  // Hold the dialog visibility in state
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Function to toggle the dialog visibility
  const toggleDialog = () => {
    if (!isDialogOpen) {
      // reset on opening
      setMonth(defaultMonth ?? new Date());
      setRange(rangeProps?.selected);
    }
    setIsDialogOpen(!isDialogOpen);
  };

  const handleDayPickerSelect = (date: Date) => {
    if (date) {
      const dateTime = DateTime.fromJSDate(date);
      if (onDayPicked) onDayPicked(dateTime);
    }
    setIsDialogOpen(false);
  };

  return (
    <DayPickerContext.Provider
      value={{
        defaultMonth: defaultMonth,
        disableDaysBefore: disableDaysBefore,
        disableDaysAfter: disableDaysAfter,
        modifiers: modifiers,
        onDayMouseEnter: onDayMouseEnter,
        onDayMouseLeave: onDayMouseLeave,
        modifiersClassNames: modifiersClassNames,
        month: month,
        range: range,
        onMonthChange: setMonth,
        toggleDialog: toggleDialog,
        date: date,
      }}
    >
      <View
        onClick={disabled ? null : toggleDialog}
        extend={inputField_style(disabled ?? false)}
      >
        <View>
          {date ? dateTimeToString(date, "date-only") : (placeholder ?? "")}
        </View>

        <CalendarDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}>
          <Paper
            sx={{
              padding: "15px 20px",
              minHeight: 360,
              minWidth: 380,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {mode === "single" ? (
              <SingleDayPicker handleDayPickerSelect={handleDayPickerSelect} />
            ) : selectionUnit === "months" ? (
              <MonthRangePicker
                rangeProps={rangeProps}
                onRangePicked={setRange}
              />
            ) : (
              <DayRangePicker
                rangeProps={rangeProps}
                onRangePicked={setRange}
              />
            )}
          </Paper>
        </CalendarDialog>
        {CalendarIcon}
      </View>
    </DayPickerContext.Provider>
  );
}

const SingleDayPicker = ({ handleDayPickerSelect }: DayCalendarProps) => {
  const context = useContext(DayPickerContext);

  return (
    <DayPicker
      month={context.month}
      defaultMonth={context.defaultMonth}
      onMonthChange={context.onMonthChange}
      mode={"single"}
      selected={context.date?.toJSDate()}
      onSelect={handleDayPickerSelect}
      endMonth={context.disableDaysAfter?.endOf("month").toJSDate()}
      startMonth={context.disableDaysBefore?.startOf("month").toJSDate()}
      disabled={{
        before: context.disableDaysBefore?.toJSDate(),
        after: context.disableDaysAfter?.toJSDate(),
      }}
      modifiers={context.modifiers}
      modifiersClassNames={context.modifiersClassNames}
      onDayMouseEnter={context.onDayMouseEnter}
      onDayMouseLeave={context.onDayMouseLeave}
    />
  );
};

const DayRangePicker = ({ rangeProps, onRangePicked }: RangePickerProps) => {
  const context = useContext(DayPickerContext);

  return (
    <View>
      <DayPicker
        month={context.month}
        defaultMonth={context.defaultMonth}
        onMonthChange={context.onMonthChange}
        endMonth={context.disableDaysAfter?.endOf("month").toJSDate()}
        startMonth={context.disableDaysBefore?.startOf("month").toJSDate()}
        mode={"range"}
        selected={context.range}
        onSelect={(selected, triggerDate, modifiers, e) => {
          if (selected) onRangePicked(selected)
        }}
        excludeDisabled
        modifiers={context.modifiers}
        modifiersClassNames={context.modifiersClassNames}
        onDayMouseEnter={context.onDayMouseEnter}
        onDayMouseLeave={context.onDayMouseLeave}
        disabled={{
          before: context.disableDaysBefore?.toJSDate(),
          after: context.disableDaysAfter?.toJSDate(),
        }}
      />
      {<P small>{"Double click to select a different start date."}</P>}
      <View
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          noBg
          disabled={!context.range?.to || !context.range?.from}
          onClick={() => {
            context.range &&
              rangeProps &&
              rangeProps.onSelectionMade &&
              rangeProps.onSelectionMade(context.range);
            context.toggleDialog();
          }}
        >
          {"OK"}
        </Button>
      </View>
    </View>
  );
};

const MonthRangePicker = ({ rangeProps, onRangePicked }: RangePickerProps) => {
  const [selecting, setSelecting] = useState<"start" | "end">("start");

  const context = useContext(DayPickerContext);

  return (
    <View>
      <DayPicker
        month={context.month}
        defaultMonth={context.defaultMonth}
        onMonthChange={context.onMonthChange}
        endMonth={context.disableDaysAfter?.endOf("month").toJSDate()}
        startMonth={selecting === "end" ? context.range?.from : undefined}
        mode={"range"}
        selected={context.range}
        onSelect={(selected, triggerDate, modifiers, e) => {
          if (selecting === "start") {
            onRangePicked({
              from: DateTime.fromJSDate(triggerDate)
                .startOf("month")
                .toJSDate(),
              to: undefined,
            });
            setSelecting("end");
          } else {
            onRangePicked({
              from: context.range!.from,
              to: DateTime.fromJSDate(triggerDate).endOf("month").toJSDate(),
            });
            setSelecting("start");
          }
        }}
        excludeDisabled
        modifiers={context.modifiers}
        modifiersClassNames={context.modifiersClassNames}
        onDayMouseEnter={context.onDayMouseEnter}
        onDayMouseLeave={context.onDayMouseLeave}
        disabled={{
          before:
            selecting === "end"
              ? context.range?.from
              : context.disableDaysBefore?.toJSDate(),
          after: context.disableDaysAfter?.toJSDate(),
        }}
      />
      {!context.range?.to ? (
        <P small>{"Please click again to select an end month."}</P>
      ) : null}
      <View
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          noBg
          disabled={!context.range?.to || !context.range?.from}
          onClick={() => {
            context.range &&
              rangeProps &&
              rangeProps.onSelectionMade &&
              rangeProps.onSelectionMade(context.range);

            setSelecting("start"); // reset
            context.toggleDialog();
          }}
        >
          {"OK"}
        </Button>
      </View>
    </View>
  );
};

const inputField_style = (disabled: boolean) => {
  return {
    padding: "10px 5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    minWidth: 140,
    gap: 10,
    alignItems: "center",
    opacity: disabled ? 0.5 : 1,
    "&:hover": {
      cursor: disabled ? "default" : "pointer",
    },
    color: theme.colors.text,
  };
};

const CalendarDialog = ({ isDialogOpen, setIsDialogOpen, children }: {isDialogOpen: boolean, setIsDialogOpen: (val: boolean) => void, children: React.JSX.Element | React.JSX.Element[]}) => {
  const dialogId = useId();
  const headerId = useId();

  return (
    <Dialog
      role="dialog"
      id={dialogId}
      aria-modal
      aria-labelledby={headerId}
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onClick={(e) => {
        e.stopPropagation(); // don't propagate click event upwards, don't trigger parent's toggleDialog
      }}
      open={isDialogOpen}
    >
      {children}
    </Dialog>
  );
};