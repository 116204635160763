import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import { H3 } from '@modul-connect/shared/components/atoms/text'
import OverweightIcon from '@modul-connect/shared/img/weight_map_red.svg'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { DateTime } from 'luxon'
import { convertToSelectedUnit } from '../../../../../utils/unitConverter'
import { selectRoute, unselectRoute } from '../../../../../state/actions/vehicles'
import { RouteMarker } from './routeMarker'
import theme from "@modul-connect/shared/theme";


const RouteListItem = ({ index, route, selectRoute, unselectRoute, selectedRoute, isMobile }) => {
  const scrollRef = useRef(null)

  const weightIssue = route.hasWeightWarning

  const text = dateTimeToString(DateTime.fromISO(route.startTime), 'time-only') + 
  (route?.endTime ? ' — ' + dateTimeToString(DateTime.fromISO(route.endTime), 'time-only') : '') + ': ' +
  (route?.endTime ? convertToSelectedUnit(route.distance, 1, 'distance') : 'ongoing')

  const selected = (selectedRoute?.boxId === route.boxId) && (selectedRoute.endTime === route.endTime)

  return (
    <View innerRef={scrollRef} key={text}
      onClick={ () => {
        if (!selected) {
          selectRoute(route.boxId, route.endTime)
        }
        else {
          unselectRoute()
        }
      }}
      /* onMouseOver={() => {
        if (!selected) {
          selectRoute(route.boxId, route.endTime)
          setSelected(true)
        }
      }}
      onMouseLeave={() => {
        if (selected) {
          unselectRoute()
          setSelected(false)
        }
      }} */
      extend={[]}
   >
    <View className={ 'vehicleHeader' } extend={[styles.wrapper, isMobile && { paddingBottom: '15px' }, selected && { '& h3': {
        color: theme.colors.primary,
      } }]}>
      <View>
        <View extend={ styles.titles }>
          <RouteMarker text={index + 1}/> <H3 noPadding>{ text }</H3>
          { weightIssue ?
            <View extend={styles.icons}>
              { weightIssue ?
                <img style={styles.warningIcon} src={OverweightIcon} alt='Overweight'/> : undefined
              }
            </View> : null
          }
        </View>
      </View>
    </View>
    </View>
  )
}

const styles = {
  titles: {
    display: 'flex',
    paddingLeft: 4,
  },
  icons:{
    paddingRight: 4,
    '> img': {
      marginLeft: 6,
      marginTop: -6,
      width: 12
    }
  },
  wrapper: ({ theme: { colors, layout } }) => ({
    display: 'flex',
    color: colors.text,
    '& h3': {
      color: colors.text,
    },
    '& p': {
      position: 'relative',
      top: -8
    },
    padding: `8px 4px`,
    '& .icon': {
      width: 24
    },
    borderRadius: layout.borderRadius,
    '&:hover': {
      cursor: 'pointer',
      '& h3': {
        color: colors.primary,
      },
      '& .icon': {
        color: colors.primary,
      },
      '& p': {
        color: colors.primary
      },
    },
  }),
  selected: ({ theme: { layout, colors } }) => ({
    borderRadius: layout.borderRadius,
    color: colors.primary,
    '& .vehicleHeader': {
      backgroundColor: colors.primaryLight,
      '& h3': {
        color: colors.primary
      },
      '& p': {
        color: colors.white
      },
      '& .icon': {
        color: colors.primary,
      },
    },
  }),
  warningIcon: {
    marginLeft: '10px',
    maxWidth: '20px',
    maxHeight: '20px'
  },
}

const mapStateToProps = ({
  selectedVehicle,
  selectedRoute
}) => ({
  selectedVehicle,
  selectedRoute
})

const mapDispatchToProps = dispatch => ({
  selectRoute: (boxId, endTime) => dispatch(selectRoute(boxId, endTime)),
  unselectRoute: () => dispatch(unselectRoute())
})

export default connect(mapStateToProps, mapDispatchToProps)(RouteListItem)
