import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "@modul-connect/shared/components/atoms/page";
import View from "@modul-connect/shared/components/atoms/view";
import Button from "@modul-connect/shared/components/atoms/button/button";
import { P } from "@modul-connect/shared/components/atoms/text";
import Paper from '@modul-connect/shared/components/atoms/paper'
import MainContainer from "@modul-connect/shared/components/organisms/main-container";
import { getStore } from "../../state/configureStore";
import NavBar from "@modul-connect/shared/components/organisms/nav-bar";

export const LogoutLandingPage = () => {
  const auth0 = useAuth0();

  const state = getStore().getState()
  const themes = state.themes

  return (
    <View>
      <NavBar
        noOrgSelection
        noNotifications
        noUser
        showFilterBar={() => false}
        routes={[]}
        authTool='auth0'
        username={null}
        themes={ themes }
      />
    <MainContainer themes={themes} showFilterBar={() => false} blockScrolling={true} extend={{ width: '100%' }}>
    <Page>
      <Paper extend={{ width: '100%' }}>
        <View style={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          alignSelf: 'flex-start'
        }}>
          <P>You are logged out. Please note that this may happen automatically if you stay logged in for a long time.</P>
          <View>
            <Button onClick={() => { 
              auth0.loginWithRedirect().catch((e) => console.error('error logging in: ', e))
            }}>{'Login'}</Button>
          </View>
        </View>
      </Paper>
    </Page>
    </MainContainer>
    </View>
  )
}