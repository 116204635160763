import React from 'react'
import Paper from '@modul-connect/shared/components/atoms/paper'
import Page from '@modul-connect/shared/components/atoms/page.v2'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchUsers, addUser, removeUser } from '../../../state/actions/users'
import { H3 } from '@modul-connect/shared/components/atoms/text'
import user_columns from './user_columns'
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget'
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget.v2'
import AddUserModal from './addUserModal'
import { useEffect } from 'react'
import ModalRemoveItem from '@modul-connect/shared/components/molecules/modalRemoveItem'
import { fetchSubtrees } from '../../../state/actions/customer'
import { getTagString } from '../../../utils/stringify'
import { Header } from "@modul-connect/shared/components/atoms/header";

const Users = ({
  themes,
  users,
  fetchUsers,
  fetchSubtrees,
  addUser,
  removeUser,
  subtrees,
  authenticated_user,
  saving,
  loading,
  selected_organisations }) => {

  let filterTimer = null;
  const [name, setName] = React.useState('');
  const [emailToRemove, setEmailToRemove] = React.useState('')
  const [showAddUserModal, setShowAddUserModal] = React.useState(false);
  const [currentTableOptions, setCurrentTableOptions] = React.useState(null);


  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if ((!users || Object.keys(users).length === 0) && loading.fetchUsers !== 'loading') {
      fetchUsersWithAccessToken({ rowsPerPage: 10, currentpage: 1, sort: 'name', searchStr: '' })
    }
  }, [])

  useEffect(() => {
    getAccessTokenSilently()
      .then(accessToken => fetchSubtrees(accessToken))
  }, [])

  useEffect(() => {
    if (saving && saving.ajaxError) {
      setShowAddUserModal(false)
    }

    if (saving && !saving.addUser) {
      setShowAddUserModal(false)
    }
  }, [saving])

  useEffect(() => {
    if (currentTableOptions)
      fetchUsersWithAccessToken(currentTableOptions)
  }, [selected_organisations])

  const fetchUsersWithAccessToken = (options) => {
    getAccessTokenSilently()
      .then(accessToken => fetchUsers(accessToken, options))
  }

  const addUserWithAccessToken = (info) => {
    getAccessTokenSilently()
      .then(accessToken => addUser(accessToken, info))
  }

  const removeUserWithAccessToken = (email) => {
    const user = users?.data.find(u => u.email === email)
    let tags = user?.metadata?.info?.tags
    if (!tags) return; // todo: better error handling. but this shouldn't happen.

    let tagStrings = []
    tags.forEach(t => {
      tagStrings.push(t.tags)
    })

    getAccessTokenSilently()
      .then(accessToken => removeUser(accessToken, email, tagStrings))
  }

  const users_prepared = users.data ? users.data.map(item => {
    let info = item && item.metadata ? item.metadata.info : null;

    return {
      name: (item?.firstName && item?.lastName) ? item.firstName + ' ' + item.lastName : null,
      email: item?.email ? item.email : null,
      tags: info ? info.tags : null,
      translatedTags: getTagString(item?.translatedTags),
      id: item?.email // for the table widget
    }
  }) : []

  const handleNameChange = (event) => {
    setFilterAfterDelay(event.target.value)
  }

  const setFilterAfterDelay = (newFilter) => {
    if (filterTimer) {
      clearTimeout(filterTimer)
    }

    filterTimer = setTimeout(() => {
      setName(newFilter)
    }, 1000)
  }

  const handleClick = (event, id) => {
    // history.push('/users/' + id);
  }

  let columns_filtered
  switch (themes.device) {
    case 'tablet':
      columns_filtered = user_columns.filter(x => { return x.hide.indexOf('tablet') === -1 })
      break
    case 'mobile':
      columns_filtered = user_columns.filter(x => { return x.hide.indexOf('mobile') === -1 })
      break
    default:
      columns_filtered = user_columns
  }

  const onSameLevel = (tags1, tags2) => {
    let same = false
    tags1.forEach(tag1 => {
      tags2.forEach(tag2 => {
        if (tag1.tags === tag2.tags) {
          same = true
        }
      })
    })
    return same
  }

  if (users && users_prepared) {
    return (
      <Page>
        <Paper title={'Users'} linkTitle={'Add user'} onClickLink={() => {
          setShowAddUserModal(true)
        }}>
          <SearchWidget
            onChange={handleNameChange}
            placeholder={'Search for user...'}
            initialValue={name}
          />

          <TableWidget
            loadingStatus={loading.fetchUsers}
            data={users_prepared}
            totalItems={users && users.total ? users.total : 0}
            columns={user_columns}
            onFetchData={(options) => {
              setCurrentTableOptions(options)
              fetchUsersWithAccessToken(options)
            }}
            onClickRow={handleClick}
            searchStr={name}
            themes={themes}
            defaultSort={"name"}
            onRemove={(event, email) => { setEmailToRemove(email) }}
            removableIds={users_prepared?.length ? users_prepared.map(user => {
              /* all users are under the authenticated user in the hierarchy, or same level
              => only if they are at the same level is the user NOT allowed to remove them*/

              if ((user && user.tags && user.tags.length) ? !onSameLevel(user.tags, authenticated_user.tags) : false) {
                return user.email
              }
            }) : []}
            extend={{ width: "100%", paddingTop: 24 }}
          />

          <AddUserModal
            openModal={showAddUserModal}
            onClose={() => setShowAddUserModal(false)}
            addUser={addUserWithAccessToken}
            tagtrees={subtrees?.subtrees ?? []}
            disabled={saving && saving.addUser === 'saving'}
            userAlreadyExist={saving && saving.addUser === 'conflict'}
            incorrectEmail={saving && saving.addUser === 'incorrect_email'}
            addingFailed={saving && saving.addUser === 'failed'}
          />

          <ModalRemoveItem
            openModal={emailToRemove ? true : false}
            onClose={() => setEmailToRemove('')}
            onSubmit={() => {
              removeUserWithAccessToken(emailToRemove)
              setEmailToRemove('')
            }}
            header={' Are you sure you want to remove the user?'}
            content={'The user will no longer be able to log in and use the service.'}
          />
        </Paper>
      </Page>
    );
  }
  else {
    return (
      <Paper><H3 children={"Loading..."} /></Paper>
    )
  }
}

const styles = {
  containerCSS:
    ({ theme: { layout, colors } }) => ({
      borderRadius: `${0.5 * layout.grid}px`,
      backgroundColor: 'white',
      width: '100%',
      paddingBottom: 50
    }),
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  fetchUsers: (accessToken, options) => dispatch(fetchUsers(accessToken, options)),
  addUser: (accessToken, info) => dispatch(addUser(accessToken, info)),
  removeUser: (accessToken, email, tags) => dispatch(removeUser(accessToken, email, tags)),
  fetchSubtrees: (accessToken) => dispatch(fetchSubtrees(accessToken))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);