import React from 'react'
import { P } from "@modul-connect/shared/components/atoms/text"
import { Fragment } from 'react'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { convertToSelectedUnit, formatAddress } from '../../../../utils/unitConverter'
import Tag from '@modul-connect/shared/components/atoms/tags/tag'
import View from '@modul-connect/shared/components/atoms/view/view'

 export const getDuration = (startTime, endTime) =>{
  
  function getDifference(a, b) {
    return Math.abs(a - b);
  }

  function timeConvert(n) {
    var num = n;
    var hours = (num / 3600000);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return (rhours < 10 ? '0' + rhours : rhours) + ":" + (rminutes < 10 ? '0' + rminutes : rminutes) + "h";
  }
  const duration = getDifference(endTime, startTime)
  return timeConvert(duration)
}


export const RouteInfoText = ({
  isOngoing,
  startTime,
  endTime,
  log,
}) => {
  const tagValue = log?.purpose ? (log.purpose.toLowerCase() === 'work' ? 'Business' : 'Private') : null

  return (
    <View extend={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px',
      paddingBottom: '5px',
      height: '100%',
      alignSelf: 'flex-end',
    }}>
      {
        isOngoing ?
          <InfoLine title={'Start time'} value={startTime?.isValid ? dateTimeToString(startTime, 'time-only') : ' - '} />
          :
          <InfoLine title={'Time'} value={startTime?.isValid && endTime?.isValid ?
            dateTimeToString(startTime, 'time-only') + ' - ' + dateTimeToString(endTime, 'time-only')
            : ' - '} />
      }
      <InfoLine title={'Duration'} value={getDuration(startTime, endTime)} />
      <InfoLine title={'Start'} value={log?.startAddress ? formatAddress(log.startAddress) : ' - '} />
      {!isOngoing &&
        <InfoLine title={'End'} value={log?.stopAdress ? formatAddress(log.stopAdress) : ' - '} />
      }
      <InfoLine title={'Driver'} value={log?.driver ?? '-'} />
      <InfoLine title={'Start mileage'} value={(log?.startMileage || log?.startMileage === 0) ? convertToSelectedUnit(log.startMileage, 2, 'distance') : '-'} />
      {!isOngoing &&
        <InfoLine title={'Mileage'} value={(log?.endMileage || log?.endMileage === 0) ? convertToSelectedUnit(log.endMileage, 2, 'distance') : '-'} />
      }
      <InfoLine title={'Note'} value={log?.note ? log?.note : 'No note for this trip'} />
      <View style={{ display: 'flex', flexDirection: 'row', height: 19, alignItems: 'center' }}>
        {/* <View style={{ paddingRight: 10 }}>
          <P>Purpose: </P>
        </View> */}
        {tagValue ? <Tag value={tagValue} tagColor={tagValue} /> : null}
      </View>
    </View>
  )
}

export const InfoLine = ({ value, width, paddingLeft, color, fontSize, extend}) => {
  return (
    <View style={{
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: '10px',
      paddingTop: '5px',
      width: width,
      paddingLeft: paddingLeft,
      color: color,
      fontSize: fontSize ? fontSize : 14,
    }}>
      <P noPadding extend={extend}>{value}</P>
    </View>
  )
}