import { DateTime } from 'luxon'
import moment from 'moment'

const date_and_time = 'date-and-time'
const date_only = 'date-only'
const time_only = 'time-only'
const month_and_year = 'month-and-year'
const day_and_date = 'day-and-date'
const day_month_and_year = 'day-month-and-year'
const month_only = 'month-only'
const year_only = 'year-only'
const day_and_month = 'day-and-month'

export const dateTimeToString = (
  date: DateTime, 
  format: 
    typeof date_and_time | 
    typeof date_only | 
    typeof time_only | 
    typeof month_and_year | 
    typeof day_month_and_year | 
    typeof month_only | 
    typeof year_only | 
    typeof day_and_month | 
    typeof day_and_date = date_and_time
  ) : string => {
  if (!date?.isValid) return '-'
  switch(format) {
    case date_and_time:
      return date?.toLocaleString(DateTime.DATETIME_SHORT)
    case date_only:
      return date?.toLocaleString()
    case time_only:
      return date?.toLocaleString(DateTime.TIME_SIMPLE)
    case month_and_year:
      return date.toLocaleString({ month: 'long', year: 'numeric' })
    case month_only:
      return date.toLocaleString({ month: 'long' })
    case day_and_month:
      return date.toLocaleString({ day: 'numeric' , month: 'long' })
    case year_only:
      return date.toLocaleString({ year: 'numeric' })
    case day_and_date:
      return date.toLocaleString({ weekday: 'long', day: 'numeric', month: 'long' })
    case day_month_and_year:
      return date.toLocaleString({ day: 'numeric' , month: 'long', year: 'numeric' })
    default:
      return '-'
  }
}

export const dateStringToDate = (str: string) : moment.Moment | undefined => (
  str && str.trim() != ''
  ? moment(new Date(
    Number.parseInt(str.substring(0,4)),
    (Number.parseInt(str.substring(4,6)) - 1),
    Number.parseInt(str.substring(6,8)),
    Number.parseInt(str.substring(8,10)),
    Number.parseInt(str.substring(10,12))))
  : undefined
)

export const dateTimeToEpoch = (dateTime: DateTime, rounding: "ceil" | "floor" = "ceil") => {
  if (rounding === 'floor') return Math.floor(dateTime.toUTC().toSeconds())
  return Math.ceil(dateTime.toUTC().toSeconds())
}

export const isSameDay = (day1: DateTime, day2: DateTime) => {
  return (
    day1.year === day2.year &&
    day1.month === day2.month &&
    day1.day === day2.day
  )
}