export const setMapFilter = (filter) => (
  {
    type: 'SET_MAP_FILTER',
    filter
  }
)

export const setMapStartTime = (startTime) => (
  {
    type: 'SET_MAP_START_TIME_O_CLOCK',
    startTime  
  }
)

export const setMapEndTime = (endTime) => (
  {
    type: 'SET_MAP_END_TIME_O_CLOCK',
    endTime  
  }
)

export const setMapLiveMode = (isLive, accessToken) => (
  {
    type: 'SET_MAP_LIVE_MODE',
    isLive,
    accessToken
  }
)

export const setMapDate = (date, accessToken) => (
  {
    type: 'SET_MAP_DATE',
    date,
    accessToken
  }
)