import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import theme from "@modul-connect/shared/theme";

export const ModalSection = ({ children, extend }) => {
  return (
    <View style={{
      display: 'flex',
      padding: '12px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      borderRadius: '8px',
      backgroundColor: theme.colors.white,
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
      ...extend
    }}>
      <View style={{ display: "flex", width: '100%', flexDirection: "column", gap: '26px' }}>
        {children}
      </View>
    </View>
  );
};