import React from 'react';
import View from "../view";
import chroma from 'chroma-js';

const Button = ({
    children,
    extend,
    cancel,
    disabled,
    warning,
    noBg,
    noPadding,
    device,
    issue,
    small,
    smaller,
    color,
    backgroundColor,
    ...props
  }) => {
  return <View as="button" {...props} disabled={disabled} extend={[
      styles.button,
      noBg && styles.noBg,
      cancel && noBg && styles.cancel_noBg,
      issue && noBg && styles.issue_noBg,
      warning && styles.warning,
      noPadding && styles.noPadding,
      small && styles.small,
      smaller && styles.smaller,
      color && { color: color },
      backgroundColor && {backgroundColor: backgroundColor},
      disabled && (noBg ? styles.disabled_noBg : styles.disabled),
      device == 'tablet' && styles.tablet,
      extend,
  ]}>
    {
      children
    }
  </View>
  };

const noHoverAnimation = {
  filter: 'none',
  backgroundColor: 'auto',
  cursor: 'default !important'
}

const styles = {
  button: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.primary,
    fontFamily: layout.fontFamily,
    textTransform: 'uppercase',
    fontSize: '16px',
    textAlign: 'center',
    color: colors.white,
    border: "none",
    padding: `${1 * layout.grid }px ${2 * layout.grid }px`,
    cursor: 'pointer !important',
    transition: "color 200ms ease-in-out",
    "&:hover": {
      filter: 'brightness(110%)'
    },
    borderRadius: layout.borderRadius
  }),
  warning: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.orange,
  }),
  noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: colors.primary,
    '&:hover': {
      filter: 'brightness(100%)',
      backgroundColor: chroma(colors.black).alpha(0.05).hex()
    }
  }),
  cancel_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: colors.grey,
  }),
  disabled_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: colors.disabledGrey,
    "&:hover": noHoverAnimation,
  }),
  disabled: ({ theme: { colors } }) => ({
    backgroundColor: colors.disabledGrey,
    '&:hover': noHoverAnimation,
    color: colors.grey,
  }),
  issue_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: colors.error,
  }),
  small: {
    fontSize: 12
  },
  smaller: {
    fontSize: 10
  },
  noPadding: {
    padding: 0
  },
  tablet: {
    fontSize: '14px'
  }
}

export default Button;
