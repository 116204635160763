import { getMapStyle } from './create-map'
import theme from '@modul-connect/shared/theme'
import { getCenterOffset, maxZoomLevelForClustering, updateLayerRenderOrder } from './utils'
import { getStore } from '../../../state/configureStore'
import * as turf from '@turf/turf'

const clusterRadius = 35
const font = ["Open Sans Bold","Arial Unicode MS Bold"]

function Markers ({id, map, icon, offset, scale, rotate, opacity, cluster}) {
  let mapStyle = getMapStyle(map)
  return {
    'id': id,
    'filter': ['!', ['has', 'point_count']],
    'source': {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': []
      },
      'cluster': cluster,
      'clusterMaxZoom': maxZoomLevelForClustering,
      'clusterRadius': clusterRadius, // Radius of each cluster when clustering points 
    },
    'type': 'symbol',
    'layout': {
      'text-field': ['get', 'name'], 
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
      'text-size': 12,
      'text-font': font,
      'text-allow-overlap': true,
      'text-variable-anchor': ['top', 'top-left', 'top-right', 'right', 'left'],

      'icon-image': icon,
      'icon-offset': offset || [0, 0],
      'icon-size': scale || 1,
      'icon-rotate': rotate ? ['get', 'bearing'] : 0,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    },
    'paint': {
      'icon-opacity': opacity ?? 1,
      'text-color': mapStyle === 'style_satellite' ? 'white' : 'black',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
        'text-halo-color': mapStyle === 'style_satellite' ? 'black' : 'white',
    }
  }
}

function BeaconMarkers ({id, map}) {
  const style = getMapStyle(map)
  return Markers({id, map, icon: style === 'style_basic_map' ? 'tracker_map' : 'tracker_map_light', scale: 1.3, cluster: true})
}

function SecurityMarkers ({id, map}) {
  const style = getMapStyle(map)
  return Markers({id, map, icon: style === 'style_basic_map' ? 'security_system_map' : 'security_system_map_white', scale: 0.7, opacity: 0.7, cluster: false})
}

function VehicleMarkers ({id, map}) {
  const style = getMapStyle(map)
  const icon = style === 'style_basic_map' ? 'truck' : 'truck_light'
  return Markers({id, map, icon, rotate: true, cluster: true})
}

function VehiclePositionMarkers({id, map}) {
  const style = getMapStyle(map)
  const icon = style === 'style_basic_map' ? 'truck' : 'truck_light'
  return Markers({id, map, icon, rotate: true, opacity: 0.5, cluster: true})
}

function DestinationFlag ({id, map}) {
  const style = getMapStyle(map)
  const icon = style === 'style_basic_map' ? 'flag_green' : 'flag_white'
  return Markers({id, map, icon, scale: 1, offset: [5, -8], cluster: false})
}

export const addDataLayers = (map, onSelectVehicle) => {
    let size = 100;

    // implementation of CustomLayerInterface to draw a pulsing dot icon on the map
    // see https://docs.mapbox.com/mapbox-gl-js/api/#customlayerinterface for more info
    function pulsingDot(rgb = [175, 214, 182] ) {
      return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d', { willReadFrequently: true });
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 2000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 3) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]},${1 - t})`;
          context.fill();
          context.lineWidth = 4
          context.strokeStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
          context.stroke()

          // update this image's data with data from the canvas
          this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
          ).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
    }

    function normalDot(color) {
      return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d', { willReadFrequently: true });
        },

        // called once before every frame where the icon will be used
        render: function() {
          
          var radius = (size / 2) * 0.6;
          var outerRadius = (size / 3) * 0.7 + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = color
          context.fill();
          context.lineWidth = 4
          context.strokeStyle = color
          context.stroke()

          // update this image's data with data from the canvas
          this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
          ).data;

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
    }

    let style = getMapStyle(map)
    map.addImage('pulsing-dot-green', pulsingDot(), { pixelRatio: 2 });
    map.addImage('pulsing-dot-red', pulsingDot([246, 132, 122]), { pixelRatio: 2 })
    map.addImage('normal-dot', normalDot(style === 'style_basic_map' ? 'rgba(204, 225, 203, 0.8)' : 'rgba(255, 255, 0, 0.5)'), { pixelRatio: 2.5 })
    map.addImage('normal-dot-grey', normalDot('rgba(138, 138, 139, .5)'), { pixelRatio: 2.5 })
    map.addImage('normal-dot-red', normalDot('rgba(246, 132, 122, .5)'), { pixelRatio: 2.5 })
    map.addImage('normal-dot-orange', normalDot('rgba(255, 132, 0, .5)'), { pixelRatio: 2.5 })


    addSourceAndLayerForBeaconRadius(map)
    addSourceAndLayerForSecurityRadius(map)

    map.addLayer(new BeaconMarkers({id: 'beacons', map})) 
    map.addLayer(new SecurityMarkers({id: 'securityMarkers', map})) 
    map.addLayer(new VehicleMarkers({id: 'vehicles', map}));
    map.addLayer(new VehiclePositionMarkers({id: 'lastKnownVehiclePositions', map}));
    map.addLayer(new DestinationFlag({id: 'destinationFlags', map}));

    addClustering({ 
      map: map,
      layerId: 'vehicles',
      color: theme.colors.primary,
      icon: style === 'style_basic_map' ? 'truck' : 'truck_light',
      iconOpacity: 1
    })
    addClustering({
      map: map,
      layerId: 'beacons',
      color: theme.colors.error,
      icon: style === 'style_basic_map' ? 'tracker_map' : 'tracker_map_light',
      iconScale: 1.3,
      iconOpacity: style === 'style_basic_map' ? 0.75 : 1
    })
    /* addClustering({
      map: map,
      layerId: 'security',
      color: theme.colors.error,
      icon: style === 'style_basic_map' ? 'security_system' : 'security_system',
      iconScale: 1.3,
      iconOpacity: style === 'style_basic_map' ? 0.75 : 1
    }) */
    addClustering({
      map: map,
      layerId: 'lastKnownVehiclePositions',
      color: theme.colors.grey,
      icon: style === 'style_basic_map' ? 'truck' : 'truck_light',
      iconOpacity: 1
    })

    updateLayerRenderOrder(map)
    map.on('click', 'vehicles', function(e) {
      if (!e) {
        return
      }

      onSelectVehicle(
        e.features[0].properties.boxId,
        true, // shouldScroll
        false, // deselectIfAlreadySelected
        false, //zoomToRoutes
        true // zoomToVehicle
      )
    })

    map.on('click', 'beacons', function(e) {
      if (!e) return
      onSelectVehicle(
        e.features[0].properties.boxId,
        false, // shouldScroll
        false, // deselectIfAlreadySelected
        false, //zoomToRoutes
        false // zoomToVehicle
      )
    })

    map.on('mouseenter', 'vehicles', function(e) {
      if (!e) {
        return
      }

      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'vehicles', function() {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'beacons', function(e) {
      if (!e) {
        return
      }

      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'beacons', function() {
      map.getCanvas().style.cursor = '';
    });

    /* map.on('mouseenter', 'security', function(e) {
      if (!e) {
        return
      }

      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'security', function() {
      map.getCanvas().style.cursor = '';
    }); */
}

const addClustering = ({ map, layerId, color, icon, iconScale, iconOpacity }) => {
  const mapStyle = getMapStyle(map)

  const stepSize1 = 10
  const stepSize2 = 50

  const clusterLayerId = 'clusters_' + layerId

  map.addLayer({
    id: clusterLayerId,
    type: 'circle',
    source: layerId,
    filter: ['has', 'point_count'],
    paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * small circles when point count is less than stepSize1
    //   * medium circles when point count is between stepSize1 and stepSize2
    //   * large circles when point count is greater than or equal to stepSize2
      'circle-color': color,
      'circle-opacity': 0.5,
      'circle-radius': [
        'step',
        ['get', 'point_count'],
          clusterRadius - 10, stepSize1,
          clusterRadius -  5, stepSize2,
          clusterRadius
      ],
      'circle-stroke-width': 1,
      'circle-stroke-color': theme.colors.white,
    },
  });

  map.addLayer({
    id: 'cluster-count_' + layerId,
    type: 'symbol',
    source: layerId,
    filter: ['has', 'point_count'],

    layout: {
      'symbol-sort-key': 100,
      'text-field': '{point_count_abbreviated}',
      'text-size': [
        'step',
        ['get', 'point_count'],
          13, stepSize1,
          14, stepSize2,
          15
      ],
      'text-font': font,
      'text-allow-overlap': true,

      'icon-image': icon,
      'icon-size': [
        'step',
        ['get', 'point_count'],
          1.3 * (iconScale ?? 1), stepSize1,
          1.5 * (iconScale ?? 1), stepSize2,
          1.7 * (iconScale ?? 1)
      ],
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
    paint: {
      'text-color': mapStyle !== 'style_satellite' ? theme.colors.white : theme.colors.text,
      'icon-opacity': (iconOpacity || iconOpacity === 0) ? iconOpacity : 1
    }
  });

  // inspect a cluster on click
  map.on('click', clusterLayerId, (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [clusterLayerId]
    });
    const clusterId = features[0].properties.cluster_id;
    map.getSource(layerId).getClusterExpansionZoom(
      clusterId,
      (err, zoom) => {
        if (err) return;

        const state = getStore().getState()
    
        let secondMenuIsOpen = state?.selectedVehicle?.boxId || state?.selectedRoute?.boxId
        let device = state?.themes.device
        
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
          offset: getCenterOffset(device, secondMenuIsOpen)
        });
      }
    );
  });
}

const addSourceAndLayerForSecurityRadius = (map) => {
  const isBasicMap = getMapStyle(map) === 'style_basic_map'

  map.addSource('securityRadius', {
    "type": "geojson",
    "data": {
      type: 'Feature',
      geometry: {
        type: "Polygon",
        coordinates: []
      },
      properties: {foo: 'bar'}
    }
  });
  // This is the radius
  map.addLayer({
    "id": "securityRadius",
    "type": "fill",
    "source": "securityRadius",
    "layout": {},
    "paint": {
      "fill-color": theme.colors.error,
      "fill-opacity": isBasicMap ? 0.25 : 0.35
    }
  });

  // this is the radius border
  map.addLayer({
    "id": "securityRadiusBorder",
    "type": "line",
    "source": "securityRadius",
    "layout": {},
    "paint": {
      "line-color": theme.colors.white,
      "line-opacity": 0.75,
    }
    });
}

const addSourceAndLayerForBeaconRadius = (map) => {
  const isBasicMap = getMapStyle(map) === 'style_basic_map'

  map.addSource('beaconRadius', {
    "type": "geojson",
    "data": {
      type: 'Feature',
      geometry: {
        type: "Polygon",
        coordinates: []
      },
      properties: {foo: 'bar'}
    }
  })
  map.addLayer({
    "id": "beaconRadiusMarker",
    "type": "fill",
    "source": "beaconRadius",
    "layout": {},
    "paint": {
      "fill-color": theme.colors.error,
      "fill-opacity": isBasicMap ? 0.25 : 0.35
    }
  });
  if (!isBasicMap) {
    map.addLayer({
      "id": "beaconRadiusMarkerBorder",
      "type": "line",
      "source": "beaconRadius",
      "layout": {},
      "paint": {
        "line-color": theme.colors.white,
        "line-opacity": 0.75,
      }
    });
  }
}

