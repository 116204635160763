

import React from 'react';
import View from '@modul-connect/shared/components/atoms/view'
import { ThreeDots } from "react-loader-spinner"
import theme from '@modul-connect/shared/theme'
import { H3 } from '../../atoms/text';

const size = 30
export const BubbleLoadingWidget = ({ text, horizontal, extend }) => {
  return (
    <View extend={[horizontal ? style_horizontal : style, extend  ] }>
      <View extend={horizontal && { width: size, height: '100%' }}>
        <ThreeDots color={theme.colors.primary} radius={size} width={size} wrapperStyle={{ height: '100%', display: 'flex', alignItems: 'center' }} />
      </View>
      <View extend={horizontal && { height: '100%', display: 'flex', alignItems: 'center' }}>
        <H3>{text ?? ''}</H3>
      </View>
    </View>
  )
}

const style = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}

const style_horizontal = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  height: size,
  gap: 10
}