import { createTheme } from '@mui/material/styles';

export default {
  colors: {
    navBarBackground: '#252525',
    blue: '#1744E2',
    primary: "#3A8643", //"#378541",
    primaryLight: "rgb(222 238 225)",
    border: "#ddd",
    text: "#222",
    text_v2: '#1A1A1A',
    inputTextGrey: '#8E8E8E',
    textLight: '#FDFDFD',
    modalSectionTitleText: '#141414',
    listItemText: '#141414',
    disabledText: '#9E9E9E',
    yellowText: 'rgb(237, 192, 28)',
    titleDarkGrey: '#4B4B4B',
    solarGreen: '#68D399',
    acGrey: '#92ACB5',
    dcBlack: '#464646',
    remainingOnPie: '#C1C1C1',
    pageBg: "#f5f5f5",
    bg: '#fff',
    uiBorderColor: '#CACACA',
    barBackground: '#F4F4F4',
    simpleButtonBackground: '#E8E8E8',
    borderGrey: "#DBDBDB",
    blackLine: '#686868',
    greyLine: '#DCDCDC',
    simpleButtonBorder: '#D5D5D5',
    lightgreyBackground: '#F9F9F9',
    armedRed: '#FF3A1F',
    // sideBarBg: "#f5f5f5",
    black: "#000",
    tableBackground1: '#fff',
    tableBackground2:'#F8F8F8',
    tableText: '#222222',
    white: "#fff",
    dark_white:'#FBFBFB',
    offWhite: "#FAFAFA",
    lightGrey: "#F3F3F3",
    midGrey: "rgb(138, 138,139, 0.15)",
    disabledGrey: "rgb(208, 208,208, 1)",
    grey: "rgb(138, 138,139)",
    darkGrey: "#2C2C2C",
    primaryDark: '#22434E',
    lightDividerGrey: '#C0C0C0',
    midDarkGrey: '#4D4D4D',
    selectedGrey: '#E0E0E0',
    darkerGrey: '#3E3E3E',
    inactive: "rgb(49,47,48)",
    error: '#FF5151',
    warningIcon: '#A90A00',
    yellow: "#ffff00",
    orange: "#F7B500",
    dark_orange: '#FF8D3B',
    amber: "rgb(255, 234, 85)",
    green: "rgb(56,132,65)",
    lightGreen: "#CCE1CB",
    historic_routes: "#888",
    iconBlack: '#333333',
    tag: {
      'business': 'rgb(58, 68, 134)',
      'warning': "#ffad2e",
      'issue': "#FF5151",
      'primary': '#3A8643',
      'libtblue': '#add8e6'
    },
    transparent: "rgb(0, 0, 0, 0.0)",
    buttonBackground: '#25A306',
  },
  shadowStyle: { boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.08)' },
  shadowStylePaper: { boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.08)' },
  inputFieldBoxShadow: {boxShadow: "inset 0px 0px 5px rgba(0, 0, 0, 0.27)"},
  layout: {
    borderWidth: 2,
    fontFamily: 'Inter',
    grid: 8,
    mainWidth: 1260,
    navBarHeight: 46, // the bar with the logo
    navBarHeight_tablet: 46,
    navBarHeight_mobile: 46,

    navigationBarHeight: 40, // the bar containing the navigation buttons
    filterBarHeight: 52,

    sidebarWidth: 300,
    sidebarItemHeight: 65,
    sidebarItemWidth: 80,

    borderRadius: 4
  },
  mui: createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
            input: {
              color: "#fff",
            }
        }
      },
    },
    palette: {
      primary: {
        light: '#349e61',
        main: '#02863a',
        dark: '#015d28',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      warning: {
        main: '#FF8D3B'
      }
    }
  })
};
