import React from "react";
import View from "../../atoms/view/view";
import { ListItemTitle, ListItemValue, P } from "../../atoms/text/text";

const iconWidth = 28

export const InfoListItem = ({ title, value, valueIcon, v2, noPadding }) => {
  if (v2) {
    return (
      <View extend={[styles.container_v2, noPadding && {padding: 0}]}>
        <P noPadding>{title}</P>
        <P noPadding>{value}</P>
        {valueIcon ? <Icon iconSrc={valueIcon}/> : null}
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <View style={styles.leftSide}>
        <ListItemTitle>{title}</ListItemTitle>
      </View>
      <View style={styles.rightSide}>
        <ListItemValue>{value}</ListItemValue>
        {valueIcon ? <Icon iconSrc={valueIcon}/> : null}
      </View>
    </View>
  );
};

const Icon = ({ iconSrc }) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: iconWidth,
      }}
    >
      {iconSrc && <img style={{ paddingLeft: 5 }} src={iconSrc} />}
    </View>
  );
};

const styles = {
  container: {
    width: "100%",
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container_v2: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  leftSide: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  rightSide: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};
