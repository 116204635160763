import React, { useEffect, useState } from "react"
import ReportPage from "../reportPage.v2"
import { connect } from 'react-redux'
import { timespanOptions } from "../datePickers/timespanPickers/timespanPickers"
import { DateTime } from "luxon"
import TableWidgetSeparated from "@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2"
import { defect_columns } from "./checklist_report_columns"
import { pickRandomlyFromArray } from "../../../utils/array"
import { defectOptions, technicianOptions, userOptions, vehicleOptions } from "./dummyData"
import { DefaultDetails } from "./defectDetails"
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils"
import { ReactComponent as  GreenBullet } from '@modul-connect/shared/img/green_bullet.svg'
import { ReactComponent as  RedBullet } from '@modul-connect/shared/img/red_bullet.svg'

const generateDefect = (day) => {
  const defect = pickRandomlyFromArray(defectOptions);
  const hasImage = true

  const isSolved = Math.random() > 0.7 ? true : false

  const preparedDefect = {
    vehicle: pickRandomlyFromArray(vehicleOptions),
    date: day,
    user: pickRandomlyFromArray(userOptions),
    description: defect.description,
    danger: defect.danger,
    solved: isSolved ? 'Yes' : 'No',
    solved_icon: isSolved ? <GreenBullet /> : <RedBullet />,
    informed: pickRandomlyFromArray(technicianOptions),
    attachments: hasImage ? ["Attached image"] : [],
  }

  return {
    ...preparedDefect,
    isExpandable: true,
    onExpand: () => {
    },
    collapsibleComponent: <DefaultDetails defect={preparedDefect} />
  };
};

const generateDefectData = (startTime, endTime) => {
  let day = startTime
  const defects = []
  while (day <= endTime) {
    console.log('generating data for day', day)
    const hasDefect = Math.random() > 0.8
    console.log('hasDefect:', hasDefect)

    if (hasDefect) {
      defects.push(generateDefect(day))
    }

    day = day.plus({ days: 1 })
  }

  // if empty, generate at least one entry
  if (!defects?.length) {
    let days = endTime.diff(startTime, 'days').toObject().days
    let intervalDay1 = Math.floor(Math.random() * days)
    let intervalDay2 = Math.floor(Math.random() * days)

    const day1 = startTime.plus({days: intervalDay1})
    const day2 = startTime.plus({days: intervalDay2})

    defects.push(generateDefect(day1))
    defects.push(generateDefect(day2))
  }

  return defects
}

const DefectsReportPage = ({
  themes
}) => {
  const isLoading = false
  const loadingFailed = false
  const disabled = false

  const [startDate, setStartDate] = useState(DateTime.local().startOf('month'))
  const [endDate, setEndDate] = useState(DateTime.local().endOf('month'))
  const [defectData, setDefectData] = useState([])

  const onTimeframePicked = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  }

  useEffect(() => {
    setDefectData(generateDefectData(startDate, endDate))
  }, [startDate, endDate])

  return (
    <ReportPage
      title={"Defect Reports"}
      headerSection={null}
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom
      ]}
      noDataMessage={"There are no defect reports for the selected time period."}
      isLoading={isLoading}
      fetchingDataFailed={loadingFailed}
      disabled={disabled}
      startDate={startDate}
      endDate={endDate}
      onTimeframePicked={onTimeframePicked}
      noSearch
      tables={[
        <TableWidgetSeparated
          data={defectData?.map((d, index) => { return {
            key: index + 1,
            id: index + 1,
            ...d,
            danger: d.danger ? 'Yes' : 'No',
            date: dateTimeToString(d.date, "date-only")
          }})}
          loadingStatus={null}
          columns={defect_columns}
          themes={themes}
          isSortable
          pagination={false}
          defaultSort={'date'}
          defaultSortOrder={'desc'}
        />
      ]}
    />
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DefectsReportPage);
