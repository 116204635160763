import React, { Fragment, useEffect, useState } from 'react'
import { convertToSelectedUnit } from "../../../../utils/unitConverter";
import { StatusKpis } from "./components/statusKpis";
import { H1, P, H4, H3, H5 } from "@modul-connect/shared/components/atoms/text";
import { InfoSection } from './components/infoSection'
import theme from '@modul-connect/shared/theme'
import View from "@modul-connect/shared/components/atoms/view";
import {ArmedVehicle} from "@modul-connect/shared/components/molecules/armedVehicle";
import ButtonRow from './components/buttonRow';
import { BubbleLoadingWidget } from '@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget';
import RouteListItem from './components/routeListItem';
import { DateTime } from 'luxon';
import BeaconListItem from '../../vehiclesList/beaconListItem';

export const VehicleDetails = ({
  vehicle,
  current_drivers,
  services,
  vehicle_animation_status,
  goToVehicle,
  isToday,
  loadingRoutes,
  date,
  goToDrivingLog,
  routes,
  map_settings,
  themes,
  beacons,
  loadingBeaconsStatus,
  securitySystemStatus
}) => {
  //const currentStatus = vehicle?.currentStatus
  const animationStatus = vehicle ? vehicle_animation_status[vehicle.boxId] : null
  //const driver = current_drivers?.data && vehicle?.boxId ? current_drivers.data[vehicle.boxId] : null
  const vehicleName = vehicle?.vehicle?.baseVehicleName
  const licenceNumber = vehicle?.vehicle?.licenseNumber
  const headerText =  (vehicleName ? vehicleName.toUpperCase() : '') + (licenceNumber ? ' - ' + licenceNumber : '')

  const isMobile = themes.device === 'mobile'

  const [filteredbeacons, setFilteredBeacons] = useState(beacons.filter(beacon => beacon.boxId === vehicle?.boxId))
  useEffect(() => {
    setFilteredBeacons(beacons.filter(beacon => beacon.boxId === vehicle?.boxId))
  }, [beacons, vehicle?.boxId])


  const selectedDate = DateTime.fromISO(date)
  const startTime = DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, map_settings.startClockTime.hour, map_settings.startClockTime.minute)
  const endTime = DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, map_settings.endClockTime.hour, map_settings.endClockTime.minute)
  const routesAreCorrect = vehicle 
    ? routes?.length 
      ? routes[0].boxId === vehicle.boxId && DateTime.fromISO(routes[0].startTime) >= startTime && DateTime.fromISO(routes[0].startTime) < endTime
      : true
    : true


  const hasPrivateRoutes = (vehicle) => {
    if(vehicle?.geoJSON?.routes && vehicle.geoJSON.routes[map_settings?.date]) {
      const hasRoutes = Object.values(vehicle.geoJSON.routes[map_settings?.date])?.length
  
      return hasRoutes && Object.values(vehicle.geoJSON.routes[map_settings?.date])?.some(log => log.isPrivate)
    }
    return false
  }
  
  const hasPrivatePosition = (vehicle) => {
    if (vehicle?.lastKnownPosition) {
      return vehicle.lastKnownPosition[endTime.toFormat('yyyy-LLL-dd-HH-mm')]?.isPrivate
    }
    return false
  }

  const generateTextWhenNoRoutes = (hasPrivateRoutes, hasPrivatePosition) => {
    let text = "No routes matched the selected filtering."

    if(hasPrivateRoutes) text = text + " Private routes are not shown."
    if(hasPrivatePosition) text = "The vehicle's position is private and not displayed."

    return <P>{text}</P>
  }

  return (
    <Fragment>
      <H1 text={vehicle?.name} noPadding />
      <View extend={{ paddingBottom: 15 }}>
        <H4 extend={{ paddingBottom: 5, color: theme.colors.grey }}>
          {headerText}
        </H4>
        <View
          extend={{
            display: "flex",
            justifyContent: "space-between",
            flesDirection: "row",
          }}
        >
          {map_settings?.isLive ? (
            <H3 noPadding extend={{ color: theme.colors.grey }}>
              {(animationStatus?.speed ?? 0) < 2
                ? "Vehicle is stationary"
                : "Traveling at: " +
                  convertToSelectedUnit(animationStatus.speed, 0, "speed")}
            </H3>
          ) : null}
          <ArmedVehicle map_settings={map_settings} securitySystemStatus={securitySystemStatus} />
        </View>
      </View>

      {isToday ? (
        <InfoSection>
          <StatusKpis vehicle={vehicle} />
        </InfoSection>
      ) : null}

      {loadingRoutes === null && routes?.length ? (
        routes
          .sort((route1, route2) =>
            route1.startTime > route2.startTime ? 1 : -1
          )
          .map((route, index) => {
            return (
              <RouteListItem
                isMobile={isMobile}
                index={index}
                key={index}
                route={route}
                onClick={() => {
                  /* if(!services.hasService(ServiceType.DrivingLogs)) {
                  return
                }
                
                goToDrivingLog(route) */
                }}
              />
            );
          })
      ) : loadingRoutes === "loading" || !routesAreCorrect ? (
        <BubbleLoadingWidget text={"Loading ... "} />
      ) : (
        generateTextWhenNoRoutes(
          hasPrivateRoutes(vehicle),
          hasPrivatePosition(vehicle)
        )
      )}
      {/* {
        !loadingRoutes &&  
          ? <P>There are private routes that is not displayed.</P> 
          : null
      }
      {
        !loadingRoutes && 
          ? <P>The vehicle's position is private and not displayed.</P> 
          : null
      } */}

      {/* {
        currentStatus?.weightSystem ?
          <InfoSection
            header={'Weight'}
            textLines={[
                'Load: ' + convertToSelectedUnit(currentStatus?.weightSystem?.net, 0, 'weight'),
                'Gross: ' + 
                  convertToSelectedUnit(currentStatus?.weightSystem?.gross, 0, 'weight') + ' (total),' +
                  convertToSelectedUnit(currentStatus?.weightSystem?.axle1, 0, 'weight') + ' (front), ' +
                  convertToSelectedUnit(currentStatus?.weightSystem?.axle2, 0, 'weight') + ' (rear)'
            ]}
          />
          : null
      }

      {
        driver ?
        <InfoSection
          header={'Driver'}
          textLines={[driver.name]}
        />
        : null
      } */}

      {/* {
        vehicle?.vehicle ?
          <InfoSection
            header={'Vehicle'}
            textLines={[
              vehicle.vehicle.vin && vehicle.vehicle.vin !== 'unknown' && 'VIN: ' + vehicle.vehicle.vin,
              vehicle.vehicle.maxWeight &&
                'Max. weights: ' +
                vehicle.vehicle.maxWeight.maxGross + ' (total), ' +
                vehicle.vehicle.maxWeight.maxFront + ' (front) ' +
                vehicle.vehicle.maxWeight.maxRear + ' (rear)'
            ]}
          />
          : null
      } */}

      <InfoSection header={"More information"}>
        <ButtonRow goToVehicle={goToVehicle} />
      </InfoSection>

      <View>
        {map_settings.isLive && loadingBeaconsStatus === "loading" ? (
          <BubbleLoadingWidget text={"Loading ... "} />
        ) : null}
        {map_settings.isLive && loadingBeaconsStatus === "failed" ? (
          <P>Failed to load beacon information.</P>
        ) : null}
        {filteredbeacons &&
        map_settings.isLive &&
        !loadingBeaconsStatus &&
        vehicle &&
        beacons?.find((b) => b.boxId === vehicle.boxId) &&
        loadingRoutes !== "loading" ? (
          <BeaconList
            filteredbeacons={filteredbeacons}
            isLive={map_settings?.isLive}
          />
        ) : null}
      </View>
    </Fragment>
  );
}

const BeaconList = ({ filteredbeacons, isLive }) => {
  return (
    <View extend={styles.vehicles_list}>
      <H5>ASSET TRACKERS</H5>
      {
        filteredbeacons.filter(beacon => beacon.name) // key below needs to change on beacon updating, otherwise it doesn't rerender ... 
          .map((beacon) => { return <BeaconListItem key={JSON.stringify(beacon)} beacon={beacon} disabled={!isLive} />})
      }
    </View>
  )
}

const styles = {
  vehicles_list: ({ theme: { colors } }) => ({
    overflowY: 'auto',
    width: '100%',
    borderTop: `1px solid ${colors.white}`,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: 12
  })
}