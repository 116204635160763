import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import HistorySubpage from "./subpages/history/historySubpage";
import { fetchLPSFootprint, fetchLPSReportSummaries } from "../../../state/actions/vehicles";
import FootprintSubpage from "./subpages/footprint/footprintSubpage";
import { useNavigate, useLocation} from 'react-router-dom';
import { getServiceList } from "../../../utils/ServiceList";
import { ServiceType } from "@modul-connect/shared/utils/services";


export const subPageOptions = {
  history: 'history',
  footprint: 'footprint'
}

const LpsReportPage = ({ 
  selected_organisations,
  log_loading, 
  lps_report, 
  subtrees, 
  fetchLPSReportSummaries, 
  fetchLPSFootprint }) => {
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(()=>{
    if(subPage !== subPageOptions.history && subPage !== subPageOptions.footprint)
    {
      navigate('/reports/reports_lps-reports/' + subPageOptions.history)
      setSubPage(subPageOptions.history)
    }
  }, [])

  useEffect(() =>{
    setSubPage(location.pathname.split('/')[location.pathname.split('/').length - 1])
  }, [location ])

  const [subPage, setSubPage] = useState( useLocation().pathname.split('/')[useLocation().pathname.split('/').length - 1] )


  const disabled = !getServiceList({subtrees}).hasService(ServiceType.LpsReports)

  const isLoadingDetails = log_loading.fetchLPSReportDetails === "loading"
  const loadingDetailsFailed = log_loading.fetchLPSReportDetails === "failed"
  
  const isLoadingSummaries = log_loading.fetchLPSReportSummaries === "loading"
  const loadingSummariesFailed = log_loading.fetchLPSReportSummaries === "failed"

  const isLoadingFootprint = log_loading.fetchLpsFootprint === "loading"
  const loadingFootprintFailed = log_loading.fetchLpsFootprint === "failed"

  const { getAccessTokenSilently } = useAuth0();

  let [startDate, setStartDate] = useState(DateTime.utc().startOf("month"));
  let [endDate, setEndDate] = useState(DateTime.utc().endOf("month"));

  const fetchLPSReportSummariesWithAccessToken = () => {
    getAccessTokenSilently().then((accessToken) => {

      if(subPage === subPageOptions.footprint)
        fetchLPSFootprint(accessToken, startDate, endDate);
      else
        fetchLPSReportSummaries(accessToken, startDate, endDate);
    })
  };

  const fetchLPSReportDetailsWithAccessToken = (start, end, boxId) => {
    if (!boxId) {
      return;
    }

    if (isLoadingDetails) {
      // TODO: create that in the reducer
      return;
    }

    getAccessTokenSilently().then((accessToken) => {
      //      fetchLPSReportDetails(accessToken, start, end, boxId ? [boxId] : null);
    });
  };

  useEffect(() => {
    fetchLPSReportSummariesWithAccessToken();
  }, [endDate, subPage, selected_organisations]);

  /* const handleClickOnSingleLog = (event, id) => {
    fetchLPSReportDetailsWithAccessToken(
      startTime,
      endTime.isValid ? endTime : null,
      boxId ?? log.boxId
    );
  }; */

   return (
    subPage === subPageOptions.footprint 
    ? <FootprintSubpage
      isLoading={isLoadingFootprint}
      loadingFailed={loadingFootprintFailed}
      disabled={disabled}
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
    />
    : (<HistorySubpage
        isLoading={isLoadingSummaries || !lps_report?.summary}
        loadingFailed={loadingSummariesFailed}
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    )
  );
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  fetchLPSReportSummaries: (accessToken, startDate, endDate) => dispatch(fetchLPSReportSummaries(accessToken, startDate, endDate)),
  fetchLPSFootprint: (accessToken, boxId, start, end) => dispatch(fetchLPSFootprint(accessToken, boxId, start, end))
})

export default connect(mapStateToProps, mapDispatchToProps)(LpsReportPage);
