import * as turf from '@turf/turf'
import { DateTime } from "luxon"
import { getMapStyle } from './create-map'
import theme from '@modul-connect/shared/theme'
import chroma from 'chroma-js'
import { getVehicleBeaconStatus, hasBatteryIssue, hasClimateSensorIssue, hasWeightIssue, RouteWithinTimeframe, vehicleMatchesMapFiltering, warningLogWithinTimePeriod, weightIssue, weightWarning } from '../../../utils/vehicleUtils';
import mapboxgl from 'mapbox-gl'
import { getStore } from '../../../state/configureStore'

const dashboard_offset_mobile = 500
const dashboard_offset = 400

export const maxZoomLevelForClustering = 14

export function isToday(date) {
  return DateTime.fromISO(date).hasSame(DateTime.local(), 'day')
}

export function updateSelectedVehicleMarker({ map, position, hasWarning, isLive }) {
  let source = map.getSource('selectedVehicle')
  if(!source) {
    source = map.addSource('selectedVehicle', {
      'type': 'geojson',
      'data': GeoJSON( position.geometry.coordinates, {geometry: 'Point'} )
    })
  }
  else {
    source.setData(position)
  }

  if(!map.getLayer('selectedVehicle')) {
    map.addLayer({
      'id': 'selectedVehicle',
      'type': 'symbol',
      'source': 'selectedVehicle',
      'layout': {
        'visibility': isLive ? 'visible' : 'none',
        'icon-image': hasWarning ? 'pulsing-dot-red' : 'pulsing-dot-green'
      }
    })
  }
}

export function updateVehicleWarningMarkers({ map, vehicles, beacons, selectedVehicle, isLive }) {
  let source = map.getSource('vehicleWarningMarkers')

  const prepared_vehicles = vehicles?.filter(v => v.boxId !== selectedVehicle?.boxId).map(v => { // selected vehicle marker is handled separately in function above
    return {
      ...v,
      hasWarning: hasBatteryIssue(v) || hasClimateSensorIssue(v) || (beacons && getVehicleBeaconStatus(v, beacons)?.warning) || hasWeightIssue(v)
    }
  })

  if (!isLive) { // don't show these markers if not in live mode
    if(source) {
      source.setData({
        'type': 'FeatureCollection',
        'features': []
      })
    }
  }
  else {
    const features = prepared_vehicles?.filter(v => v.hasWarning) 
    .map(v => {
      return {
        coordinates: v.geoJSON?.position?.geometry?.coordinates,
        properties: v.geoJSON?.position?.properties
      }
    })

    const newData = {
      'type': 'FeatureCollection',
      'features': features.map(f => { return GeoJSON(f.coordinates, {geometry: "Point" }, f.properties) })
    }

    if(!source) {
      source = map.addSource('vehicleWarningMarkers', {
        'type': 'geojson',
        'data': newData
      })   
    }
    else {
      source.setData(newData)
    }
  }


  if(map.getLayer('vehicleWarningMarkers')) map.removeLayer('vehicleWarningMarkers')
  
  if (source) {
    map.addLayer({
      'id': 'vehicleWarningMarkers',
      'type': 'symbol',
      'source': 'vehicleWarningMarkers',
      'layout': {
        'visibility': 'visible',
        'icon-image': 'pulsing-dot-red'
      }
    })
  }
}

export function getCurrentVehiclePosition(vehicle) {
  if(!(vehicle && vehicle.geoJSON && vehicle.geoJSON.position && vehicle.geoJSON.position.geometry)) {
    return null
  }

  return vehicle.geoJSON.position.geometry.coordinates
}

export function setCurrentVehiclePosition(vehicle, position) {
  if(!(vehicle && vehicle.geoJSON && vehicle.geoJSON.position && vehicle.geoJSON.position.geometry)) {
    vehicle.geoJSON = {
      ...(vehicle.geoJSON ?? {}),
      position: {
        ...(vehicle.geoJSON?.position ?? {}),
        geometry: {
          coordinates: []
        }
      }
    }
  }

  vehicle.geoJSON.position.geometry.coordinates = position
}

export function clearAssetTrackerRadiusFromMap(map) {
  updateSource({ id: 'beaconRadius', data: {
    type: 'Feature',
    geometry: {
      type: "Polygon",
      coordinates: []
    },
    properties: {foo: 'bar'}
  }, map })
}


export function clearSecurityRadiusFromMap(map) {
  updateSource({ id: 'securityRadius', data: {
    type: 'Feature',
    geometry: {
      type: "Polygon",
      coordinates: []
    },
    properties: {}
  }, map })
  updateSource({ id: 'securityMarkers', data: {
    type: 'FeatureCollection',
    properties: {},
    features: []
  }, map })
}


export function updateSource({ id, data, map }) {
  let source = map.getSource(id) 
  if (!source) {
    map.addSource(id, {
      "type": "geojson",
      "data": data
    })
    source = map.getSource(id)
  }
  
  else source.setData(data);
}

function clearRoute({map, boxId}) {
  const layers = []

  let index = 0
  while (true) {
    const name = 'route_' + boxId + '_' + index++
    const layer = map.getLayer(name);
    if (!layer) {
      break;
    }
    layers.push(name)
  }

  const maxPointIndex = 2 // index 0 - normal, 1 - warning, 2 - prewarning

  index = 0
  while (true && index <= maxPointIndex) {
    const name = 'points_' + boxId + '_' + index++
    const layer = map.getLayer(name);
    if (!layer) {
      continue;
    }
    layers.push(name)
  }

  for (let layer of layers) {
    map.removeLayer(layer)
    map.removeSource(layer)
  }
}

export function clearAllRoutes({map, vehicles}) {
  map.getSource('destinationFlags').setData({
    'type': 'FeatureCollection',
    'features': []
  })
  vehicles.forEach(v => clearRoute({ map, boxId: v.boxId }))
}

const chromaToRGB = (col) => {
  return 'rgb(' + col.rgb().join(',') + ')'
}

export function getRouteColor(isLive, map, boxId, selectedVehicleId, selectedRoute, thisRoute, isWarningRoute, isPreWarningRoute) {
  const mapStyle = getMapStyle(map)
  const isSelectedVehicle = (boxId === selectedVehicleId)
  const isSelectedRoute = selectedRoute && (thisRoute.endTime === selectedRoute.endTime)
  const satteliteView = mapStyle === 'style_satellite'

  const base_col = isWarningRoute ? 
    chroma(theme.colors.error) : 
    isPreWarningRoute ? chroma(theme.colors.tag['warning']).darken(0.5).saturate(4) :
    satteliteView ? 
      chroma.scale(['yellow', theme.colors.primary])(0.75).brighten(4).saturate(5) :
      chroma(theme.colors.primary).darken(0.5).saturate(1.5)

  const weaker_base_col = 
    satteliteView ? 
      base_col.darken(0.25).desaturate(2) :
      base_col.brighten(0.75).desaturate(1)

  return isSelectedVehicle ? 
    (isSelectedRoute ? chromaToRGB(base_col) : chromaToRGB(weaker_base_col)) : 
    theme.colors.historic_routes
}

function addRouteLayers(map, boxId, routes, getColor, startIndex, onSelectVehicle, onSelectRoute) {
  for (const route of routes) {
    const color = getColor(route)

    const routeLayer = 'route_' + boxId + '_' + startIndex++
    if(!map.getLayer(routeLayer)) {
      map.addLayer(new Route({
        id: routeLayer,
        source: {
          'type': 'geojson',
          'data': route
        },
        color
      }))
    } else {
      map.getSource(routeLayer).setData(route)
      map.setPaintProperty(routeLayer, 'line-color', color )
    }

    map.on('click', routeLayer, function(e) {
      const endTime = map.getSource(routeLayer)?._data?.endTime
      if (!e|| !endTime) {
        return
      }
      const id = routeLayer.split('_')[1] + '_' + routeLayer.split('_')[2]
      //onSelectRoute(id, endTime)
      onSelectVehicle(
        id, 
        true, // shouldScroll
        false, // deselectIfAlreadySelected
        true,    //zoomToRoutes
        true // zoomToVehicle
        )
    })
    map.on('mouseenter', routeLayer, function(e) {
      if (!e) {
        return
      }
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', routeLayer, function() {
      map.getCanvas().style.cursor = '';
    });
  }
}

let openedPopups = []
let pointLayers = []
let oldMap = null
function addPointLayer(map, boxId, points, icon, index, store) {
  const pointLayer = 'points_' + boxId + '_' + index

  const pointCollection = {
    'type': 'FeatureCollection',
    'features': points
  }

  if(!map.getLayer(pointLayer)) {
    map.addLayer(new Marker({
      id: pointLayer,
      source: {
        'type': 'geojson',
        'data': pointCollection
      },
      icon
    }))
  } else {
    map.getSource(pointLayer).setData(pointCollection)
  }
  map.setPaintProperty(pointLayer, 'text-color', theme.colors.black)
  map.setLayoutProperty(pointLayer, 'text-font', ['Roboto Bold'])
  map.setLayoutProperty(pointLayer, 'text-size', 25)
  map.setLayoutProperty(pointLayer, 'text-offset', [0, -0.5])

  if (oldMap !== map) {
    pointLayers = []
    oldMap = map
  }
  else if (pointLayers.find(layer => layer === pointLayer)) return
  
  pointLayers.push(pointLayer)

  map.on('mouseenter', pointLayer, e => {
    if (!e) return

    const pointProps = e.features[0]._vectorTileFeature.properties
    const popupId = pointLayer + '|' + pointProps.startTime

    if (openedPopups.some(id => id === popupId)) return
    openedPopups.push(popupId)

    const popup = new mapboxgl.Popup()

    const coordinates = e.features[0].geometry.coordinates.slice()
    const popupDOM = document.createElement('div')
    popupDOM.setAttribute('id', popupId)

    popup
    .setLngLat(coordinates)
    .setDOMContent(popupDOM)
    .addTo(map)
    .on('close', () => {
      openedPopups = openedPopups.filter(id => id !== popupId)
      store.dispatch({type: 'CLOSED_MAP_POPUP', popupId})
    })

    store.dispatch({type: 'OPENED_MAP_POPUP', popup, popupId, pointProps})
  })
}

function drawRoute({map, boxId, warnings, routes, selectedVehicleId, onSelectVehicle, onSelectRoute, selectedRoute, map_settings, store }) {
  const finalRoutes = []
  const warningRoutes = []
  const preWarningRoutes = []

  const finalPoints = []
  const warningPoints = []
  const preWarningPoints = []

  let addPreWarning = false
  let addWarning = false
  let addRoute = false
  let hasWeightWarning = false
  let prevRoute

  const allRoutes = {...routes}
  const publicRouteKeys = []
  Object.keys(allRoutes).forEach(routeKey => {
    if (!allRoutes[routeKey]?.isPrivate) publicRouteKeys.push(routeKey)
  })

  const publicRoutes = []
  publicRouteKeys?.forEach(routeKey => {
    publicRoutes[routeKey] = allRoutes[routeKey]
  })
  // dictionaries have no order -> need to sort manually to be able to know route number
  const sortedRoutesArray = publicRoutes ? Object.keys(publicRoutes)?.sort((a, b) => DateTime.fromISO(a) > DateTime.fromISO(b) ? 1 : -1) : []

  for (let startTime in publicRoutes) {
    let route = publicRoutes[startTime]
    if (route.isPrivate) continue

    const previousLastPoint = prevRoute && prevRoute.geometry.coordinates[prevRoute?.geometry.coordinates.length - 1]
    const currentFirstPoint = route.geometry.coordinates[0]
    if (prevRoute && previousLastPoint !== currentFirstPoint &&
        turf.distance(turf.point(previousLastPoint), turf.point(currentFirstPoint), { units: 'meters' }) < 10)
      prevRoute.geometry.coordinates.push(route.geometry.coordinates[0])

    if (route.geometry.coordinates.length === 0) continue
      
    const routeStart = DateTime.fromISO(startTime).toSeconds()
    const routeEnd = route.endTime ? DateTime.fromISO(route.endTime).toSeconds() : undefined

    let routeHasWarning = false
    let routeHasPreWarning = false

    const relevantWarnings = warnings?.overweightWarnings?.filter(w => w.boxId === boxId)
    let highestWeightRatio = null
    for(let i in relevantWarnings) {
      const warning = relevantWarnings[i]
      
      if (warningLogWithinTimePeriod(warning, routeStart, routeEnd)) {
        if (warning.hasOverweightGross) {
          if (warning.ratioGross > highestWeightRatio) {
            highestWeightRatio = warning.ratioGross
          }
        }
        if (warning.hasOverweightRear) {
          if (warning.ratioRear > highestWeightRatio) {
            highestWeightRatio = warning.ratioRear
          }
        }
        if (warning.hasOverweightFront) {
          if (warning.ratioFront > highestWeightRatio) {
            highestWeightRatio = warning.ratioFront
          }
        }
      }
    }

    if (highestWeightRatio) {
      if (weightIssue(highestWeightRatio * 100)) routeHasWarning = true
      else if (weightWarning(highestWeightRatio * 100)) routeHasPreWarning = true
    }

    if (routeHasWarning) {
      addWarning = true
      addPreWarning = false
      addRoute = false
    }
    else if (routeHasPreWarning) {
      addWarning = false
      addPreWarning = true
      addRoute = false
    }
    else {
      addWarning = false
      addPreWarning = false
      addRoute = true
    }

    const firstPos = route.geometry.coordinates[0]
    const routeNumber =  sortedRoutesArray?.findIndex(routeStart => routeStart === startTime) + 1

    const point = GeoJSON( firstPos, { geometry: 'Point'}, { title: routeNumber, boxId, startTime, endTime: route.endTime, hasWeightWarning: routeHasWarning})
    route = {
      ...route,
      hasWeightWarning: hasWeightWarning, 
    }

    if (addRoute && !map_settings.filter.mustHaveWeightWarning) {
      finalPoints.push(point)
      finalRoutes.push(route)
    } else if (addWarning) {
      warningPoints.push(point)
      warningRoutes.push(route)
    }
    else if (addPreWarning) {
      preWarningPoints.push(point)
      preWarningRoutes.push(route)
    }

    prevRoute = route
  }

  if (finalRoutes.length === 0 && warningRoutes.length === 0 && preWarningRoutes.length === 0) return

  const getColor = (thisRoute) => { 
    return getRouteColor(map_settings.isLive, map, boxId, selectedVehicleId, selectedRoute, thisRoute, false, false)
  }
  const getWarningRouteColor = (thisRoute) => { 
    return getRouteColor(map_settings.isLive, map, boxId, selectedVehicleId, selectedRoute, thisRoute, true, false)
  }
  const getPreWarningRouteColor = (thisRoute) => { 
    return getRouteColor(map_settings.isLive, map, boxId, selectedVehicleId, selectedRoute, thisRoute, false, true)
  }

  addRouteLayers(map, boxId, finalRoutes, getColor, 0, onSelectVehicle, onSelectRoute)
  addRouteLayers(map, boxId, warningRoutes, getWarningRouteColor, finalRoutes.length, onSelectVehicle, onSelectRoute)
  addRouteLayers(map, boxId, preWarningRoutes, getPreWarningRouteColor, finalRoutes.length + warningRoutes.length, onSelectVehicle, onSelectRoute)
  
  if (finalPoints.length > 0) addPointLayer(map, boxId, finalPoints, map_settings.isLive ? 'normal-dot' : 'normal-dot-grey', 0, store)
  if (warningPoints.length > 0) addPointLayer(map, boxId, warningPoints, 'normal-dot-red', 1, store)
  if (preWarningPoints.length > 0) addPointLayer(map, boxId, preWarningPoints, 'normal-dot-orange', 2, store)

  /* if (!map_settings.isLive) {
    const flagSource = map.getSource('destinationFlags')
    const flagData = flagSource._data
    const lastRoute = addRoute ? finalRoutes[finalRoutes.length - 1] : warningRoutes[warningRoutes.length - 1]
    flagData.features.push(turf.point(lastRoute.geometry.coordinates[lastRoute.geometry.coordinates.length - 1]))
    flagSource.setData(flagData)
  } */

  updateLayerRenderOrder(map)
  //map.moveLayer('destinationFlags')
}

export function drawAllRoutes({map, vehicles, date, selectedVehicleId, onSelectVehicle, onSelectRoute, selectedRoute, map_settings, store }) {
  vehicles.filter(v => v.geoJSON?.routes).forEach(v =>
    drawRoute({ map, boxId: v.boxId, warnings: v.warning_logs ? v.warning_logs[date] : null, routes: v.geoJSON.routes[date], selectedVehicleId, onSelectVehicle: onSelectVehicle, onSelectRoute: onSelectRoute, selectedRoute: selectedRoute, map_settings: map_settings, store: store })
  )
}

export function selectVehicleOnMap({ self, map, date, onSelectVehicle, onSelectRoute, store }) {
    const { vehicles, selectedVehicle, selectedRoute, map_settings } = self.props
    const index = vehicles.findIndex(v => v.boxId === selectedVehicle.boxId)

    drawRouteOnMap({ vehicle: vehicles[index], self, map, date, onSelectVehicle, onSelectRoute, selectedRoute, map_settings, store })

    if (self.state.date !== date || self.state.index !== index){
      self.setState({index: index, date: date})
    }
    setBounds({map, vehicles, date})
}

export function selectRouteOnMap({ self, map, date, onSelectVehicle, onSelectRoute, store }) {
  const { vehicles, selectedVehicle, selectedRoute, map_settings} = self.props
  const index = vehicles.findIndex(v => v.boxId === selectedVehicle.boxId)

  drawRouteOnMap({ vehicle: vehicles[index], self, map, date, onSelectVehicle, onSelectRoute, selectedRoute, map_settings, store })

  const routeIndex = (index + '_' + selectedRoute.endTime)

  if (self.state.date !== date || self.state.index !== routeIndex){
    self.setState({index: routeIndex, date: date})
    setBounds({map, vehicles, date})
  }
}

export function unselectSecurityAlarmIncidentOnMap({ self, map, date }) {
  clearSecurityRadiusFromMap(map)
}

export function selectSecurityAlarmIncidentOnMap({ self, map, date }) {
  const { vehicles, selectedSecurityAlarm } = self.props
  setBounds({ map, vehicles, date })
  clearSecurityRadiusFromMap(map)

  if(selectedSecurityAlarm)
  {
    var center = [
      selectedSecurityAlarm?.longitude,
      selectedSecurityAlarm?.latitude
    ]
    var radius = 100;
    var options = { steps: 64, units: 'meters', properties: { foo: 'bar' } };
    var circle = turf.circle(center, radius, options);
    updateSource({ id: 'securityRadius', data: circle, map })
    
    updateSource({ id: 'securityMarkers', data: {
      type: 'FeatureCollection',
      features: [{
        geometry: {
          type: 'Point',
          coordinates: [selectedSecurityAlarm?.longitude, selectedSecurityAlarm?.latitude]
        },
        properties: {
          alarmId: selectedSecurityAlarm?.alarmId,
          boxId: selectedSecurityAlarm?.boxId,
        }
      }]
    }, map })
  
    flyToSecurityIncident({ map, selectedSecurityAlarm })
  }
}

export function selectAssetTrackerOnMap({ self, map, date }) {
  const { vehicles, beacons, selectedAssetTracker } = self.props

  if (!beacons) return

  const selectedBeacon = beacons.find(b => b.boxId === selectedAssetTracker.boxId && b.beaconId === selectedAssetTracker.beaconId)
  if (!selectedBeacon || selectedBeacon.isConnected) {
    // zoom to vehicle instead
    setBounds({map, vehicles, date})
    clearAssetTrackerRadiusFromMap(map)
    return
  }
  
  // render radius, if beacon not inside vehicle
  if (!selectedBeacon.isConnected && selectedBeacon.currentStatus?.geoLocation) {
    var center = [
      selectedBeacon.currentStatus?.geoLocation.longitude,
      selectedBeacon.currentStatus?.geoLocation.latitude
    ]
   var radius = 100;
    var options = {steps: 64, units: 'meters', properties: {foo: 'bar'}};
    var circle = turf.circle(center, radius, options);
    updateSource({ id: 'beaconRadius', data: circle, map})
  }

  // zoom in on asset tracker
  flyToBeacon({ map, selectedBeacon })
}

export function drawRouteOnMap({ vehicle, self, map, date, onSelectVehicle, onSelectRoute, selectedRoute, map_settings, store }) {
  if (!vehicle?.geoJSON) return

  let position = vehicle?.geoJSON?.position
  if (!position) {
    const day = DateTime.fromISO(map_settings.date)
    const endTime = DateTime.local(day.year, day.month, day.day, map_settings.endClockTime.hour, map_settings.endClockTime.minute)
    const lastKnownPos = vehicle.lastKnownPosition && vehicle.lastKnownPosition[endTime.toFormat('yyyy-LLL-dd-HH-mm')]

    position = (lastKnownPos?.longitude || lastKnownPos?.longitude === 0) && (lastKnownPos?.latitude || lastKnownPos?.latitude === 0) ? {
      type: 'Feature',
        geometry: {
          type: "Point",
          coordinates: [lastKnownPos.longitude, lastKnownPos.latitude]
        },
        properties: {
          boxId: vehicle.boxId,
          bearing: lastKnownPos.course
        },     
    } : null
  }


  if (position)
    updateSelectedVehicleMarker({ map, position: position, hasWarning: self.hasWarning, isLive: map_settings.isLive })
  drawRoute({
    map, 
    boxId: vehicle.boxId, 
    warnings: vehicle.warning_logs ? vehicle.warning_logs[date] : null, 
    routes: vehicle.geoJSON.routes[date], 
    position: vehicle.geoJSON.position, 
    selectedVehicleId: vehicle.boxId,
    onSelectVehicle: onSelectVehicle,
    onSelectRoute: onSelectRoute,
    selectedRoute: selectedRoute,
    map_settings: map_settings,
    store: store
  })
}

export function unSelectVehicleOnMap({ self, map }) {
  let source = map.getLayer('selectedVehicle');

  if(source) {
    map.removeLayer('selectedVehicle')
  }

  if (self.state.index > -1 && !self.props.selectedVehicle?.boxId){
    self.setState({index: -1})
  }
}

export function getLastPosition (v, map_settings) {
  const day = DateTime.fromISO(map_settings.date)
  const endTime = DateTime.local(day.year, day.month, day.day, map_settings.endClockTime.hour, map_settings.endClockTime.minute)

  return v.lastKnownPosition ? v.lastKnownPosition[endTime.toFormat('yyyy-LLL-dd-HH-mm')] : null
}

function getAllPositions({vehicles, date, map_settings}) {
  let positions = []


  for (var i = 0; i < vehicles.length; i++) {
    const vehicle = vehicles[i]
    const lastPosition = getLastPosition(vehicle, map_settings)
    if(vehicle.geoJSON && vehicle.geoJSON.routes.hasOwnProperty(date)) {
      const routes = vehicle.geoJSON.routes[date]
      const keys = Object.keys(routes)
      keys.forEach(key => {
        routes[key].geometry.coordinates.forEach(coord =>
          positions.push(coord)  
        )
      })    
    }
    else if (lastPosition &&
      (lastPosition.longitude || lastPosition.longitude === 0) &&
      (lastPosition.latitude || lastPosition.latitude === 0)) {
        positions.push([lastPosition.longitude, lastPosition.latitude])
    }
  }

  return positions
}

function getAllPositionsForBoxId({vehicles, date, boxId}) {
  let positions = []

  let vehicle = vehicles.find(v => v.boxId === boxId)
  if (vehicle) {
    if(vehicle.geoJSON && vehicle.geoJSON.routes.hasOwnProperty(date)) {
      const routes = vehicle.geoJSON.routes[date]
      const keys = Object.keys(routes)
      keys.forEach(key => {
        if (!routes[key].isPrivate) {
          routes[key].geometry.coordinates.forEach(coord => positions.push(coord))
        }
      })
    }
  }

  return positions
}

function getAllPositionsForRoute ({vehicles, date, boxId, endTime}) {
  let positions = []

  let vehicle = vehicles.find(v => v.boxId === boxId)
  if (vehicle) {
    if(vehicle.geoJSON && vehicle.geoJSON.routes.hasOwnProperty(date)) {
      const routes = vehicle.geoJSON.routes[date]
      const keys = Object.keys(routes)
      keys.forEach(key => {
        const route = routes[key]
        if (route.endTime === endTime) {
          route.geometry.coordinates.forEach(coord =>
            positions.push(coord)  
          )
        }
      })
    }
  }

  return positions
}

function getCurrentPositions({vehicles, map_settings}) {
  let positions = []

  for (var i = 0; i < vehicles.length; i++) {
    const vehicle = vehicles[i]

    const lastPosition = getLastPosition(vehicle, map_settings)


    if(vehicle.currentStatus && vehicle.currentStatus.geoLocation.hasOwnProperty('latitude')) {
      let point = vehicle.currentStatus.geoLocation
      positions.push([point.longitude, point.latitude])
    }
    else if (lastPosition &&
      (lastPosition.longitude || lastPosition.longitude === 0) && 
      (lastPosition.latitude || lastPosition.latitude === 0)) {

      let point = [lastPosition.longitude, lastPosition.latitude]
      positions.push([point.longitude, point.latitude])
    }
  }

  return positions
}

export function updateBeaconPositions(beacons_to_render, map) {
  updateSource({ id: 'beacons', data: {
    type: 'FeatureCollection',
    features: beacons_to_render.filter(b => b.currentStatus?.geoLocation).map(v => ({
      geometry: {
        type: 'Point',
        coordinates: [v.currentStatus.geoLocation.longitude, v.currentStatus.geoLocation.latitude]
      },
      properties: {
        beaconId: v.beaconId,
        name: v.name,
        boxId: v.boxId
      }
    }))
  }, map })
}

export function updateVehiclePositions({ vehicles, map, date, map_settings }) {
  let forMap = {}

  if(map_settings.isLive) {
    forMap = vehicles.map(x => {
      const isAnimating = x.targets?.length
      const lastPosition = (isAnimating && x.geoJSON?.position?.geometry?.coordinates) ? x.geoJSON.position?.geometry?.coordinates : x.currentStatus.geoLocation 
        ? [x.currentStatus.geoLocation.longitude, x.currentStatus.geoLocation.latitude]
        : null
      x.geoJSON.position = turf.point(
        lastPosition,
        { boxId: x.boxId, bearing: isAnimating ? x.geoJSON?.position?.properties?.bearing : x.currentStatus.geoLocation.course, name: x.name }
      )
      return {
        vehicleForMap: x.geoJSON.position,
        batteryWarningForMap: x.currentStatus?.batteries?.some((b, i) => {
          const batteryGuard = x.vehicle.batteryGuards?.[i]
          if (!batteryGuard) return b.voltage < 11.85
          return b.voltage < batteryGuard.lowGuard || (batteryGuard.highGuard && b.voltage >= batteryGuard.highGuard)
      }) && turf.point(
          lastPosition,
          { boxId: x.boxId, bearing: x.currentStatus.geoLocation.course  }
        ),
        overweightWarningForMap: ((
          x.currentStatus?.weightSystem?.gross && x.vehicle?.maxWeight?.maxGross &&
            x.currentStatus.weightSystem.gross / x.vehicle.maxWeight.maxGross > 1)/* || (
          x.currentStatus?.weightSystem?.axle1 && x.vehicle?.maxWeight?.maxFront &&
            x.currentStatus.weightSystem.axle1 / x.vehicle.maxWeight.maxFront >= 1) || (
          x.currentStatus?.weightSystem?.axle2 && x.vehicle?.maxWeight?.maxRear &&
            x.currentStatus.weightSystem.axle2 / x.vehicle.maxWeight.maxRear >= 1)*/)
          && turf.point(
            lastPosition,
            { boxId: x.boxId, bearing: x.currentStatus.geoLocation.course  }
          )
      }
    })
  } else {
    forMap = vehicles.filter(x =>
      x.geoJSON &&
      x.geoJSON.routes?.hasOwnProperty(date) &&
      x.geoJSON.routes[date] &&
      x.geoJSON.routes[date].size > 2
    ).map(x => {
      const nextToLastPosition = x.geoJSON.routes[date].geometry.coordinates[x.geoJSON.routes[date].geometry.coordinates.length - 2]
      const lastPosition = x.geoJSON.routes[date].geometry.coordinates[x.geoJSON.routes[date].geometry.coordinates.length - 1]

      return {
        vehicleForMap: turf.point(
          lastPosition,
          { boxId: x.boxId, bearing: nextToLastPosition ? turf.bearing(nextToLastPosition, lastPosition) : 0 }
        ),
        batteryWarningForMap: [],
        overweightWarnings: []
      }
    })
  }

  const vehiclesForMap = forMap.map(x => x.vehicleForMap).filter(x => x)

  let positions = {
    type: 'FeatureCollection',
    features: vehiclesForMap
  }
  updateSource({ id: 'vehicles', data: positions, map })
}

export const getBounds = ({ positions }) => {
  if(positions.length <= 1) {
    return false
  }

  let line = turf.lineString(positions)
  let bbox = turf.bbox(line)

  bbox = [
    [
      bbox[0],
      bbox[1]
    ],
    [
      bbox[2],
      bbox[3]
    ],
  ]

  return bbox
}

const isSecondMenuOpen = () => {
  const selectedVehicle = getStore()?.getState()?.selectedVehicle
  const selectedRoute = getStore()?.getState()?.selectedRoute
  const selectedBeacon = getStore()?.getState()?.selectedBeacon

  return selectedVehicle?.boxId || selectedRoute?.boxId || selectedBeacon?.boxId
}

export const centerOnPosition = ({map, position, offset, zoom}) => {
  if (map) map.flyTo({
    center: position,
    offset: offset ?? getCenterOffset(),
    zoom: zoom ?? 16
  })
}

export const flyToBeacon = ({ map, selectedBeacon }) => {
  if (!selectedBeacon?.currentStatus?.geoLocation) return

  centerOnPosition({
    map: map,
    position: [selectedBeacon.currentStatus.geoLocation.longitude, selectedBeacon.currentStatus.geoLocation.latitude],
  })
}

export const flyToSecurityIncident = ({ map, selectedSecurityAlarm }) => {
  if (!selectedSecurityAlarm?.longitude && !selectedSecurityAlarm?.latitude) return

  centerOnPosition({
    map: map,
    position: [selectedSecurityAlarm.longitude, selectedSecurityAlarm.latitude],
  })
}

export const setBounds = ({map, vehicles, date}) => {
  const map_settings = getStore()?.getState()?.map_settings
  const selectedVehicle = getStore()?.getState()?.selectedVehicle
  const selectedRoute = getStore()?.getState()?.selectedRoute

  if(vehicles.length === 0) {
    return
  }

  if(vehicles.length === 1 && map_settings.isLive && !selectedVehicle) {
    let position = vehicles[0].geoJSON.position.geometry.coordinates

    map.flyTo({
      center: position,
      offset: getCenterOffset(),
      zoom: 12
    })

    return
  }

  let positions = []

  if (selectedVehicle?.boxId) {
    let vehicle = vehicles.find(v => v.boxId === selectedVehicle.boxId)
  
      if (vehicle) {
        if (map_settings.isLive && !selectedRoute?.endTime && !selectedVehicle.zoomToRoutes && vehicle.geoJSON?.position?.geometry) {
          let position = vehicle.geoJSON.position.geometry.coordinates
  
          if (selectedVehicle.zoomToVehicle && position && position.length === 2) {
            map.flyTo({
              center: position,
              offset: getCenterOffset(),
              zoom: maxZoomLevelForClustering + 1
            })
            return
          }
        }
        else {
          if (selectedRoute?.endTime) {
            positions = getAllPositionsForRoute({ vehicles, date, boxId: selectedVehicle.boxId, endTime: selectedRoute.endTime })
          }
          else {
            // set bounds to show all the routes for this vehicle and day
            positions = getAllPositionsForBoxId({ vehicles, date, boxId: selectedVehicle.boxId }) 
            const lastKnownPosition = getLastPosition(vehicle, map_settings)
            if (!positions?.length) {
              if (
                (lastKnownPosition?.longitude || lastKnownPosition?.longitude === 0) && 
                (lastKnownPosition?.latitude || lastKnownPosition?.latitude === 0)) {
                map.flyTo({
                  center: [lastKnownPosition.longitude, lastKnownPosition.latitude],
                  offset: getCenterOffset(),
                  zoom: 12
                })
              }
            }
          }
        }
    }
  }
  else {
    let filtered_vehicles = vehicles.filter(v => v.currentStatus?.geoLocation)
    positions = map_settings.isLive ? getCurrentPositions({vehicles: filtered_vehicles, map_settings}) : getAllPositions({vehicles, date, map_settings})
  }

  fitMapToBounds(map, positions)  
}

const fitMapToBounds = (map, positions) => {
  const themes = getStore()?.getState()?.themes
  let bounds = getBounds({ positions })

  if(bounds && bounds.length) {
    let padding
    if(themes?.device === 'desktop' || themes?.device === 'tablet') {
      padding = { top: 200, bottom: 150, left: (isSecondMenuOpen() ? 1.5 : 1) * dashboard_offset, right: 100}
    } else {
      padding = { top: 100, bottom: dashboard_offset_mobile, left: 50, right: 50 }
    }

    map.fitBounds(bounds, { padding: padding })
  }
}

export const getCenterOffset = () => {
  const themes = getStore()?.getState()?.themes
  return themes?.device === 'mobile' ? 
    [0, -dashboard_offset_mobile/3] : 
    [(isSecondMenuOpen() ? 2 : 1) * dashboard_offset/3, 0]
}

export const createGeoJSON = (vehicle, position) => {
  const hasGeoLocation = position ?? vehicle.currentStatus.geoLocation // won't have it if hidden from user due to privacy reasons

  let json = {
    position: hasGeoLocation ? turf.point([hasGeoLocation.longitude, hasGeoLocation.latitude], {
      "boxId": vehicle.boxId,
      "bearing": hasGeoLocation.course
    }) : null,
    "routes": {},
    "speed": hasGeoLocation ? hasGeoLocation.speed : null
  }
  return json
}

export const GeoJSON = (coordinates, options, properties = null) => {
  const {
      geometry
  } = options

  return {
      "type":"Feature",
      "geometry":{
          "type": geometry || "LineString",
          "coordinates": coordinates || []
      },
      "properties": properties
  }
}

function Route({id, source, color}) {
  return {
    'id': id,
    'type': 'line',
    'source': source || {
      'type': 'geojson',
      'data': GeoJSON( [], { geometry: 'LineString'})
    },
    'layout': {
        'line-join': 'round',
        'line-cap': 'round',
    },
    'paint': {
      'line-color': color || theme.colors.primary,
      'line-width': 6,
      'line-opacity': 0.5
    }
  }
}

function Marker({id, source, icon, iconSize = 0.5, iconOffset = [0, 0]}) {
  return {
    'id': id,
    'type': 'symbol',
    'source': source || {
      'type': 'geojson',
      'data': GeoJSON( [], { geometry: 'Point'})
    },
    'layout': {
      'icon-image': icon,
      'icon-size': iconSize,
      'icon-offset': iconOffset,
      // get the title name from the source's "title" property
      'text-field': [
        'format',
        ['get', 'title'],
        {
          'font-scale': 0.8,
          // 'text-font': [
          //   'literal',
          //   ['Open Sans Regular']
          // ]
        }
      ],
      'text-offset': [0, -0.6],
      'text-anchor': 'top'
    },
    'paint': {
      'text-color': theme.colors.white
    }
  }
}

// should be called every time we add a layer, to make sure important things still get rendered on top
export function updateLayerRenderOrder(map) {
  if (!map) return

  // render at the very bottom
  if (map.getLayer('beaconRadiusMarkers')) map.moveLayer('beaconRadiusMarkers')
  if (map.getLayer('beaconRadiusMarkersBorder')) map.moveLayer('beaconRadiusMarkers')

  if (map.getLayer('securityRadius')) map.moveLayer('securityRadius')
  if (map.getLayer('securityRadiusBorder')) map.moveLayer('securityRadius')
  if (map.getLayer('securityMarkers')) map.moveLayer('securityMarkers')
    
  map.moveLayer('vehicles')
  if (map.getLayer('selectedVehicle')) map.moveLayer('selectedVehicle')

  // moves the clusters to the top of the rendering hierarchy -> on top of everything else
  if (map.getLayer('clusters_lastKnownVehiclePositions')) map.moveLayer('clusters_lastKnownVehiclePositions')
  //if (map.getLayer('clusters_beacons')) map.moveLayer('clusters_beacons')
  if (map.getLayer('clusters_vehicles')) map.moveLayer('clusters_vehicles')

  if (map.getLayer('cluster-count_lastKnownVehiclePositions')) map.moveLayer('cluster-count_lastKnownVehiclePositions')
  if (map.getLayer('cluster-count_beacons')) map.moveLayer('cluster-count_beacons')
  if (map.getLayer('cluster-count_vehicles')) map.moveLayer('cluster-count_vehicles')

  // only vehicle warning markers should be rendered on top of clusters
  if (map.getLayer('vehicleWarningMarkers')) map.moveLayer('vehicleWarningMarkers')
}

export const getStartEndTimeFromMapsettings = (map_settings) => {
  const day = DateTime.fromISO(map_settings.date)
  const startTime = DateTime.local(day.year, day.month, day.day, map_settings.startClockTime.hour, map_settings.startClockTime.minute)
  const endTime = DateTime.local(day.year, day.month, day.day, map_settings.endClockTime.hour, map_settings.endClockTime.minute)

  return {startTime, endTime}
}

export const getVehiclesToRender = ({vehicles, map_settings, date}) => {
  if (!map_settings || !date || !vehicles || !(vehicles.length || vehicles.length === 0)) {
    console.error('getVehiclesToRender is missing information: map_settings:', map_settings, 'date:', date, ', vehicles:', vehicles)
    return undefined
  }
  let vehicles_to_render = []
  if(map_settings.isLive) {
    vehicles_to_render = vehicles?.filter(v => {
      return (
        vehicleMatchesMapFiltering(v, date, DateTime.local().minus({ hours: 24 }), DateTime.local(), map_settings) &&
        v.currentStatus?.geoLocation
      )
    })
  }
  else {
    const {startTime, endTime} = getStartEndTimeFromMapsettings(map_settings)
    
    vehicles_to_render = applyMapFiltering(JSON.parse(JSON.stringify(vehicles)), date, startTime, endTime, map_settings)
  }

  return vehicles_to_render
}

export const applyMapFiltering = (vehicles, date, startTime, endTime, map_settings) => {
  // filter vehicles
  let vehicles_to_render = vehicles?.filter(v => {
    return vehicleMatchesMapFiltering(v, date, startTime, endTime, map_settings)
  })
  
  // filter routes
  return vehicles_to_render.map(v => {
    let routes = v.geoJSON?.routes && v.geoJSON?.routes[date]
    let newRoutesForDate = {}
    if (routes) {
      Object.keys(routes).map(routeStartTime => {
        const route = routes[routeStartTime]
        if (RouteWithinTimeframe(DateTime.fromISO(routeStartTime), DateTime.fromISO(route.endTime), startTime, endTime) &&
        (!map_settings.filter.mustHaveWeightWarning || route.hasWeightWarning)) {
          newRoutesForDate[routeStartTime] = route
        }
      })

      v.geoJSON.routes[date] = newRoutesForDate
    }    
    return v
  })
}