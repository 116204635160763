import { getTemperatureWarningTagColorName } from "../../../../utils/vehicleUtils";

export const log_columns = [
    {
        id: 'startTimeString',
        label: 'From',
        width: 120,
        hide: [],
        align: 'left'
    },
    {
        id: 'endTimeString',
        label: 'To',
        width: 120,
        hide: [],
        align: 'left'
    },
    {
        id: 'vehicle',
        label: 'Vehicle',
        width: 250,
        hide: [],
        align: 'center'
    },
    {
        id: 'thermometerName',
        label: 'Location',
        hide: []
    },
    {
        id: 'temperature',
        label: 'Temperature',
        isTag: true,
        hasGuard: true,
        tagColor: getTemperatureWarningTagColorName,
        textColor: 'black',
        hide: []
    },
]