import { call, takeEvery, put, take, fork, select, takeLatest, delay } from 'redux-saga/effects'
import * as signalR from '@microsoft/signalr';
import { notificationsServerUrl } from '@modul-connect/shared/config';
import { sec } from "@modul-connect/shared/auth0/auth0Helper"
import { END, eventChannel } from 'redux-saga';
import { getStore } from '../configureStore';
import notification_sound from '../../assets/sounds/notification_sound.mp3'

function* startSignalRConnection({}) {

  const accessToken = yield sec?.getAccessTokenSilently()

  const existing = yield select(state => state.notificationsSignalRConnection)
  if (existing != null) {
    yield put({ type: 'SIGNALR_CONNECTION_ALREADY_ACTIVE'})
    return
  }
  const url = notificationsServerUrl() + '/notificationshub'
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, { accessTokenFactory: () => accessToken })
    .configureLogging(signalR.LogLevel.None)
    .build()

  try {
    yield connection.start()
    yield fork(setupSignalRListeners, connection)
    yield put({ type: 'NOTIFICATIONS_SIGNALR_CONNECTION_STARTED', data: connection })
  } catch (error) {
    yield put({ type: 'NOTIFICATIONS_SIGNALR_CONNECTION_FAILED', data: error })
  }
}

const playSound = () => {
  const soundUrl = notification_sound;
  const audio= new Audio(soundUrl);
  
  audio.play().catch(error => {
    console.error('Error playing sound:', error);
  });
};



function* setupSignalRListeners(connection) {
  if (connection != null) {
    try {
      const notificationsChannel = eventChannel(notificationsListener => {
        connection.on('SecurityAlarmStarted', (boxId, data) =>{
          var type = 'SecurityAlarmStarted'
          notificationsListener({type, data})
        })
        connection.on('SecurityAlarmUpdated', (boxId, data) =>{
          var type = 'SecurityAlarmUpdated'
          notificationsListener({type, data})
        })

        connection.onclose(() => notificationsListener(END))
  
        return () => {}
      })
      while (connection.state === signalR.HubConnectionState.Connected) {
        const status = yield take(notificationsChannel)
        const isSound = getStore().getState()?.user_settings?.notificationSound 
        if(status?.type === 'SecurityAlarmStarted'){
          if (isSound) playSound()
          yield put({type: 'NEW_SECURITY_ALARM_NOTIFICATION', data: status.data})
          yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'Security system alarm received.', alertType: 'warning' })

        }
        if(status?.type === 'SecurityAlarmUpdated'){
          console.error('status', status)
        }
      }
      
    } catch (error) {
      console.warn('Notifictaions SignalR connection error: ', error)
      connection.stop()
    }
  }
}

function* stopSignalRConnection({ connection }) {
  if (connection != null) {
    yield connection.stop()
    yield put({ type: 'NOTIFICATIONS_SIGNALR_CONNECTION_STOPPED', data: null })
  } else {
    yield put({ type: 'NO_NOTIFICATIONS_SIGNALR_CONNECTION_TO_STOP' })
  }
}


export function* notificationsSagas() {
  yield takeLatest(['NOTIFICATIONS_FETCHED','START_NOTIFICATIONS_SIGNALR_CONNECTION'], startSignalRConnection)
  yield takeEvery('STOP_NOTIFICATIONS_SIGNALR_CONNECTION', stopSignalRConnection)
}