import { getHumidityWarningTagColorName } from "../../../../utils/vehicleUtils";

export const log_columns = [
    {
        id: 'startTimeString',
        label: 'From',
        width: 120,
        hide: [],
        align: 'center'
    },
    {
        id: 'endTimeString',
        label: 'To',
        width: 120,
        hide: [],
        align: 'center'
    },
    {
        id: 'vehicle',
        label: 'Vehicle',
        width: 250,
        hide: [],
        align: 'center'
    },
    {
        id: 'HumiditySensorName',
        label: 'Location',
        hide: []
    },
    {
        id: 'humidity',
        label: 'Humidity',
        isTag: true,
        hasGuard: true,
        tagColor: getHumidityWarningTagColorName,
        textColor: 'black',
        hide: []
    },
]