import { customerCommandServerUrl, customerServerUrl } from '@modul-connect/shared/config'
import { DateTime } from 'luxon'
import { takeEvery, put, select } from 'redux-saga/effects'
import { get, post } from '../../utils/ajax'
import { setMapDate, setMapEndTime, setMapLiveMode, setMapStartTime } from '../actions/map'
import { selectVehicle, startSignalRConnection, stopSignalRConnection } from '../actions/vehicles'
import { getAuthenticatedUserByEmail } from './users'

function* fetchUserTerms({ accessToken }) {
  const url = customerServerUrl() + '/user-terms'
  const result = yield get(accessToken, url)
  if (result && result.status === 'ok')
    yield put({type: 'FETCHED_USER_TERMS', data: yield result.data})
}

function* fetchAcceptedUserTerms({ accessToken }) {
  const url = customerServerUrl() + '/user-terms/user'
  const result = yield get(accessToken, url)
  if (result && result.status === 'ok')
    yield put({type: 'FETCHED_ACCEPTED_USER_TERMS', data: yield result.data})
  else
    yield put({type: 'FAILED_FETCHING_ACCEPTED_USER_TERMS'})
}

function* acceptUserTerms({ accessToken }) {
  const url = customerCommandServerUrl() + '/cmd/user-terms/accept'
  yield post(accessToken, url)
}

function* onToggleMapLiveMode(action) {
  if (action.isLive) {
    yield put(startSignalRConnection(action.accessToken))
    yield put(setMapDate(DateTime.local().toISODate(), action.accessToken))
    yield put(setMapStartTime(DateTime.local().startOf('day')))
    yield put(setMapEndTime(DateTime.local().endOf('day')))

    // if currently selected vehicle is hidden due to privacy -> deselect
    const selected_vehicle = yield select(state => state.selectedVehicle)
    if (selected_vehicle?.boxId) {
      const vehicles = yield select(state => state.vehicles)
      const vehicle = vehicles.find(v => v.boxId === selected_vehicle?.boxId)
      if (vehicle?.isPrivate) {
        yield put(selectVehicle(selected_vehicle?.boxId, false, true, false, true))
      }
    }
  }
  else {
    const signalRConnection = yield select(state => state.signalRConnection)
    yield put(stopSignalRConnection(signalRConnection))
  }
}

function* onSetMapDate(action) {
  // if the new date is today and start and end times are still 00:00 and 23:59, then go into live mode
  const chosenDate = action.date?.split('-')
  const today = DateTime.local()

  const map_settings = yield select(state => state.map_settings)
  let noTimeFiltering = map_settings.startClockTime.hour === 0 && map_settings.startClockTime.minute === 0 &&
    map_settings.endClockTime.hour === 23 && map_settings.endClockTime.minute === 59

  const isToday = chosenDate.length == 3 && chosenDate[0] == today.year && chosenDate[1] == today.month && chosenDate[2] == today.day

  if (isToday && noTimeFiltering && !map_settings.isLive) {
    yield put(setMapLiveMode(true, action.accessToken))
  }
}

function* fetchNotifications({ accessToken }) {
  const url = customerServerUrl() + '/notifications/security'
  const result = yield get(accessToken, url)
  if (result && result?.status === 'ok' )
    yield put({type: 'NOTIFICATIONS_FETCHED', data: yield result.data })
  else if (result && result?.status === 'no_content')
    yield put({type: 'NOTIFICATIONS_FETCHED', data: yield [] })
  else 
  {
    yield put({ type: 'FETCHING_NOTIFICATIONS_FAILED'})
    yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'Failed to fetch notification.'})
  }
}

function* markNotificationsAsSeen({ accessToken, notificationType, notificationIds }) {

  const userState = yield select(state => state.acceptedUserTerms)

  const url = customerCommandServerUrl() + '/cmd/notifications/seen'
  let body = {
    UserId: userState?.id?.replace('user/',''),
    DateOfSeen: DateTime.utc(),
    GroupOfNotifications: notificationType,
    NotificationIds: notificationIds
  }
  const result = yield post(accessToken, url, body)
  if(result.status !== 'ok')
  {
    yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'There was a issue with marking some notifications as seen.'})
    return
  }

  yield put({type: 'NOTIFICATIONS_SEEN', notificationType: notificationType, notificationIds: notificationIds})
}

function* promptUserLogout() {
  yield put({ type: 'SHOW_WARNING_NOTIFICATION', message: 'There was an issue fetching data. Logging out and back in again may resolve the problem.' })
}

export function* appSagas() {
  yield takeEvery('APP_STARTED', getAuthenticatedUserByEmail)
  yield takeEvery('FETCH_USER_TERMS', fetchUserTerms)
  yield takeEvery('FETCH_ACCEPTED_USER_TERMS', fetchAcceptedUserTerms)
  yield takeEvery('ACCEPT_USER_TERMS', acceptUserTerms)

  yield takeEvery('SET_MAP_LIVE_MODE', onToggleMapLiveMode)
  yield takeEvery('SET_MAP_DATE', onSetMapDate)

  yield takeEvery('FETCH_NOTIFICATIONS', fetchNotifications)
  yield takeEvery('MARK_NOTIFICATIONS_SEEN', markNotificationsAsSeen)

  yield takeEvery('FORBIDDEN_SERVER_CALL', promptUserLogout)
}