/* eslint-disable react/jsx-no-undef */
import React from "react";
import { connect } from "react-redux";
import KpiRow from '@modul-connect/shared/components/molecules/kpiRow/kpiRow';

const AssetStatusWidget = ({
  configuredAssets,
  missingAssets,
  batteryIssueAssets,
  isLoading
}) => {
  return <KpiRow isLoading={isLoading} title={'Status'} data={[
    { title: 'Total', value: configuredAssets, unit: null },
    { title: 'Away from homebase', value: missingAssets, unit: null },
    { title: 'Battery warning', value: batteryIssueAssets, unit: null }
  ]} />
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AssetStatusWidget);
