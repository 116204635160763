import React from 'react'
import './timespanPickers.css'
import './dayPicker.css'
import { DateCalendar } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

export const MonthPicker = ({ startTime, setSelectedStartTime, setSelectedEndTime }) => {
  const [selectedYear, setSelectedYear] = React.useState(startTime?.year)

  return <DateCalendar
    value={startTime} 
    views={["year", "month"]}
    openTo="month"
    shouldDisableMonth={(date) => date.set({year: selectedYear}) > DateTime.local()}
    shouldDisableYear={(date) => date.year > DateTime.local().year}
    onChange={(value, selectionState) => { 
      if (selectionState === 'partial') { // year selected
        setSelectedYear(value.year)
      }

      else if (selectionState === 'finish') { // month selected too
        const selectedDate = value.set({year: selectedYear})
        const beginningOfMonth = selectedDate.startOf('month')
        const endOfMonth = selectedDate.endOf('month')
        setSelectedStartTime(beginningOfMonth)
        setSelectedEndTime(endOfMonth)  
      }
    }
  }/>
}