import React from "react";
import { useFela } from "react-fela";

const defaultStyle = {
  display: "block"
};

const View = ({
  children,
  className,
  innerRef = null,
  as: As = "div",
  extend,
  ...props
}) => {
  const { css } = useFela(props);

  return (
    <As
      {...props}
      ref={innerRef}
      className={css(defaultStyle, extend) + (className ? " " + className : "")}
    >
      {children}
    </As>
  );
};

export default View;
