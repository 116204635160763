import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Users from "./users";
import Vehicles from "./vehicles/vehicles";
import "./fleet.css";
import { dismissAjaxError } from "../../state/actions/app";
import MainPage from "../mainPage";

const Fleet = ({
  vehicles_sorted,
  vehicles,
}) => {
  const [wordReplacements, setWordReplacements] = useState(null);

  useEffect(() => {
    const vehicleNames = [
      ...(vehicles_sorted.data ?? []),
      ...(vehicles ?? []),
    ].map((vehicle) => ({
      word: vehicle.mainbox_id,
      replacement: vehicle.vehicleName ?? vehicle.vehicle?.baseVehicleName,
    }));
    setWordReplacements([...vehicleNames]);
  }, [vehicles_sorted, vehicles]);

  return (
    <MainPage
      rootURL={"fleet"}
      landingPageURL={"fleet/fleet_vehicles"}
      initialPageIndex={1} // the initial value needs to match the value of the landing page (see routes file)
      hideBreadcrumbs={true}
      breadCrumbWordReplacements={wordReplacements}
      sidebarContent={sidebarContent}
    />
  );
};

const sidebarContent = [
  {
    id: 'users',
    title: "Users",
    content: <Users />,
    icon: "MdSupervisorAccount",
    subPages: [{ id: "main", title: "Users" }],
  },
  {
    id: 'vehicles',
    title: "Vehicles",
    content: <Vehicles />,
    icon: "MdDirectionsCar",
    subPages: [{ id: "main", title: "Vehicles" }],
  },
  // {title: 'Organisations', content: <Organisations />, icon: 'MdBusiness'}
];

const mapStateToProps = ({ vehicles_sorted, vehicles }) => ({
  vehicles_sorted,
  vehicles,
});

const mapDispatchToProps = (dispatch) => ({
  dismissAjaxError: () => dispatch(dismissAjaxError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fleet);
