import React from "react";
import { connect } from "react-redux";
import ChartPie from "./ChartPie";
import View from "@modul-connect/shared/components/atoms/view";


const Top5Vehicles = ({
  data,
  title,
  width,
  height,
  innerRadius,
  outerRadius,
  titleIcon
}) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ flex: 1, }}>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 12, gap: 5 }}>
          <img width={22} src={titleIcon} />
          <View>
            {
              title
            }
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
          {
            data.map((d, index) =>
              <View key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginRight: 5 }}>
                <ChartPie
                  data={d}
                  width={width}
                  height={height}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  styleText={{
                    fontSize: "12px",
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontFamily: "Inter",
                  }}
                />
                <View style={{ fontSize: "10px", fontWeight: 400, maxWidth: width, fontStyle: 'normal', marginTop: 10, fontFamily: "Inter", textAlign: 'center', overflowWrap: 'break-word' }}>
                  {
                    d[0].vehicleName
                  }
                </View>
              </View>
            )
          }
        </View>
      </View>
    </View>
  )
}


const mapStateToProps = (props) => props;

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Top5Vehicles);
