import React, { Fragment } from 'react'
import View from "@modul-connect/shared/components/atoms/view";
import StartEndTimePicker from '@modul-connect/shared/components/molecules/timePicker/startEndTimePicker'
import MapFilterPicker from './mapFilterPicker';
import { H3 } from '@modul-connect/shared/components/atoms/text'
import { MdTune } from 'react-icons/md';
import theme from '@modul-connect/shared/theme'
import { getServiceList } from '../../../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';

const FilterMenu = ({
  setSelectedStartTime,
  setSelectedEndTime,
  startTime,
  endTime,
  filter,
  setFilter,
  subtrees,
  themes
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const isMobile = themes.device === 'mobile'
  const services = getServiceList({subtrees})

  let nAppliedFilters = 0
  if (filter?.mustHaveWeightWarning) nAppliedFilters++
  if (!(startTime.hour === 0 && startTime.minute === 0)) nAppliedFilters++
  if (!(endTime.hour === 23 && endTime.minute === 59)) nAppliedFilters++

  return (
    <Fragment>
      <View extend={styles.header} onClick={() => setMenuOpen(!menuOpen)}>
        <View style={ styles.filterHeading }>
          <MdTune size={16} color={theme.colors.grey}/><H3 noPadding> {'FILTERS' + ' (' + nAppliedFilters + ')'}</H3>
        </View>
        {/* <Input name='SearchVehicle' placeholder='Search...' onChange={event => setSearch(event.target.value.trim())} /> */}
      </View>
      {/* <IconButton device={themes.device} icon={'tune'} noBg onClick={() => setMenuOpen(!menuOpen)}>Filter</IconButton> */}
      {/* <TransitionGroup>
        <CSSTransition
          key={menuOpen}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          timeout={500}
          classNames={isMobile ? 'fade-mobile' : 'fade'}
        > */}
          <View extend={[{ marginTop: '10px' }, menuOpen ? styles.showing : styles.hiding]}>
            {
              services.hasService(ServiceType.DrivingLogs) ?
                <StartEndTimePicker
                  setStartTime={setSelectedStartTime}
                  setEndTime={setSelectedEndTime}
                  startTime={startTime}
                  endTime={endTime}
                />
              : null
            }
            {
              <MapFilterPicker 
                isMobile={isMobile}
                filter={filter}
                setFilter={setFilter}
              />
            }
          </View>
        {/* </CSSTransition>
      </TransitionGroup> */}
    </Fragment>
  )
}

const styles = {
  showing: {
    display: 'flex',
    flexDirection: 'column'
  },
  hiding: {
    display: 'none'
  },
  filterHeading: { marginBottom: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '5px' }
}

export default FilterMenu;
