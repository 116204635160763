import columns_byVehicle from './tollPassageColumnsByVehicle'

const columns = [
  columns_byVehicle[0],
  columns_byVehicle[1],
  columns_byVehicle[2],
  columns_byVehicle[3],
  columns_byVehicle[4],
  {
    id: 'vehicleName',
    label: 'Vehicle',
    minWidth: 120,
    hide: [],
    align: 'left'
  },
  {
    id: 'boxId',
    label: 'Mainbox Id',
    minWidth: 120,
    hide: []
  },
  columns_byVehicle[6]
]

export default columns