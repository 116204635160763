import React from "react";
import WeightIcon from "@modul-connect/shared/img/vehicle-weighing-black.svg";
import OverweightIcon from "@modul-connect/shared/img/vehicle-weighing-red.svg";
import BatteryIcon from "@modul-connect/shared/img/battery-monitoring-black.svg";
import BatteryWarningIcon from "@modul-connect/shared/img/battery-monitoring-red.svg";
import AssetTrackerIcon from "@modul-connect/shared/img/tracker_map.svg";
import AssetTrackerWarningIcon from "@modul-connect/shared/img/tracker_map_red.svg";
import ClimateWarningIcon from "@modul-connect/shared/img/updated_climate_sensor_warning.svg";
import ClimateIcon from "@modul-connect/shared/img/updated_climate_sensor.svg";
import View from "@modul-connect/shared/components/atoms/view";
import SecurityIcon from "@modul-connect/shared/img/securitySystem.svg";
import {
  hasClimateSensorIssue,
  hasBatteryIssue,
  getVehicleBeaconStatus,
  hasWeightIssue,
  getThermometers,
  getHumiditySensors,
} from "../../../utils/vehicleUtils";

export const VehicleListItemStatusRowV2 = ({
  vehicle,
  beacons,
  map_settings,
  securitySystemStatus,
}) => {
  const isPrivate = vehicle.disabled;
  const hasWeighingSystem = vehicle?.currentStatus?.weightSystem;
  const hasClimateIssue = hasClimateSensorIssue(vehicle);
  const weightIssue = hasWeightIssue(vehicle);
  const batteryIssue = hasBatteryIssue(vehicle);
  const privateRoute = isPrivate;

  const beaconStatus = getVehicleBeaconStatus(vehicle, beacons);

  return (
    <View extend={styles.icons}>
      <View extend={styles.firstIconContainer}>
        {beacons &&
        map_settings.isLive &&
        beaconStatus.expectedBeacons !== 0 ? (
          beaconStatus.warning ? (
            <img height={18} src={AssetTrackerWarningIcon} alt="AssetTrackers" />
          ) : (
            <img height={18} src={AssetTrackerIcon} alt="AssetTrackers" />
          )
        ) : (
          <img
            style={{ opacity: 0.5 }}
            height={18}
            src={AssetTrackerIcon}
            alt="AssetTrackers"
          />
        )}
      </View>
      <View extend={{ ...styles.iconContainer}}>
        {!isPrivate &&
          map_settings.isLive ?
          (weightIssue ? (
            <img height={18} src={OverweightIcon} alt="Overweight" />
          ) : hasWeighingSystem ? (
            <img height={18} src={WeightIcon} alt="Weight" />
          ) : (
            <img
              style={{ opacity: 0.5 }}
              height={18}
              src={WeightIcon}
              alt="AssetTrackers"
            />
          )): <img
          style={{ opacity: 0.5 }}
          height={18}
          src={WeightIcon}
          alt="AssetTrackers"
        />}
      </View>
      <View extend={{ ...styles.iconContainer}}>
        {securitySystemStatus?.securitySystem ? (
          <img height={18} src={SecurityIcon} alt="Security icon" />
        ) : (
          <img
            style={{ opacity: 0.5 }}
            height={18}
            src={SecurityIcon}
            alt="Security icon"
          />
        )}
      </View>
      <View extend={styles.iconContainer}>
        {(getThermometers(vehicle).length ||
          getHumiditySensors(vehicle).length) &&
        map_settings.isLive ? (
          hasClimateIssue ? (
            <img height={18} src={ClimateWarningIcon} alt="Climate icon" />
          ) : (
            <img height={18} src={ClimateIcon} alt="Climate icon" />
          )
        ) : (
            <img style={{opacity: 0.5 }} height={18} src={ClimateIcon} alt="Climate icon" />
        )}
      </View>
      <View extend={styles.iconContainer}>
        {!isPrivate && map_settings.isLive ? (
          batteryIssue ? (
            <img height={18} src={BatteryWarningIcon} alt="Battery low" />
          ) : (
            <img height={18} src={BatteryIcon} alt="Battery" />
          )
        ) : <img style={{ opacity: 0.5 }} height={18} src={BatteryIcon} alt="Battery" />}
      </View>

      {/*  {privateRoute ?
                  <img src={EyeIcon} alt='Private route' /> : undefined
                } */}
      {/* vehicle?.currentStatus?.tyres?.some(hasWarning)  ?
                <img src={TyrePressureIcon} alt='Tyre Pressure low'/> : undefined
              */}
    </View>
  );
};

const styles = {
  icons: {
    gap: "8px",
    flex: 1,
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  firstIconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  lastIconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
