export const log_columns = [
  {
    id: "homebase",
    label: "Homebase",
    width: 220,
    hide: [],
    align: "left",
  },
  {
    id: "missing",
    label: "Missing",
    width: 200,
    hide: [],
    warning: (value) => {
      return !value || value === "-" ? false : true;
    },
    align: "left",
  },
  {
    id: "assetTrackers",
    label: "Asset trackers",
    width: 210,
    hide: [],
    align: "left",
  },
  {
    id: "battery",
    label: "Battery",
    width: 120,
    hide: [],
    align: "left",
  },
];
