import React from 'react'
import View from '@modul-connect/shared/components/atoms/view'
import Card from '../atoms/card/card'

const dateSelectionWidget = ({ datePickerComponent, rightHandSide, extendDatePickerStyle }) => {
  return (
    <Card extend={{
      minHeight: 110,
      padding: '16px 24px',
      paddingRight: 0,
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      alignContent: 'center',
    }}>
      <View extend={[styles.datePickerSection, extendDatePickerStyle]}>
        {datePickerComponent}
      </View>
      {/* <Divider sx={styles.divider} orientation="vertical" flexItem variant='middle'/> */}
      <View extend={styles.rightHandSection}>
        {rightHandSide}
      </View>
    </Card>
  )
}

const styles = {
  rightHandSection: {
    flex: 4,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    flexWrap: 'wrap'
  },
  /* divider: {
    flex: 0.05,
    height: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
  }, */
  datePickerSection: {
    flex: 5,
    display: 'flex',
    flexDirection: 'row',
    borderRight: '1px solid lightGrey',
    paddingRight: '35px',
    marginRight: '35px',
    height: '100%',
  }
}

export default dateSelectionWidget