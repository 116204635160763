import React from 'react'
import View from '@modul-connect/shared/components/atoms/view';
import { A } from '@modul-connect/shared/components/atoms/text'
import { Icon } from '@modul-connect/shared/components/atoms/icon/icon';
import theme from "@modul-connect/shared/theme";
import Button from '@modul-connect/shared/components/atoms/button'

export const ReportDownloadButton = ({ onClick, disabled }) => {
  return (
    <Button disabled={disabled} extend={{
      borderRadius: theme.layout.grid / 2,
      backgroundColor: theme.colors.darkerGrey,
      height: '40px',
      width: '210px',
    }} onClick={onClick} noPadding>
      <View style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <A extend={{ color: 'white' }} >
          {'DOWNLOAD PERIOD'}
        </A>
        <Icon style={{}} icon={'DownloadWhite'} />
      </View>
    </Button>
  )
}