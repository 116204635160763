export const FETCH_SUBTREES = 'FETCH_SUBTREES'
interface FetchSubtrees {
  type: typeof FETCH_SUBTREES,
  accessToken: string
}

export const FETCH_ORGANISATION_NAME = 'FETCH_ORGANISATION_NAME'
interface FetchOrganisationName {
  type: typeof FETCH_ORGANISATION_NAME,
  accessToken: string,
  id: string
}

export const FETCH_API_KEYS = 'FETCH_API_KEYS'
interface FetchApiKeys {
  type: typeof FETCH_API_KEYS,
  accessToken: string,
  includeExpired: boolean
}

export const REQUEST_API_KEY = 'REQUEST_API_KEY'
interface RequestApiKey {
  type: typeof REQUEST_API_KEY,
  accessToken: string,
  expiresAt: Date,
  organisations: string[]
}

export const FORCE_EXPIRE_API_KEY = 'FORCE_EXPIRE_API_KEY'
interface ForceExpireApiKey {
  type: typeof FORCE_EXPIRE_API_KEY,
  accessToken: string,
  id: string
}

export interface Subtree {
  uuid: string
  name: string
  services: string[]
  children: Subtree[]
}
export const SUBTREES_FETCHED = 'SUBTREES_FETCHED'
interface SubtreesFetched { 
  type: typeof SUBTREES_FETCHED,
  subtrees: Subtree[],
  total: number
}

export interface OrganisationName {
  translated: string[],
  uuid: string
}
export const FETCHED_ORGANISATION_NAME = 'FETCHED_ORGANISATION_NAME'
interface FetchedOrganisationName {
  type: typeof FETCHED_ORGANISATION_NAME,
  organisation: OrganisationName
}

export interface ApiKey {
  id: string,
  expiresAt: Date,
  organisations: string[]
}
export const FETCHED_API_KEYS = 'FETCHED_API_KEYS'
interface FetchedApiKeys {
  type: typeof FETCHED_API_KEYS,
  keys: ApiKey[],
  total: number
}

export interface NewApiKey {
  public_id: string,
  api_key: string,
  expires_at_utc: Date,
}
export const RECEIVED_NEW_API_KEY = 'RECEIVED_NEW_API_KEY'
interface ReceivedNewApiKey {
  type: typeof RECEIVED_NEW_API_KEY,
  key: NewApiKey
}

export const EXPIRED_API_KEY_BY_FORCE = 'EXPIRED_API_KEY_BY_FORCE'
interface ExpiredApiKeyByForce {
  type: typeof EXPIRED_API_KEY_BY_FORCE,
  id: string
}

export type SelectedOrganisations = string[]

export const SET_SELECTED_ORGANISATIONS = 'SET_SELECTED_ORGANISATIONS'
interface SetSelectedOrganisation {
  type: typeof SET_SELECTED_ORGANISATIONS,
  uuids: SelectedOrganisations
}

export const APP_STARTED = 'APP_STARTED' 

interface AppStarted {
  type: typeof APP_STARTED,
  token: string,
  email: string
}

export const AUTHENTICATED_USER_FETCHED = 'AUTHENTICATED_USER_FETCHED' 

interface AuthenticatedUserFetched {
  type: typeof AUTHENTICATED_USER_FETCHED,
  token: string,
  user: object,
  isNewUser: boolean
}

export type CustomerActionTypes = FetchSubtrees | FetchOrganisationName | FetchApiKeys | RequestApiKey | ForceExpireApiKey | SubtreesFetched | FetchedOrganisationName | FetchedApiKeys | ReceivedNewApiKey | ExpiredApiKeyByForce | SetSelectedOrganisation | AppStarted | AuthenticatedUserFetched