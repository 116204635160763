import React, { useState } from 'react'
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import { H3, DatePickerTitle } from '@modul-connect/shared/components/atoms/text'
import { DateTime } from "luxon"
import { styles } from '../datePickerStyles'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import theme from '@modul-connect/shared/theme';
import { Icon } from '@modul-connect/shared/components/atoms/icon/icon';
import { DayPickerInput } from '@modul-connect/shared/components/molecules/daySelector/DayPickerInput'

const StartEndDatePicker = ({ loading, startDate, endDate, onStartMonthPicked, onEndMonthPicked, disabled, noTitle }) => {
  return (
    <View extend={styles.dayPickerWithTitle}>
      <View extend={styles.monthPickerView}>
        {noTitle ? null : <View extend={styles.filterHeaderContainer}><DatePickerTitle extend={{ width: '100%' }} title1={'Selected time'} title2={'period:'} /></View>}
        <View extend={[styles.filterSelectContainer, noTitle && {paddingLeft: 20}]}>
          <View extend={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              startDate={startDate}
              endDate={endDate}
              onStartMonthPicked={onStartMonthPicked}
              onEndMonthPicked={onEndMonthPicked}
              loading={loading}
              dateIs='start'
            />
          </View>
          <H3 extend={{ paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: 'center', textAlign: 'center', lineHeight: 0 }}>―</H3>
          <View extend={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              startDate={startDate}
              endDate={endDate}
              onStartMonthPicked={onStartMonthPicked}
              onEndMonthPicked={onEndMonthPicked}
              loading={loading}
              dateIs='end'
            />
          </View>
        </View>
      </View>
    </View>
  )
}

const DatePicker = ({
  startDate,
  endDate,
  loading,
  dateIs,
  disabled,
  onStartMonthPicked,
  onEndMonthPicked
}) => {
  let [hoverRange, setHoverRange] = useState(null)

  const handleDayEnter = date => {
    setHoverRange(getMonthRange(date))
  }

  const handleDayLeave = () => {
    setHoverRange(getMonthRange(undefined))
  }

  const getMonthRange = (date) => {
    let dateTime = DateTime.fromJSDate(date)

    const days = []
    for (let i = 1; i <= dateTime.daysInMonth; i++) {
      days.push(DateTime.local(dateTime.year, dateTime.month, i).toJSDate())
    }
    return days
  }

  const hoverRangeStart = hoverRange && hoverRange.length > 0 && [hoverRange[0]]
  const hoverRangeEnd = hoverRange && hoverRange.length > 0 && [hoverRange[hoverRange.length - 1]]

  return (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <DayPickerInput
        mode='range'
        date={dateIs === 'start' ? startDate : endDate}
        placeholder=''
        defaultMonth={endDate.toJSDate()}
        disabled={(disabled || loading.progress === 'loading') ? true : false}
        selectionUnit="months"
        disableDaysAfter={DateTime.local().endOf("month")}

        rangeProps={{
          selected: { from: startDate.toJSDate(), to: endDate.toJSDate() },
          onSelectionMade: (range) => {
            onStartMonthPicked(DateTime.fromJSDate(range.from).startOf('month'))          
            onEndMonthPicked(DateTime.fromJSDate(range.to).endOf('month'))
          },
        }}

        onDayMouseEnter={(date) => handleDayEnter(date)}
        onDayMouseLeave={(date) => handleDayLeave(date)}

        modifiers={{
          start: startDate.toJSDate(),
          end: endDate.toJSDate(),
          hoverRange: hoverRange,
          hoverRangeStart: hoverRangeStart ?? [],
          hoverRangeEnd: hoverRangeEnd ?? [],
        }}
        modifiersClassNames={{
          hoverRange: "hover-range",
          hoverRangeStart: "hover_range_start",
          hoverRangeEnd: "hover_range_end"
        }}
        
      />
    </View>
  )
}

// TODO: click on this should do the same as clicking inside the input field

// TODO: want to change to a different input component that supports icons properly, like MUI
// (had problems with rerender flickering with MUI, so for now rendering the icon like this)
export const CalendarIcon = () => {
  return (
    <View style={{ // for positioning the icon
      height: styles.inputField.height, width: 0,
      position: 'relative',
      alignSelf: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <View style={{
        position: 'absolute',
        height: '100%',
        padding: styles.inputField.padding,
        right: 0,

        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}>
        <Icon icon={'Calendar'} />
      </View>
    </View>
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps)(StartEndDatePicker)
