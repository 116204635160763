import React, { Fragment, useState } from "react";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import { Link } from "@modul-connect/shared/components/atoms/text/text";
import { Icon } from "@modul-connect/shared/components/atoms/icon/icon";
import IconButton from "@modul-connect/shared/components/atoms/iconButton/iconButton";
import {
  removeDocument,
} from "../../../portal/src/state/actions/vehicles";
import UploadFileModal from "../../../portal/src/pages/reports/assetTrackerStatus/modals/uploadFileModal";
import { useAuth0 } from "@auth0/auth0-react";

const UploadFileLink = ({
  uploaded_file,
  saving,
  removeDocument,
  text,
  showFile=true,
  showProgressSpinner=true,
  noIcon,
  noUnderline,
  normalTextColor,
  disabled,
  onSave
}) => {
  // TODO: use this on mark Period as done modal and on attach document modal
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  
  const sendRemoveDocument = (documentId) => {
    getAccessTokenSilently().then((accessToken) => {
      removeDocument(accessToken, documentId)
    })
  }

  const isUploadingDocument = saving.uploadDocument === 'uploading'

  return (
    <Fragment>
      {uploaded_file && showFile ? (
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Link
            disabled={disabled}
            onClick={() => {
              setOpenUploadModal(true);
            }}
            underline={!noUnderline}
            normalTextColor={normalTextColor}
            gap={5}
            icon={noIcon ? null : <Icon icon="Clip" />}
          >
            {text ?? "Change document"}
          </Link>
          <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <Link disabled={disabled} normalTextColor={normalTextColor} underline={noUnderline} gap={5}>
              {uploaded_file.fileName}
            </Link>
            <IconButton
              v2
              icon={"DeleteX"}
              onClick={() => sendRemoveDocument(uploaded_file.documentId)}
            />
          </View>
        </View>
      ) : isUploadingDocument && showProgressSpinner ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <Link
          disabled={disabled}
          normalTextColor={normalTextColor}
          onClick={() => {
            setOpenUploadModal(true);
          }}
          underline={!noUnderline}
          gap={5}
          icon={noIcon ? null : <Icon icon="Clip" />}
        >
          {text ?? "Attach document"}
        </Link>
      )}

      <UploadFileModal
        pdfOnly
        updateFileOnChange
        open={openUploadModal}
        textForAcceptedFileTypes={'Supported file types: PDF'}
        acceptedFileTypes={'.pdf'}
        onClose={() => {
          setOpenUploadModal(false);
        }}
        onSave={() => {
          setOpenUploadModal(false);
          onSave && onSave()
        }}
        uploadingStatus={saving.uploadDocument}
      />
    </Fragment>
  );
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  removeDocument: (accessToken, documentId) =>
    dispatch(removeDocument(accessToken, documentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFileLink);
