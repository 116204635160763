import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { H4 } from "@modul-connect/shared/components/atoms/text";
import { DateTime } from "luxon";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { WarningIcon } from "@modul-connect/shared/components/atoms/icons/warningIcon";
import { connect } from "react-redux";
import {
  deselectAssetTracker,
  selectAssetTracker,
} from "../../../state/actions/vehicles";
import { ListItem } from "@modul-connect/shared/components/molecules/listitem/listItem";

export const calculateDistance = (lonVeh, latVeh, lonBeac, latBeac) => {
  var R = 6371;
  var difLat = ((latBeac - latVeh) * Math.PI) / 180;
  var difLon = ((lonBeac - lonVeh) * Math.PI) / 180;
  var distance =
    Math.pow(Math.sin(difLat / 2), 2) +
    Math.cos((latVeh * Math.PI) / 180) *
      Math.cos((latBeac * Math.PI) / 180) *
      Math.pow(Math.sin(difLon / 2), 2);
  var sphericalDistance =
    2 * Math.atan2(Math.sqrt(distance), Math.sqrt(1 - distance));
  return (sphericalDistance * R * 1000).toFixed(0);
};

export const roundDistance = (dist) => {
  if (dist === null || dist === undefined) return undefined;

  if (dist < 50) return dist;

  const n = (dist / 50).toFixed(0);
  return 50 * n;
};

const BeaconListItem = ({
  disabled,
  beacon,
  selectAssetTracker,
  deselectAssetTracker,
  selectedAssetTracker,
}) => {
  const TextComponent = ({ text, isHeader }) => {
    return (
      <H4 extend={isHeader ? { fontWeight: 500 } : null} noPadding>
        {text}
      </H4>
    );
  };

  return beacon ? (
    <ListItem
      key={beacon.beaconId}
      isSelected={selectedAssetTracker && selectedAssetTracker.beaconId === beacon.beaconId}
      isDisabled={disabled}
      dontChangeBgColorOnSelection
      onClick={() => {
        selectedAssetTracker?.beaconId === beacon.beaconId
          ? deselectAssetTracker(beacon.beaconId, beacon.boxId)
          : selectAssetTracker(beacon.beaconId, beacon.boxId);
      }}
      titleComponent={
        <View extend={styles.nameDisplay}>
          <TextComponent text={"Name: " + beacon.name} isHeader />
          {!beacon.isConnected ? (
            <WarningIcon size={15} extend={{ paddingLeft: "4px" }} />
          ) : null}
        </View>
      }
      subtitleComponent={
        beacon?.currentStatus?.timestamp ? (
          <TextComponent
            text={"Last seen: " + dateTimeToString(DateTime.fromISO(beacon.currentStatus.timestamp), "date-and-time")}
          />
        ) : null
      }
    />
  ) : null;
};

const mapStateToProps = ({ selectedAssetTracker }) => ({
  selectedAssetTracker,
});

const mapDispatchToProps = (dispatch) => ({
  selectAssetTracker: (beaconId, boxId) =>
    dispatch(selectAssetTracker(beaconId, boxId)),
  deselectAssetTracker: (beaconId, boxId) =>
    dispatch(deselectAssetTracker(beaconId, boxId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeaconListItem);

const styles = {
  container: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  nameDisplay: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};
