export const beacons = (state = [], action) => {
  var beacon = null
  switch (action.type) {
    case 'BEACON_SNAPSHOTS_FETCHED':
      return action.data
    case 'ASSET_TRACKER_NAME_CHANGED':
      {
        beacon = state.find(x => x.beaconId === action.status.data.beaconId)
        if(!beacon)
          return state
        beacon.name = action.status.data.name
        return [...state]
      }
    case 'ASSET_TRACKER_CATEGORY_CHANGED':
      {
        beacon = state.find(x => x.beaconId === action.status.data.beaconId)
        if(!beacon)
          return state

        beacon.category = action.status.data.category
        return [...state]
      }
    case 'ASSET_TRACKER_REMOVED':
      {
        beacon = state.find(b => b.beaconId === action.status.data.beaconId)
        if (!beacon)
          return state
        return [
          ...state.filter(b => b.beaconId !== action.status.data.beaconId)
        ]
      }
    case 'ASSET_TRACKER_ASSIGNED':
      {
        beacon = state.find(b => b.beaconId === action.status.data.beaconId)
        if (beacon)
          return state

        state.push(action.status.data)
        return [
          ...state
        ]
      }
      case 'ASSET_TRACKER_FOUND':
        {
          beacon = state.find(x => x.beaconId === action.status.data.beaconId)
          if(!beacon)
            return state
          beacon.isConnected = true
          return [...state]
        }
      case 'ASSET_TRACKER_IS_MISSING':
        {
          beacon = state.find(x => x.beaconId === action.status.data.beaconId)
          if(!beacon)
            return state

          beacon.isConnected = false
          beacon.currentStatus.timeStamp = action.status.data.timeStamp
          beacon.currentStatus.geoLocation = action.status.data.lastKnownPosition
          return [...state]
        }
    default:
      return state;
  }
}