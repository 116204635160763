import React from 'react';
import View from '@modul-connect/shared/components/atoms/view';

const Card = ({ children, v2, extend }) => {
  return(
    <View extend={[v2 ? styles.container_v2 : styles.container, extend]}>
      {children}
    </View>
  )
};

const styles = {
  container: ({ theme: { layout, colors, shadowStyle } }) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: "8px 10px",
    backgroundColor: colors.white,
    borderColor: colors.primaryDark,
    borderWidth: 1,
    borderRadius: layout.grid,
    ...shadowStyle,
  }),
  container_v2: ({ theme: { colors } }) => ({
    display: 'flex',
    padding: '12px 24px 24px 24px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '24px',
    borderRadius: '8px',
    backgroundColor: colors.white,
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)'
  })
}

export default Card;