import React, { Fragment } from "react";
import { connect, useStore } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import Page from "@modul-connect/shared/components/atoms/page";
import { DateTime } from "luxon";
import { useAuth0 } from '@auth0/auth0-react'
import "./dashboard.css";
import {
  fetchCurrentDrivers,
  fetchVehicles,
  selectRoute,
  fetchWarningLogs,
  fetchVehiclesServices,
} from "../../state/actions/vehicles";
import Notification from '@modul-connect/shared/components/molecules/notification/notification'
import OverviewSidebar from "./sidebars/overview/components/overviewSidebar";
import DetailsSidebar from "./sidebars/details/detailsSidebar";
import { setMapFilter, setMapStartTime, setMapEndTime, setMapLiveMode, setMapDate } from "../../state/actions/map";
import { annotateVehicleRoutesWithWarnings, RouteWithinTimeframe } from "../../utils/vehicleUtils";
import LiveMapToggle from "./map/liveMapToggle";
import { getServiceList } from "../../utils/ServiceList";
import { P } from '@modul-connect/shared/components/atoms/text'
import { ServiceType } from "@modul-connect/shared/utils/services";
import { getStartEndTimeFromMapsettings } from "./map/utils";
import Map from "./map/map";

const MissingServiceAllert = () => {
  return (
    <View
      style={{
        minWidth: 150,
        minHeight: 100,
        padding: 5,
        backgroundColor: "black",
        display: "flex",
        flex: 1,
        alignSelf: "center",
        justifyContent: "center",
        width: "15%",
        height: "7%",
        zIndex: 10,
        borderRadius: 10,
        margin: "auto",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)"
      }}
    >
      <P
        extend={{
          color: "white",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          textAlign: "center"
        }}
      >
        There is no active subscription for the map
      </P>
    </View>

  )
}


const Dashboard = React.memo(({
  themes,
  fetchVehicles,
  loading,
  selectedVehicle,
  fetchCurrentDrivers,
  selected_organisations,
  selectRoute,
  vehicles,
  setMapFilter,
  setMapStartTime,
  setMapEndTime,
  map_settings,
  setMapLiveMode,
  setMapDate,
  subtrees,
  fetchVehiclesServices,
  vehicles_security_system_status
}) => {
  const store = useStore()
  const services = getServiceList({ subtrees })
  const hasMapAccess = services.hasService(ServiceType.mapAccess)
  const hasSideBarAccess = services.hasService(ServiceType.mapAccess) // If there is a need for sidebar access in the future
  const isMobile = themes.device === 'mobile'
  const orientation = themes.orientation === 'mobile'
  const [showSidebar, setShowSidebar] = React.useState(true);
  const [showDetailsSidebar, setShowDetailsSidebar] = React.useState(selectedVehicle.boxId ? true : false);
  const [mapBottom, setMapBottom] = React.useState(isMobile && orientation === 'portrait' && showSidebar ? '60%' : 0)
  const { getAccessTokenSilently } = useAuth0();
  const [fetchingRoutesIsSlow, setFetchingRoutesIsSlow] = React.useState(false)
  const [fetchingLastKnownPositionsIsSlow, setFetchingLastKnownPositionsIsSlow] = React.useState(false)
  const [fetchingVehiclePositionsIsSlow, setFetchingVehiclePositionsIsSlow] = React.useState(false)
  const [detailsClosedIndirectly, setDetailsClosedIndirectly] = React.useState(false)
  const [routesToShow, setRoutesToShow] = React.useState([])
  const dateIsToday = (date) => {
    return date === DateTime.local().toISODate()
  }

  let isToday = dateIsToday(map_settings.date)
  const hasRoutes = (vehicles, date, boxId) => {
    let foundRoute = false
    vehicles.forEach(v => {
      if (foundRoute) return

      if (boxId && v.boxId !== boxId) return

      if (v.geoJSON?.routes) {
        if (v.geoJSON.routes[date])
          foundRoute = true
        return
      }
    })
    return foundRoute
  }

  React.useEffect(() => {
    getAccessTokenSilently()
      .then((accessToken) => {
        fetchCurrentDrivers(accessToken)
      })
  }, [selected_organisations]);


  React.useEffect(() => {
    if (themes.device !== 'mobile' && mapBottom !== 0) {
      setMapBottom(0)
    }
  }, [themes]);

  React.useEffect(() => {
    getAccessTokenSilently()
      .then((accessToken) => {fetchVehicles(accessToken)
        fetchVehiclesServices(accessToken)
      })
      
  }, [selected_organisations]);

  React.useEffect(() => {
    if (selectedVehicle.boxId) {
      showBoth()
    }
    else {
      setShowDetailsSidebar(false)
    }
  }, [selectedVehicle])

  /* React.useEffect(() => {
    if (!warning_logs?.data?.overweightWarnings?.length || !drivingLog?.boxId) {
      setOverweightWarning(null)
      return
    }

    const startTime = DateTime.fromISO(drivingLog.startTime)
    const endTime = DateTime.fromISO(drivingLog.endTime)

    if (warning_logs.data?.overweightWarnings?.length) {
      let warningLog = warning_logs.data.overweightWarnings.find(
        log => (
           log.boxId === drivingLog.boxId && 
           DateTime.fromISO(log.endTime) >= (DateTime.fromISO(startTime)) &&
           DateTime.fromISO(log.endTime) <= (DateTime.fromISO(endTime))
        ))
      setOverweightWarning(warningLog)
    }
    else {
      setOverweightWarning(null)
    }
  }, [warning_logs, drivingLog]) */
  React.useEffect(() => {
    switch (loading.fetchVehicleRoutes) {
      case 'loading':
        setFetchingRoutesIsSlow(false)

        const timer = setTimeout(() => {
          if (loading.fetchVehicleRoutes === 'loading') {
            setFetchingRoutesIsSlow(true)
          }
        }, 1000);
        return () => clearTimeout(timer);
      case 'failed':
        setFetchingRoutesIsSlow(false)
        break
      case 'null':
        setFetchingRoutesIsSlow(false)
      default:
        return
    }

  }, [loading.fetchVehicleRoutes]);

  React.useEffect(() => {
    switch (loading.fetchLastKnownPositions) {
      case 'loading':
        setFetchingLastKnownPositionsIsSlow(false)

        const timer = setTimeout(() => {
          if (loading.fetchLastKnownPositions === 'loading') {
            setFetchingLastKnownPositionsIsSlow(true)
          }
        }, 1000);
        return () => clearTimeout(timer);
      case 'failed':
        setFetchingLastKnownPositionsIsSlow(false)
        break
      case 'null':
        setFetchingLastKnownPositionsIsSlow(false)
      default:
        return
    }

  }, [loading.fetchLastKnownPositions]);

  React.useEffect(() => {
    switch (loading.fetchVehiclePositions) {
      case 'loading':
        setFetchingVehiclePositionsIsSlow(false)

        const timer = setTimeout(() => {
          if (loading.fetchVehiclePositions === 'loading') {
            setFetchingVehiclePositionsIsSlow(true)
          }
        }, 1000);
        return () => clearTimeout(timer);
      case 'failed':
        setFetchingVehiclePositionsIsSlow(false)
        break
      case 'null':
        setFetchingVehiclePositionsIsSlow(false)
      default:
        return
    }

  }, [loading.fetchVehiclePositions]);

  React.useEffect(() => {
    selectRoute(null, null)
  }, [map_settings?.date])

  const showBoth = () => {
    setShowSidebar(true)
    setShowDetailsSidebar(true)
  }

  const hideBoth = () => {
    if (showDetailsSidebar)
      setDetailsClosedIndirectly(true)

    setShowSidebar(false)
    setShowDetailsSidebar(false)
  }

  React.useEffect(() => {
    if (showSidebar && detailsClosedIndirectly) {
      setShowDetailsSidebar(true)
      setDetailsClosedIndirectly(false)
    }
  }, [showSidebar])

  React.useEffect(() => {
    isToday = map_settings?.date === DateTime.local().toISODate()
  }, [map_settings?.date])

  React.useEffect(() => {
    const logsWithWarnings = annotateVehicleRoutesWithWarnings(vehicles, map_settings.date)
      ?.filter(v => v && v.boxId === selectedVehicle?.boxId)
      .map(v => ({ boxId: v.boxId, routes: v.geoJSON?.routes?.[map_settings.date] }))

    const {startTime, endTime} = getStartEndTimeFromMapsettings(map_settings)

    const result = []
    if (logsWithWarnings) {
      for (const box of logsWithWarnings) {
        for (const startTime in box.routes) {
          const route = box.routes[startTime]
          result.push({
            boxId: box.boxId,
            startTime,
            endTime: route.endTime,
            distance: route.distance,
            hasWeightWarning: route.hasWeightWarning,
            isPrivate: route.isPrivate
          })
        }
      }
    }

    const routes_to_show = result?.filter(log => {
      if (log.isPrivate) return false
      if (map_settings?.filter.mustHaveWeightWarning)
        return log.hasWeightWarning
      return RouteWithinTimeframe(DateTime.fromISO(log.startTime), DateTime.fromISO(log.endTime), startTime, endTime)
    }) ?? []

    setRoutesToShow({
      data: [...routes_to_show],
      length: routes_to_show.length
    })
  }, [map_settings?.date, map_settings?.filter?.mustHaveWeightWarning, selectedVehicle, vehicles, map_settings?.startClockTime, map_settings?.endClockTime])

  const toggleMapLiveMode = (newMode) => {
    getAccessTokenSilently()
      .then(accessToken => setMapLiveMode(newMode, accessToken))
  }


  const { startTime, endTime } = getStartEndTimeFromMapsettings(map_settings)
  const startEpochs = startTime.toSeconds().toFixed()
  const endEpochs = endTime.toSeconds().toFixed()
  const loadingDetailedRoutes = loading.fetchVehicleRoutesDetailed && loading.fetchVehicleRoutesDetailed[startEpochs + '_' + endEpochs] === "loading"

  return (
    <Page>
      <Notification
        loadingNotification
        themes={themes}
        show={
          (loading.fetchVehicles === 'loading') ||
          (loading.fetchVehicleRoutes === 'loading' && fetchingRoutesIsSlow) || loadingDetailedRoutes ||
          (loading.fetchLastKnownPositions === 'loading' && fetchingLastKnownPositionsIsSlow) ||
          (loading.fetchVehiclePositions === 'loading' && fetchingVehiclePositionsIsSlow) ||
          (loading.fetchBeaconSnapshots === 'loading')
        }
        fadeDuration={350}
      />

      <Notification
        themes={themes}
        show={loading.fetchVehicleRoutes === null && hasRoutes(vehicles, map_settings?.date) && !hasRoutes(vehicles, map_settings?.date, selectedVehicle?.boxId)}
        text={"Vehicle has no routes for this day."}
      />

      <Notification
        themes={themes}
        show={loading.fetchVehicleRoutes === 'failed'}
        fadeDuration={350}
        text={'Loading vehicle routes failed.'}
      />
      {!hasMapAccess ?
        <Fragment >
          <View style={{
            opacity: 0.8,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: "white",
            zIndex: 2,
            position: 'absolute'
          }}>
            <MissingServiceAllert />
          </View>
          <View style={{
            pointerEvents: 'none'
          }}>
            <Map style={{ zIndex: 1 }} getAccessTokenSilently={getAccessTokenSilently} date={map_settings?.date} store={store} />
          </View>

        </Fragment>
        :
        <Fragment>
        <LiveMapToggle themes={themes} map_settings={map_settings} setMapLiveMode={toggleMapLiveMode} />
          <Map getAccessTokenSilently={getAccessTokenSilently} date={map_settings?.date} bottom={mapBottom} store={store} />
        </Fragment>
      }
      {hasSideBarAccess ?
        <View extend={styles.dashboard}>
          <OverviewSidebar
            setSelectedStartTime={(value) => {
              if (!value || value.invalid)
                return

              getAccessTokenSilently()
                .then(accessToken => setMapLiveMode(false, accessToken))
              setMapStartTime(value)
            }}
            setSelectedEndTime={(value) => {
              if (!value || value.invalid)
                return

              getAccessTokenSilently()
                .then(accessToken => setMapLiveMode(false, accessToken))
              setMapEndTime(value)
            }}
            showSidebar={showSidebar}
            setShowSidebar={(b) => {
              if (b)
                setShowSidebar(true)
              else
                hideBoth()
            }}
            date={map_settings?.date}
            setDate={(newDate) => {
              if (!newDate) return
              if (map_settings?.date !== newDate) getAccessTokenSilently()
                .then((accessToken) => {
                  if (!dateIsToday(newDate)) {
                    setMapLiveMode(false, accessToken)
                  }
                  setMapDate(newDate, accessToken)
                })
            }}
            submenuIsOpen={showDetailsSidebar}
            startTime={map_settings.startClockTime}
            endTime={map_settings.endClockTime}

            filteredRoutes={routesToShow}

            filter={map_settings.filter}
            setFilter={(newFilter) => {
              setMapFilter(newFilter)
            }}
            vehicles_security_system_status = {vehicles_security_system_status}
          />

          <DetailsSidebar
            isToday={isToday}
            date={map_settings?.date}
            routes={routesToShow?.data}
            loadingRoutes={loading.fetchVehicleRoutes || loading.fetchWarningLogs}
            depth={1}
            showSidebar={showDetailsSidebar}
            setShowSidebar={(b) => {
              if (b)
                showBoth()
              else
                setShowDetailsSidebar(false)
            }} />
        </View> :
        <View />
      }
    </Page>
  );
});

const styles = {
  dashboard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
};

const mapStateToProps = ({
  themes,
  organisation,
  loading,
  vehicles,
  selectedVehicle,
  selected_organisations,
  map_settings,
  subtrees,
  vehicles_security_system_status
}) => ({
  themes,
  organisation,
  loading,
  vehicles,
  selectedVehicle,
  selected_organisations,
  map_settings,
  subtrees,
  vehicles_security_system_status
  // warning_logs
})

const mapDispatchToProps = dispatch => ({
  fetchVehicles: (accessToken) => dispatch(fetchVehicles(accessToken)),
  fetchCurrentDrivers: (accessToken) => dispatch(fetchCurrentDrivers(accessToken)),
  selectRoute: (boxId, endTimestamp) => dispatch(selectRoute(boxId, endTimestamp)),
  fetchWarningLogs: (accessToken, boxIds, start, end) => dispatch(fetchWarningLogs(accessToken, boxIds, start, end)),
  setMapFilter: (filter) => dispatch(setMapFilter(filter)),
  setMapStartTime: (startTime) => dispatch(setMapStartTime(startTime)),
  setMapEndTime: (endTime) => dispatch(setMapEndTime(endTime)),
  setMapLiveMode: (newMode, accessToken) => dispatch(setMapLiveMode(newMode, accessToken)),
  setMapDate: (date, accessToken) => dispatch(setMapDate(date, accessToken)),
  fetchVehiclesServices: (accessToken) => dispatch(fetchVehiclesServices(accessToken))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);