import React from 'react'
import { Fade, Modal } from '@mui/material'
import View from '@modul-connect/shared/components/atoms/view'
import Button from '@modul-connect/shared/components/atoms/button'
import TextField from '@modul-connect/shared/components/molecules/textField'
import { H1, P } from '@modul-connect/shared/components/atoms/text'
import TreeWidget from "@modul-connect/shared/components/molecules/treeWidget"
import { useEffect } from 'react'
import modal from '@modul-connect/shared/components/atoms/modal'
import theme from '@modul-connect/shared/theme'
import { isEmail } from '@modul-connect/shared/utils/text'


/* currently unused */
const AddUserModal = ({ 
    openModal,
    onClose,
    addUser,
    userAlreadyExist,
    incorrectEmail,
    addingFailed,
    disabled,
    tagtrees
}) => {
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [tagName, setTagName] = React.useState('')
    const [orgTreeTag, setOrgTreeTag] = React.useState(null)
    const [errorFirstName, setErrorFirstName] = React.useState(false)
    const [errorLastName, setErrorLastName] = React.useState(false)
    const [errorEmail, setErrorEmail] = React.useState(false)
    const [errorOrgTreeTag, setErrorOrgTreeTag] = React.useState(false)
    const [userPosted, setUserPosted] = React.useState(false)

    const tagtree = tagtrees[0] // TODO: display the other ones as well

    useEffect(() => {
        if(openModal) {
            setFirstName(null)
            setLastName(null)
            setEmail(null)
            setOrgTreeTag(null)
            setErrorFirstName(null)
            setErrorLastName(null)
            setErrorEmail(null)
            setErrorOrgTreeTag(null)
            setUserPosted(false)
        }
    }, [openModal])

    useEffect(() => {
        if(openModal && ( incorrectEmail || userAlreadyExist)) {
            setErrorEmail(true)
        }
    }, [userAlreadyExist, incorrectEmail])

    const isName = (name) => {
        if(!name) {
            return false
        }

        if(!name.length) {
            return false
        }

        return true
    }

    const onSave = () => {
        let hasErrors

        let cleanEmail = email ? email.trim().toLowerCase() : ''

        if(!isName(firstName)) {
            setErrorFirstName(true)
            hasErrors = true
        }

        if(!isName(lastName)) {
            setErrorLastName(true)
            hasErrors = true
        }

        if(!isEmail(cleanEmail)) {
            setErrorEmail(true)
            hasErrors = true
        }

        if(!orgTreeTag) {
             setErrorOrgTreeTag(true)
             hasErrors = true
        }

        if(hasErrors) {
            return
        }

        const data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            name: firstName.trim() + " " + lastName.trim(),
            email: cleanEmail,
            tags: orgTreeTag,
            tagName: tagName,
            customerId: tagtree.uuid
        }

        addUser(data)
        setUserPosted(true)
    }

    const getErrorEmailText = () => {
        if(userAlreadyExist) {
            return '- User already exist'
        }

        if (incorrectEmail) {
            return "- Email is invalid"
        }

        return null
    }

    const errorEmailFeedbackText = getErrorEmailText()

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (disabled && reason === 'backdropClick') {
                return
              }
              onClose()
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Add user"}/>
                        </View>
                        <View extend={ modal.content }>
                          {
                            userPosted && addingFailed &&
                            <P large style={{color: theme.colors.error}}>Adding user failed. (Server error)</P>
                          }
                            <TextField
                                type='email'
                                name="Email"
                                disabled={ disabled }
                                onBlur={ (e) => setErrorEmail(!isEmail(e.target.value)) }
                                placeholder="charlie.anderson@modulconnect.com"
                                error={errorEmail}
                                errorText={ errorEmailFeedbackText }
                                onChange={event => setEmail(event.target.value)}
                            />
                            <TextField
                                name="First Name"
                                placeholder="Charlie"
                                disabled={ disabled }
                                onBlur={ (e) => setErrorFirstName(!isName(e.target.value)) }
                                error={errorFirstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            <TextField
                                name="Last Name"
                                placeholder="Anderson"
                                disabled={ disabled }
                                onBlur={ (e) => setErrorLastName(!isName(e.target.value)) }
                                error={errorLastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                             {
                                tagtree && (
                                    <TreeWidget
                                        title={ 'Organisational unit'}
                                        tree={ tagtree }
                                        disabled={ disabled }
                                        selected={ orgTreeTag }
                                        rootDisabled={ false }
                                        onSelect={ (t, name) => {
                                            setTagName(name)
                                            setOrgTreeTag(t)
                                            setErrorOrgTreeTag(false)
                                        }}
                                        error={ errorOrgTreeTag }
                                    />
                                )
                            }
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg disabled={ disabled } cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg disabled={ disabled } noPadding onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default AddUserModal
