import * as turf from '@turf/turf'
import { DateTime } from 'luxon';
import {
  setCurrentVehiclePosition,
  drawRouteOnMap,
  updateSource,
  GeoJSON,
  updateSelectedVehicleMarker,
  updateVehicleWarningMarkers,
  getVehiclesToRender
} from './utils'
import { getStore } from '../../../state/configureStore';

const minSpeed = 1; // 1 km/h
const minDistance = 0.001; // 1 meter

export const startAnimation = (map, self, onSelectVehicle, onSelectRoute, store) => {
  if(self.isAnimating) return
  const waitForMapToBeFullyLoaded = setInterval(() => {
    if(map && map.getSource('vehicles') && self.props.vehicles.length > 0) {
      startAnimating(self, onSelectVehicle, onSelectRoute, store)
      clearInterval(waitForMapToBeFullyLoaded)
    }
  }, 100)
}

export const stopAnimation = self => {
  if (!self.isAnimating) return
  cancelAnimationFrame(self.animationFrame)
  self.isAnimating = false
}

const startAnimating = (self, onSelectVehicle, onSelectRoute, store) => {
  if(self.isAnimating) return
  self.isAnimating = true
  self.prevTimestamp = Number.MAX_SAFE_INTEGER
  self.animationFrame = requestAnimationFrame(timestamp => mapAnimation(self, timestamp, onSelectVehicle, onSelectRoute, store))
}

const mapAnimation = (self, timestamp, onSelectVehicle, onSelectRoute, store) => {
  const { vehicles, date, map_settings } = self.props 

  if (!self.isAnimating || !map_settings.isLive) return

  const deltaTime = timestamp - self.prevTimestamp
  self.prevTimestamp = timestamp

  if(deltaTime < 0 || !vehicles) {
    requestAnimationFrame(timestamp => mapAnimation(self, timestamp, onSelectVehicle, onSelectRoute))
    return
  }

  // important: cannot filter out vehicles that shouldn't be rendered here, because it will mess up the animatedIndices. They need to match the vehicles reducer
  const animatedIndices = new Set()
  Promise.all(updateVehicles(self, vehicles, date, animatedIndices, deltaTime)).then(() => {
    if (animatedIndices.size > 0) updateMap(self, vehicles, date, animatedIndices, onSelectVehicle, onSelectRoute, store)
    requestAnimationFrame(timestamp => mapAnimation(self, timestamp, onSelectVehicle, onSelectRoute, store))
  })
}

const updateMap = (self, vehicles, date, animatedIndices, onSelectVehicle, onSelectRoute, store) => {
  const { map, index } = self.state

  const { map_settings, selectedVehicle, beacons } = self.props

  if (!map.isStyleLoaded()) return

  const vehicles_to_render = getVehiclesToRender({ vehicles: vehicles, map_settings: map_settings, date: date })

  updateSource({ id: 'vehicles', data: {
    type: 'FeatureCollection',
    features: vehicles_to_render.map(v => ({
      ...v.geoJSON.position,
      properties: {
        ...v.geoJSON.position?.properties,
        name: v.name
      }
    }))  }, map })

  updateVehicleWarningMarkers({ map, vehicles: vehicles_to_render, beacons, selectedVehicle, isLive: true })

  if(animatedIndices.has(index)) {
    const vehicle = vehicles[index]

    let name
    let routeIndex = 0

    while (true) {
      const temp = `route_${vehicle.boxId}_${routeIndex}`
      if (!map.getLayer(temp)) break
      name = temp
      routeIndex++
    }

    if (routeIndex === 0) {
      drawRouteOnMap({ vehicle: vehicles[index], self, map, date, onSelectVehicle, onSelectRoute, map_settings, store })
      return
    }

    const routes = vehicle.geoJSON?.routes?.[date]
    if (!routes) return
    const keys = Object.keys(routes)
    if (!keys.length) return

    let position = vehicle?.geoJSON?.position
    if (!position) {
      const day = DateTime.fromISO(map_settings.date)
      const endTime = DateTime.local(day.year, day.month, day.day, map_settings.endClockTime.hour, map_settings.endClockTime.minute)      
      const lastKnownPos = vehicle.lastKnownPosition && vehicle.lastKnownPosition[endTime.toFormat('yyyy-LLL-dd-HH-mm')]

      position = lastKnownPos ? {
        type: 'Feature',
          geometry: {
            type: "Point",
            coordinates: [lastKnownPos.longitude, lastKnownPos.latitude]
          },
          properties: {
            boxId: vehicle.boxId,
            bearing: lastKnownPos.course
          },     
      } : null
    }

    if (position) {
      updateSelectedVehicleMarker({ map, position: position, hasWarning: self.hasWarning, isLive: true })
    }

    const sortedKeys = keys?.sort((a, b) => DateTime.fromISO(a) > DateTime.fromISO(b) ? 1 : -1)// (dictionaries are not ordered)

    map.getSource(name).setData(routes[sortedKeys[sortedKeys.length-1]])
  }
}

const updateVehicles = (self, vehicles, date, animatedIndices, deltaTime) =>
  vehicles.map(async (vehicle, index) => await updateVehicle(self, vehicle, index, date, animatedIndices, deltaTime))

const updateVehicle = async (self, vehicle, index, date, animatedIndices, deltaTime) => {
  if (!vehicle.targets || vehicle.targets.length === 0) return

  const vehicle_animation_status = getStore().getState().vehicle_animation_status

  const currentPosition = vehicle.geoJSON?.position?.geometry?.coordinates
  let currentDestination

  if (!currentPosition) return

  const positionPoint = turf.point(currentPosition);
  let destinationPoint

  let distance

  const oldSpeed = vehicle.geoJSON.speed

  let target
  while (vehicle.targets.length > 0) {
    target = getSmallest(vehicle.targets)
    vehicle.geoJSON.speed = target.velocity
    currentDestination = target.coordinates
    destinationPoint = turf.point(currentDestination)
    distance = turf.distance(positionPoint, destinationPoint, { units: 'kilometers' })
    if (distance < minDistance || vehicle.geoJSON.speed < minSpeed)
      vehicle.targets = vehicle.targets.filter(x => x.timestamp !== target.timestamp)
    else
      break
  }

  const prevAnimationEpoch = vehicle_animation_status?.[vehicle?.boxId]?.timestampEpoch
  if (!target?.timestamp || target?.timestamp < prevAnimationEpoch) {
    vehicle.targets = vehicle.targets.filter(x => x.timestamp !== target.timestamp)
    return
  }
  if (prevAnimationEpoch !== undefined) vehicle_animation_status[vehicle?.boxId].timestampEpoch = target?.timestamp

  if (vehicle.geoJSON.speed !== oldSpeed)
    self.props.updateAnimationSpeed(vehicle.boxId, vehicle.geoJSON.speed)

  if (distance < minDistance || vehicle.geoJSON.speed < minSpeed) return

  const step = turf.along(
    turf.lineString([currentPosition, currentDestination]), 
    vehicle.geoJSON.speed * (deltaTime/(1000 * 60 * 60)),
    { units: 'kilometers' })

  setCurrentVehiclePosition(vehicle, step.geometry.coordinates)

  const oldBearing = vehicle.geoJSON.position.properties.bearing
  const newBearing = turf.bearing(positionPoint, destinationPoint)
  vehicle.geoJSON.position.properties.bearing = newBearing

  let routes = vehicle?.geoJSON?.routes?.[date]
  if (!routes) {
    vehicle.geoJSON.routes = {[date]: {}}
    routes = vehicle.geoJSON.routes[date]
  }
  let keys = Object.keys(routes)
  if (!keys.length) {
    routes[DateTime.fromSeconds(target.timestamp).toUTC().toISO()] = GeoJSON([], { geometry: 'LineString'})
    keys = Object.keys(routes)
  }

  const sortedKeys = keys?.sort((a, b) => DateTime.fromISO(a) > DateTime.fromISO(b) ? 1 : -1)// (dictionaries are not ordered)
  const route = routes[sortedKeys[sortedKeys.length-1]]
  if (Math.abs(newBearing - oldBearing) > 1 )
    route.geometry.coordinates.push(step.geometry.coordinates)
  else
    route.geometry.coordinates[route.geometry.coordinates.length - 1] = step.geometry.coordinates

  animatedIndices.add(index)
}

const getSmallest = targets => {
  let smallest = targets[0]
  for (const target of targets) {
    if (target.timestamp < smallest.timestamp)
      smallest = target
  }
  return {...smallest}
}