
import React from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import "../../dashboard.css";
import HideableSideBar from '../hideableSidebar';
import { VehicleDetails } from './vehicleDetails'
import { useNavigate } from 'react-router-dom';
import { DateTime } from "luxon";
import { getServiceList } from '../../../../utils/ServiceList'

const DetailsSidebar = ({
  showSidebar,
  setShowSidebar,
  selectedVehicle,
  selectedRoute,
  vehicles,
  vehicle_animation_status,
  current_drivers,
  depth,
  isToday,
  date,
  subtrees,
  themes,
  loadingRoutes,
  routes,
  map_settings,
  beacons,
  loading,
  selectRoute,
  vehicles_security_system_status
}) => {
  const navigate = useNavigate()
  const goToVehicle = () => {
    navigate('/fleet/fleet_vehicles/' + selectedVehicle.boxId);
  }

  const goToDrivingLog = (route) => {
    if (!route?.startTime || !selectedVehicle?.boxId)
      return

    navigate('/reports/reports_driving-logs')
    return
    // put this back in, when we can link to individual driving logs again?
    const date = DateTime.fromISO(route.startTime)
    navigate('/reports/reports_driving-logs/' + 
    selectedVehicle.boxId + '/' +
    route.startTime)
  }

  const services = getServiceList({subtrees})

  const vehicle = vehicles.find(v => v.boxId === selectedVehicle.boxId)

  return (
    <HideableSideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} depth={depth} hideOpenCloseButton={themes.device === 'mobile' ? !showSidebar : false}>
      {
        <View className={'sideMenuContainer'}>
          <View extend={styles.sideMenu}>
            <VehicleDetails 
              themes={themes}
              map_settings={map_settings}
              loadingRoutes={loadingRoutes}
              loadingBeaconsStatus={loading.fetchBeaconSnapshots}
              date={date}
              subtrees={subtrees}
              isToday={isToday}
              vehicle={vehicle} 
              current_drivers={current_drivers} 
              vehicle_animation_status={vehicle_animation_status}
              goToVehicle={goToVehicle}
              routes={routes}
              services={services}
              goToDrivingLog={goToDrivingLog}
              beacons={beacons}
              selectRoute={selectRoute}
              securitySystemStatus={
                vehicles_security_system_status?.filter(
                  (s) => s.mainboxId === selectedVehicle.boxId
                )[0]
              }
            />
          </View>
        </View>
      }
    </HideableSideBar>
  )
}

const styles = {
  sideMenu: ({ theme: { colors } }) => ({
    // "& h3": {
    //   display: 'inline',
    // }
    overflowY: 'auto',
    paddingBottom: 30,
    paddingTop: 10
  }),
};

const mapStateToProps = ({
  organisation,
  loading,
  vehicles,
  selectedVehicle,
  selectedRoute,
  vehicle_animation_status,
  current_drivers,
  themes,
  subtrees,
  map_settings,
  beacons,
  vehicles_security_system_status
}) => ({
  organisation,
  loading,
  vehicles,
  selectedVehicle,
  selectedRoute,
  vehicle_animation_status,
  current_drivers,
  themes,
  subtrees,
  map_settings,
  beacons,
  vehicles_security_system_status
})

export default connect(mapStateToProps)(DetailsSidebar);