export const mapPopups = (state = [], action) => {
  switch (action.type) {
    case 'OPENED_MAP_POPUP':
      if (state.find(popup => popup.popupId === action.popupId))
        return state
      return [...state, action]
    case 'CLOSED_MAP_POPUP':
      return state.filter(popup => popup.popupId !== action.popupId)
    default:
      return state
  }
}