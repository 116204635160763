import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import { DateTime } from "luxon";
import{ MapImage } from "./routeDetailsSubpage";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import { P } from "@modul-connect/shared/components/atoms/text";
import ReportPage from "../../reportPage.v2";
import { getVehicleName } from "../../../../utils/vehicleUtils";
import { dateTimeToEpoch } from "@modul-connect/shared/utils/dateTimeUtils";
import WarningSection from "./Sections/warningSection";
import NoteSection from "./Sections/noteSection";

const SingleDrivingLogDetails = ({
  loading,
  log,
  logId,
  driving_log_details,
  themes,
  warning_logs_history,
  toll_reports,
  vehicles_data,
  disabled,
  saving
}) => {
  const logDetails = driving_log_details && driving_log_details[logId];
  const vehicleName =
    log?.boxId && vehicles_data
      ? getVehicleName(vehicles_data.data, log.boxId)
      : null;

  const [isLoading, setIsLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [loadingWarningsFailed, setLoadingWarningsFailed] = useState(false);
  const [loadingTollFailed, setLoadingTollFailed] = useState(false);

  const startTimeDT = DateTime.fromISO(log?.startTime)
  const endTimeDT = DateTime.fromISO(log?.endTime)

  const warningLogsKey = log?.boxId + '_' + dateTimeToEpoch(startTimeDT)

  const hasFetchedToll = Object.keys(toll_reports).includes(logId)
  const hasFetchedWarnings = Object.keys(warning_logs_history).includes(logId)
  const relevantWarnings = hasFetchedWarnings ? (warning_logs_history[warningLogsKey] ?? []) : undefined

  const greatestOverweightWarnings =
    relevantWarnings?.data?.overweightWarnings?.length
      ? relevantWarnings?.data?.overweightWarnings?.sort((a, b) => (a?.ratioGross < b?.ratioGross ? -1 : 1))[0]
      : null;

  const hasAllData =
    vehicleName && log && driving_log_details[logId] && hasFetchedWarnings && hasFetchedToll;

  const pageIsReady = !isLoading && hasAllData;

  const isOngoing = !log?.endTime;

  useEffect(() => {
    setIsLoading(
        (!logDetails && loading.fetchDrivingLogDetails[logId] === "loading") ||
        (!hasFetchedWarnings && loading.fetchWarningLogs === "loading") ||
        (!hasFetchedToll && loading.fetchTollReport === "loading")
    );
    setLoadingFailed(
      loading.fetchBatteryReport === "failed" ||
        loading.fetchDrivingLogDetails[logId] === "failed"
    );
    setLoadingWarningsFailed(loading.fetchWarningLogs === "failed");
    setLoadingTollFailed(loading.fetchTollReport === "failed");
  }, [loading]);

  const highestWeightPercentage = Math.max(
    !isNaN(greatestOverweightWarnings?.ratioGross)
      ? greatestOverweightWarnings.ratioGross * 100
      : null,
    !isNaN(greatestOverweightWarnings?.ratioFront)
      ? greatestOverweightWarnings.ratioFront * 100
      : null,
    !isNaN(greatestOverweightWarnings?.ratioRear)
      ? greatestOverweightWarnings.ratioRear * 100
      : null
  );

  if (disabled) {
    return (
      <ReportPage
        disabled
        noFilterBar
        noTimePickerBar
        title={"Route Details"}
      />
    );
  }
  return (
    <View extend={{ width: "100%" }}>
      <View>
        {pageIsReady && (loadingTollFailed || loadingWarningsFailed) ? (
          <P>
            Failed to fetch some information from the server. The values you see
            may not be accurate.
          </P>
        ) : null}
        {pageIsReady ? null : null}
        {isLoading ? <BubbleLoadingWidget text={"Loading ..."} /> : null}

        {!isLoading && !pageIsReady ? <P> Could not fetch data. </P> : null}

        <View extend={{ display: 'flex', flexDirection: 'row', height: 400, padding: 24, gap: 25 }}>
          <MapSection
            pageIsReady={pageIsReady}
            isOngoing={isOngoing}
            themes={themes}
            logDetails={logDetails}
            highestWeightPercentage={highestWeightPercentage}
            loadingFailed={loadingFailed}
          />

          <InfoSection 
            pageIsReady={pageIsReady}
            highestWeightPercentage={highestWeightPercentage}
            log={log}
            loadingFailed={loadingFailed}
            saving={saving}
          />
        </View>
      </View>
    </View>
  );
};

const MapSection = ({
  pageIsReady,
  isOngoing,
  themes,
  logDetails,
  highestWeightPercentage,
  loadingFailed
}) => {
  let content = null
  if (pageIsReady) content = (
    loadingFailed ? (
      <P>
        {" "}
        Failed to fetch some information from the server. The values you see
        may not be accurate.{" "}
      </P>
    ) : (
      <MapImage
        noPadding
        themes={themes}
        route={logDetails?.length ? logDetails[0]?.logs : null ?? []}
        isOngoing={isOngoing}
        highestWeightPercentage={highestWeightPercentage}
      />
    )
  )

  return (
    <View extend={{ flex: 1 }}>{content}</View>
  );
};

const InfoSection = ({
  pageIsReady,
  highestWeightPercentage,
  log,
  loadingFailed,
}) => {
  let content = null
  if (pageIsReady) content = (
    loadingFailed ? (
      <P>
        {" "}
        Failed to fetch some information from the server. The values you see
        may not be accurate.{" "}
      </P>
    ) : (
      <View extend={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <NoteSection log={log}/>
        <WarningSection highestWeightPercentage={highestWeightPercentage} log={log}/>
      </View>
    )
  )

  return (
    <View extend={{ flex: 1 }}>{content}</View>
  );
};

const mapStateToProps = (props) => props;

export default connect(mapStateToProps)(SingleDrivingLogDetails);
