import { DateTime } from "luxon"
import { getNotificationIcon, NotificationActionTypes, NOTIFICATIONS_FETCHED, NotificationData, NOTIFICATIONS_SEEN, getNotificationType, NEW_SECURITY_ALARM_NOTIFICATION } from "../types/notifications"


export const notifications = (state: NotificationData[] = [] , action: NotificationActionTypes) : NotificationData[] => {
  switch (action.type) {
    case NOTIFICATIONS_FETCHED:
       const notifications: NotificationData[]  = []
      action.data.map(d => notifications.push({
        group: d.notificationType,
        id: d.notificationId,
        seen: d.hasUserSeen ,
        vehicle: d.vehicleName,
        group_icon: getNotificationIcon(d.notificationType),
        time_stamp: d.startedAt,
      }))
      return (
        [...notifications]
      ) 
    case NOTIFICATIONS_SEEN:
      const seen_notifications: NotificationData[] = []
      state?.map(d => {
        seen_notifications.push({
        group: d.group,
        id: d.id,
        seen: getNotificationType(action.notificationType) === d.group ? true : d.seen,
        vehicle: d.vehicle,
        group_icon: d.group_icon,
        time_stamp: d.time_stamp,
        })}
      )
      return(
        [...seen_notifications]
      )
      case NEW_SECURITY_ALARM_NOTIFICATION:{
        const notificationExists = state.findIndex(notification => notification.id === action.data.id)
        if(notificationExists !== -1) return state

        const updatedNotifications: NotificationData[]  = state
        updatedNotifications.unshift({
          group: 'Security',
          id: action.data.id,
          seen: false,
          vehicle: action.data.vehicleName,
          group_icon: getNotificationIcon('Security'),
          time_stamp: action.data.startedAt,
        })
        return (
          [...updatedNotifications]
        ) 
      }
    default:
      return state
  }
}