import React, { useRef } from 'react'
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import { H3, DatePickerTitle } from '@modul-connect/shared/components/atoms/text';
import { DateTime } from "luxon";
import { styles } from '../datePickerStyles'
import theme from '@modul-connect/shared/theme';
import { DayPickerInput } from "@modul-connect/shared/components/molecules/daySelector/DayPickerInput"

const StartEndDatePicker = ({ loading, startDate, endDate, onStartDatePicked, onEndDatePicked, disabled, noTitle }) => {

  const startDatePickerRef = useRef(undefined)
  const endDatePickerRef = useRef(undefined)

  return (
    <View extend={styles.dayPickerWithTitle}>
      <View extend={styles.dayPickerView}>
        {noTitle ? null : <View extend={styles.filterHeaderContainer}>
          <DatePickerTitle extend={{ width: '100%' }} title1={'Selected time'} title2={'period:'} />
        </View>}
        <View extend={[styles.filterSelectContainer, noTitle && {paddingLeft: 20}]}>
          <View extend={{  display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              datePickerRef={startDatePickerRef}
              disabledDays={{ after: DateTime.local() }}
              startDate={startDate}
              endDate={endDate}
              onStartDatePicked={onStartDatePicked}
              onEndDatePicked={onEndDatePicked}
              loading={loading}
              dateIs='start'
            />
          </View>
          <H3 extend={{ paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: 'center', textAlign: 'center', lineHeight: 0 }}>―</H3>
          <View extend={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              datePickerRef={endDatePickerRef}
              disabledDays={{after: DateTime.local()}}
              startDate={startDate}
              endDate={endDate}
              onStartDatePicked={onStartDatePicked}
              onEndDatePicked={onEndDatePicked}
              loading={loading}
              dateIs='end'
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export const DatePicker = ({
  datePickerRef,
  disabledDays,
  startDate,
  endDate,
  onStartDatePicked,
  onEndDatePicked,
  loading,
  dateIs,
  disabled
}) => {
  const inputRef = useRef(null);

  return (
    <View 
      style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', minWidth: 125 }}
      onClick={() => { 
        if (inputRef?.current) inputRef.current.focus()
       }}>
      <DayPickerInput
        mode="range"
        ref={datePickerRef}
        date={dateIs === 'start' ? startDate : endDate}
        placeholder='YYYY-MM-DD'
        disabled={(disabled || loading?.progress === 'loading') ? true : false}
        disableDaysAfter={disabledDays.after}
        disableDaysBefore={disabledDays.before}
        defaultMonth={dateIs === 'start' ? startDate.toJSDate() : endDate.toJSDate()}

        rangeProps={{
          selected: { from: startDate.toJSDate(), to: endDate.toJSDate() },
          onSelectionMade: (dateRange) => {
            onStartDatePicked(DateTime.fromJSDate(dateRange.from))
            onEndDatePicked(DateTime.fromJSDate(dateRange.to))
          },

          startMonth: dateIs === 'end' ? new Date(startDate.year, startDate.month - 1) : null,
          endMonth: dateIs === 'end' ? DateTime.local().toJSDate() : new Date(endDate.year, endDate.month - 1),
        }}
        inputProps={{
          ref: inputRef, // TODO
          style: { // TODO
            ...styles.inputField,
            backgroundColor: (disabled || loading?.progress === 'loading') ? theme.colors.grey : styles.inputField.backgroundColor
          },
          readOnly: true,
        }}
      />
    </View>
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps)(StartEndDatePicker)
