import React, { useState, useEffect, Fragment, createContext, useContext } from 'react';
import theme from "@modul-connect/shared/theme";
import View from "@modul-connect/shared/components/atoms/view";
import { TableSortLabel, Collapse, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import { H3, P , H5} from '../../atoms/text';
import Button from '../../atoms/button'
import { MdDelete, MdEdit, MdCheck, MdClose } from "react-icons/md";
import Tag from '../../atoms/tags/tag';
import { BubbleLoadingWidget } from '../bubbleLoadingWidget/bubbleLoadingWIdget';
import { Box, IconButton } from '@mui/material';
import "./tableWidgetSeparated.v2.css"
import chroma from 'chroma-js';
import {WarningIcon} from '@modul-connect/shared/components/atoms/icons/warningIcon'
import SimpleTable from './simpleTable';
import ArrowDownIcon from '@modul-connect/shared/img/expand_arrow_down.svg'
import ArrowUpIcon from '@modul-connect/shared/img/expand_arrow_up.svg'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { visuallyHidden } from '@mui/utils';
import SortableArrowsIcon from '@modul-connect/shared/img/sortable_arrows.svg'
import { TableLink } from '../../atoms/text/text';
import { Icon } from '../../atoms/icon/icon';
import { CustomTooltip } from '../tooltip';

// like TableWidget, but has updated styling with header and separated table rows

export const cellPadding = '6px 20px'
const cellHeight = '50px'
const dividerHeight = '28px' //cellHeight - (paddingBottom + paddingTop + margin-block-start + margin-block-end)

const headerCellHeight = 48

const hoverBackgroundColor = chroma(theme.colors.lightGrey).brighten(0.1).hex()

const sortData = (data, sort, sortOrder) => {
  const sorted = data.sort((d1, d2) => {
    const toCompare1 = d1[sort + '_sort'] ? d1[sort + '_sort'] : d1[sort] === '-' ? 0 : d1[sort]
    const toCompare2 = d2[sort + '_sort'] ? d2[sort + '_sort'] : d2[sort] === '-' ? 0 : d2[sort]
    return sortOrder === 'asc' ? (toCompare1 < toCompare2 ? -1 : 1 ) : (toCompare1 > toCompare2 ? -1 : 1)
  })
  return sorted
}

const TableContext = createContext({
  tableTitle: undefined,
  selectedRows: undefined,
  columns_filtered: undefined,
  onEdit: undefined,
  onRemove: undefined,
  onDownload: undefined,
  sortOrder: undefined,
  setSort: undefined,
  setSortOrder: undefined,
  setPage: undefined,
  columns_total: undefined,
  hasExpandableData: undefined,
  onClickRow: undefined,
  isDownloading: undefined,
  editableIds: undefined,
  removableIds: undefined,
  collapsibleDataLoadingStatus: undefined,
  prepareCollapsibleData: undefined,
  collapsibleLogColumns: undefined,
  collapsibleDefaultSortIndex: undefined,
  collapsibleDefaultSortOrder: undefined,
  collapsibleOpenForIds: [],
  setCollapsibleOpenForIds: undefined,
  setShowCollapsibleForIds: undefined,
  showInnerSortArrows: undefined
});

const TableWidgetSeparated = ({
  data,
  totalItems,
  columns,
  onClickRow,
  searchStr,
  themes,
  extend,
  error,
  selectedRows,
  onRemove,
  editableIds,
  removableIds,
  onEdit,
  onDownload,
  isDownloading,
  rowsOptions,
  tag,
  defaultSort,
  defaultSortOrder,
  isSortable = true,
  pagination = true,
  fetchAgainOnChange,
  sidescroll,
  loadingStatus,
  hide,
  isSaving,
  collapsibleDataLoadingStatus,
  prepareCollapsibleData,
  collapsibleLogColumns,
  collapsibleDefaultSortIndex,
  collapsibleDefaultSortOrder,
  collapsibleOnRowClick,
  tableTitle,
  onelineOnly,
  showInnerSortArrows
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pagination ? (rowsOptions ? rowsOptions[0] : 10) : undefined)
  const [sort, setSort] = useState(defaultSort ? defaultSort : 'created_datedesc')
  const [sortOrder, setSortOrder] = useState(defaultSortOrder ?? 'asc')
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false)
  const updateSearchStr = React.useRef(null);
  let currentpage = page + 1

  const [preparedData, setPreparedData] = useState(data)
  const [sortedData, setSortedData] = useState([])

  const [collapsibleOpenForIds, setCollapsibleOpenForIds] = useState([])
  const [showCollapsibleForIds, setShowCollapsibleForIds] = useState([]) // when it is closed, it should not even show, because otherwise we get a gap in the table

  useDeepCompareEffect(() => {
    setPreparedData(data)
  }, [data])

  //const [showLoading, setShowLoading] = useState(false)
  //useLoadingTimer(setShowLoading)

  const isLoading = loadingStatus === 'loading'
  const loadingFailed = loadingStatus === 'failed'
  const noData = !data || data.length === 0

  const firstCellRef = React.useRef(undefined)
  const secondCellRef = React.useRef(undefined)

  const [titleHeaderWidth, setTitleHeaderWidth] = useState(0)

  let columns_filtered
  switch (themes.device) {
    case 'tablet':
      columns_filtered = columns.filter(x => { return !x.hide || x.hide?.indexOf('tablet') === -1 })
      break
    case 'mobile':
      columns_filtered = columns.filter(x => { return !x.hide || x.hide?.indexOf('mobile') === -1 })
      break
    default:
      columns_filtered = columns
  }

  const hasExpandableData = preparedData?.some(d => d?.isExpandable) ?? false
  const columns_total = (columns_filtered?.length + (onDownload ? 1 : 0) + (hasExpandableData ? 1 : 0))

  useLoadingTimer(() => setShowTableWhileLoading(false))

  useEffect(() => {
    handleResize()
  })

  function handleResize() {
    setTitleHeaderWidth(
      firstCellRef?.current?.clientWidth + ((firstCellRef?.current?.clientWidth < 175) ? secondCellRef?.current?.clientWidth : 0)
    )
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

  useEffect(() => {
    setRowsPerPage(rowsOptions ? rowsOptions[0] : 10)
  }, [rowsOptions])

  /* useDeepCompareEffect(() => {
    console.log('sorting data ...', sort, sortOrder)
    setPreparedData(data.sort((d1, d2) => {
      return sortOrder === 'asc' ? (d1[sort] < d2[sort] ? -1 : 1 ) : (d1[sort] > d2[sort] ? -1 : 1)
    }))
  }, [rowsPerPage, currentpage, sort, sortOrder, searchStr, tag, fetchAgainOnChange, data]) */
  
  useEffect(() => {
    setShowTableWhileLoading(false)
  }, [rowsPerPage, searchStr, tag, fetchAgainOnChange])

  useEffect(() => {
    setShowTableWhileLoading(true)
  }, [sort, currentpage])

  useEffect(() => {
    if (!updateSearchStr.current !== searchStr) {
      setPage(0)
    }

    updateSearchStr.current = searchStr
  }, [searchStr]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [hoveringRow, setHoveringRow] = useState(null)

  useEffect(() => {
    setSortedData(sortData(preparedData, sort, sortOrder))
  }, [preparedData, sort, sortOrder])

  const dataToDisplay = pagination ? sortedData.slice(0, rowsPerPage) : sortedData

  return (
    hide ? null :
      <View extend={[extend, error && styles.error, sidescroll && { overflowY: 'scroll' }, { width: '100%', maxWidth: '100%' }]}>
        {isSaving && <P>Saving ...</P>}
        {(!isLoading || showTableWhileLoading)  && !noData ? (
          <Fragment>
            <TableContext.Provider value={{
              tableTitle: tableTitle,
              selectedRows: selectedRows,
              columns_filtered: columns_filtered,
              onEdit: onEdit,
              onRemove: onRemove,
              onDownload: onDownload,
              sortOrder: sortOrder,
              setSort: setSort,
              setSortOrder: setSortOrder,
              setPage: setPage,
              columns_total: columns_total,
              hasExpandableData: hasExpandableData,
              onClickRow: onClickRow,
              isDownloading: isDownloading,
              editableIds: editableIds,
              removableIds: removableIds,
              collapsibleDataLoadingStatus: collapsibleDataLoadingStatus,
              prepareCollapsibleData: prepareCollapsibleData,
              collapsibleLogColumns: collapsibleLogColumns,
              collapsibleDefaultSortIndex: collapsibleDefaultSortIndex,
              collapsibleDefaultSortOrder: collapsibleDefaultSortOrder,
              collapsibleOnRowClick: collapsibleOnRowClick,
              collapsibleOpenForIds: collapsibleOpenForIds,
              setCollapsibleOpenForIds: setCollapsibleOpenForIds,
              setShowCollapsibleForIds: setShowCollapsibleForIds,
              showCollapsibleForIds: showCollapsibleForIds,
              showInnerSortArrows: showInnerSortArrows
            }}
            >
              <Table sx={styles.separated_table} aria-label="sticky table">
                <TableWidgetHead 
                  secondCellRef={secondCellRef}
                  isSortable={isSortable}
                  firstCellRef={firstCellRef}
                  titleHeaderWidth={titleHeaderWidth}
                  sort={sort}
                />
                <TableBody>
                  {dataToDisplay.map((row, index) => {
                    if (!row.id) console.error('Missing row ID!')
                    return (
                        <TableWidgetRow
                          key={'row' + index}
                          row={row}
                          setHoveringRow={setHoveringRow}
                          hoveringRow={hoveringRow}
                          index={index}
                          onelineOnly={onelineOnly}
                          showCollapsibleForIds={showCollapsibleForIds}
                        />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContext.Provider>
            {pagination &&
              <TablePagination
                showFirstButton
                showLastButton
                rowsPerPageOptions={rowsOptions || [10, 25, 50, 100]}
                component="div"
                count={totalItems || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />}
          </Fragment>
        ) : (
          <Fragment>
            {
              isLoading ? <BubbleLoadingWidget text={"Loading ... "} />
                : loadingFailed ? <H3>Could not fetch data.</H3>
                  : (noData ? <H3>No data to display.</H3> : null)
            }
          </Fragment>
        )}
      </View>
  )
}

const TableWidgetHead = ({
  secondCellRef,
  isSortable,
  firstCellRef,
  sort,
}) => {
  const columns_filtered = useContext(TableContext).columns_filtered
  const onEdit = useContext(TableContext).onEdit
  const sortOrder = useContext(TableContext).sortOrder
  const setSort = useContext(TableContext).setSort
  const setSortOrder = useContext(TableContext).setSortOrder
  const columns_total = useContext(TableContext).columns_total
  const hasExpandableData = useContext(TableContext).hasExpandableData

  const firstLabelIndex = columns_filtered?.findIndex((c) => c.label);
  const secondLabelIndex = columns_filtered?.findIndex(
    (c, index) => index > firstLabelIndex && c.label
  );

  const handleSortBy = (sortValue) => {
    let order = "asc";
    if (sortValue === sort) {
      order = sortOrder === "asc" ? "desc" : "asc";
    }
    order === "asc" ? setSort(sortValue) : setSort(sortValue);
    setSortOrder(order);
   // setPage(0);
  };
  const tableTitleStyle = {
    fontWeight: 400,
    fontSize: 16,
    color: theme.colors.white,
  }

  const downloadCellStyle = {
    borderBottom: "none",
    marginLeft: "100px",
    textAlign: "center",
    color: theme.colors.midDarkGrey,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: cellHeight,
    fontWeight: 300,
    fontFamily: "Inter",
    minWidth: 225,
  }

  const columnHeaderStyle = (column) => { return {
    maxWidth: column.width ?? column.maxWidth ?? 'auto',
    minWidth: column.width ?? column.minWidth ?? 'auto',
    width: column.width ?? 'auto',
    borderBottom: "none",
    padding: 0,
    fontFamily: "Inter",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'uppercase',
    
    color: theme.colors.tableText,
    overflow: "hidden", textOverflow: "ellipsis"
  }}

  const ActionColumnHeader = ({title, minWidth, extend}) => {
    return (
      <TableCell
        className={"separatedTableWidgetHeadCell"}
        key={"downloadHeader"}
        sx={extend ? {...downloadCellStyle, ...extend} : downloadCellStyle}
      >
        <LabelCell justifyContent="flex-end">
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingRight: 20,
            }}
          >
            {title}
          </View>
        </LabelCell>
      </TableCell>
    );
  };
  const TableTitle = () => {
    const tableContext = useContext(TableContext)
    const tableTitle = tableContext.tableTitle
    const columns_filtered = tableContext.columns_filtered
    const onEdit = tableContext.onEdit
    const onRemove = tableContext.onRemove
    const onDownload = tableContext.onDownload
    const hasExpandableData = tableContext.hasExpandableData
    return (
      <TableCell
        className={"separatedTableWidgetCell"}
        key={"header_text"}
        colSpan={
          columns_filtered.length +
          (onEdit || onRemove || onDownload ? 1 : 0) + (hasExpandableData ? 1 : 0)
        }
        style={{padding: 0, backgroundColor: theme.colors.midDarkGrey}}
      >
        <View
          extend={[
            styles.headerCell,
            {
              height: 35,
              padding: 0,
              justifyContent: "flex-start",
              alignItems: "center",
            },
          ]}
        >
          <H5 extend={tableTitleStyle}>{tableTitle}</H5>
        </View>
      </TableCell>
    );
  };

  const tableContext = useContext(TableContext)
  const tableTitle = tableContext.tableTitle
  const onRemove = tableContext.onRemove
  const onDownload = tableContext.onDownload

  return (
    (<TableHead>
      {tableTitle ? (
        <TableRow
          className={"separatedTableWidgetHead"}
          colSpan={4}
          key={"header"}
        >
          <TableTitle/>
        </TableRow>
      ) : null}
      <TableRow className={"separatedTableWidgetHead"}>
        {columns_filtered.map((column, index) => {

          const align = column.align
          ? column.align === "left"
            ? "flex-start"
            : column.align === "right"
            ? "flex-end"
            : "center"
          : column.isTag
          ? "flex-end"
          : "flex-start"

          return column?.label ? (
            <TableCell
              className={"separatedTableWidgetHeadCell"}
              ref={
                index === firstLabelIndex
                  ? firstCellRef
                  : index === secondLabelIndex
                  ? secondCellRef
                  : null
              }
              key={column.key || column.id}
              colSpan={column.colSpan ?? 1}
              align={column.align}
              style={columnHeaderStyle(column)}
              onClick={() => {
                if (isSortable) {
                  handleSortBy(column.id);
                }
              }}
              sx={{
                textAlign: align,
                color: theme.colors.midDarkGrey,
                lineHeight: "13.28px",
                padding: 0,
                maxWidth: column.width ?? column.maxWidth ?? 'auto',
                minWidth: column.width ?? column.minWidth ?? 'auto',
                width: column.width ?? 'auto',
              }}
            >
              <LabelCell
                className={"separatedTableWidgetHeadCell"}              
                justifyContent={align}
              >
                <View
                  style={{
                    ...styles.headerCell,
                    width: index < columns_total - 1 ? "100%" : "auto",
                  }}
                >
                  {column.label}
                  {isSortable ? (
                    <TableSortLabel
                      sx={{padding: 0}}
                      active={sort === column.id || sort === column.id + "desc"}
                      direction={sortOrder === "asc" ? "asc" : "desc"}
                      IconComponent={() =>{ 
                        return (
                          // not index
                          (sort === column.id ? (<Box component="span" sx={visuallyHidden}>
                            {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>) : null)
                        );
                        }
                      }
                    ><img style={{ paddingLeft: 10 }} src={SortableArrowsIcon} /></TableSortLabel>
                  ) : null}
                </View>
              </LabelCell>
            </TableCell>
          ) : null;
        })}
        {onDownload || onEdit || onRemove || hasExpandableData ? (
          <ActionColumnHeader extend={hasExpandableData && { minWidth: 'auto' }} title={""} />
        ) : null} 
      </TableRow>
    </TableHead>)
  );
};
const TableWidgetRow = ({
  row,
  setHoveringRow,
  hoveringRow,
  index,
  onelineOnly,
  showCollapsibleForIds
}) => {
  let columnIndex = 0;  
  const tableContext = useContext(TableContext)
  const selectedRows = tableContext.selectedRows
  const columns_filtered = tableContext.columns_filtered
  const columns_total = tableContext.columns_total
  const onClickRow = tableContext.onClickRow
  const collapsibleDataLoadingStatus = tableContext.collapsibleDataLoadingStatus
  const setShowCollapsibleForIds = tableContext.setShowCollapsibleForIds
  return (
    (<Fragment>
      <TableRow
        className={"separatedTableWidgetRow"}
        style={
          selectedRows && selectedRows.includes(row.id) ? styles.selected : null
        }
        role="checkbox"
        tabIndex={-1}
        key={row?.key || row?.id || row?.name || index}
        sx={{ height: cellHeight }}
        onMouseEnter={() => {
          setHoveringRow(row.id);
        }}
        onMouseLeave={() => {
          setHoveringRow(null);
        }}
      >
        {columns_filtered.map((column, index) => {
          columnIndex = index;
          let value, guard;
          if (column.hasGuard) {
            value =
              row[column.id]?.value || row[column.id]?.value === 0
                ? row[column.id].value
                : "-";
            guard =
              row[column.id]?.guard || row[column.id]?.value === 0
                ? row[column.id].guard
                : null;
          } else
            value =
              row[column.id] || row[column.id] === 0
                ? row[column.id]
                : column.defaultValue ?? "-";
          var warning = column.warning
            ? column.warning(value)
            : null;
          var icon = column.icon ?? null;
          const align = column.align
            ? column.align === "left"
              ? "flex-start"
              : column.align === "right"
              ? "flex-end"
              : "center"
            : column.isTag
            ? "flex-end"
            : "flex-start";
          return (
            (<TableCell
              className={"separatedTableWidgetCell"}
              key={column.id}
              align={column.isTag ? "right" : column.align}
              onClick={(event) => {
                const wasClickOnBtn =
                  event.target.tagName === "BUTTON" ||
                  event.target.tagName === "IMG";
                if (onClickRow && !wasClickOnBtn) {
                  onClickRow(event, row.id, row);
                }
              }}
              sx={[{
                ...styles.bodyCell,
                color:
                  !row[column.id] && column.greyIfNoValue
                    ? theme.colors.disabledText
                    : theme.colors.text,
                maxWidth: column.width ?? column.maxWidth ?? "auto",
                minWidth: column.width ?? column.minWidth ?? "auto",
                width: column.width ?? "auto"
              }, onClickRow ? {
                cursor: "pointer"
              } : {
                cursor: "default"
              }, (onClickRow && hoveringRow === row.id ? { backgroundColor: hoverBackgroundColor } : {}), (typeof column.extend === 'function' ? column.extend(value) : column.extend)]}
            >
              <TableCellWrapper
                align={align}
                index={index}
                columns_total={columns_total}
                tooltip={row[column.id + '_tooltip'] ? row[column.id + '_tooltip'] : typeof(value) === "string" || typeof(value) === "number" ? (column.format ? column.format(value) : value) : null}
                icon={row[column.id + '_icon'] ? row[column.id + '_icon'] : null}
              >
                {column.isTag ? (
                  <Tag
                    extend={{ height: "90%", fontSize: 12 }}
                    textColor={column.textColor}
                    value={value}
                    tagColor={
                      column.tagColor ? column.tagColor(value, guard) : null
                    }
                  />
                ) : column.isCheck && value === true ? (
                  <MdCheck />
                ) : column.isCheck ? (
                  <MdClose />
                ) : column.format && typeof value === "number" ? (
                  column.format(value)
                ) : warning ? (
                  <View
                    extend={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: column.align ? align : "auto",
                    }}
                  >
                    {column.warningPosition !== "afterValue" ? (
                      <WarningIcon
                        size={"medium"}
                        color={typeof column.warningColor === 'function' ? column.warningColor(value) : column.warningColor}
                        extend={{ paddingRight: 7.5 }}
                      />
                    ) : null}
                    <div
                      className={
                        onelineOnly ? "text-ellipsis--1" : "text-ellipsis--2"
                      }
                    >
                      {value}
                    </div>
                    {column.warningPosition === "afterValue" ? (
                      <WarningIcon
                        size={"medium"}
                        color={typeof column.warningColor === 'function' ? column.warningColor(value) : column.warningColor}
                        extend={{ paddingLeft: 7.5 }}
                      />
                    ) : null}
                  </View>
                ) : icon ? (
                  icon
                ) : (
                  <div
                    className={
                      onelineOnly ? "text-ellipsis--1" : "text-ellipsis--2"
                    }
                  >
                    {value}
                  </div>
                )}
                {column.showButton && column.showButton(value) && (
                  <Button
                    noBg
                    noPadding
                    smaller
                    extend={{
                      marginLeft: "15px",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    disabled={column.buttonDisabled(row)}
                    onClick={() =>
                      column.onClickButton && column.onClickButton(row)
                    }
                  >
                    {typeof column.buttonTitle === "function"
                      ? column.buttonTitle(row)
                      : column.buttonTitle}
                  </Button>
                )}

                {/* {
                  column.label === 'Note' ?
                  <Icon icon={'Dropdown'} style={{ marginLeft: -100 }} />
                  : null
                } */}
              </TableCellWrapper>
            </TableCell>)
          );
        })}
        <SpecialCells
          hoveringRow={hoveringRow}
          row={row}
          index={columnIndex}
        />
      </TableRow>
      {row?.isExpandable && showCollapsibleForIds?.includes(row.id) ? (
        <CollapsibleContent
          isOpen={tableContext.collapsibleOpenForIds?.includes(row.id)}
          onClose={() => setShowCollapsibleForIds(showCollapsibleForIds?.filter(showForId => showForId !== row.id)) ?? []}
          data={row.collapsibleData}
          collapsibleComponent={row.collapsibleComponent}
          loadingStatus={(typeof collapsibleDataLoadingStatus === 'function' ? collapsibleDataLoadingStatus(row.id) : collapsibleDataLoadingStatus)}
          columns_total={tableContext.columns_total}
        />
      ) : null}
    </Fragment>)
  );
};

const TableCellWrapper = ({ children, align, onClick, tooltip, icon }) => {
  const content = (  <View
    onClick={onClick}
    extend={{
      padding: cellPadding,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      maxHeight: cellHeight,
      alignItems: "center",
      justifyContent: align,
    }}
  >
    <View style={{ paddingRight: 10 }}>
      {icon}
    </View>
    {children}
  </View>)

  let tooltipPos = "bottom"
  switch (align) {
    case 'flex-start': tooltipPos = "bottom-start"; break;
    case 'flex-end': tooltipPos = "bottom-end"; break;
    default: break;
  }

  return (  
    tooltip ? 
    <CustomTooltip title={tooltip} placement={tooltipPos}>
    {content}
    </CustomTooltip>
  :
    content
  );
};

const SpecialCells = ({
  hoveringRow,
  row,
  index,
}) => {
  let newIndex = index + 1

  const specialCells = []

  const tableContext = useContext(TableContext)
  const onEdit = tableContext.onEdit
  const onRemove = tableContext.onRemove
  const onDownload = tableContext.onDownload
  const onClickRow = tableContext.onClickRow
  const isDownloading = tableContext.isDownloading
  const editableIds = tableContext.editableIds
  const removableIds = tableContext.removableIds
  const collapsibleOpenForIds = tableContext.collapsibleOpenForIds
  const setCollapsibleOpenForIds = tableContext.setCollapsibleOpenForIds

  if (onEdit && (editableIds ? editableIds.includes(row.id) : true)) {
    specialCells.push(<IconButton
      key={newIndex}
      sx={styles.actionButton}
   //   onClick={(event) => onDownload(event, row.id)}
    >
      <MdEdit onClick={(event) => onEdit(event, row.id)} />
    </IconButton>)
    newIndex++
  }
  if (onRemove && (removableIds ? removableIds.includes(row.id) : true)) {
    specialCells.push(<IconButton
      key={newIndex}
      sx={styles.actionButton}
 //     onClick={(event) => onDownload(event, row.id)}
    >
      <MdDelete onClick={(event) => onRemove(event, row.id)} />
    </IconButton>)
    newIndex++
  }
  if (onDownload) {
    const downloadingThisRow =  isDownloading?.length ? isDownloading.some(id => id === row.id) : isDownloading === row.id
    specialCells.push(
      <View key={newIndex} style={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'row', minWidth: 200}}>
        {
          !downloadingThisRow ? <TableLink onClick={(event) => onDownload(event, row.id)}>Download to Excel</TableLink> : null          
        }
        {
          !downloadingThisRow ? <View extend={styles.actionButton}> <Icon icon={'DownloadCloud'}/> </View> : null
        }
        {downloadingThisRow && <BubbleLoadingWidget horizontal/>}
      </View>
    )
    newIndex++
  }
  if (row?.isExpandable) {
    specialCells.push(
      <ExpandButton
        key={newIndex}
        expandableArrowText={row.expandableArrowText}
        isOpen={collapsibleOpenForIds.includes(row.id)}
        onClick={() => {
          const alreadyOpen = collapsibleOpenForIds.includes(row.id)
          if (alreadyOpen) setCollapsibleOpenForIds(collapsibleOpenForIds.filter(o => o !== row.id))
          else setCollapsibleOpenForIds([...collapsibleOpenForIds, row.id])
    
          if (row?.onExpand) {
            row.onExpand()
            
            // test
            tableContext.setShowCollapsibleForIds([
              ...tableContext.showCollapsibleForIds,
              row.id
            ])
          }
        }}
      />
    )
    newIndex++
  }
  return (
    (onEdit || onRemove || onDownload || row?.isExpandable) ? (
      <TableCell
        className={"separatedTableWidgetCell"}
        key={"button"}
        style={{ ...styles.bodyCell, ...styles.action }}
        sx={
          onClickRow && hoveringRow === row.id
            ? { backgroundColor: hoverBackgroundColor }
            : {}
        }
        onClick={(event) => {
          const wasClickOnBtn =
            event.target.tagName === "BUTTON" ||
            event.target.tagName === "IMG";
          if (onClickRow && !wasClickOnBtn) {
            onClickRow(event, row.id, row);
          }
        }}
      >
        { specialCells?.length ? specialCells : null }
      </TableCell>
  ) : null)
}

export const ExpandButton = ({ expandableArrowText, onClick, isOpen }) => {
  return (
    <TableCellWrapper
      align={"right"}
      onClick={onClick}>
      <View style={{height: '100%', width: '100%', display:'flex', flexDirection: "row", cursor: 'pointer', justifyContent: 'flex-end'}}>
        <Fragment>
          <View style={{color: theme.colors.text, fontSize: '16px'}}>{expandableArrowText?? ''}</View> 
          <img style={{ paddingLeft: 10, maxHeight: '100%' }} src={isOpen ? ArrowUpIcon : ArrowDownIcon} /> 
        </Fragment>
      </View>
    </TableCellWrapper>
  )
}

const LabelCell = ({ children, justifyContent = 'space-between' }) => {
  return (
    <View extend={{
      display: 'flex',
      flexDirection: 'row',
      height: headerCellHeight,
      maxWidth: '100%',
      paddingTop: '5px',
      paddingBottom: '5px',
      justifyContent: justifyContent
    }}>
      <View extend={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: justifyContent,
        maxHeight: cellHeight,
        textAlign: justifyContent === 'flex-start' ? 'left' : justifyContent === 'flex-end' ? 'right' : 'center',
      }}>
        {children}
      </View>
    </View>
  )
}

export const CollapsibleContent = ({ isOpen, onClose, data, loadingStatus, collapsibleComponent, columns_total }) => {
  const tableContext = useContext(TableContext)

  const [openAnimation, setOpenAnimation] = useState(false)

  const showSortArrows = tableContext.showInnerSortArrows

  useEffect(() => {
    isOpen && setOpenAnimation(true)

    return () => {
      setOpenAnimation(false)
    }
  }, [])

  return (
    <TableRow className={"separatedTableWidgetRow"} sx={{ margin: 0 }}>
      <TableCell
        padding='none'
        style={{...styles.bodyCell, paddingTop: 0, width: '100%', borderBottom: "none" }}
        sx={{ margin: 0 }}
        colSpan={columns_total}
      >
        <Collapse style={{margin: 0}} onExited={() => {onClose()}} in={isOpen && openAnimation} timeout="auto" unmountOnExit sx={{ marginTop: 0, marginBottom: 0  }}>
          <Box sx={{ margin: 0 }}>
            {collapsibleComponent ? collapsibleComponent : <SimpleTable
              data={data}
              prepareData={tableContext.prepareCollapsibleData}
              isLoading={loadingStatus === 'loading'}
              loadingFailed={loadingStatus === 'failed'}
              columns={tableContext.collapsibleLogColumns}
              defaultSortIndex={tableContext.collapsibleDefaultSortIndex}
              defaultSortOrder={tableContext.collapsibleDefaultSortOrder}
              onTableEntryClick={tableContext.collapsibleOnRowClick}
              showSortArrows={showSortArrows}
            />}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

const styles = {
  error: {
    border: `1px solid ${theme.colors.error}`
  },
  selected: {
    backgroundColor: theme.colors.primaryLight
  },
  bodyCell: {
    borderBottom: 'none',
    padding: 0,
    overflow: 'clip',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '22px',
    color: theme.colors.tableText,
  },
  action: {
    height: cellHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    color: theme.colors.grey,
    fontSize: 18,
    borderBottom: 'none',
  },
  actionButton: {
    marginLeft: '5px',
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    
    zIndex: 99, // so click on button does not register as click on row
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  separated_table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 7.5px',
    marginTop: '-5px' // don't do the spacing thing above the table

    // TODO: should find a better solution for this
   // marginBottom: '-10px', // ugly fix to reduce spacing between tables - the above is applied to the top and bottom of both otherwise, leading to double the distance between them 
  }
}

export default TableWidgetSeparated;