import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { A, P } from '../../atoms/text'
import View from '../../atoms/view'
import { dateTimeToString } from '../../../utils/dateTimeUtils'
import { DateTime } from 'luxon'

const capitalFirstLetters = str => {
  return str.trim().split(' ').map(split =>
    split[0].toUpperCase() + split.substr(1)).join(' ')
  }

const Breadcrumbs = ({ wordReplacements, keepQueryString, rootUrl }) => {
  keepQueryString = keepQueryString ?? false
  const navigate = useNavigate()
  const location = useLocation()
  const onClickCrumb = index =>
   { 
    const browse = {
      pathname: `/${path.slice(0, index + 1).join('/')}`
    }
    if ( index == 0 && rootUrl){
      browse.pathname = rootUrl
    }
    if ( keepQueryString && index > 0){
      browse.search = location.search
    }

    navigate(browse.pathname)
    }

  const replaceWords = text => {
    const regex = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$')
    if (regex.test(text)) return dateTimeToString(DateTime.fromISO(text), 'day-and-date')
    wordReplacements?.forEach(({word, replacement}) =>
      text = text.replace(word, replacement)
    )

    if(text.indexOf('-' !== -1)) text = text.split('-')[0]
    if(text.indexOf('_' !== -1)) text = text.split('_').pop()
    return text
  }

  const [path, setPath] = useState(location.pathname.split('/').filter(link => link.length)) // Ok?
  const [queue, setQueue] = useState([])
  const [queueIndex, setQueueIndex] = useState(path.length - 1)

  useEffect(() => {
    if (!queue.length) return
    setQueue(path.map(link => replaceWords(link).replace('_', ' ')))
  }, [wordReplacements])

  useEffect(() => {
    const tempPath = navigate.pathname.split('/').filter(link => link.length)
    if (tempPath.length > path.length || !tempPath.every((link, index) => link === path[index]))
      setPath(tempPath)
    else if (tempPath.length < path.length || tempPath[tempPath.length - 1] === path[path.length - 1])
      setQueueIndex(tempPath.length - 1)
  }, [location])

  useEffect(() => {
    setQueue(path.map(link => replaceWords(link).replace('_', ' ')))
    setQueueIndex(path.length - 1)
  }, [path])

  if (queue.length < 1) return null

  return (
    <View extend={ styles.container }>
      { queue.map((link, index) =>
        <View key={ index } extend={[styles.breadcrumb, index > queueIndex && styles.nonActive]}>
          <A onClick={ () => onClickCrumb(index) }>{ capitalFirstLetters(link) }</A>
          { index < queue.length - 1 && <P>{ '>' }</P> }
        </View>
      )}
    </View>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'left',
    marginBottom: '-15px'
  },
  breadcrumb: ({ theme: { colors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    '> a': {
      cursor: 'pointer',
      margin: '0px 10px 0px 10px',
      color: colors.black,
      fontFamily: 'Inter',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }),
  nonActive: ({ theme: { colors } }) => ({
    '> a': {
      color: colors.disabledGrey
    }
  })
}

export default Breadcrumbs