import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDrivingLogsColumns, log_summary_columns_driver, log_summary_columns_vehicle } from './log_summary_columns'
import { useAuth0 } from '@auth0/auth0-react';
import { downloadDrivingLogs } from '../../../../state/actions/vehicles'
import ReportPage from '../../reportPage.v2';
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers';
import { GroupingOptionSelector } from '../../tollPassages/summary/groupingOptionSelector';
import { DateTime } from 'luxon';
import { dateTimeToEpoch, dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils';
import PurposeIcon_Private from '@modul-connect/shared/img/purpose_private.svg'
import PurposeIcon_Business from '@modul-connect/shared/img/purpose_business.svg'
import Weight_Black from '@modul-connect/shared/img/weight_map.svg'
import NoteExists from '@modul-connect/shared/img/checkmark.svg'
import NoNote from '@modul-connect/shared/img/ep_warning-filled.svg'
import Mileage from '@modul-connect/shared/img/mileage_unit.svg'
import {ServiceType} from '@modul-connect/shared/utils/services'
import { convertToSelectedUnit, formatAddress } from '../../../../utils/unitConverter';
import { RouteMarker } from '../../../dashboard/sidebars/details/components/routeMarker';
import { GroupingContext, groupingOptions } from '../drivingLogPage';
import SingleDrivingLogDetails from '../singleDrivingLogDetails.js/singleDrivingLogDetails';
import { getServiceList } from '../../../../utils/ServiceList';

// TODO: fix padding when no data. Like we did for asset trackers.

const prepareData = (data, groupBy, fetchDrivingLogDetails, detailsLoading, showWeight) => {
  if (!data || !data?.length) return data

  let lastDay = null

  const toReturn = []

  let routeNumber = 0
  data.forEach((log, index) => {
    const startDT = DateTime.fromISO(log.startTime)
    if (startDT.day + '_' + startDT.month + '_' + startDT.year !== lastDay) {
      toReturn.push({ isDivider: true, text: dateTimeToString(startDT, 'day-and-date') })
      lastDay = startDT.day + '_' + startDT.month + '_' + startDT.year
      routeNumber = 0
    }

    const endDT = DateTime.fromISO(log.endTime)

    routeNumber++

    const row = []
    row.push({sortValue: routeNumber, component: <RouteMarker color={'#313139'} textColor={'#E4E4E4'} large text={routeNumber} />})
    row.push({value1: dateTimeToString(startDT, 'time-only'), value2: dateTimeToString(endDT, 'time-only')})
    row.push({value1: formatAddress(log.startAddress), value2: !log?.stopAdress ? 'ongoing' : formatAddress(log.stopAdress)})
    if (showWeight) row.push({value: weightText(log.startWeight?.grossAverage, log.startWeight?.grossMax), icon: Weight_Black})
    row.push({value: log.purpose?.toLowerCase() === 'work' ?  'Business' : 'Private', icon: log.purpose?.toLowerCase() === 'work' ?  PurposeIcon_Business : PurposeIcon_Private})
    row.push({value: convertToSelectedUnit(log.distance, 1, 'distance'), sortValue: log.distance})
    row.push({value: 'Note', icon: log?.note ? NoteExists : NoNote })
    row.push(groupBy === groupingOptions.byDriver ? {value: log.vehicleName} : {value: log.driver ?? "Unknown driver"})
    row.push({value: !log?.endTime ? 'ongoing' : convertToSelectedUnit(log.endMileage,0, 'distance'), icon: Mileage})
    row.push({ collapsibleComponent: 
      <SingleDrivingLogDetails 
        loading={detailsLoading}
        log={log}
        logId={log.boxId + '_' + dateTimeToEpoch(startDT)}
      />,
      onExpand: () => fetchDrivingLogDetails(startDT, endDT, log.boxId, log.driverId)
    })

    toReturn.push(row)
  })

  let i = 0
  let newToReturn = []
  while(toReturn.length){
    let curr = toReturn.pop()
    if (curr?.isDivider) {
      i = 0
    } else {
      i ++
      curr[0] = { sortValue: i, component: <RouteMarker color={'#313139'} textColor={'#E4E4E4'} large text={i} /> }
    }
    newToReturn.push(curr)
  }
  return newToReturn.reverse()
}

const weightText = (grossWeight, grossMax) => {
  if(!(grossWeight || grossWeight === 0))
    return '- kg / - %'

  if(!grossMax)
    return `${grossWeight} kg / - %`

  const percent = Math.round((grossWeight / grossMax) * 100)
  return `${grossWeight} kg / ${percent}%`
}

const DrivingLogTable = ({
  themes,
  loading,
  driving_log_summary,
  driving_logs,
  startDate,
  endDate,
  onTimeframePicked,
  downloadDrivingLogs,
  downloading,
  setGroupBy,
  disabled,
  onRowSelected,
  fetchDrivingLogDetails,
  isLoadingDetails,
  subtrees
}) => {
  const groupBy = React.useContext(GroupingContext);
  const services = getServiceList({subtrees})

  const showWeight = services.hasService(ServiceType.DrivingLogsWithStartWeight)

  const { getAccessTokenSilently } = useAuth0()

  const downloadDrivingLogsWithAccessToken = async (id, groupBy) => {
    const groupByDriver =  (groupBy === groupingOptions.byDriver)
    const accessToken = await getAccessTokenSilently()
    const driverId = groupByDriver ? (!id ? 'none' : id) : null
    const boxId = !groupByDriver ? id : null
    downloadDrivingLogs(accessToken, boxId, driverId, null, startDate, endDate, groupBy ?? groupingOptions.byVehicle)
  }

  const isDownloading = downloading?.driving_logs?.status === "working" ? (
    (groupBy === groupingOptions.byDriver) ? downloading?.driving_logs?.driverIds : downloading?.driving_logs?.boxIds
    ) : null

  const [tableData, setTableData] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const [loadingFailed, setLoadingFailed] = useState(false)

  useEffect(() => {
    setIsLoading(loading?.fetchDrivingLogSummaries === 'loading' || loading?.fetchVehiclesData === 'loading')
    setLoadingFailed(loading?.fetchDrivingLogSummaries === 'failed' || loading?.fetchVehiclesData === 'failed')
  }, [loading])

  useEffect(() => {
    if (!driving_log_summary)
      return

    const summaries_prepared = prepareSummariesForTable(driving_log_summary, driving_logs)
    setTableData(summaries_prepared)
  }, [driving_log_summary, driving_logs])

const prepareSummariesForTable = useCallback(
  (summaries, driving_logs) => {
    if (!summaries || !summaries.length) return [];

    const prepared_data = summaries.map((entry, index) => {
      const relevantLogs = groupBy === groupingOptions.byDriver ? 
        driving_logs?.[entry.driverId]?.filter(log => log.driverId === entry.driverId || (!log.driverId && entry.driverId === 'unknown'))
        : driving_logs?.[entry.boxId]?.filter(log => log.boxId === entry.boxId)

      return {
        id: groupBy === groupingOptions.byDriver ? entry.driverId ?? 'unknown' : entry.boxId,
        boxId: entry.boxId,
        driverId: entry.driverId,
        name: entry.name ?? ((groupBy === groupingOptions.byDriver) ? "Unknown driver" : entry.name),
        businessKilometers: entry.businessKilometers,
        privateKilometers: entry.privateKilometers,
        businessRoutesWithoutNotes: entry.businessRoutesWithoutNotes,
        numberOfRoutes: entry.numberOfRoutes,

        isExpandable: true,
        onExpand: () => {
          if (groupBy === groupingOptions.byDriver) onRowSelected(null, entry.driverId ?? 'unknown')
          else onRowSelected(entry.boxId, null)
          
        },
        collapsibleData: relevantLogs,
      };
    });

    return prepared_data;
  },
  [groupBy, driving_logs]
);

  return (
    <ReportPage
      title={"Routes"}
      headerSection={null}
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom
      ]}
      noDataMessage={"There are no driving logs for the selected time period."}
      isLoading={isLoading}
      fetchingDataFailed={loadingFailed}
      disabled={disabled}
      groupingOptionSelector={
        <GroupingOptionSelector
          onChange={(selection) => {
            setGroupBy(selection);
          }}
          options={groupingOptions}
          value={groupBy}
          disabled={isLoading}
        />
      }
      startDate={startDate}
      endDate={endDate}
      onTimeframePicked={onTimeframePicked}
      noSearch
      tables={
        <TableWidgetSeparated
          data={tableData}
          onelineOnly
          loadingStatus={
            loading.fetchDrivingLogSummaries === "failed" ||
            loading.fetchVehiclesData === "failed"
              ? "failed"
              : (loading.fetchDrivingLogSummaries === "loading" || loading.fetchDrivingLogSummaries === undefined) || // undefined when it hasn't ever been set to anything yet
                (loading.fetchVehiclesData === "loading" || loading.fetchVehiclesData === undefined)
              ? "loading"
              : null
          }
          columns={groupBy === groupingOptions.byDriver ? log_summary_columns_driver : log_summary_columns_vehicle}
          themes={themes}
          isSortable
          pagination={false}
          collapsibleLogColumns={getDrivingLogsColumns(groupBy, showWeight)}
          collapsibleDataLoadingStatus={(id) => {return loading?.fetchDrivingLogs[id]}}
          prepareCollapsibleData={(data) => {return prepareData(data, groupBy, fetchDrivingLogDetails, isLoadingDetails, showWeight)}}
          collapsibleDefaultSortOrder={'desc'}
          isDownloading={isDownloading}
          onDownload={(_, id) =>
            downloadDrivingLogsWithAccessToken(id, groupBy)
          }
        />
      }
    />
  );
}


const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  downloadDrivingLogs: (accessToken, boxId, driverId, deviceType, start, end, groupBy) => dispatch(downloadDrivingLogs(accessToken, boxId, driverId, deviceType, start, end, groupBy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrivingLogTable);